import React, { useEffect, useRef, useState } from 'react';
import { message } from 'antd';
import FAQ from '../FAQ';
import axios from 'axios';
import confetti from 'canvas-confetti';
import {
  TextField,
  Button,
  Snackbar
} from '@mui/material';
import { CloseIcon } from '../CompareImage/Icons';
import MoneyBackGuarantee from '../MoneyBackGuarantee';
import Testimonial from '../Testimonial';

const APP_URL = process.env.REACT_APP_APP_URL;
const CHECKOUT_30CREDITS = '99,000';
const CHECKOUT_100CREDITS = '99,000';
const CHECKOUT_200CREDITS = '499,000';
const CHECKOUT_1000CREDITS = '699,000';

const CHECKOUT_PRO_MONTHLY_URL = `${process.env.REACT_APP_PAYMENT_CHECKOUT_URL}/${process.env.REACT_APP_PAYMENT_PRO_MONTHLY}`;
const CHECKOUT_PRO_YEARLY_URL = `${process.env.REACT_APP_PAYMENT_CHECKOUT_URL}/${process.env.REACT_APP_PAYMENT_PRO_YEARLY}`;
const CHECKOUT_STARTER_URL = `${process.env.REACT_APP_PAYMENT_CHECKOUT_URL}/${process.env.REACT_APP_PAYMENT_STARTER}`;

const PAYMENT_METHOD = {
  VIETCOMBANK: 'VCB',
  MOMO: 'MOMO',
};

const CopyToClipboardButton = ({refLink}) => {
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(true)
    navigator.clipboard.writeText(refLink)
  }

  function shortenLink(link, maxLength=26) {
    if (link.length > maxLength) {
      return link.substring(0, maxLength) + '...';
    }
    return link;
  }
  
  return (
      <>
        <Button onClick={handleClick} style={{textTransform: 'lowercase', color: '#c6c6c6'}}>{shortenLink(refLink)}</Button>
        <Snackbar
          open={open}
          onClose={() => setOpen(false)}
          autoHideDuration={2000}
          message="Đã copy đường dẫn"
        />
      </>
  )
}

const NewPricing = ({ userData, firebase, onShowPricing, onFetchUser, t, currentLanguage }) => {
  const popupRef = useRef(null);
  const [refLink, setRefLink] = useState('');
  const [affCode, setAffCode] = useState('');
  const [showPopup, setShowPopup] = useState(true);
  const [showSub, setShowSub] = useState(true);
  const [isExpanded, setIsExpanded] = useState([true, false, false]); // Initial states for each component
  const [showPaymentSelection, setShowPaymentSelection] = useState(false);
  const [price, setPrice] = useState('0');
  const [credits, setCredits] = useState(0);
  const [showPurchasingScreen, setShowPurchasingScreen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [formError, setFormError] = useState({
    phoneNumber: false,
  });

  useEffect(() => {
    // Retrieve the stored referralCode from local storage
    const storedAffCode = localStorage.getItem('affCode');

    if (storedAffCode) {
      setAffCode(storedAffCode)
    }

    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (popupRef.current && event.target.contains(popupRef.current)) {
        onShowPricing(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    let interval;
    // firebase
    //   .getTransactions(firebase.auth.currentUser.uid)
    //   .then(result => {
    //     const transactions = result.docs.map(doc => ({
    //       id: doc.id,
    //       ...doc.data(),
    //     }));

    //     if (transactions.length > 0) {
    //       if (!transactions[0].isActivated) {
    //         setPrice(transactions[0].price)
    //         setShowSub(false);
    //         setShowPaymentSelection(false);
    //         setShowPurchasingScreen(true);
    //         hearts();
    //         let counter = 0;
    //         interval = setInterval(() => {
    //           counter++; // Increment counter

    //           // Do something every 10 seconds
    //           firebase
    //             .getTransactions(firebase.auth.currentUser.uid)
    //             .then(result => {
    //               const transactions = result.docs.map(doc => ({
    //                 id: doc.id,
    //                 ...doc.data(),
    //               }));

    //               if (transactions.length > 0 && transactions[0].isActivated) {
    //                 confetti({
    //                   origin: { y: 0.5 },
    //                   spread: 400,
    //                   particleCount: 100,
    //                   origin: { y: 0.4 },
    //                 });
    //                 onShowPricing(false);
    //                 onFetchUser();
    //                 message.success(t('message.success.activated'));
    //                 clearInterval(interval);
    //               }
    //             })
    //             .catch(err => {
    //               message.error(err.message);
    //             });

    //           if (counter === 6) {
    //             clearInterval(interval); // Clear the interval after 10 iterations
    //           }
    //         }, 10000); // 10000 milliseconds = 10 seconds
    //       } else {
    //         setShowSub(true);
    //       }
    //     } else {
    //       setShowSub(true);
    //     }
    //   })
    //   .catch(err => {
    //     message.error(err.message);
    //   });

    //   firebase
    //   .getRefLink(firebase.auth.currentUser.uid)
    //   .then(result => {
        
    //     const refLinkArray = result.docs.map(doc => ({
    //       id: doc.id,
    //       ...doc.data(),
    //     }));
  
    //     if (refLinkArray.length > 0) {
    //       setRefLink(refLinkArray[0].refLink);
    //   }})
    //   .catch(error => {
    //     console.log(error);
    //   });

    return () => {
      document.removeEventListener('click', handleClickOutside);
      // clearInterval(interval);
      // console.log('Interval cleared');
    };
  }, [popupRef]);

  const handleToggleClick = index => {
    const newExpandedState = isExpanded.map((_, i) => i === index);
    setIsExpanded(newExpandedState); // Update the state array with the new state
  };

  function onSub(event, CHECKOUT_ITEM) {
    event.stopPropagation();
    switch (CHECKOUT_ITEM) {
      case CHECKOUT_100CREDITS:
        setPrice(CHECKOUT_100CREDITS);
        setCredits(100);
        break;
      case CHECKOUT_1000CREDITS:
        setPrice(CHECKOUT_1000CREDITS);
        setCredits(1000);
        break;
      default:
        break;
    }

    setShowSub(false);
    setShowPaymentSelection(true);
  }

  function showSupport() {
    window.HubSpotConversations.widget.open();
  }

  function onConfirm(event, paymentName) {
    event.stopPropagation();

    // if (phoneNumber === "" || phoneNumber === null || phoneNumber === undefined) {
    //   alert("Vui lòng nhập số điện thoại giúp chúng tôi liên hệ trong quá trình thanh toán khi cần thiết");
    //   return;
    // }
    // showConfirm();
    setShowSub(false);
    setShowPaymentSelection(false);
    setShowPurchasingScreen(true);
    // setTimeout(() => {
    //   setShowPurchasingScreen(true);
    // }, 2000);

    const requestData = {
      data: {
        uid: firebase.auth.currentUser.uid,
        email: firebase.auth.currentUser.email,
        credits: credits,
        paymentName: paymentName,
        price: price,
        content: userData.monthlyBasicCode,
        // phoneNumber: phoneNumber
      },
    };

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${firebase.auth.currentUser.accessToken}`,
    };

    axios
      .post(`${APP_URL}/payment`, requestData, { headers })
      .then(response => {
        let counter = 0;
        const interval = setInterval(() => {
          counter++; // Increment counter

          firebase
            .getTransactions(firebase.auth.currentUser.uid)
            .then(result => {
              const transactions = result.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
              }));

              if (transactions.length > 0 && transactions[0].isActivated) {
                confetti({
                  origin: { y: 0.5 },
                  spread: 400,
                  particleCount: 100,
                  origin: { y: 0.4 },
                });
                onShowPricing(false);
                onFetchUser();
                message.success(t('message.success.activated'));
                clearInterval(interval);
              }
            })
            .catch(err => {
              message.error(err.message);
            });

          if (counter === 30) {
            clearInterval(interval); // Clear the interval after 10 iterations
          }
        }, 10000);
      })
      .catch(error => {
        message.error(error);
      });
  }

  function hearts() {
    const elements = document.querySelectorAll('.thanks-mess .hearts');
    elements.forEach(element => {
      const heartcount = (element.offsetWidth / 50) * 2;
      for (let i = 0; i <= heartcount; i++) {
        const size = Math.floor(Math.random() * (12 - 6 + 1)) + 6;
        const top = Math.floor(Math.random() * (80 - 20 + 1)) + 20;
        const left = Math.floor(Math.random() * (95 - 0 + 1)) + 0;
        const delay = Math.floor(Math.random() * (30 - 0 + 1)) + 0;
        const particle = document.createElement('span');
        particle.classList.add('particle');
        particle.style.top = `${top}%`;
        particle.style.left = `${left}%`;
        particle.style.width = `${size}px`;
        particle.style.height = `${size}px`;
        particle.style.animationDelay = `${delay / 10}s`;
        element.appendChild(particle);
      }
    });
  }

  function renderLove() {
    hearts();
  }

  function handleChangePhoneNumber(event) {
    setFormError(prevData => ({
      ...prevData,
      phoneNumber: false,
    }));
    setPhoneNumber(event.target.value);
  }

  return (
    <>
      {showPopup && (
        <div className="pricing-showbox inset-0 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0">
          <section id="pricing-container" className="shadow-lg" ref={popupRef}>
            {showSub && (
              <>
                <div>
                  <div id="pricing-switch">
                    <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                      <span>{t('landing.pricingLabel')}</span>
                    </div>
                    <div className="x-price-card--onboarding-fee toggle-price-content font-bold text-white-500 ">
                      {t('pricing.credit.title')}
                    </div>
                    <div className="mt-2 mb-4">
                      <MoneyBackGuarantee/>
                    </div>
                  </div>
                  <div id="pricing-cards">
                    <div className="price-card">
                      {/* <div className="price-card--hero-text">{t('pricing.popular')}</div> */}
                      <div className="price-card--header">
                        <h4>
                          {t('pricing.first.title')}
                        </h4>
                      </div>
                      <div className="price-card--price">
                        <div className="price-card--price-text">
                          <div className="price-card--price-number toggle-price-content odometer">
                            {t('pricing.first.price')}
                          </div>
                        </div>
                        <div className="price-card--price-conditions">
                          <div className="x-price-card--onboarding-fee toggle-price-content">
                            {/* <span style={{ marginLeft: '4px', color: '#72ff9a' }}> */}
                            {t('pricing.first.priceDetail')}
                            {/* </span> */}
                          </div>
                        </div>
                      </div>
                      <div className="price-card--cta">
                        <a
                          className="price-card--cta--button upgrade-button"
                          href={`${CHECKOUT_STARTER_URL}?checkout[email]=${userData.email}&aff=${affCode}&checkout[custom][email]=${userData.email}`}
                        >
                          {t('button.subscribe')}
                          <span style={{ marginLeft: '4px' }}>↗</span>
                        </a>
                      </div>
                      <div className="price-card--features">
                        <ul className="price-card--features--list">
                          <li className="price-card--features--item font-bold text-white">
                            <span className="green-check">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="#ffc337"><path d="M17.333 4v9.333h8L14.666 28v-9.333H6.667z"/></svg>
                            </span>
                            {t('pricing.first.credit')}
                          </li>
                          <li className="price-card--features--item font-bold text-white">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.first.two')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.first.five')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.first.fourteen')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.first.one')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.first.nine')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.first.four')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.first.seven')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.first.eight')}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="x-price-card price-card--hero">
                      <div className="price-card--hero-text">
                        {t('pricing.popular')}
                        <svg version="1.1" fill="rgb(251, 190, 40)" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="14px" height="14px" viewBox="0 0 51.2 51.2" xmlSpace="preserve" style={{ marginLeft: "4px", marginTop: "2px"}}>
                          <g>
                            <path className="st0" d="M51.2 15.247c0 -2.147 -1.742 -3.888 -3.889 -3.888 -2.148 0 -3.891 1.741 -3.891 3.888 0 1.05 0.417 2.002 1.094 2.7 -2.645 5.478 -7.702 7.391 -11.62 5.659 -3.491 -1.544 -4.778 -5.956 -5.214 -9.375 1.423 -0.748 2.394 -2.239 2.394 -3.959C30.073 7.802 28.072 5.8 25.6 5.8c-2.47 0 -4.473 2.002 -4.473 4.472 0 1.72 0.97 3.211 2.394 3.959 -0.436 3.419 -1.723 7.831 -5.214 9.375 -3.919 1.731 -8.975 -0.181 -11.62 -5.659 0.677 -0.698 1.094 -1.65 1.094 -2.7 0 -2.147 -1.742 -3.888 -3.889 -3.888C1.742 11.359 0 13.1 0 15.247c0 1.978 1.478 3.608 3.388 3.855l4.483 16.408h35.459l4.483 -16.408C49.723 18.855 51.2 17.225 51.2 15.247z"/>
                            <path className="st0" d="M45.502 42.506c0 1.598 -1.295 2.894 -2.895 2.894H8.594C6.995 45.4 5.7 44.105 5.7 42.506v-0.241c0 -1.6 1.295 -2.895 2.894 -2.895h34.013c1.6 0 2.895 1.295 2.895 2.895v0.241z"/>
                          </g>
                        </svg>
                      </div>
                      <div className="price-card--header">
                        <h4>
                          {t('pricing.second.title')}
                          <span className="price-card--price-number-after" style={{ marginLeft: '4px', color: '#72ff9a' }}>
                            {t('pricing.second.subTitle')}
                          </span>
                        </h4>
                      </div>
                      <div className="price-card--price">
                        <div className="price-card--price-text">
                          <div className="price-card--price-number toggle-price-content odometer">
                            {t('pricing.second.price')}
                          </div>
                        </div>
                        <div className="price-card--price-conditions">
                          <div className="x-price-card--onboarding-fee toggle-price-content">
                              {t('pricing.second.priceDetail')}
                          </div>
                        </div>
                      </div>
                      <div className="price-card--cta">
                        <a
                          className="price-card--cta--button upgrade-button"
                          href={`${CHECKOUT_PRO_MONTHLY_URL}?checkout[email]=${userData.email}&aff=${affCode}&checkout[custom][email]=${userData.email}`}
                        >
                          {t('pricing.second.subscribed')}
                          <span style={{ marginLeft: '4px' }}>↗</span>
                        </a>
                      </div>
                      <div className="price-card--features">
                        <ul className="price-card--features--list">
                          <li className="price-card--features--item font-bold text-white">
                            <span className="green-check">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="#ffc337"><path d="M17.333 4v9.333h8L14.666 28v-9.333H6.667z"/></svg>
                            </span>
                            {t('pricing.second.credit')}
                          </li>
                          <li className="price-card--features--item font-bold text-white">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#72ff9a"
                                />
                              </svg>
                            </span>
                            {t('pricing.second.two')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#72ff9a"
                                />
                              </svg>
                            </span>
                            {t('pricing.second.five')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#72ff9a"
                                />
                              </svg>
                            </span>
                            {t('pricing.second.fourteen')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#72ff9a"
                                />
                              </svg>
                            </span>
                            {t('pricing.second.one')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#72ff9a"
                                />
                              </svg>
                            </span>
                            {t('pricing.second.nine')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#72ff9a"
                                />
                              </svg>
                            </span>
                            {t('pricing.second.four')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#72ff9a"
                                />
                              </svg>
                            </span>
                            {t('pricing.second.seven')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#72ff9a"
                                />
                              </svg>
                            </span>
                            {t('pricing.second.eight')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#72ff9a"
                                />
                              </svg>
                            </span>
                            {t('pricing.second.ten')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#72ff9a"
                                />
                              </svg>
                            </span>
                            {t('pricing.second.eleven')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#72ff9a"
                                />
                              </svg>
                            </span>
                            {t('pricing.second.six')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#72ff9a"
                                />
                              </svg>
                            </span>
                            {t('pricing.second.three')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#72ff9a"
                                />
                              </svg>
                            </span>
                            {t('pricing.second.twelve')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#72ff9a"
                                />
                              </svg>
                            </span>
                            {t('pricing.second.thirteen')}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="price-card">
                      {/* <div className="price-card--hero-text">{t('pricing.popular')}</div> */}
                      <div className="price-card--header">
                        <h4>
                          {t('pricing.third.title')}
                          <span className="price-card--price-number-after" style={{ marginLeft: '4px', color: '#72ff9a' }}>
                            {t('pricing.third.subTitle')}
                          </span>
                        </h4>
                      </div>
                      <div className="price-card--price">
                        <div className="price-card--price-text">
                          <div className="price-card--price-number toggle-price-content odometer">
                            {t('pricing.third.price')}
                          </div>
                        </div>
                        <div className="price-card--price-conditions">
                          <div className="x-price-card--onboarding-fee toggle-price-content">
                            {t('pricing.third.priceDetail')}
                          </div>
                        </div>
                      </div>
                      <div className="price-card--cta">
                        <a
                          className="price-card--cta--button upgrade-button"
                          href={`${CHECKOUT_PRO_YEARLY_URL}?checkout[email]=${userData.email}&aff=${affCode}&checkout[custom][email]=${userData.email}`}
                        >
                          {t('pricing.second.subscribed')}
                          <span style={{ marginLeft: '4px' }}>↗</span>
                        </a>
                      </div>
                      <div className="price-card--features">
                        <ul className="price-card--features--list">
                          <li className="price-card--features--item font-bold text-white">
                            <span className="green-check">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="#ffc337"><path d="M17.333 4v9.333h8L14.666 28v-9.333H6.667z"/></svg>
                            </span>
                            {t('pricing.third.credit')}
                          </li>
                          <li className="price-card--features--item font-bold text-white">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.third.two')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.third.five')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.third.fourteen')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.third.one')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.third.nine')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.third.four')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.third.seven')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.third.eight')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.third.ten')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.third.eleven')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.third.six')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.third.three')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.third.twelve')}
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            {t('pricing.third.thirteen')}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative mx-auto mt-6 mb-6 max-w-lg space-y-4">
                  <div className="flex items-center gap-3 justify-content-center">
                    <div className="flex -space-x-3 overflow-hidden">
                      <img
                        src="/images/customer13.webp"
                        alt=""
                        className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                      />{' '}
                      <img
                        style={{ marginLeft: '-12px' }}
                        src="/images/customer16.webp"
                        alt=""
                        className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                      />{' '}
                      <img
                        style={{ marginLeft: '-12px' }}
                        src="/images/customer17.webp"
                        alt=""
                        className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                      />{' '}
                      <img
                        style={{ marginLeft: '-12px' }}
                        src="/images/customer11.webp"
                        alt=""
                        className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                      />{' '}
                      <img
                        style={{ marginLeft: '-12px' }}
                        src="/images/customer19.webp"
                        alt=""
                        className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                      />
                    </div>{' '}
                    <div className="flex" style={{ color: '#facc15' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>{' '}
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>{' '}
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>{' '}
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>{' '}
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    </div>
                  </div>{' '}
                  <p className="text-sm text-gray-500 flex justify-content-center">{t('landing.loveBy')}</p>
                </div>
                <Testimonial t={t}/>
                <div className='mb-10'/>
              </>
            )}
            {/* {showPaymentSelection && (
              <div className="banks-container">
                <div className="price-card">
                  <div className="price-card--header">
                    <h4>Chọn phương thức thanh toán</h4>
                    <span style={{color: "#e2e2e2", fontSize: "11px"}}>Không phải đăng nhập bất kì đâu rất an toàn</span>
                  </div>
                  <div className="modal-content" role="document">
                    <div className="modal-body">
                      <div id="accordion-controlled-example" role="tablist" className="panel-group">
                        <div style={{ cursor: 'pointer' }} className="panel panel-default" onClick={() => handleToggleClick(0)}>
                          <div className="panel-heading">
                            <div className="panel-title">
                              <a>
                                <div className="bank-option">
                                  <img
                                    src="images/bank.webp"
                                    className="option-icon img-rounded"
                                  />
                                  <div className="option-title">
                                    <p>
                                      <span>Chuyển khoản qua ngân hàng</span>
                                    </p>
                                    <p>
                                      <span>Hỗ trợ từ 8:30 đến 22:00 hàng ngày</span>
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div className={`panel-collapse collapse ${isExpanded[0] ? 'in' : ''}`}>
                            <div className="option-content panel-body">
                              <div>
                                <p>Để hoàn tất, bạn vui lòng chuyển khoản theo nội dung sau: </p>
                                <div style={{marginLeft: "15px"}}>
                                <p>
                                  Vietcombank (VCB): <span className="high-light-color">0441000810601</span>
                                </p>
                                <p>
                                  Chủ tài khoản: <span className="high-light-color">Phùng Thanh An</span>
                                </p>
                                <p>
                                  Số tiền: <span className="high-light-color">{price} đ</span>
                                </p>
                                <p>
                                  Nội dung chuyển khoản: <span className="high-light-color">{userData.monthlyBasicCode}</span>
                                </p>
                                </div>
                                <hr />
                                <p>Sau khi chuyển khoản xong, vui lòng chọn xác nhận đã thanh toán ở đây.</p>
                                <div className="price-card--cta" onClick={e => onConfirm(e, PAYMENT_METHOD.VIETCOMBANK)}>
                                  <button className="price-card--cta--button upgrade-button" style={{ marginTop: '5px' }}>
                                    Tôi xác nhận đã thanh toán
                                  </button>
                                </div>
                              </div>
                              <hr />
                              <div>
                                <div className="sub-info">
                                  <p>
                                    <span style={{ color: 'red' }}>*</span>
                                    <span> Nếu bạn cần sự hỗ trợ:</span>{' '}
                                    <a onClick={showSupport} style={{ cursor: 'pointer', fontWeight: '700' }}>
                                      <span>CHAT NGAY</span>
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ cursor: 'pointer' }} className="panel panel-default" onClick={() => handleToggleClick(1)}>
                          <div className="panel-heading">
                            <div className="panel-title">
                              <a>
                                <div className="bank-option">
                                  <img
                                    src="images/qr_code.webp"
                                    className="option-icon img-rounded"
                                    style={{ background: 'white' }}
                                  />
                                  <div className="option-title">
                                    <p>
                                      <span>Thanh toán qua QR Code</span>
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div className={`panel-collapse collapse ${isExpanded[1] ? 'in' : ''}`}>
                            <div className="option-content panel-body">
                              <div>
                                <p>1. Đăng nhập ứng dụng hỗ trợ thanh toán QR Code</p>
                                <p>2. Quét mã thanh toán/ tải mã về sau đó quét mã</p>
                                <p>3. Kiểm tra thông tin đơn hàng</p>
                                <div style={{marginLeft: "15px"}}>
                                  <p>
                                    Vietcombank (VCB): <span className="high-light-color">0441000810601</span>
                                  </p>
                                  <p>
                                    Chủ tài khoản: <span className="high-light-color">Phùng Thanh An</span>
                                  </p>
                                  <p>
                                    Số tiền: <span className="high-light-color">{price} đ</span>
                                  </p>
                                </div>
                                <p>
                                  4. Nhập nội dung chuyển khoản: <span className="high-light-color">{userData.monthlyBasicCode}</span>
                                </p>
                                <hr />
                                <div className="price-card--cta">
                                  <img src={`images/vcb_${price}.webp`} width={150} />
                                </div>
                                <hr />
                                <p>Sau khi thanh toán xong, vui lòng chọn xác nhận đã thanh toán ở đây.</p>
                                <div className="price-card--cta" onClick={e => onConfirm(e, PAYMENT_METHOD.VIETCOMBANK)}>
                                  <button className="price-card--cta--button upgrade-button" style={{ marginTop: '5px' }}>
                                    Tôi xác nhận đã thanh toán
                                  </button>
                                </div>
                              </div>
                              <hr />
                              <div>
                                <div className="sub-info">
                                  <p>
                                    <span style={{ color: 'red' }}>*</span>
                                    <span> Nếu bạn cần sự hỗ trợ:</span>{' '}
                                    <a onClick={showSupport} style={{ cursor: 'pointer', fontWeight: '700' }}>
                                      <span>CHAT NGAY</span>
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ cursor: 'pointer' }} className="panel panel-default" onClick={() => handleToggleClick(2)}>
                          <div className="panel-heading">
                            <div className="panel-title">
                              <a>
                                <div className="bank-option">
                                  <img
                                    src="images/momo.webp"
                                    alt="PD"
                                    className="option-icon img-rounded"
                                  />
                                  <div className="option-title">
                                    <p>
                                      <span>Thanh toán qua ví điện tử Momo</span>
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div className={`panel-collapse collapse ${isExpanded[2] ? 'in' : ''}`}>
                            <div className="option-content panel-body">
                              <div>
                                <p>1. Đăng nhập ứng dụng MOMO</p>
                                <p>2. Quét mã thanh toán</p>
                                <p>3. Kiểm tra thông tin đơn hàng</p>
                                <div style={{marginLeft: "15px"}}>
                                  <p>
                                    MOMO: <span className="high-light-color">0973898494</span>
                                  </p>
                                  <p>
                                    Chủ tài khoản: <span className="high-light-color">Phùng Thanh An</span>
                                  </p>
                                  <p>
                                    Số tiền: <span className="high-light-color">{price} đ</span>
                                  </p>
                                </div>
                                <p>
                                  4. Nhập nội dung chuyển khoản: <span className="high-light-color">{userData.monthlyBasicCode}</span>
                                </p>
                                <hr />
                                <div className="price-card--cta">
                                  <img src={`images/momo_${price}.webp`} width={150} />
                                </div>
                                <hr />
                                <p>Sau khi thanh toán xong, vui lòng chọn xác nhận đã thanh toán ở đây.</p>
                                <div className="price-card--cta" onClick={e => onConfirm(e, PAYMENT_METHOD.MOMO)}>
                                  <button className="price-card--cta--button upgrade-button" style={{ marginTop: '5px' }}>
                                    Tôi xác nhận đã thanh toán
                                  </button>
                                </div>
                              </div>
                              <hr />
                              <div>
                                <div className="sub-info">
                                  <p>
                                    <span style={{ color: 'red' }}>*</span>
                                    <span> Nếu bạn cần sự hỗ trợ:</span>{' '}
                                    <a onClick={showSupport} style={{ cursor: 'pointer', fontWeight: '700' }}>
                                      <span>CHAT NGAY</span>
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showPurchasingScreen && (
              <div>
                <div id="pricing-cards">
                  <div id="container-purchasing">
                    <div id="success-box">
                      <div className="face">
                        <div className="eye" />
                        <div className="eye right" />
                        <div className="mouth happy" />
                      </div>
                      <div className="shadow scale" />
                      <div className="message">
                        <h1 className="alert">
                          <span onLoad={renderLove} className="thanks-mess hearts">
                            {' '}
                            {t('bill.afterPayment.thankyouMess')}{' '}
                          </span>
                        </h1>
                        <p>{t('bill.afterPayment.checking')}</p>
                        <hr/>
                        <p>Nếu bạn vẫn chưa thanh toán vui lòng hoàn tất theo hướng dẫn ở đây:</p>
                        <div style={{marginLeft: "15px"}}>
                        <p>
                          Vietcombank (VCB): <span className="high-light-color">0441000810601</span>
                        </p>
                        <p>
                          Chủ tài khoản: <span className="high-light-color">Phùng Thanh An</span>
                        </p>
                        <p>
                         hoặc MOMO: <span className="high-light-color">0973898494</span>
                        </p>
                        <p>
                          Số tiền: <span className="high-light-color">{price} đ</span>
                        </p>
                        <p>
                          Nội dung chuyển khoản: <span className="high-light-color">{userData.monthlyBasicCode}</span>
                        </p>
                        </div>
                        <hr />
                        <p style={{ paddingBottom: '60px' }}>
                          <span style={{ color: 'red' }}>*</span>
                          <span> Nếu bạn cần sự hỗ trợ:</span>{' '}
                          <a onClick={showSupport} style={{ cursor: 'pointer', fontWeight: '700' }}>
                            <span>CHAT NGAY</span>
                          </a>
                        </p>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            )} */}
          </section>
        </div>
      )}
    </>
  );
};

export default NewPricing;
