const jp = {
    Basic: "ベーシック",
    Pro: "プロ",
    month: "月",
    year: "年",
    button: {
        getStarted: '始める',
        try: 'AIでデザインを始める',
        viewall: 'すべて見る',
        basic: 'ベーシック',
        pro: 'プロ',
        generate: 'デザインを生成',
        backHome: 'ホームに戻る',
        googleSignin: 'Googleで始める',
        googleSignup: 'Googleで始める',
        emailSignin: 'メールでログイン',
        emailSignup: 'メールで始める',
        submit: '送信',
        choose: '選択',
        download: 'このテンプレートをダウンロード',
        upgrade: 'クレジットを追加購入',
        subscribe: '購入',
        upgradePro: 'アップグレード',
        confirmPayment: '支払いを確認',
        tryThisFeature: '今すぐ試す',
        removeBG: '開始',
        professional: '開始',
        upscale: '開始',
        ok: 'OK',
        cancel: 'キャンセル',
        select: '選択',
        selectToStart: '選択して開始',
        uploadPose: 'ポーズをアップロード',
        uploadingPose: 'ポーズをアップロード中',
        upgradeToUpload: 'アップグレードしてアップロード',
        unlock: "アップグレード",
        englishOnly: "英語版",
        contactUs: "お問い合わせ",
        forEnterprise: "エンタープライズソリューション",
        tryThis: "試す",
        upscale: "高画質化",
        remove: "削除",
        render: "レンダリング",
        rendering: "レンダリング中",
    },
    header: {
        nav: {
            signIn: 'ログイン',
            signUp: '登録',
            signOut: 'ログアウト',
            billing: '請求',
            supportTitle: 'サポート',
            supportContact: 'お問い合わせ',
            myFlowers: 'マイデザイン'
        }
    },
    landing: {
        title: '建築用AIレンダリングツール',
        joinNow: '素晴らしいデザインを作成する準備はできましたか？',
        subTitle: "バーチャルステージング、リモデリング、ランドスケープ、インテリアデザインなどのためのAIベースのアプリ。瞬時にデザインを生成。",
        subTitle1: "簡単に時間とお金を節約。世界中の家主、Airbnbホームサービス、企業、プロフェッショナルに信頼されています。",
        input: '作成したい花の種類を教えてください？',
        inside: '室内',
        outside: '屋外',
        loveBy: '45,880以上の幸せなデザインが作成されました',
        respectTitle: 'プライバシーを尊重します',
        respectSub1: 'FaceIDや多くの写真は必要ありません。FacebookやInstagramに投稿するような1枚のセルフィーだけで十分です。',
        respectSub2: 'あなたのデータは非常に高いセキュリティを持つGoogleプラットフォームで完全に安全かつ保証されています。',
        howItWorks: '使い方',
        howItWorksSub: '撮影またはアップロード → スタイルと背景を選択 → ポーズを選択またはアップロード → ヘッドショット写真を受け取る！',
        unBlurryTitle: '写真エンハンサー',
        unBlurrySub: 'Facebook、Instagram、TikTokなどのソーシャルメディアプラットフォームに投稿する前に、写真のぼかしを除去し、品質を向上させ、色を修正します。',
        useThisFeature: '今すぐ作成',
        anotherFeatureTitle: '注目の機能',
        anotherFeatureSub: '無料、高速、正確。以下の機能をお試しください。',
        feature1: '背景削除',
        feature1Sub: 'あらゆる背景を正確に削除します。',
        feature2: 'インテリアデザイン',
        feature2Sub: 'genhomeai.comでインテリアデザイン、エクステリアデザイン、スケッチを作成',
        feature3: '背景変更（近日公開）',
        feature3Sub: 'AI REOKであらゆるオブジェクトの背景を変更',
        hotFeatureTitle: 'お客様の声',
        hotFeatureSub: '最もリアルな結果。プロフェッショナルに信頼されています。すべての人のために作られました。',
        pricingTitle: 'プランを選択',
        pricingLabel: '価格',
        pricingSub: 'プロフェッショナルな写真1枚 = 10クレジット以下',
        step1: 'スペースをアップロード',
        step1Sub: 'スタイルを選択するか、独自のものを入力してください。',
        step2: '数秒でAIデザイナーが作業',
        step2Sub: '高度な技術が迅速にあなたの装飾を作成します。',
        step3: '装飾を受け取る',
        step3Sub: '装飾をダウンロードします。',
        trialTitle: "無料トライアル",
        trialSubTitle: "AIヘッドショット写真ジェネレーター #1 📸",
        feedbackLabel: 'フィードバック',
        faqLabel: 'よくある質問',
        sketch: 'スケッチ',
        creativity: '創造性',
        upscale4k: 'アップスケール',
        remove: 'クリーンルーム',
        fillup: '家具',
        exterior: 'エクステリア',
        interior: 'インテリア',
        steps: 'ステップ',
        interiorShowCase: {
            label: "インテリア",
            title: "あらゆるスタイルでリデザイン",
            subTitle: "様々なスタイルであなたの場所を変身させ、素晴らしく見せるお手伝いをします。様々な種類のインテリア、エクステリア、または庭をリデザインします。"
        },
        exteriorShowCase: {
            label: "エクステリア",
            title: "エクステリアのクリエイティブなリデザイン",
            subTitle: "革新的なリデザインソリューションで屋内外のスペースを更新し、真に印象的な雰囲気を作り出すために、あらゆる角にクリエイティビティを注入します。"
        },
        furnitureShowCase: {
            label: "家具",
            title: "空の部屋を家具で埋める",
            subTitle: "完璧なセットアップを見つけるまで、様々な配置を試すことができます。Decorifyを使用して空の部屋を家具で装飾します。建設中または空の部屋に最適です。"
        },
        cleanRoomShowCase: {
            label: "クリーンルーム",
            title: "不要なオブジェクトを削除",
            subTitle: "不要なオブジェクトを簡単に削除して部屋を変更し、あなたの好みに合わせた完璧な環境を作り出すことができます。"
        },
        upscaleShowCase: {
            label: "アップスケール",
            title: "あらゆるデザインを4Kにアップスケール",
            subTitle: "当社のアップスケールデザインサービスで、あらゆる環境の雰囲気を向上させ、真に没入感のある体験のために4K解像度まで驚くべき明瞭さと詳細を確保します。"
        },
        creativityShowCase: {
            label: "創造性",
            title: "カスタムAI創造性",
            subTitle: "AIインターベンションであなたのデザインの旅をコントロールします。AIがどれだけ支援すべきかを決定します。いくつかの提案が欲しいだけか、完全な変更が必要かに関わらず、すべてはあなた次第です。"
        },
        sketchShowCase: {
            label: "スケッチ",
            title: "スケッチからリアルなデザインへ",
            subTitle: "あらゆるスケッチを生き生きとしたリアルなデザインに変換し、明確な精度と真正性であなたのコンセプトを実現します。"
        },
    },
    yearbook: {
        title: 'AI卒業アルバム撮影 📸',
        subTitle: "多くのセルフィー画像を使用しません。AIフォトグラファーと1枚のセルフィーで、数秒で90年代の卒業アルバム写真を取得します。時間を10倍節約します。",
        input: '作成したい花の種類を教えてください？',
        inside: '室内',
        outside: '屋外',
        loveBy: '45,880以上の幸せなデザインが作成されました',
        respectTitle: 'プライバシーを尊重します',
        respectSub1: 'FaceIDを使用したり、多くの写真を必要としたりしません。FacebookやInstagramによく投稿するようなセルフィーを使用するだけです。',
        respectSub2: 'あなたのデータは非常に高いセキュリティを持つGoogleプラットフォームで完全に安全かつ保証されています。',
        howItWorks: 'AI卒業アルバムの仕組み',
        howItWorksSub: 'セルフィーを撮るか、はっきりしたID写真を使用し、スタイルとポーズを選択し、数時間ではなく数秒でヘッドショット写真を作成します！',
        unBlurryTitle: '写真エンハンサー',
        unBlurrySub: 'Facebook、Instagram、TikTokなどのソーシャルメディアプラットフォームに投稿する前に、写真のぼかしを除去し、品質を向上させ、色を修正します。',
        useThisFeature: '今すぐ作成',
        anotherFeatureTitle: '注目の機能',
        anotherFeatureSub: '無料、高速、正確。以下の機能をお試しください。',
        feature1: '背景削除',
        feature1Sub: 'あらゆる背景を正確に削除します。',
        feature2: 'インテリアデザイン',
        feature2Sub: 'genhomeai.comでインテリアデザイン、エクステリアデザイン、スケッチを作成',
        feature3: '背景変更（近日公開）',
        feature3Sub: 'AI REOKであらゆるオブジェクトの背景を変更',
        hotFeatureTitle: '当社のAI卒業アルバム写真',
        hotFeatureSub: 'これらはAIが生成したヘッドショット写真です。',
        pricingTitle: 'プランを選択',
        pricingLabel: '価格',
        pricingSub: 'プロフェッショナルな写真1枚 = 10クレジット以下',
    },
    showcase: {
        title: '花のサンプル',
        status: {
            pending: '処理中',
            upscaling: 'アップスケール中',
            choosing: '選択中',
            finished: '完了'
        },
        mode: {
            pro: 'プロ',
            basic: 'ベーシック'
        }
    },
    home: {
        title: 'あなたのデザイン',
        hotColletion: "カスタムスタイル",
        credits: '残りのデザイン',
        uploadTitle: 'スペースをアップロード',
        uploadPlaceHolder: 'ファイルをドロップするかクリックしてアップロード',
        stylePlaceHolder: '選択',
        uploadPosePlaceHolder: '選択',
        uploadBGPlaceHolder: '選択',
        roomTitle: '部屋のタイプを選択（29）',
        themeTitle: '部屋のスタイルを選択（34）',
        pro: {
            title: 'すべての種類の花のための創造性',
            input: 'あなたの花を説明してください...',
        },
        basic: {
            title: 'プロで無限の創造性',
            chooseFlowerTitle: '花',
            flowers: 'リビングルーム',
            styles: 'スタイル',
            decoration: '装飾',
            events: 'イベント',
            backgrounds: '背景'
        },
    },
    profile: {
        title: 'マイデザイン',
        before: 'ビフォー -',
        after: 'アフター -'
    },
    terms: {
        title: '利用規約',
        content: ''
    },
    // Part2
    privacy: {
        title: 'プライバシーポリシー',
        one: 'あなたのプライバシーは私たちにとって非常に重要です。decorify.proのプライバシーポリシーは、当社のウェブサイトdecorify.proおよび当社が所有・運営する他のウェブサイトで収集する可能性のある情報に関するあなたのプライバシーを尊重します。',
        two: '私たちは、サービスを提供するために本当に必要な場合にのみ個人情報を要求します。情報は公正かつ合法的な手段で、あなたの知識と同意のもとに収集します。また、なぜその情報を収集し、どのように使用されるかをお知らせします。',
        three: '収集した情報は、要求されたサービスを提供するために必要な期間のみ保持します。保存するデータは、商業的に許容される手段で保護し、損失や盗難、不正アクセス、開示、複製、使用、改変を防止します。',
        four: '法律で要求される場合を除き、個人を特定できる情報を公開したり、第三者と共有したりすることはありません。',
        five: '当社のウェブサイトは、当社が運営していない外部サイトにリンクする場合があります。これらのサイトのコンテンツや慣行については当社にコントロールの権限がなく、それぞれのプライバシーポリシーについて責任や義務を負うことはできませんのでご注意ください。',
        six: 'あなたは個人情報の提供を拒否する自由がありますが、その場合、希望するサービスの一部を提供できない可能性があることをご理解ください。',
        seven: '当社のウェブサイトを継続して使用することは、プライバシーと個人情報に関する当社の慣行を受け入れたものとみなされます。ユーザーデータと個人情報の取り扱いについて質問がある場合は、お気軽にお問い合わせください。',
        eight: 'このポリシーは2023年9月15日から有効です。'
    },
    refund: {
        moneyBack: '返金保証',
        title: '返金ポリシー',
        one: '私たちの目標は、すべてのお客様に高品質のサービスと結果を提供することです。出力が期待に沿わない場合があることを理解しています。',
        two: 'お客様の満足を保証します。Reok.proで生成された写真に満足できない場合、全額返金を喜んで提供いたします。また、場合によっては、期待により適合するよう写真を再作成することもあります。',
        three: 'このポリシーに基づく返金リクエストは、1〜3営業日以内に確認し、回答いたします。返金が承認された場合、5〜10営業日以内にカードに返金されます。返金は購入時に使用された元の支払い方法に処理されます。別のアカウントへの返金はできません。',
        four: '返金リクエストは、購入後30日以内かつ20クレジット未満の使用の場合にのみ処理できます。',
        five: '返金を申請するには、hello@decorify.proにメールを送るか、ウェブサイトのチャット機能をご利用ください。返金について連絡する際は、注文時に使用したメールアドレスを含めてください。',
        six: 'ヘッドショットにReok.proをお選びいただき、ありがとうございます。'
    },
    footer: {
        title: 'Decorifyは、バーチャルステージング、リモデリング、ランドスケーピング、インテリアデザインなどのためのAI駆動アプリです。',
        terms: '利用規約',
        privacy: 'プライバシーポリシー',
        app: 'アプリ',
        signin: 'ログイン',
        signup: '登録',
        email: 'メール',
        chatNow: 'チャットを開始',
        partner: 'パートナー',
        reportBug: 'バグを報告',
        require: 'リクエストを送信',
        page: 'ページ',
        tos: '法的情報',
        contact: 'お問い合わせ',
        other: 'その他',
        affiliate: 'アフィリエイト',
        yearbook: 'AI卒業アルバム',
        blog: 'ブログ',
        pricing: '料金',
        howItWorks: '使い方',
        sendFeedback: 'フィードバックを送る'
    },
    tos: {
        agree: '新規アカウントは以下の条件に同意するものとします ',
        one: '1. はじめに',
        oneDetail: 'decorify.proを使用することにより、あなたはこれらの利用規約に同意し、拘束されることになります。',
        two: '2. 利用規約への同意',
        twoDetail: 'この契約は、decorify.proアプリを最初に使用した日から有効となります。',
        three: '3. 機能',
        threeDetail: '特定の有料プランに登録すると、すべての機能を使用できるようになります。利用可能な機能の詳細は、料金ページでご確認いただけます。',
        four: '4. 返金ポリシー',
        fourDetail: '購入後14日以内の失敗した取引について返金を許可しています。返金をリクエストするには、サポートにお問い合わせください。',
        five: '5. 製品の使用',
        fiveDetail: 'decorify.proを使用することにより、Googleアカウントに関連付けられたメールアドレスまたはアカウント登録時に使用したメールアドレスを通じて、decorify.proから重要な製品アップデートを受け取ることに同意するものとします。各メールの下部にある「配信停止」リンクをクリックすることで、いつでもこれらの製品アップデートの受信を停止できます。重要な製品アップデートのみを送信します。',
        six: '6. 免責事項',
        sixDetail: 'decorify.proがお客様の要件を満たすこと、またはその操作が中断されないこと、エラーがないことを保証するものではありません。本契約に明示的に記載されていないすべての保証または条件（利益の損失、データの損失または破損、事業の中断、またはその他の金銭的損失、損害、費用、または費用を含むがこれらに限定されない）は、法律で許可される最大限の範囲で除外され、否認されます。本契約は、消費者としてのお客様の法定の権利に影響を与えるものではありません。',
        seven: '7. 保証および法的制限',
        sevenDetail: 'decorify.proは、ソフトウェアの品質、特定の目的への適合性、またはその他の特性やパフォーマンスについて、いかなる保証、保証、またはその他の表明も行いません。decorify.proは、本契約またはdecorify.proの使用に起因または関連する利益の損失、または結果的、特別、偶発的、または間接的な損害について（過失を含む、いかなる形で発生したものであっても）、法律で責任を除外できない場合を除き、お客様に対して責任を負いません。',
        eight: '8. 一般条項および法律',
        eightDetail: 'これらのサービスの使用の結果として、あなたとdecorify.proの間にジョイントベンチャー、パートナーシップ、雇用、または代理関係が存在しないことを認めます。あなたはdecorify.proの代表者、代理人、または従業員として自身を表明しないことに同意します。decorify.proが当社のプラットフォームの誤用を防止または対処するために取る行動について、decorify.proがあなたに対して責任を負わないことに同意します。decorify.proの使用を提供するにあたり、当社があなたの本契約の条件に拘束されることへの同意に依存していることを認めます。',
    },
    faq: {
        title: 'よくある質問',
        q1: 'Decorifyはどのように機能しますか？',
        answer1: 'Decorifyは、インテリア、エクステリア、庭、パティオなど、家のあらゆる部分を変換・再設計できるウェブベースのアプリケーションです。人工知能の力を活用し、数秒ごとに新しいコンセプトを生成し、幅広いスタイル、モード、部屋のタイプなどを提供して、希望の結果を達成するのを支援します。',
        q2: '本当に時間とお金を節約できますか？',
        answer2: 'はい、当社のアプリは個人用と業務用の両方に費用対効果の高いソリューションを提供します。高価な専門家を雇わずに、異なる家具、装飾、裏庭のデザインで家がどのように見えるかを視覚化でき、インスピレーションを得るために無限のウェブサイトをスクロールする必要もありません。当社のAIデザイナーがあなたの好みを分析し、パーソナライズされた推奨事項を生成するため、素晴らしい家を簡単に視覚化し、作成できます。',
        q3: 'どのデバイスでも動作しますか？（ノートPC、MacOS、PC、iOS、Android）',
        answer3: 'もちろんです！モバイルアプリやデスクトッププログラムをインストールする必要はありません。最も一般的に使用されているブラウザ（Chrome、Firefox、Safari）から実行するだけです。',
        q4: '使い方は簡単ですか？',
        answer4: '画像をアップロードするだけで、新しいAIのアイデアの生成を開始できます。',
        q5: 'Decorify AIは誰が使用できますか？',
        answer5: '当社のアプリは、インテリア、庭、または建築に関する知識や専門性に関係なく、誰でも簡単に使用できます。リモデルや建築を検討している家主、新しいアイデアやすぐに結果が必要な建築家、装飾のアイデアを探しているインテリアデザイナー、さらには造園業者も利用できます。不動産業者や不動産会社も、リストを強化し、家を簡単に売却し、迅速なAIインテリアおよびエクステリアのアイデアを示すために当社のソフトウェアを使用しています。',
        q6: 'AIデザイナーはどのように私の家を向上させることができますか？',
        answer6: 'AIデザイナーはインテリアに多くの可能性を提供します。幅広いスタイルやデコレーションオプションを探索して、調和のとれた視覚的に魅力的な生活空間を作り出すことができます。',
        q7: '建築計画やスケッチでも機能しますか？',
        answer7: 'もちろんです！当社のソフトウェアは建築スケッチでも動作するように設計されています。手書きのスケッチやデジタルレンダリングを簡単に当社のプラットフォームにアップロードし、スケッチをリアルな視覚化にレンダリングできます。',
        q8: '建設中の家/住宅/インテリア/エクステリアでも機能しますか？',
        answer8: 'もちろんです！当社のユーザーは、建設中のインテリアやエクステリア（部屋、家、裏庭）をどのように装飾するかのアイデアを生成するために、当社のソフトウェアを頻繁に使用しています。',
        q9: 'バーチャルステージングやホームステージングでも機能しますか？',
        answer9: 'もちろんです！空の部屋や既存のスペースの写真をアップロードすることで、バーチャルステージングを試すことができます。不動産のプロフェッショナル、家主、Airbnbホームサービスにとって非常に価値のあるツールです。',
        q10: '返金ポリシーはありますか？',
        answer10: 'はい！私たちは自社の技術に非常に自信があるため、全額返金保証を提供しています。詳細については返金ポリシーをご覧ください。'
    },
    signin: {
        title: 'ログイン',
        another: 'またはメールで',
        emailPlaceHolder: 'メールアドレス',
        passwordPlaceHolder: 'パスワード',
        rememberTitle: '次回のために記憶する',
        forgetPassword: 'パスワードをお忘れですか？',
        noAccount: 'アカウントをお持ちでない方'
    },
    signup: {
        title: 'アカウントを作成するか、既存のアカウントでログインしてください。',
        another: 'またはメールで',
        firstNamePlaceHolder: '名',
        lastNamePlaceHolder: '姓',
        emailPlaceHolder: 'メールアドレス',
        passwordPlaceHolder: 'パスワード',
        rememberTitle: '次回のために記憶する',
        forgetPassword: 'パスワードをお忘れですか？',
        gotAccount: 'すでにアカウントをお持ちの方'
    },
    // Part3
    forgetPassword: {
        title: 'パスワードの再設定',
        content: 'パスワード再設定用のメールを送信します',
        emailPlaceHolder: 'メールアドレスを入力してください',
        sendBtn: '送信',
        backSignin: 'ログインに戻る'
    },
    pricing: {
        yearlySubscription: '1000デザイン',
        monthlySubscription: '100デザイン',
        saveDesk: '10%オフ',
        saveMobile: '1000デザイン購入で10%オフ',
        popular: "76%のユーザーがこのプランを選択",
        free: {
            title: '無料クレジット',
            price: '0円',
            trialTitle: '登録コード：ログインしてください',
            joinTitle: 'ここにコードを貼り付けてください',
            one: '20無料クレジットを獲得',
            two: '100クレジットの全機能を含む',
            affiliateTitle: 'アフィリエイトプログラム',
            shareTitle: '友達とシェア👇',
            loginToShare: 'シェアするにはログインしてください'
        },
        basic: {
            title: '100クレジット',
            price: '700円',
            duration: '/永久',
            recommend: '個人向け',
            one: '20枚のプロフェッショナル写真',
            two: '背景削除',
            three: '4Kへのアップスケール',
            four: '全ての写真をダウンロード',
            five: '無制限のクラウドストレージ',
            six: '写真の商用利用',
            seven: '全ての新機能へのアクセス',
            subscribed: '購入済み'
        },
        pro: {
            title: '1000クレジット',
            subTitle: '(30%オフ)',
            price: '4,900円',
            duration: '/永久',
            recommend: 'カフェオーナー、企業、写真スタジオ向け',
            one: '200枚のプロフェッショナル写真',
            two: '100クレジットの全機能を含む',
            three: '節約',
            subscribed: '購入済み'
        },
        credit: {
            title: 'あなたのニーズに合ったプランを選択してください！'
        },
        first: {
            title: 'スターター',
            price: '$19',
            priceDetail: '100デザイン - サブスクリプションなし',
            duration: '/永久',
            recommend: '1デザイン = 5クレジット',
            five: '基本的なAIデザイナーでデコレーション',
            credit: '500クレジット',
            two: '100デザイン/購入',
            three: '全モードがアンロック',
            one: 'AI家具削除',
            six: '商用ライセンス',
            four: 'スケッチからレンダリング',
            seven: '85+の部屋タイプが利用可能',
            eight: '40+のスタイルが利用可能',
            nine: 'あらゆるデザインを4Kにアップスケール',
            ten: '高品質でダウンロード',
            eleven: '無制限のレンダリングストレージ',
            twelve: '新機能への早期アクセス',
            thirteen: 'いつでもキャンセル可能',
            fourteen: 'AIバーチャルステージング',
        },
        second: {
            title: 'プロ',
            subTitle: '(85%オフ)',
            price: '$29',
            priceDetail: '月1,000デザイン',
            duration: '/永久',
            recommend: 'カフェオーナー、企業、写真スタジオ向け',
            five: '常に最新のAIデザイナー',
            credit: '5,000クレジット',
            two: '1,000デザイン/月',
            three: '全モードがアンロック',
            one: 'AI家具削除',
            six: '商用ライセンス',
            four: 'スケッチからレンダリング',
            seven: '85+の部屋タイプが利用可能',
            eight: '40+のスタイルが利用可能',
            nine: 'あらゆるデザインを4Kにアップスケール',
            ten: '高品質でダウンロード',
            eleven: '無制限のレンダリングストレージ',
            twelve: '新機能への早期アクセス',
            thirteen: 'いつでもキャンセル可能',
            fourteen: 'AIバーチャルステージング',
            subscribed: '購読'
        },
        third: {
            title: 'プロ年間',
            subTitle: '(93%オフ)',
            price: '$199',
            priceDetail: '年12,000デザイン',
            duration: '/永久',
            recommend: 'カフェオーナー、企業、写真スタジオ向け',
            five: '常に最新のAIデザイナー',
            credit: '60,000クレジット',
            two: '12,000デザイン/年',
            three: '全モードがアンロック',
            one: 'AI家具削除',
            six: '商用ライセンス',
            four: 'スケッチからレンダリング',
            seven: '85+の部屋タイプが利用可能',
            eight: '40+のスタイルが利用可能',
            nine: 'あらゆるデザインを4Kにアップスケール',
            ten: '高品質でダウンロード',
            eleven: '無制限のレンダリングストレージ',
            twelve: '新機能への早期アクセス',
            thirteen: 'いつでもキャンセル可能',
            fourteen: 'AIバーチャルステージング',
            subscribed: '購読'
        },
        four: {
            title: 'エンタープライズソリューション',
            subTitle: '(お問い合わせ)',
            price: '0円',
            priceDetail: '0円 / デザイン',
            duration: '/永久',
            recommend: 'カフェオーナー、企業、写真スタジオ向け',
            one: '高品質なデザイン',
            two: '企業やリモートチーム向け',
            three: '時間とコストの節約',
            four: '数秒でデザインを作成',
            five: '従来の撮影不要',
            subscribed: '購入済み',
        },
        currency: '円',
        perMonth: '/月',
        safePayment: '安全な支払い',
        qr: 'またはQRコードを使用'
    },
    milestoneMessage: {
        first: '以下の数のデザインで',
        second: '顧客満足度が向上'
    },
    message: {
        success: {
            redering: 'デザインをレンダリング中です。しばらくお待ちください',
            rederingDone: 'AIがデザインの作成を完了しました',
            activated: 'アクティベーションに成功しました！美しく満足のいくデザインをお楽しみください！',
            removingBG: '背景を削除中',
            upscale: '解像度をアップスケール中',
            resetPassword: 'パスワードをリセットするためのメールを送信しました。確認してください！',
            signIn: 'アカウントをアクティベートするためのメールを送信しました。確認してください！',
            reviewing: 'リクエストを受け付けました。1日以内に確認します',
            remindReviewing: 'この写真は新しいクレジットを請求するために確認中です',
            removed: '写真を正常に削除しました',
            useTheTemplate: "テンプレートを使用中",
            subscribed: "購読しました！"
        },
        error: {
            selectPhotographer: '開始するにはAIデザイナーを選択してください',
            uploadPhoto: 'スペースをアップロードしてください',
            chooseRoom: '部屋のタイプを選択してください',
            chooseTheme: '部屋のスタイルを選択してください',
            fileOverBasic: '5MB未満のファイルをアップロードするか、より大きなファイルをアップロードするにはProにアップグレードしてください',
            fileOverPro: '10MB未満のファイルをアップロードしてください',
            wrongFile: '無効なファイルです。別のファイルを試してください',
            wrongFace: '自撮りを近すぎる位置で撮らないでください。別の写真を試してください！',
            busy: '接続が中断されました。もう一度お試しください',
            gender: '性別を選択してください',
            age: '年齢を選択してください',
            roomType: 'タイプを選択してください',
            style: 'スタイルを1つ選択するかカスタマイズしてください',
            numberOfResult: 'デザインの数を選択してください',
            outOfCredits: 'クレジットがなくなりました。クレジットを追加購入してください！',
            userNotFound: 'このアカウントが見つかりません。正しいアカウントを入力してください！',
            wrongPassword: 'パスワードが間違っています！パスワードを忘れた場合は「パスワードを忘れた」を使用してください。',
            accountExist: 'アカウントは既に存在します。代わりにこのアカウントでログインしてください',
            tiktok: 'Googleユーザーのセキュリティ上の理由から、ChromeまたはSafariブラウザを使用してください。decorify.proにアクセスしてください。',
            reviewing: '確認中のため、この写真を削除することはできません',
            background: '背景を選択してください',
            pose: 'ポーズを選択してください',
            signUpReCaptcha: '登録する前にreCAPTCHAを完了してください。',
            emptyBG: '背景が空です。入力してください！',
            tooShortBG: '背景は2語以上である必要があります。',
            tooLongBG: '背景が長すぎます。',
            badWordsBG: '背景に不適切な言葉が含まれています。別のものを試してください',
            emptyStyle: 'スタイルが空です。入力してください！',
            tooShortStyle: 'スタイルは2語以上である必要があります。',
            tooLongStyle: 'スタイルが長すぎます。',
            badWordsStyle: 'スタイルに不適切な言葉が含まれています。別のものを試してください',
            emptyEmail: 'メールアドレスを入力してください',
            validEmail: '有効なメールアドレスを入力してください',
            subscribeError: '申し訳ありませんが、登録できませんでした。'
        },
        warning: {
            runOutBasicCredits: 'ベーシックプランのクレジットをすべて使用しました。クレジットを追加購入してください',
            runOutProCredits: '使用するにはクレジットを追加購入してください ^^',
            selectPose: 'ポーズを選択する前に性別を選択してください',
            aiVirtualStaging: 'AIバーチャルホームに切り替え',
            aiFurnitureRemoval: 'AI家具削除に切り替えています。削除履歴は保存されないため、離れる前に画像をダウンロードしてください。',
            upscale: 'アップスケールに切り替え',
        },
        prompt: {
            professional: 'プロフェッショナル写真',
            removeBG: '背景削除',
            upscale: '解像度アップスケール',
            rendering: 'AIレンダリング',
            faceNotFound: '顔が近すぎます。別の自撮りを試してください！',
            inReview: '確認中',
            styles: {
                business: 'プロフェッショナルなビジネス写真',
                realEstate: 'プロフェッショナルな不動産業者の写真',
                carShowroom: 'プロフェッショナルな自動車販売員の写真',
                doctor: 'プロフェッショナルな医師の写真',
                yearbook: 'プロフェッショナルな90年代の卒業アルバム写真',
                wedding: 'プロフェッショナルな結婚式の写真',
                halloween: 'ハロウィーン写真'
            },
        },
        maintain: {
            backsoon: 'この機能はメンテナンス中です。すぐに復旧します。その間、他の機能をご利用ください。ありがとうございます！ 💗💗💗'
        }
    }, 
    // Part 4   
    app : {
        menu: {
            creditTitle: 'あなたのクレジット',
            affiliateTitle: 'アフィリエイトプログラム',
            affiliateSub: '紹介したすべての顧客の支払いに対して30%のコミッションを受け取れます！',
            professionalMode: 'AIホームデザイン',
            removeBGMode: '家具の削除',
            unBluryMode: 'アップスケール',
            myPhotos: 'マイデザイン',
            aiVirtualStaging: "AIバーチャル演出"
        },
        tryThese: '画像がない場合は、これらを試してください：',
        goodSelfie: '良い自撮り',
        badSelfie: '悪い自撮り',
        trick: 'より良い品質を得るにはアップスケールを使用してください',
        removeBGSub: 'AIで背景の削除が迅速、簡単、正確になります。',
        professionalMode: {
            title: 'AIで任意のスペースをデコレーション',
            refundTitle: '私たちのAIは素晴らしい結果のために学習を重ねています 💓',
            creditTitle: '1枚の写真 = 10クレジット',
            genderTitle: 'タイプを選択',
            ageTitle: '年齢を選択',
            styleTitle: 'スタイルを選択またはカスタマイズ',
            quantityTitle: 'デザインの数',
            poseTitle: 'ポーズを選択/アップロード',
            backgroundTitle: '背景を選択/カスタマイズ',
            skipPose: '元のポーズを維持するためにスキップ',
            defaultPose: 'デフォルト',
            uploadedPose: 'アップロード済み',
            defaultBackground: '背景',
            defaultStyle: 'スタイル',
            headshotsTab: 'ヘッドショット',
            selectPhotographer: 'AIデザイナーを選択',
            selectPhotographerSub: 'あなたのニーズに合ったデザイナーを選んでください',
            requirements: 'カスタマイズ',
            guideSub: 'デザイナーの要件に従ってください 👇',
            creativity: "AI創造性",
            customize: "✍️ カスタマイズ (ベータ版)",
            enOnlyCustomize: "✍️ カスタマイズ (英語版のみ)",
            interiorsTitle: "インテリア",
            exteriorsTitle: "エクステリア",
            gardensTitle: "庭園",
        },
        upscaleMode: {
            title: 'AIで素早く正確にぼかしを除去し、写真を強化します。',
        }
    },
    myDesign: {
        title: 'マイデザイン',
        scrollTitle: 'スクロールしてすべてのデザインを見る。',
    },
    imageComponent: {
        compare: '表示',
        download: 'ダウンロード',
        removeObjects: '家具の削除',
        upscale: 'アップスケール',
        virtualHome: 'デコールステージング',
        errorPhoto: '不良写真の更新'
    },
    results: {
        one: '1つのデザイン',
        four: '4つのデザイン',
    },
    genders: {
        man: '男性',
        woman: '女性'
    },
    styles: {
        "modern": "モダン",
        "minimalist": "ミニマリスト",
        "professional": "プロフェッショナル",
        "tropical": "トロピカル",
        "coastal": "コースタル",
        "vintage": "ヴィンテージ",
        "neoclassic": "ネオクラシック",
        "tribal": "トライバル",
        "industrial": "インダストリアル",
        "bohemian": "ボヘミアン",
        "contemporary": "コンテンポラリー",
        "farmhouse": "ファームハウス",
        "scandinavian": "スカンジナビアン",
        "skichalet": "スキーシャレー",
        "artdeco": "アールデコ",
        "biophilic": "バイオフィリック",
        "christmas": "クリスマス",
        "tetvn": "旧正月",
        "midautumn": "中秋節",
        "halloween": "ハロウィーン",
        "easter": "イースター",
        "baroque": "バロック",
        "cottagecore": "コテージコア",
        "japanesestyle": "和風",
        "rustic": "ラスティック",
        "artnouveau": "アールヌーボー",
        "maximalist": "マキシマリスト",
        "coffee": "コーヒー",
        "medieval": "中世",
        "frenchcountry": "フレンチカントリー",
        "vaporwave": "ヴェイパーウェイブ",
        "hotpink": "ホットピンク",
        "gaming": "ゲーミング",
        "cyberpunk": "サイバーパンク",
        dating: "デート",
        other: "その他",
        custom: {
            title: 'カスタムスタイル',
            btn: 'カスタム開始',
            dialogTitle: 'あなたのスタイルと服装',
            dialogSubTitle: 'あなたのスタイル、服装、年齢を教えてください。AIフォトグラファーがそれに合わせて表現します！',
            upgrade: 'この機能をアップグレード',
            example: 'ネクタイなしのクラシックスーツ、26歳、あなたの希望に応じて...',
        },
        "cyberpunk_style_bathroom": "サイバーパンクスタイル",
        "eclectic_style_coworking_space": "エクレクティックスタイル",
        "maximalist_style_bedroom": "マキシマリストスタイル",
        "contemporary_style_hotel_bedroom": "コンテンポラリースタイル",
        "zen_style_bedroom": "禅スタイル",
        "cyberpunk_style_outdoor_patio": "サイバーパンクスタイル",
        "midcentury_modern_style_pool": "ミッドセンチュリーモダンスタイル",
        "easter_style_drop_zone": "イースタースタイル",
        "coastal_style_kitchen": "コースタルスタイル",
    },
    backgrounds: {
        coffeeShop: "コーヒーショップ",
        office: "オフィス",
        cityStreet: "都市の通り",
        factory: "工場",
        garden: "庭",
        carShowroom: "カーショールーム",
        bookShelf: "本棚",
        hotel: "ホテル",
        goldenGate: "ゴールデンゲート",
        snow: "雪",
        realtor: "不動産業者",
        brick: "レンガ",
        custom: {
            title: '背景',
            btn: 'カスタム開始',
            dialogTitle: 'あなたの背景',
            dialogSubTitle: 'あなたの背景を教えてください。AIフォトグラファーがそれに合わせて表現します！',
            upgrade: 'この機能をアップグレード',
            example: 'コーヒーショップ、あなたの希望する場所に応じて...',
        },
    },
    removeTitle: 'この写真を削除',
    removeSub: 'この写真を削除してもよろしいですか？この写真は永久に削除されます。この操作は取り消せません。',
    renewTitle: 'この写真を更新',
    renewSub: 'リクエストを確認します。この写真に問題がある場合、永久に削除されます。10個の新しいクレジットを獲得します。',
    reasonTitle: 'なぜ更新したいのか教えてください？',
    reasonEx: '非公式でした...',
    photographers: {
        sam: {
            name: "Sam、👋 こんにちは、お客様",
            title: "スタイルが得意です",
            intro: "私にできること：",
            intro1: "数秒で任意のスタイルと雰囲気を作成",
            intro2: "不動産、アート、ブログ写真",
            intro3: "インテリア＆エクステリアデザイン",
            intro4: "空の部屋を家具で埋める",
            intro5: "フォトリアルなレンダリング"
        },
        david: {
            name: "David、👋 こんにちは、お客様",
            title: "スケッチが得意です",
            intro: "私にできること：",
            intro1: "数秒でスケッチをリアルな写真に変換",
            intro2: "スケッチから任意のデザインへ",
            intro3: "ハウスデザイン",
            intro4: "建築デザイン",
            intro5: "インテリアデザイン"
        },
        annie: {
            name: "Annie、👋 こんにちは、お客様",
            title: "リモデリングが得意です",
            intro: "私にできること：",
            intro1: "窓、天井、ドアを維持",
            intro2: "部屋を家具で埋める",
            intro3: "ハウスデザイン",
            intro4: "建築デザイン",
            intro5: "インテリアデザイン"
        }
    },
    roomsList: {
        "living_room": "リビングルーム",
        "bedroom": "ベッドルーム",
        "dining_room": "ダイニングルーム",
        "bath_room": "バスルーム",
        "study_room": "書斎",
        "gaming_room": "ゲームルーム",
        "meeting_room": "会議室",
        "kitchen": "キッチン",
        "toilet": "トイレ",
        "coworking_space": "コワーキングスペース",
        "fitness_gym": "フィットネスジム",
        "mudroom": "マッドルーム",
        "walk_in_closet": "ウォークインクローゼット",
        "office": "オフィス",
        "hotel_room": "ホテルの部屋",
        "hotel_lobby": "ホテルロビー",
        "hotel_bathroom": "ホテルのバスルーム",
        "exhibition_space": "展示スペース",
        "house_exterior": "家の外観",
        "attic": "屋根裏",
        "onsen": "温泉",
        "drop_zone": "ドロップゾーン",
        "workshop": "ワークショップ",
        "nail": "ネイルサロン",
        "restaurant": "レストラン",
        "coffee_shop": "コーヒーショップ",
        "clothing_store": "衣料品店",
        "family_room": "ファミリールーム",
        "kids_room": "子供部屋",
        "balcony": "バルコニー",
        "wine_cellar": "ワインセラー",
        "rooftop_terrace": "屋上テラス",
        "sunroom": "サンルーム",
        "home_spa": "ホームスパ",
        "craft_room": "クラフトルーム",
        "dressing_room": "ドレッシングルーム",
        "guest_bedroom": "ゲストベッドルーム",
        "home_bar": "ホームバー",
        "library": "図書室",
        "art_studio": "アートスタジオ",
        "yoga_studio": "ヨガスタジオ",
        "photo_studio": "フォトスタジオ",
        "multimedia_room": "マルチメディアルーム",
        "auditorium": "講堂",
        "medical_exam_room": "医療診察室",
        "reception_area": "受付エリア",
        "music_room": "音楽室",
        "science_laboratory": "科学実験室",
        "home_theater": "ホームシアター",
        "wedding_room": "ウェディングルーム",
        "laundry_room": "ランドリールーム",
        "outdoor_kitchen": "アウトドアキッチン",
        "utility_room": "ユーティリティルーム",
        "pet_room": "ペットルーム",
        "home_gym": "ホームジム",
        "lounge": "ラウンジ",
        "play_room": "プレイルーム",
        "reading_nook": "読書コーナー",
        "sauna": "サウナ",
        "man_cave": "マンケイブ",
        "foyer": "玄関ホール",
        "green_house": "温室",
        "she_shed": "シーシェッド",
        "conservatory": "温室",
        "nursery": "保育室",
        "prayer_room": "祈祷室",
        "side_of_house": "家の側面",
        "front_of_house": "家の正面",
        "back_of_house": "家の裏側",
        "backyard": "裏庭",
        "patio": "パティオ",
        "terrace": "テラス",
        "front_yard": "前庭",
        "garden": "庭",
        "court_yard": "中庭",
        "pool_area": "プールエリア",
        "porch": "ポーチ",
        "playground": "遊び場"
    },
    giveAway: {
        title: '全プラン10%オフ',
        subTitle: 'アプリを使用して新機能や今後のプレゼントについて通知を受け取る 👇',
        enterYourEmail: 'メールアドレスを入力...',
        notifyMe: '今すぐ取得 ↗',
        note: '重要なアップデートのみ送信します。スパムではありません！いつでも登録解除可能。',
        submitting: '送信中...',
        emailSent: 'メール送信完了。',
        emailSentSub: 'メールを確認してDecorifyの使用を開始してください！',
        emailSentTitle: 'やった！登録完了！',
    },
    feedback: {
        title: 'フィードバックをお寄せください！',
        subTitle: '改善のためのフィードバックをお聞かせください。100クレジットを獲得するチャンスがあります！',
        enterYourFeedback: 'ご意見をお聞かせください...',
        notifyMe: '今すぐ送信 ↗',
        note: '重要なアプリの更新のみをお送りします。スパムは送りません！いつでも購読解除できます。',
        submitting: '送信中...',
        sent: 'フィードバックを受け取りました ✅',
        sentSub: 'フィードバックを確認し、すぐにご連絡いたします！',
        type: {
            bug: 'バグ報告',
            feature: '機能リクエスト',
            feedback: 'その他のフィードバック',
            refund: '返金リクエスト',
        },
        emptyFeedback: 'ご意見を入力してください！',
        emptyFeedbackType: 'フィードバックの種類を選択してください！',
    }
};

export default jp;