import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LovedBy from '../LovedBy';

const CHECKOUT_PRO_MONTHLY_URL = `${process.env.REACT_APP_PAYMENT_CHECKOUT_URL}/${process.env.REACT_APP_PAYMENT_PRO_MONTHLY}`;
const CHECKOUT_PRO_YEARLY_URL = `${process.env.REACT_APP_PAYMENT_CHECKOUT_URL}/${process.env.REACT_APP_PAYMENT_PRO_YEARLY}`;
const CHECKOUT_STARTER_URL = `${process.env.REACT_APP_PAYMENT_CHECKOUT_URL}/${process.env.REACT_APP_PAYMENT_STARTER}`;


const PricingLandingPage = ({ t, currentLanguage, userData, showLoginForPayment }) => {
  const [affCode, setAffCode] = useState('');

  useEffect(() => {
    // Retrieve the stored referralCode from local storage
    const storedAffCode = localStorage.getItem('affCode');

    if (storedAffCode) {
      setAffCode(storedAffCode)
    }
  });

  return (
    <>
      <div id="pricing" className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
        <span>{t('landing.pricingLabel')}</span>
      </div>
      <h2 className="mx-auto relative z-[10] t-50 text-white mb-3 md:mb-6 font-bold text-center mx-auto md:text-center">
        {t('landing.pricingTitle')}
      </h2>
      <div
        className="mx-auto font-body relative z-10 md:mb-12 t-20-l text-gray-500  text-center mx-auto md:text-center"
        style={{ maxWidth: '722px' }}
      >
        <p>{t('pricing.credit.title')}</p>
      </div>
      <div id="pricing-landing">
        <div id="pricing-cards">
          <div className="price-card price-card">
            <div className="price-card--header">
              <h4>
                {t('pricing.first.title')}
              </h4>
            </div>
            <div className="price-card--price">
              <div className="price-card--price-text">
                <div className="price-card--price-number toggle-price-content odometer">
                  {t('pricing.first.price')}
                </div>
              </div>
              <div className="price-card--price-conditions">
                <div className="price-card--onboarding-fee toggle-price-content">
                  {t('pricing.first.priceDetail')}
                </div>
              </div>
            </div>
            <div className="price-card--cta">
              {!(userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) ? (

                <a
                  className="price-card--cta--button upgrade-button"
                  href={`${CHECKOUT_STARTER_URL}?checkout[email]=${userData.email}&aff=${affCode}&checkout[custom][email]=${userData.email}`}
                >
                  {t('button.subscribe')}
                  <span style={{ marginLeft: '4px' }}>↗</span>
                </a>
              ) : (
                <Link
                  onClick={() => showLoginForPayment(`${CHECKOUT_STARTER_URL}?aff=${affCode}`)}
                  // to={currentLanguage === 'en' ? '/signin' : `/${currentLanguage}/signin`}
                  className="price-card--cta--button upgrade-button"
                >
                  {t('button.subscribe')}
                  <span style={{ marginLeft: '4px' }}>↗</span>
                </Link>
              )}
            </div>
            <div className="price-card--features">
              <ul className="price-card--features--list">
                <li className="price-card--features--item font-bold text-white">
                  <span className="green-check">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="#ffc337"><path d="M17.333 4v9.333h8L14.666 28v-9.333H6.667z" /></svg>
                  </span>
                  {t('pricing.first.credit')}
                </li>
                <li className="price-card--features--item font-bold text-white">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.first.two')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.first.five')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.first.fourteen')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.first.one')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.first.nine')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.first.four')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.first.seven')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.first.eight')}
                </li>
              </ul>
            </div>
          </div>
          <div className="x-price-card price-card--hero">
            <div className="price-card--hero-text">{t('pricing.popular')}</div>
            <div className="price-card--header">
              <h4>
                {t('pricing.second.title')}
                <span className="price-card--price-number-after" style={{ marginLeft: '4px', color: '#72ff9a' }}>
                  {t('pricing.second.subTitle')}
                </span>
              </h4>
            </div>
            <div className="price-card--price">
              <div className="price-card--price-text">
                <div className="price-card--price-number toggle-price-content odometer">
                  {t('pricing.second.price')}
                </div>
              </div>
              <div className="price-card--price-conditions">
                <div className="price-card--onboarding-fee toggle-price-content">
                  {t('pricing.second.priceDetail')}
                </div>
              </div>
            </div>
            <div className="price-card--cta">
              {!(userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) ? (

                <a
                  className="price-card--cta--button upgrade-button"
                  href={`${CHECKOUT_PRO_MONTHLY_URL}?checkout[email]=${userData.email}&aff=${affCode}&checkout[custom][email]=${userData.email}`}
                >
                  {t('pricing.second.subscribed')}
                  <span style={{ marginLeft: '4px' }}>↗</span>
                </a>
              ) : (
                <Link
                  onClick={() => showLoginForPayment(`${CHECKOUT_PRO_MONTHLY_URL}?aff=${affCode}`)}
                  // to={currentLanguage === 'en' ? '/signin' : `/${currentLanguage}/signin`}
                  className="price-card--cta--button upgrade-button"
                >
                  {t('pricing.second.subscribed')}
                  <span style={{ marginLeft: '4px' }}>↗</span>
                </Link>
              )}
            </div>
            <div className="price-card--features">
              <ul className="price-card--features--list">
                <li className="price-card--features--item font-bold text-white">
                  <span className="green-check">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="#ffc337"><path d="M17.333 4v9.333h8L14.666 28v-9.333H6.667z" /></svg>
                  </span>
                  {t('pricing.second.credit')}
                </li>
                <li className="price-card--features--item font-bold text-white">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#72ff9a"
                      />
                    </svg>
                  </span>
                  {t('pricing.second.two')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#72ff9a"
                      />
                    </svg>
                  </span>
                  {t('pricing.second.five')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#72ff9a"
                      />
                    </svg>
                  </span>
                  {t('pricing.second.fourteen')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#72ff9a"
                      />
                    </svg>
                  </span>
                  {t('pricing.second.one')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#72ff9a"
                      />
                    </svg>
                  </span>
                  {t('pricing.second.nine')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#72ff9a"
                      />
                    </svg>
                  </span>
                  {t('pricing.second.four')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#72ff9a"
                      />
                    </svg>
                  </span>
                  {t('pricing.second.seven')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#72ff9a"
                      />
                    </svg>
                  </span>
                  {t('pricing.second.eight')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#72ff9a"
                      />
                    </svg>
                  </span>
                  {t('pricing.second.ten')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#72ff9a"
                      />
                    </svg>
                  </span>
                  {t('pricing.second.eleven')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#72ff9a"
                      />
                    </svg>
                  </span>
                  {t('pricing.second.six')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#72ff9a"
                      />
                    </svg>
                  </span>
                  {t('pricing.second.three')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#72ff9a"
                      />
                    </svg>
                  </span>
                  {t('pricing.second.twelve')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#72ff9a"
                      />
                    </svg>
                  </span>
                  {t('pricing.second.thirteen')}
                </li>
              </ul>
            </div>
          </div>
          <div className="price-card">
            <div className="price-card--header">
              <h4>
                {t('pricing.third.title')}
                <span className="price-card--price-number-after" style={{ marginLeft: '4px', color: '#72ff9a' }}>
                  {t('pricing.third.subTitle')}
                </span>
              </h4>
            </div>
            <div className="price-card--price">
              <div className="price-card--price-text">
                <div className="price-card--price-number toggle-price-content odometer">
                  {t('pricing.third.price')}
                </div>
              </div>
              <div className="price-card--price-conditions">
                <div className="price-card--onboarding-fee toggle-price-content">
                  {t('pricing.third.priceDetail')}
                </div>
              </div>
            </div>
            <div className="price-card--cta">
              {!(userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) ? (

                <a
                  className="price-card--cta--button upgrade-button"
                  href={`${CHECKOUT_PRO_YEARLY_URL}?checkout[email]=${userData.email}&aff=${affCode}&checkout[custom][email]=${userData.email}`}
                >
                  {t('pricing.second.subscribed')}
                  <span style={{ marginLeft: '4px' }}>↗</span>
                </a>
              ) : (
                <Link
                  onClick={() => showLoginForPayment(`${CHECKOUT_PRO_YEARLY_URL}?aff=${affCode}`)}
                  // to={currentLanguage === 'en' ? '/signin' : `/${currentLanguage}/signin`}
                  className="price-card--cta--button upgrade-button"
                >
                  {t('pricing.third.subscribed')}
                  <span style={{ marginLeft: '4px' }}>↗</span>
                </Link>
              )}
            </div>
            <div className="price-card--features">
              <ul className="price-card--features--list">
                <li className="price-card--features--item font-bold text-white">
                  <span className="green-check">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="#ffc337"><path d="M17.333 4v9.333h8L14.666 28v-9.333H6.667z" /></svg>
                  </span>
                  {t('pricing.third.credit')}
                </li>
                <li className="price-card--features--item font-bold text-white">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.third.two')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.third.five')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.third.fourteen')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.third.one')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.third.nine')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.third.four')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.third.seven')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.third.eight')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.third.ten')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.third.eleven')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.third.six')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.third.three')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.third.twelve')}
                </li>
                <li className="price-card--features--item">
                  <span className="green-check">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                        fill="#339557"
                      />
                    </svg>
                  </span>
                  {t('pricing.third.thirteen')}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div style={{ color: "#fff" }}>
        <LovedBy t={t} />
      </div>
    </>
  );
};

export default PricingLandingPage;
