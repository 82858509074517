import React from 'react';

const styleList = [
  {
    before: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F1.webp?alt=media&token=7324c826-ecd9-460b-a38d-581901182099",
    after: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F2.webp?alt=media&token=e867de48-3bf5-48a7-a7c0-616d6fa3cc31",
    title: ""
  },
  {
    before: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F3.webp?alt=media&token=bca96e92-1d8f-47b5-b74f-e41fbfbb044d",
    after: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F4.webp?alt=media&token=5ee936a2-291d-44c9-9fcc-bb2f45be1283",
    title: ""
  },
  {
    before: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F5.webp?alt=media&token=3b21a822-754e-4aff-acd8-278b17250ca3",
    after: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F6.webp?alt=media&token=fa13d8fd-8698-4cd6-8f5f-f078f8116f25",
    title: ""
  },
  {
    before: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F7.webp?alt=media&token=deb7ef7c-9bd5-47d0-9a96-495446f8ad0d",
    after: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F8.webp?alt=media&token=448a9ed9-c41a-4333-ad04-96265b881ede",
    title: ""
  },
  {
    before: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F9.webp?alt=media&token=7101f42e-3498-4248-8666-eb77dc40a1d7",
    after: "https://firebasestorage.googleapis.com/v0/b/decorifyai.appspot.com/o/meta%2Fsketch_output.webp?alt=media&token=21b0d36f-1353-435f-8635-ab1fbf6364ab",
    title: ""
  },
  {
    before: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F19.webp?alt=media&token=76315c4f-2c5d-47f2-a993-a2b29eba4894",
    after: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F20.webp?alt=media&token=8b314ff9-80aa-48ae-8b6e-1c9d12ea6350",
    title: ""
  },
  {
    before: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F25.webp?alt=media&token=a6237cf3-1345-4e56-883b-a6d6b0340f82",
    after: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F12.webp?alt=media&token=242aad0d-51e6-4633-94ce-7e5c695ad442",
    title: ""
  },
  {
    before: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F13.webp?alt=media&token=18e81f9c-cd80-4766-b331-bbcc609c4e34",
    after: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F14.webp?alt=media&token=c5ce624e-e7c4-4ac2-8b3c-074004e5e1f9",
    title: ""
  },
  {
    before: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F15.webp?alt=media&token=670be663-1b50-42bb-bee0-cfcd9d4f7d11",
    after: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F16.webp?alt=media&token=4f7da4e1-ba2c-4296-ad82-e4f478cf3a43",
    title: ""
  },
  // {
  //   before: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F17.webp?alt=media&token=3198d125-3bc4-4b5a-878a-0309fdd8374c",
  //   after: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F18.webp?alt=media&token=9c7f8f16-d46a-4318-a31b-f0e10a795b4f",
  //   title: ""
  // },
  {
    before: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F21.webp?alt=media&token=e894742f-5809-4c33-9086-1e824fb87cf8",
    after: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F22.webp?alt=media&token=f82d81e4-24e5-418d-b4c4-f83e036016d7",
    title: ""
  },
  {
    before: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F23.webp?alt=media&token=c3be757a-f838-4946-ab40-8a258cb356ff",
    after: "https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F24.webp?alt=media&token=10a382b0-7376-4f66-bab2-e0b0eb907786",
    title: ""
  },
]

const BeforeAfterTestimonial = ({ images }) => {
  return (
    <div className="relative">
      <div className="slider-in group relative grid slider-in-motions">
        <div className="relative mx-auto grid h-full w-full max-w-[2200px] py-1.5 grid-cols-2">
          <div className="relative col-span-1 overflow-hidden">
            <div className="space-y-8 md:space-y-[85px]">
              <div className="relative mx-auto max-w-[2200px] overflow-hidden">
                <div className="infinite-scroll-16 -mt-4 flex max-h-[780px] min-w-[6900px] overflow-hidden lg:min-w-[6900px]">
                  <div className="slider-running-wrapper">
                    {styleList.map((c, i) => {
                      return (
                        <div className="relative" key={i}>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper_landing">
                              <div className="result-box">
                                <img
                                  className="banner-video"
                                  loading="eager" decoding="async"
                                  alt="decorify"
                                  src={c.before}
                                ></img>
                              </div>
                            </div>
                          </article>
                        </div>
                      )
                    })}
                  </div>
                  <div className="slider-running-wrapper">
                    {styleList.map((c, i) => {
                      return (
                        <div className="relative" key={i}>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper_landing">
                              <div className="result-box">
                                <img
                                  className="banner-video"
                                  loading="eager" decoding="async"
                                  alt="decorify"
                                  src={c.before}
                                ></img>
                              </div>
                            </div>
                          </article>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="absolute left-1/2 top-0 z-10 h-full w-3 -translate-x-1/2 rounded-sd border-2 transition-opacity md:w-3.5"
            style={{
              borderRadius: "10px",
              background: "#fff",
              borderWidth: "2px",
              border: "2px solid #ffc337"
            }}
          />
          <div className="relative col-span-1 overflow-hidden">
            <div className="space-y-8 md:space-y-[85px]">
              <div className="relative mx-auto max-w-[2200px] overflow-hidden">
                <div className="slider-in-right infinite-scroll-16 -mt-4 flex max-h-[780px] min-w-[6900px] overflow-hidden lg:min-w-[6900px]">
                  <div className="slider-running-wrapper-right">
                    {styleList.map((c, i) => {
                      return (
                        <div className="relative" key={i}>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper_landing">
                              <div className="result-box">
                                <img
                                  className="banner-video"
                                  loading="eager" decoding="async"
                                  alt="decorify"
                                  src={c.after}
                                ></img>
                              </div>
                            </div>
                          </article>
                        </div>
                      )
                    })}
                  </div>
                  <div className="slider-running-wrapper-right">
                    {styleList.map((c, i) => {
                      return (
                        <div className="relative" key={i}>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper_landing">
                              <div className="result-box">
                                <img
                                  className="banner-video"
                                  loading="eager" decoding="async"
                                  alt="decorify"
                                  src={c.after}
                                ></img>
                              </div>
                            </div>
                          </article>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeforeAfterTestimonial;
