import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';

const countries = [
  {
    label: 'English',
    lang: 'en',
    flag: '🇺🇸',
  },
  {
    label: 'Tiếng Việt',
    lang: 'vi',
    flag: '🇻🇳',
  },
  {
    label: 'Indonesia',
    lang: 'id',
    flag: '🇮🇩'
  },
  {
    label: '日本語',
    lang: 'jp',
    flag: '🇯🇵',
  },
  {
    label: '繁體中文',
    lang: 'zh',
    flag: '🇨🇳',
  },
  {
    label: '한국어',
    lang: 'ko',
    flag: '🇰🇷',
  }
];

const QuickLanguageSwitcher = () => {
  const [selected, setSelected] = useState(0);
  const dropdownRef = useRef(null);

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        dropdownRef.current.classList.remove('show');
        // Your code to handle closing the popup goes here
      }
    };

    document.addEventListener('click', handleClickOutside);

    const currentLanguage = i18n.language;
    const index = countries.findIndex((country) => country.lang === currentLanguage);
    setSelected(index);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [i18n.language]);

  const handleLanguageChange = (index) => {
    setSelected(index);
    dropdownRef.current.classList.remove('show');
    localStorage.setItem('mainLang', countries[index].lang);
  };

  function onOpenToggler(e) {
    e.stopPropagation();
    dropdownRef.current.classList.toggle('show');
  }

  function handleMouseEnter() {
    if (!dropdownRef.current.classList.contains('show')) {
      dropdownRef.current.classList.toggle('show');
    }
  }

  return (
    <div className="screen-mode-btn cursor-pointer" style={{paddingRight: "5px"}} onClick={onOpenToggler}>
      <span>{countries[selected].flag}</span>
      <div className="lang-drop-down text-gray-700 shadow-md rounded text-sm absolute mb-20 bottom-0 right-0 min-w-full" ref={dropdownRef}>
        <div className="overflow-auto rounded w-full relative z-10 lang-border">
          <ul className="list-reset">
            {countries.map((item, index) => (
              <li key={index}>
                <a
                  href={item.lang === 'en' ? "/app" : `/${item.lang}/app` }
                  className={`px-4 py-2 flex hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 ${index === selected ? 'font-bold' : ''}`}
                  onClick={() => handleLanguageChange(index)}
                >
                  <span className="inline-block pr-4">{item.flag}</span>
                  <span className="inline-block text-white">{item.label}</span>
                  {index === selected && (
                    <span style={{marginTop: "-2px", marginLeft: "2px"}}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z" fill="#72ff9a"></path></svg>
                    </span>
                  )}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default QuickLanguageSwitcher;

