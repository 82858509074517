import React, {useEffect, useState} from "react"
import { useStore } from "../lib/states"
import { UPSCALE_MODEL, VIRTUAL_STAGING_MODEL, REMOVE_OBJECTS_MODEL } from '../../../constants/model';

const ImageSize = ({choseModel}) => {
  const [imageWidth, imageHeight, settings, file] = useStore((state) => [
    state.imageWidth,
    state.imageHeight,
    state.settings,
    state.file,
  ])
  const [credits, setCredits] = useState(0);

  useEffect(() => {
    if (!imageWidth || !imageHeight || !file) {
      setCredits(0);
    } else {
      if (choseModel === UPSCALE_MODEL) {
        if (settings.upscaleSize === 2) {
          setCredits(5);
        } else {
          setCredits(10);
        }
      }
      if (choseModel === REMOVE_OBJECTS_MODEL) {
        setCredits(1);
      }
      if (choseModel === VIRTUAL_STAGING_MODEL) {
        setCredits(5);
      } 
    }
  }, [imageWidth, imageHeight, settings, choseModel, file]);

  return (
    <div className="border rounded-lg px-2 py-[6px] z-10 bg-background mobile-image-size">
      {imageWidth}x{imageHeight} <span>{": [-"}
        <svg style={{marginTop: "-3px"}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="#ffc337"><path d="M17.333 4v9.333h8L14.666 28v-9.333H6.667z"/></svg>
        {credits}{"]"}
      </span>
    </div>
  )
}

export default ImageSize
