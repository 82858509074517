const ko = {
    Basic: "기본",
    Pro: "프로",
    month: "월",
    year: "년",
    button: {
        getStarted: '시작하기',
        try: 'AI로 디자인 시작하기',
        viewall: '모두 보기',
        basic: '기본',
        pro: '프로',
        generate: '디자인 생성',
        backHome: '홈으로 돌아가기',
        googleSignin: 'Google로 시작하기',
        googleSignup: 'Google로 시작하기',
        emailSignin: '이메일로 로그인',
        emailSignup: '이메일로 시작하기',
        submit: '제출',
        choose: '선택',
        download: '이 템플릿 다운로드',
        upgrade: '크레딧 구매',
        subscribe: '구매하기',
        upgradePro: '업그레이드',
        confirmPayment: '결제 확인',
        tryThisFeature: '지금 시도해보기',
        removeBG: '시작',
        professional: '시작',
        upscale: '시작',
        ok: '확인',
        cancel: '취소',
        select: '선택',
        selectToStart: '시작하려면 선택하세요',
        uploadPose: '포즈 업로드',
        uploadingPose: '포즈 업로드 중',
        upgradeToUpload: '업로드하려면 업그레이드하세요',
        unlock: "업그레이드",
        englishOnly: "영어 버전",
        contactUs: "문의하기",
        forEnterprise: "기업 솔루션",
        tryThis: "시도해보기",
        upscale: "업스케일",
        remove: "제거",
        render: "렌더링",
        rendering: "렌더링 중",
    },
    header: {
        nav: {
            signIn: '로그인',
            signUp: '회원가입',
            signOut: '로그아웃',
            billing: '결제',
            supportTitle: '고객지원',
            supportContact: '문의하기',
            myFlowers: '내 디자인'
        }
    },
    landing: {
        title: '건축용 AI 렌더링 도구',
        joinNow: '멋진 디자인을 만들 준비가 되셨나요?',
        subTitle: "가상 스테이징, 리모델링, 조경, 인테리어 디자인 등을 위한 AI 기반 앱. 순식간에 디자인을 생성합니다.",
        subTitle1: "시간과 비용을 쉽게 절약하세요. 전 세계의 주택 소유자, 에어비앤비 홈 서비스, 기업 및 전문가들이 신뢰합니다.",
        input: '만들고 싶은 꽃의 종류를 알려주세요?',
        inside: '실내',
        outside: '실외',
        loveBy: '45,880개 이상의 행복한 디자인이 이미 생성되었습니다',
        respectTitle: '우리는 귀하의 개인정보를 존중합니다',
        respectSub1: 'FaceID나 여러 장의 사진이 필요하지 않습니다. 페이스북이나 인스타그램에 올리는 것처럼 단일 셀카만 사용하세요.',
        respectSub2: '귀하의 데이터는 구글 플랫폼의 높은 보안 수준으로 완전히 안전하게 보호됩니다.',
        howItWorks: '작동 방식',
        howItWorksSub: '사진 찍기 또는 업로드 -> 스타일과 배경 선택 -> 포즈 선택 또는 업로드 -> 헤드샷 받기!',
        unBlurryTitle: '사진 업스케일러',
        unBlurrySub: '페이스북, 인스타그램, 틱톡과 같은 소셜 미디어 플랫폼에 게시하기 전에 흐림 제거, 향상 및 사진 색상 개선.',
        useThisFeature: '지금 만들어보세요',
        anotherFeatureTitle: '뛰어난 기능',
        anotherFeatureSub: '무료, 빠르고 정확합니다. 아래 기능들을 시도해보세요.',
        feature1: '배경 제거',
        feature1Sub: '정확하게 모든 배경을 제거합니다.',
        feature2: '인테리어 디자인',
        feature2Sub: 'genhomeai.com을 통한 인테리어 디자인, 외부 디자인 및 스케치',
        feature3: '배경 변경 (곧 출시)',
        feature3Sub: 'REOK의 AI로 모든 객체의 배경 변경',
        hotFeatureTitle: '고객들의 평가',
        hotFeatureSub: '가장 현실적입니다. 전문가들이 신뢰합니다. 모든 사람을 위해 만들어졌습니다.',
        pricingTitle: '플랜 선택',
        pricingLabel: '가격',
        pricingSub: '전문 사진 1장 = 10 크레딧 이하',
        step1: '공간 업로드',
        step1Sub: '스타일을 선택하거나 직접 입력하세요.',
        step2: 'AI 디자이너가 순식간에 작업을 시작합니다',
        step2Sub: '최첨단 기술로 빠르게 장식을 만듭니다.',
        step3: '장식 받기',
        step3Sub: '장식을 다운로드하세요.',
        trialTitle: "무료 체험",
        trialSubTitle: "#1 AI 헤드샷 생성기 📸",
        feedbackLabel: '피드백',
        faqLabel: '자주 묻는 질문',
        sketch: '스케치',
        creativity: '창의성',
        upscale4k: '업스케일',
        remove: '클린룸',
        fillup: '가구',
        exterior: '외부',
        interior: '내부',
        steps: '단계',
        interiorShowCase: {
            label: "인테리어",
            title: "모든 스타일로 재디자인",
            subTitle: "다양한 스타일로 공간을 변경하여 멋지게 만들어 드립니다. 모든 유형의 인테리어, 외부 또는 정원을 재디자인하세요."
        },
        exteriorShowCase: {
            label: "외부",
            title: "외부를 위한 창의적인 재디자인",
            subTitle: "혁신적인 재디자인 솔루션으로 실내외 공간을 개선하여 모든 구석에 창의성을 불어넣어 진정으로 매력적인 분위기를 만듭니다."
        },
        furnitureShowCase: {
            label: "가구",
            title: "빈 방을 가구로 채우기",
            subTitle: "완벽한 것을 찾을 때까지 다양한 설정을 시도해볼 수 있습니다. Decorify로 빈 방을 가구로 장식하세요. 공사 중이거나 빈 공간에도 적용 가능합니다."
        },
        cleanRoomShowCase: {
            label: "클린룸",
            title: "원치 않는 물체 제거",
            subTitle: "원치 않는 물체를 쉽게 제거하여 방을 손쉽게 변형하고 선호하는 환경을 만들 수 있습니다."
        },
        upscaleShowCase: {
            label: "업스케일",
            title: "모든 디자인을 4K로 업스케일",
            subTitle: "업스케일 디자인 서비스로 모든 환경의 분위기를 높이고, 4K 해상도까지 놀라운 선명도와 디테일을 보장하여 진정으로 몰입감 있는 경험을 제공합니다."
        },
        creativityShowCase: {
            label: "창의성",
            title: "맞춤형 AI 창의성",
            subTitle: "AI 개입으로 디자인 여정을 주도하세요. AI의 도움을 얼마나 받을지 결정할 수 있습니다. 약간의 제안만 원하든 완전한 변신을 원하든 모두 당신의 선택입니다."
        },
        sketchShowCase: {
            label: "스케치",
            title: "스케치를 현실적인 디자인으로",
            subTitle: "모든 스케치를 생생하고 현실적인 디자인으로 변환하여 정밀도와 진정성으로 당신의 개념을 생생하게 실현합니다."
        },
    },
    yearbook: {
        title: 'AI 졸업앨범 사진 촬영 📸',
        subTitle: "많은 셀카 이미지를 사용하지 않습니다. 단일 셀카로 AI 사진작가와 함께 90년대 졸업앨범 사진을 순식간에 얻으세요. 시간을 10배 절약하세요.",
        input: '만들고 싶은 꽃의 종류를 알려주세요?',
        inside: '실내',
        outside: '실외',
        loveBy: '45,880개 이상의 행복한 디자인이 이미 생성되었습니다',
        respectTitle: '우리는 귀하의 개인정보를 존중합니다',
        respectSub1: '우리는 FaceID를 사용하거나 많은 사진을 요구하지 않습니다. 페이스북과 인스타그램에 자주 올리는 것과 같은 셀카만 사용하세요.',
        respectSub2: '귀하의 데이터는 구글 플랫폼의 높은 보안 수준으로 완전히 안전하게 보호됩니다.',
        howItWorks: 'AI 졸업앨범 작동 방식',
        howItWorksSub: '셀카를 찍거나 명확한 ID 사진을 사용하고, 스타일과 포즈를 선택하면 순식간에 헤드샷을 만듭니다!',
        unBlurryTitle: '사진 업스케일러',
        unBlurrySub: '페이스북, 인스타그램, 틱톡과 같은 소셜 미디어 플랫폼에 게시하기 전에 흐림 제거, 향상 및 사진 색상 개선.',
        useThisFeature: '지금 만들어보세요',
        anotherFeatureTitle: '뛰어난 기능',
        anotherFeatureSub: '무료, 빠르고 정확합니다. 아래 기능들을 시도해보세요.',
        feature1: '배경 제거',
        feature1Sub: '정확하게 모든 배경을 제거합니다.',
        feature2: '인테리어 디자인',
        feature2Sub: 'genhomeai.com을 통한 인테리어 디자인, 외부 디자인 및 스케치',
        feature3: '배경 변경 (곧 출시)',
        feature3Sub: 'REOK의 AI로 모든 객체의 배경 변경',
        hotFeatureTitle: '우리의 AI 졸업앨범 사진',
        hotFeatureSub: '이것들은 AI로 생성된 헤드샷입니다.',
        pricingTitle: '플랜 선택',
        pricingLabel: '가격',
        pricingSub: '전문 사진 1장 = 10 크레딧 이하',
    },
    showcase: {
        title: '샘플 꽃',
        status: {
            pending: '처리 중',
            upscaling: '업스케일링 중',
            choosing: '선택 중',
            finished: '완료'
        },
        mode: {
            pro: '프로',
            basic: '기본'
        }
    },
    home: {
        title: '내 디자인',
        hotColletion: "맞춤 스타일",
        credits: '남은 디자인',
        uploadTitle: '공간 업로드',
        uploadPlaceHolder: '파일을 드롭하거나 클릭하여 업로드',
        stylePlaceHolder: '선택',
        uploadPosePlaceHolder: '선택',
        uploadBGPlaceHolder: '선택',
        roomTitle: '방 유형 선택 (29)',
        themeTitle: '방 스타일 선택 (34)',
        pro: {
            title: '모든 유형의 꽃을 위한 창의성',
            input: '꽃을 설명해주세요...',
        },
        basic: {
            title: '프로로 무한한 창의성',
            chooseFlowerTitle: '꽃',
            flowers: '거실',
            styles: '스타일',
            decoration: '장식',
            events: '이벤트',
            backgrounds: '배경'
        },
    },
    profile: {
        title: '내 디자인',
        before: '이전 -',
        after: '이후 -'
    },
    terms: {
        title: '이용 약관',
        content: ''
    },
    //Part 2
    privacy: {
        title: '개인정보 처리방침',
        one: '귀하의 개인정보는 저희에게 매우 중요합니다. decorify.pro의 개인정보 처리방침은 당사 웹사이트 decorify.pro 및 당사가 소유하고 운영하는 다른 웹사이트에서 귀하로부터 수집할 수 있는 모든 정보에 대해 귀하의 개인정보를 존중합니다.',
        two: '저희는 귀하에게 서비스를 제공하기 위해 진정으로 필요한 경우에만 개인정보를 요청합니다. 저희는 귀하의 인지와 동의 하에 공정하고 합법적인 방법으로 이를 수집합니다. 또한 저희는 왜 이 정보를 수집하고 어떻게 사용할 것인지 알려드립니다.',
        three: '저희는 귀하가 요청한 서비스를 제공하는 데 필요한 기간 동안만 수집된 정보를 보유합니다. 저희가 저장하는 데이터는 손실과 도난, 무단 접근, 공개, 복사, 사용 또는 수정을 방지하기 위해 상업적으로 허용되는 수단 내에서 보호합니다.',
        four: '저희는 법률에 의해 요구되는 경우를 제외하고는 개인 식별 정보를 공개적으로 또는 제3자와 공유하지 않습니다.',
        five: '저희 웹사이트는 저희가 운영하지 않는 외부 사이트로 연결될 수 있습니다. 이러한 사이트의 콘텐츠와 관행에 대해 저희는 통제할 수 없으며 그들의 개인정보 처리방침에 대해 책임이나 의무를 지지 않음을 유의해 주시기 바랍니다.',
        six: '귀하는 개인정보 제공 요청을 거부할 자유가 있습니다. 다만, 이 경우 저희가 귀하가 원하는 일부 서비스를 제공하지 못할 수 있음을 이해해 주시기 바랍니다.',
        seven: '귀하가 계속해서 저희 웹사이트를 사용하는 것은 개인정보와 관련된 저희의 관행을 수락하는 것으로 간주됩니다. 사용자 데이터와 개인정보 처리 방식에 대해 궁금한 점이 있으시면 언제든 문의해 주시기 바랍니다.',
        eight: '이 정책은 2023년 9월 15일부터 유효합니다.'
    },
    refund: {
        moneyBack: '환불 보장',
        title: '환불 정책',
        one: '저희의 목표는 모든 고객에게 고품질의 서비스와 결과를 제공하는 것입니다. 저희는 결과물이 귀하의 기대에 미치지 못하는 경우가 있을 수 있음을 이해합니다.',
        two: '저희는 귀하의 만족을 보장합니다. Reok.pro를 사용하여 생성된 사진에 만족하지 않으시면 전액 환불해 드리겠습니다. 또는 경우에 따라 귀하의 기대에 더 잘 부합하도록 사진을 다시 제작해 드릴 수 있습니다.',
        three: '이 정책에 따라 환불 요청을 검토하고 1-3 영업일 이내에 응답해 드리겠습니다. 환불이 승인되면 5-10 영업일 이내에 귀하의 카드로 환불됩니다. 환불은 구매 시 사용한 원래의 결제 방법으로 처리되며, 다른 계정으로는 환불할 수 없습니다.',
        four: '구매 후 30일 이내에 제출된 환불 요청과 20 크레딧 미만 사용에 대해서만 환불 처리가 가능합니다.',
        five: '환불을 신청하려면 hello@decorify.pro로 이메일을 보내거나 웹사이트에서 제공하는 채팅 기능을 사용해 주세요. 환불에 대해 문의하실 때는 주문 시 사용한 이메일 주소를 포함해 주시기 바랍니다.',
        six: 'Reok.pro를 선택해 주셔서 감사합니다.',
    },
    footer: {
        title: 'Decorify는 가상 스테이징, 리모델링, 조경, 인테리어 디자인 등을 위한 AI 기반 앱입니다.',
        terms: '이용약관',
        privacy: '개인정보 처리방침',
        app: '앱',
        signin: '로그인',
        signup: '회원가입',
        email: '이메일',
        chatNow: '지금 채팅하기',
        partner: '파트너',
        reportBug: '버그 신고',
        require: '요청 제출',
        page: '페이지',
        tos: '법률',
        contact: '연락처',
        other: '기타',
        affiliate: '제휴',
        yearbook: 'AI 졸업앨범',
        blog: '블로그',
        pricing: '가격',
        howItWorks: '작동 방식',
        sendFeedback: '피드백 보내기'
    },
    tos: {
        agree: '새 계정은 다음 약관에 동의해야 합니다 ',
        one: '1. 소개',
        oneDetail: 'decorify.pro를 사용함으로써 귀하는 이 이용 약관에 동의하고 이에 구속됩니다.',
        two: '2. 약관 동의',
        twoDetail: '이 계약은 귀하가 decorify.pro 앱을 처음 사용한 날부터 유효합니다.',
        three: '3. 기능',
        threeDetail: '특정 유료 플랜을 구독한 후에 모든 기능을 사용할 수 있습니다. 사용 가능한 기능에 대한 자세한 내용은 가격 페이지에서 확인할 수 있습니다.',
        four: '4. 환불 정책',
        fourDetail: '구매 후 14일 이내의 실패한 거래에 대해 환불을 허용합니다. 환불을 요청하려면 고객 지원에 문의하세요.',
        five: '5. 제품 사용',
        fiveDetail: 'decorify.pro를 사용함으로써 귀하는 Google 계정과 연결된 이메일 또는 계정 등록에 사용한 이메일을 통해 decorify.pro로부터 중요한 제품 업데이트를 받는 것에 동의합니다. 각 이메일 하단의 "구독 취소" 링크를 클릭하여 언제든지 이러한 제품 업데이트 수신을 거부할 수 있습니다. 저희는 중요한 제품 업데이트만 보냅니다.',
        six: '6. 면책 조항',
        sixDetail: '저희는 decorify.pro가 귀하의 요구 사항을 충족하거나 그 작동이 중단되지 않거나 오류가 없을 것이라고 보장하지 않습니다. 이 계약에 명시적으로 규정되지 않은 모든 보증 또는 조건(이익 손실, 데이터 손실 또는 손상, 사업 중단 또는 기타 모든 성격의 금전적 손실, 손해, 비용 또는 지출을 포함하되 이에 국한되지 않음)은 법률이 허용하는 최대 범위 내에서 제외되고 부인됩니다. 이 계약은 소비자로서 귀하가 가질 수 있는 법적 권리에 영향을 미치지 않습니다.',
        seven: '7. 보증 및 법적 제한',
        sevenDetail: 'decorify.pro는 소프트웨어의 품질, 특정 목적에 대한 적합성 또는 기타 특성이나 성능에 대해 어떠한 보증, 보장 또는 기타 표현도 하지 않습니다. decorify.pro는 이 계약 또는 decorify.pro 사용과 관련하여 발생하는 모든 이익 손실 또는 결과적, 특별, 부수적 또는 간접적 손해에 대해 책임을 지지 않습니다(과실을 포함한 어떤 방식으로든 발생한 경우). 단, 법률에 따라 책임을 배제할 수 없는 경우는 예외입니다.',
        eight: '8. 일반 조건 및 법률',
        eightDetail: '귀하는 이러한 서비스를 사용한 결과로 귀하와 decorify.pro 사이에 합작 투자, 파트너십, 고용 또는 대리인 관계가 존재하지 않음을 인정합니다. 귀하는 decorify.pro의 대표자, 대리인 또는 직원으로 자신을 내세우지 않을 것에 동의합니다. 귀하는 decorify.pro가 플랫폼의 오용을 방지하거나 해결하기 위해 취하는 어떠한 조치에 대해서도 귀하에게 책임을 지지 않을 것에 동의합니다. 귀하는 decorify.pro의 사용을 제공함에 있어 저희가 귀하가 이 계약의 조건에 구속된다는 귀하의 동의에 의존했음을 인정합니다.',
    },
    faq: {
        title: '자주 묻는 질문',
        q1: 'Decorify는 어떻게 작동하나요?',
        answer1: 'Decorify는 실내, 실외, 정원, 파티오 등 집의 모든 부분을 변형하고 재설계할 수 있는 웹 기반 애플리케이션입니다. 인공지능의 힘을 활용하여 앱은 몇 초마다 새로운 컨셉을 생성하며, 원하는 결과를 얻을 수 있도록 다양한 스타일, 모드, 방 유형 등을 제공합니다.',
        q2: '정말로 시간과 비용을 절약할 수 있나요?',
        answer2: '네, 우리 앱은 개인 및 전문가 사용 모두에게 비용 효율적인 솔루션을 제공합니다. 비싼 전문가를 고용하지 않고도 다양한 가구, 장식, 뒷마당 디자인으로 집이 어떻게 보일지 시각화할 수 있으며, 영감을 얻기 위해 끝없는 웹사이트를 스크롤할 필요가 없습니다. 우리의 AI 디자이너는 사용자의 선호도를 분석하고 개인화된 추천을 생성하여 멋진 집을 쉽게 시각화하고 만들 수 있게 합니다.',
        q3: '모든 기기에서 작동하나요? (노트북, MacOS, PC, iOS, Android)',
        answer3: '물론입니다! 모바일 앱이나 데스크톱 프로그램을 설치할 필요 없이 가장 일반적으로 사용되는 브라우저(Chrome, Firefox, Safari)에서 실행할 수 있습니다.',
        q4: '얼마나 쉽게 사용할 수 있나요?',
        answer4: '이미지를 업로드하고 새로운 AI 아이디어 생성을 시작하기만 하면 됩니다.',
        q5: '누가 Decorify AI를 사용할 수 있나요?',
        answer5: '우리 앱은 실내, 정원 또는 건축에 대한 지식이나 전문성에 관계없이 누구나 쉽게 사용할 수 있습니다. 리모델링이나 건축을 원하는 주택 소유자, 새로운 아이디어나 즉각적인 결과가 필요한 건축가, 장식 제안을 찾는 인테리어 디자이너, 심지어 조경사도 사용할 수 있습니다. 부동산 중개인과 에이전시도 우리 소프트웨어를 사용하여 매물 목록을 개선하고, 집을 더 쉽게 판매하며, 빠른 AI 실내 및 실외 아이디어를 보여줍니다.',
        q6: 'AI 디자이너는 어떻게 내 집을 개선할 수 있나요?',
        answer6: 'AI 디자이너는 실내 공간에 대한 다양한 가능성을 제공합니다. 다양한 스타일과 장식 옵션을 탐색하여 조화롭고 시각적으로 매력적인 생활 공간을 만들 수 있습니다.',
        q7: '건축 계획과 스케치에도 적용되나요?',
        answer7: '물론입니다! 우리 소프트웨어는 건축 스케치와 함께 작동하도록 설계되었습니다. 손으로 그린 스케치나 디지털 렌더링이든 쉽게 플랫폼에 업로드한 다음 스케치를 현실적인 시각화로 렌더링할 수 있습니다.',
        q8: '공사 중인 주택/집/실내/실외에도 적용되나요?',
        answer8: '물론입니다! 우리 사용자들은 공사 중인 실내 및 실외(방, 집, 뒷마당)를 어떻게 장식할지 아이디어를 생성하기 위해 우리 소프트웨어를 많이 사용하고 있습니다.',
        q9: '가상 스테이징과 홈 스테이징에도 적용되나요?',
        answer9: '물론입니다! 빈 방이나 기존 공간의 사진을 업로드하면 가상 스테이징을 실험할 수 있도록 도와드립니다. 부동산 전문가, 주택 소유자, 에어비앤비 홈 서비스 모두에게 매우 가치 있는 도구입니다.',
        q10: '환불 정책이 있나요?',
        answer10: '네! 우리는 우리 기술에 매우 자신이 있어 전액 환불 보증을 제공합니다. 자세한 내용은 환불 정책을 참조해 주세요.'
    },
    signin: {
        title: '로그인',
        another: '또는 이메일로',
        emailPlaceHolder: '이메일 주소',
        passwordPlaceHolder: '비밀번호',
        rememberTitle: '다음에도 기억하기',
        forgetPassword: '비밀번호를 잊으셨나요?',
        noAccount: '계정이 없으신가요?'
    },
    signup: {
        title: '계정을 만들거나 기존 계정으로 로그인하세요.',
        another: '또는 이메일로',
        firstNamePlaceHolder: '이름',
        lastNamePlaceHolder: '성',
        emailPlaceHolder: '이메일 주소',
        passwordPlaceHolder: '비밀번호',
        rememberTitle: '다음에도 기억하기',
        forgetPassword: '비밀번호를 잊으셨나요?',
        gotAccount: '이미 계정이 있으신가요?'
    },
    // Part 3
    forgetPassword: {
        title: '비밀번호 찾기',
        content: '비밀번호 재설정 이메일을 보내드리겠습니다',
        emailPlaceHolder: '이메일을 입력해주세요',
        sendBtn: '보내기',
        backSignin: '로그인으로 돌아가기'
    },
    pricing: {
        yearlySubscription: '1000개 디자인',
        monthlySubscription: '100개 디자인',
        saveDesk: '10% 할인',
        saveMobile: '1000개 디자인 구매 시 10% 할인',
        popular: "76%가 이 플랜을 선택합니다",
        free: {
            title: '무료 크레딧',
            price: '0원',
            trialTitle: '등록 코드: 로그인해주세요',
            joinTitle: '여기에 코드를 붙여넣으세요',
            one: '20개의 무료 크레딧 받기',
            two: '100 크레딧의 모든 기능 포함',
            affiliateTitle: '제휴 프로그램',
            shareTitle: '친구와 공유하기👇',
            loginToShare: '공유하려면 로그인해주세요'
        },
        basic: {
            title: '100 크레딧',
            price: '7,000원',
            duration: '/평생',
            recommend: '개인용',
            one: '20장의 전문 사진',
            two: '배경 제거',
            three: '4K로 업스케일',
            four: '모든 사진 다운로드',
            five: '무제한 클라우드 저장소',
            six: '사진 상업적 사용 가능',
            seven: '모든 새로운 기능 접근',
            subscribed: '구매완료'
        },
        pro: {
            title: '1000 크레딧',
            subTitle: '(30% 할인)',
            price: '49,000원',
            duration: '/평생',
            recommend: '카페 주인, 기업, 사진 스튜디오',
            one: '200장의 전문 사진',
            two: '100 크레딧의 모든 기능 포함',
            three: '비용 절약',
            subscribed: '구매완료'
        },
        credit: {
            title: '당신의 필요에 맞는 플랜을 선택하세요!'
        },
        first: {
            title: '스타터',
            price: '$19',
            priceDetail: '100개 디자인 - 구독 없음',
            duration: '/평생',
            recommend: '1 헤드샷 = 5 크레딧',
            five: '기본 AI 디자이너로 장식',
            credit: '500 크레딧',
            two: '구매당 100개 디자인',
            three: '모든 모드 잠금 해제',
            one: 'AI 가구 제거',
            six: '상업적 라이선스',
            four: '스케치에서 렌더링',
            seven: '85+ 방 유형 사용 가능',
            eight: '40+ 스타일 사용 가능',
            nine: '모든 디자인 4K로 업스케일',
            ten: '고품질 다운로드',
            eleven: '무제한 렌더링 저장',
            twelve: '새로운 기능 조기 접근',
            thirteen: '언제든지 취소 가능',
            fourteen: 'AI 가상 스테이징',
        },
        second: {
            title: '프로',
            subTitle: '(85% 할인)',
            price: '$29',
            priceDetail: '월 1,000개 디자인',
            duration: '/평생',
            recommend: '카페 주인, 기업, 사진 스튜디오',
            five: '항상 최신 AI 디자이너',
            credit: '5,000 크레딧',
            two: '월 1,000개 디자인',
            three: '모든 모드 잠금 해제',
            one: 'AI 가구 제거',
            six: '상업적 라이선스',
            four: '스케치에서 렌더링',
            seven: '85+ 방 유형 사용 가능',
            eight: '40+ 스타일 사용 가능',
            nine: '모든 디자인 4K로 업스케일',
            ten: '고품질 다운로드',
            eleven: '무제한 렌더링 저장',
            twelve: '새로운 기능 조기 접근',
            thirteen: '언제든지 취소 가능',
            fourteen: 'AI 가상 스테이징',
            subscribed: '구독하기'
        },
        third: {
            title: '프로 연간',
            subTitle: '(93% 할인)',
            price: '$199',
            priceDetail: '연 12,000개 디자인',
            duration: '/평생',
            recommend: '카페 주인, 기업, 사진 스튜디오',
            five: '항상 최신 AI 디자이너',
            credit: '60,000 크레딧',
            two: '연 12,000개 디자인',
            three: '모든 모드 잠금 해제',
            one: 'AI 가구 제거',
            six: '상업적 라이선스',
            four: '스케치에서 렌더링',
            seven: '85+ 방 유형 사용 가능',
            eight: '40+ 스타일 사용 가능',
            nine: '모든 디자인 4K로 업스케일',
            ten: '고품질 다운로드',
            eleven: '무제한 렌더링 저장',
            twelve: '새로운 기능 조기 접근',
            thirteen: '언제든지 취소 가능',
            fourteen: 'AI 가상 스테이징',
            subscribed: '구독하기'
        },
        four: {
            title: '기업 솔루션',
            subTitle: '(문의하기)',
            price: '0원',
            priceDetail: '헤드샷 당 0원',
            duration: '/평생',
            recommend: '카페 주인, 기업, 사진 스튜디오',
            one: '고품질 헤드샷',
            two: '기업 또는 원격 팀',
            three: '시간과 비용 절약',
            four: '몇 초 만에 헤드샷 촬영',
            five: '전통적인 사진 촬영 없이',
            subscribed: '구매완료',
        },
        currency: '원',
        perMonth: '/월',
        safePayment: '안전한 결제',
        qr: 'QR 코드 사용'
    },
    milestoneMessage: {
        first: '더 행복한 고객과 함께',
        second: '개의 디자인 생성'
    },
    message: {
        success: {
            redering: '디자인을 렌더링 중입니다. 잠시만 기다려주세요',
            rederingDone: 'AI가 디자인 생성을 완료했습니다',
            activated: '활성화 성공! 아름답고 만족스러운 디자인을 만들어보세요!',
            removingBG: '배경을 제거하고 있습니다',
            upscale: '해상도를 업스케일하고 있습니다',
            resetPassword: '비밀번호 재설정 이메일을 보냈습니다. 확인해주세요!',
            signIn: '계정 활성화 이메일을 보냈습니다. 확인해주세요!',
            reviewing: '요청을 받았습니다. 하루 안에 검토될 예정입니다',
            remindReviewing: '이 사진은 새 크레딧을 받기 위해 검토 중입니다',
            removed: '사진이 성공적으로 제거되었습니다',
            useTheTemplate: "템플릿 사용 중",
            subscribed: "구독되었습니다!"
        },
        error: {
            selectPhotographer: '시작하려면 AI 디자이너를 선택해주세요',
            uploadPhoto: '공간 사진을 업로드해주세요',
            chooseRoom: '방 유형을 선택해주세요',
            chooseTheme: '방 스타일을 선택해주세요',
            fileOverBasic: '5MB 미만의 파일을 업로드하거나 더 큰 파일을 업로드하려면 프로로 업그레이드하세요',
            fileOverPro: '10MB 미만의 파일을 업로드해주세요',
            wrongFile: '잘못된 파일입니다. 다른 파일을 시도해주세요',
            wrongFace: '셀카를 너무 가까이 찍지 마세요. 다시 시도해주세요!',
            busy: '연결이 중단되었습니다. 다시 시도해주세요',
            gender: '성별을 선택해주세요',
            age: '나이를 선택해주세요',
            roomType: '유형을 선택해주세요',
            style: '스타일을 선택하거나 직접 지정해주세요',
            numberOfResult: '헤드샷 수를 선택해주세요',
            outOfCredits: '크레딧이 부족합니다. 더 많은 크레딧을 구매해주세요!',
            userNotFound: '계정을 찾을 수 없습니다. 계정을 정확히 입력해주세요!',
            wrongPassword: '잘못된 비밀번호입니다! 비밀번호가 기억나지 않으면 비밀번호 찾기를 사용하세요.',
            accountExist: '이미 존재하는 계정입니다. 대신 이 계정으로 로그인해보세요',
            tiktok: '구글 사용자 보안상의 이유로 Chrome 또는 Safari 브라우저를 사용해주세요. decorify.pro에 접속하세요.',
            reviewing: '이 사진은 검토 중이므로 제거할 수 없습니다',
            background: '배경을 선택해주세요',
            pose: '포즈를 선택해주세요',
            signUpReCaptcha: '가입하기 전에 reCAPTCHA를 완료해주세요.',
            emptyBG: '배경이 비어 있습니다. 입력해주세요!',
            tooShortBG: '배경은 2단어 이상이어야 합니다.',
            tooLongBG: '배경이 너무 깁니다.',
            badWordsBG: '배경에 부적절한 단어가 포함되어 있습니다. 다른 것을 시도해주세요',
            emptyStyle: '스타일이 비어 있습니다. 입력해주세요!',
            tooShortStyle: '스타일은 2단어 이상이어야 합니다.',
            tooLongStyle: '스타일이 너무 깁니다.',
            badWordsStyle: '스타일에 부적절한 단어가 포함되어 있습니다. 다른 것을 시도해주세요',
            emptyEmail: '이메일을 입력해주세요',
            validEmail: '유효한 이메일 주소를 입력해주세요',
            subscribeError: '죄송합니다. 구독을 완료할 수 없었습니다.'
        },
        warning: {
            runOutBasicCredits: '기본 플랜의 모든 크레딧을 사용했습니다. 더 많은 크레딧을 구매해주세요',
            runOutProCredits: '더 많은 크레딧을 구매하여 사용해주세요 ^^',
            selectPose: '포즈를 선택하기 전에 성별을 선택해주세요',
            aiVirtualStaging: 'AI 가상 홈으로 전환',
            aiFurnitureRemoval: 'AI 가구 제거로 전환합니다. 제거 기록이 저장되지 않으므로 떠나기 전에 이미지를 다운로드하세요.',
            upscale: '업스케일로 전환',
        },
        prompt: {
            professional: '전문 사진',
            removeBG: '배경 제거',
            upscale: '해상도 업스케일',
            rendering: 'AI 렌더링',
            faceNotFound: '얼굴이 너무 가까웠습니다. 다른 셀카를 시도해주세요!',
            inReview: '검토 중',
            styles: {
                business: '전문 비즈니스 사진',
                realEstate: '전문 부동산 중개인 사진',
                carShowroom: '전문 자동차 판매원 사진',
                doctor: '전문 의사 사진',
                yearbook: '전문 90년대 졸업앨범 사진',
                wedding: '전문 웨딩 사진',
                halloween: '할로윈 사진'
            },
        },
        maintain: {
            backsoon: '이 기능은 현재 유지보수 중이며 곧 다시 사용할 수 있습니다. 그동안 다른 기능을 이용해주세요. 감사합니다! 💗💗💗'
        }
    },   
    // Part 4 
    app : {
        menu: {
            creditTitle: '내 크레딧',
            affiliateTitle: '제휴 프로그램',
            affiliateSub: '소개한 모든 고객의 결제에 대해 30% 수수료를 받으세요!',
            professionalMode: 'AI 홈 디자인',
            removeBGMode: '가구 제거',
            unBluryMode: '디자인 업스케일',
            myPhotos: '내 디자인',
            aiVirtualStaging: "AI 가상 스테이징"
        },
        tryThese: '이미지가 없나요? 이것들을 시도해보세요:',
        goodSelfie: '좋은 셀카',
        badSelfie: '나쁜 셀카',
        trick: '더 나은 품질을 위해 업스케일을 사용하세요',
        removeBGSub: 'AI로 배경 제거가 빠르고 간단하며 정확해집니다.',
        professionalMode: {
            title: 'AI로 모든 공간 꾸미기',
            refundTitle: '우리의 AI는 훌륭한 결과를 위해 학습 중입니다 💓',
            creditTitle: '1장의 사진 = 10 크레딧',
            genderTitle: '유형 선택',
            ageTitle: '나이 선택',
            styleTitle: '스타일 선택 또는 커스터마이즈',
            quantityTitle: '디자인 수',
            poseTitle: '포즈 선택/업로드',
            backgroundTitle: '배경 선택/커스텀',
            skipPose: '원래 포즈를 유지하려면 건너뛰기',
            defaultPose: '기본',
            uploadedPose: '업로드됨',
            defaultBackground: '배경',
            defaultStyle: '스타일',
            headshotsTab: '헤드샷',
            selectPhotographer: 'AI 디자이너 선택',
            selectPhotographerSub: '필요에 맞는 디자이너를 선택하세요',
            requirements: '커스터마이즈',
            guideSub: '디자이너 요구사항을 따르세요 👇',
            creativity: "AI 창의성",
            customize: "✍️ 커스터마이즈 (베타)",
            enOnlyCustomize: "✍️ 커스터마이즈 (영어 버전만 가능)",
            interiorsTitle: "인테리어",
            exteriorsTitle: "외관",
            gardensTitle: "정원",
        },
        upscaleMode: {
            title: 'AI로 빠르고 정확하게 사진의 흐림을 제거하고 향상시킵니다.',
        }
    },
    myDesign: {
        title: '내 디자인',
        scrollTitle: '모든 디자인을 보려면 스크롤하세요.',
    },
    imageComponent: {
        compare: '보기',
        download: '다운로드',
        removeObjects: '가구 제거',
        upscale: '업스케일',
        virtualHome: '장식 스테이징',
        errorPhoto: '나쁜 사진 갱신'
    },
    results: {
        one: '1개 디자인',
        four: '4개 디자인',
    },
    genders: {
        man: '남성',
        woman: '여성'
    },
    styles: {
        "modern": "모던",
        "minimalist": "미니멀리스트",
        "professional": "프로페셔널",
        "tropical": "트로피컬",
        "coastal": "해안",
        "vintage": "빈티지",
        "neoclassic": "네오클래식",
        "tribal": "부족",
        "industrial": "산업",
        "bohemian": "보헤미안",
        "contemporary": "컨템포러리",
        "farmhouse": "농가",
        "scandinavian": "스칸디나비안",
        "skichalet": "스키 샬레",
        "artdeco": "아트 데코",
        "biophilic": "바이오필릭",
        "christmas": "크리스마스",
        "tetvn": "음력 설",
        "midautumn": "중추절",
        "halloween": "할로윈",
        "easter": "부활절",
        "baroque": "바로크",
        "cottagecore": "코티지코어",
        "japanesestyle": "일본 스타일",
        "rustic": "러스틱",
        "artnouveau": "아르누보",
        "maximalist": "맥시멀리스트",
        "coffee": "커피",
        "medieval": "중세",
        "frenchcountry": "프렌치 컨트리",
        "vaporwave": "베이퍼웨이브",
        "hotpink": "핫 핑크",
        "gaming": "게이밍",
        "cyberpunk": "사이버펑크",
        dating: "데이팅",
        other: "기타",
        custom: {
            title: '커스텀 스타일',
            btn: '커스텀 시작',
            dialogTitle: '당신의 스타일과 옷',
            dialogSubTitle: '당신의 스타일, 옷, 나이를 알려주세요. 우리의 AI 사진작가가 그에 맞게 당신을 표현하려고 노력할 것입니다!',
            upgrade: '이 기능 업그레이드',
            example: '넥타이 없는 클래식 정장, 26세 등 원하는 대로...',
        },
        "cyberpunk_style_bathroom": "사이버펑크 스타일",
        "eclectic_style_coworking_space": "절충주의 스타일",
        "maximalist_style_bedroom": "맥시멀리스트 스타일",
        "contemporary_style_hotel_bedroom": "컨템포러리 스타일",
        "zen_style_bedroom": "젠 스타일",
        "cyberpunk_style_outdoor_patio": "사이버펑크 스타일",
        "midcentury_modern_style_pool": "미드센츄리 모던 스타일",
        "easter_style_drop_zone": "부활절 스타일",
        "coastal_style_kitchen": "해안 스타일",
    },
    backgrounds: {
        coffeeShop: "커피숍",
        office: "사무실",
        cityStreet: "도시 거리",
        factory: "공장",
        garden: "정원",
        carShowroom: "자동차 전시장",
        bookShelf: "책장",
        hotel: "호텔",
        goldenGate: "금문교",
        snow: "눈",
        realtor: "부동산 중개인",
        brick: "벽돌",
        custom: {
            title: '배경',
            btn: '커스텀 시작',
            dialogTitle: '당신의 배경',
            dialogSubTitle: '당신의 배경을 알려주세요. 우리의 AI 사진작가가 그 배경에 맞게 당신을 표현하려고 노력할 것입니다!',
            upgrade: '이 기능 업그레이드',
            example: '커피숍, 원하는 곳에 따라...',
        },
    },
    removeTitle: '이 사진 제거하기',
    removeSub: '이 사진을 제거하시겠습니까? 이 사진은 영구적으로 제거됩니다. 이 작업은 취소할 수 없습니다.',
    renewTitle: '이 사진 갱신하기',
    renewSub: '귀하의 요청을 검토하겠습니다. 이 사진에 문제가 있다면 영구적으로 제거될 것입니다. 10개의 새 크레딧을 받게 됩니다.',
    reasonTitle: '갱신하려는 이유를 알려주세요?',
    reasonEx: '비공식적이었습니다...',
    photographers: {
        sam: {
            name: "Sam, 👋 안녕하세요 고객님",
            title: "저는 스타일에 능숙합니다",
            intro: "제가 할 수 있는 것은?",
            intro1: "몇 초 만에 모든 스타일과 분위기",
            intro2: "부동산, 예술 및 블로그 사진",
            intro3: "인테리어 & 익스테리어 디자인",
            intro4: "빈 방을 가구로 채우기",
            intro5: "사실적인 렌더링"
        },
        david: {
            name: "David, 👋 안녕하세요 고객님",
            title: "저는 스케치에 능숙합니다",
            intro: "제가 할 수 있는 것은?",
            intro1: "몇 초 만에 모든 스케치를 사실적인 사진으로",
            intro2: "스케치를 모든 디자인으로",
            intro3: "주택 디자인",
            intro4: "건축 디자인",
            intro5: "인테리어 디자인"
        },
        annie: {
            name: "Annie, 👋 안녕하세요 고객님",
            title: "저는 리모델링에 능숙합니다",
            intro: "제가 할 수 있는 것은?",
            intro1: "창문, 천장, 문을 유지합니다.",
            intro2: "방을 가구로 채우기",
            intro3: "주택 디자인",
            intro4: "건축 디자인",
            intro5: "인테리어 디자인"
        }
    },
    roomsList: {
        "living_room": "거실",
        "bedroom": "침실",
        "dining_room": "식당",
        "bath_room": "욕실",
        "study_room": "서재",
        "gaming_room": "게임룸",
        "meeting_room": "회의실",
        "kitchen": "주방",
        "toilet": "화장실",
        "coworking_space": "공유 오피스",
        "fitness_gym": "피트니스 짐",
        "mudroom": "현관",
        "walk_in_closet": "드레스룸",
        "office": "사무실",
        "hotel_room": "호텔 객실",
        "hotel_lobby": "호텔 로비",
        "hotel_bathroom": "호텔 욕실",
        "exhibition_space": "전시 공간",
        "house_exterior": "주택 외관",
        "attic": "다락방",
        "onsen": "온천",
        "drop_zone": "현관 수납공간",
        "workshop": "작업실",
        "nail": "네일샵",
        "restaurant": "레스토랑",
        "coffee_shop": "카페",
        "clothing_store": "의류 매장",
        "family_room": "가족실",
        "kids_room": "아이방",
        "balcony": "발코니",
        "wine_cellar": "와인 셀러",
        "rooftop_terrace": "옥상 테라스",
        "sunroom": "일광욕실",
        "home_spa": "홈 스파",
        "craft_room": "공예실",
        "dressing_room": "탈의실",
        "guest_bedroom": "게스트룸",
        "home_bar": "홈 바",
        "library": "도서관",
        "art_studio": "아트 스튜디오",
        "yoga_studio": "요가 스튜디오",
        "photo_studio": "사진 스튜디오",
        "multimedia_room": "멀티미디어룸",
        "auditorium": "강당",
        "medical_exam_room": "의료 검진실",
        "reception_area": "접수 구역",
        "music_room": "음악실",
        "science_laboratory": "과학 실험실",
        "home_theater": "홈 시어터",
        "wedding_room": "웨딩룸",
        "laundry_room": "세탁실",
        "outdoor_kitchen": "야외 주방",
        "utility_room": "다용도실",
        "pet_room": "반려동물실",
        "home_gym": "홈 짐",
        "lounge": "라운지",
        "play_room": "놀이방",
        "reading_nook": "독서 공간",
        "sauna": "사우나",
        "man_cave": "남자의 아지트",
        "foyer": "현관 홀",
        "green_house": "온실",
        "she_shed": "여자의 아지트",
        "conservatory": "온실",
        "nursery": "유아실",
        "prayer_room": "기도실",
        "side_of_house": "집 옆면",
        "front_of_house": "집 정면",
        "back_of_house": "집 뒷면",
        "backyard": "뒷마당",
        "patio": "파티오",
        "terrace": "테라스",
        "front_yard": "앞마당",
        "garden": "정원",
        "court_yard": "안마당",
        "pool_area": "수영장 구역",
        "porch": "현관",
        "playground": "놀이터"
    },
    giveAway: {
        title: '모든 플랜 10% 할인',
        subTitle: '앱을 사용하여 새로운 기능과 향후 경품에 대한 알림을 받으세요 👇',
        enterYourEmail: '이메일을 입력하세요...',
        notifyMe: '지금 받기 ↗',
        note: '중요한 앱 업데이트만 보내드립니다. 스팸 아닙니다! 언제든 구독 취소 가능.',
        submitting: '제출 중...',
        emailSent: '이메일이 전송되었습니다.',
        emailSentSub: '이메일을 확인하고 Decorify 사용을 시작하세요!',
        emailSentTitle: '와우! 가입 완료!',
    },
    feedback: {
        title: '귀하의 피드백을 환영합니다!',
        subTitle: '개선할 수 있는 방법에 대한 피드백을 제공해 주세요. 100 크레딧을 받을 기회가 있습니다!',
        enterYourFeedback: '의견을 말씀해 주세요...',
        notifyMe: '지금 보내기 ↗',
        note: '중요한 앱 업데이트만 보내드립니다. 스팸은 보내지 않습니다! 언제든지 구독을 취소할 수 있습니다.',
        submitting: '제출 중...',
        sent: '피드백을 받았습니다 ✅',
        sentSub: '피드백을 검토하고 곧 연락드리겠습니다!',
        type: {
            bug: '버그 보고',
            feature: '기능 요청',
            feedback: '기타 피드백',
            refund: '환불 요청',
        },
        emptyFeedback: '의견을 입력해 주세요!',
        emptyFeedbackType: '피드백 유형을 선택해 주세요!',
    }
}

export default ko;
