import React, { Component } from 'react';
import AdsLanding from '../../components/AdsLanding';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setUser } from '../../actions';
// import { withFirebase } from '../../services/engine';

import Spinner from '../../components/Spinner';
import withBackend from '../../backend/withBackend';
import { Navigate } from 'react-router-dom';
import * as routes from '../../constants/routes';
import {v4} from 'uuid';

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

const RANDOM_CODE = Math.floor(Math.random() * 900000) + 100000;
const RANDOM_TRIAL_CODE = Math.floor(Math.random() * 900000) + 100000;

let initialUserData = {
  uid: null,
  role:"USER",
  email:"",
  joinedDate: new Date().getTime(),
  lastLoginDate:null,
  firstName:"",
  lastName:"",
  phone: null,
  phoneCode:84,
  phoneShort:"VN",
  profilePicUrl:null,
  companyName: null,
  country:null,
  birthDate:null,
  gender:null,
  address:null,
  city:null,
  status:"incomplete",
  isActivated: true,
  hasSubscription:false,
  emailVerified: false,
  proTrial: process.env.REACT_APP_PRO_TRIAL,
  basicTrial: process.env.REACT_APP_BASIC_TRIAL,
  upscaleTrial: process.env.REACT_APP_UPSCALE_TRIAL,
  pro: false,
  basic: true,
  trial: true,
  monthlyBasicCode: `REOK ${RANDOM_CODE}`,
  yearlyBasicCode: `YB ${RANDOM_CODE}`,
  monthlyProCode: `MP ${RANDOM_CODE}`,
  yearlyProCode: `YP ${RANDOM_CODE}`,
  trialCode: `REOK ${RANDOM_TRIAL_CODE}`,
  proCredits: 0,
  basicCredits: 30,
  refCode: ''
}

class AdsLandingPageContainer extends Component {
  state = {
    loading: false,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    error: null,
    sent: 0,
    refCode: ''
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmitWithGoogle = (e, t) => {
    e.preventDefault();

    const { redirect } = this.state;

    if (redirect === "tiktok") {
      alert(t('message.error.tiktok'));
      return;
    }

    // Perform reCAPTCHA verification here
    // const recaptchaResponse = window.grecaptcha.getResponse();

    // If recaptchaResponse is empty, the user did not complete the reCAPTCHA challenge
    // if (!recaptchaResponse) {
    //   alert(t('message.error.signUpReCaptcha'));
    //   return;
    // }

    const { engine, setUser } = this.props;
    engine
      .signInWithGoogle()
      .then(response => {
        engine.getUser(response.user.uid)
        .then (querySnapshot => {
          if (querySnapshot.data()) {
            engine.getUser(response.user.uid)
            .then(querySnapshot => setUser(querySnapshot.data()))
            .catch(error => {
              if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                error.message = t('message.error.accountExist');
              }
              this.setState({ error });
            });
          } else {
            const { refCode } = this.state;

            initialUserData.uid = response.user.uid;
            initialUserData.email = response.user.email;
            initialUserData.firstName = response.user.displayName;
            initialUserData.emailVerified = true;
            initialUserData.refCode = refCode;
            initialUserData.proCredits = 5;
            initialUserData.pro = true;
            initialUserData.trial = true;

            engine.addUser(response.user.uid, initialUserData)
            .then(() => engine.getUser(engine.auth.currentUser.uid))
            .then(querySnapshot => setUser(querySnapshot.data()))
            .catch(error => {
              if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                error.message = t('message.error.accountExist');
              }
              this.setState({ error });
            });

            const randomUUID = v4();
            const refLink = `https://decorify.pro/vi/?ref=${randomUUID}`;
            const data = { uid: response.user.uid, email: response.user.email, code: randomUUID, refLink: refLink, isActive: true, createdAt: new Date().toISOString()}
            
            engine.addAffMembers(data)
            .then(querySnapshot => {})
            .catch(error => {
              if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                error.message = t('message.error.accountExist');
              }
              this.setState({ error });
            });
          }
        })
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = t('message.error.accountExist');
        }
        if (error.code === "auth/popup-closed-by-user") {
          error.message = "Popup closed";
        }

        if (error.code === "auth/cancelled-popup-request") {
          error.message = "Cancelled popup request";
        }
        this.setState({ error });
      });
  };

  componentDidMount() {
    // Retrieve the stored referralCode from local storage
    const storedReferralCode = localStorage.getItem('refCode');

    if (storedReferralCode) {
      this.setState({ refCode: storedReferralCode });
      // You can perform further actions with the referral code here
    }

    const redirect = localStorage.getItem('redirect');

    if (redirect) {
      this.setState({ redirect: redirect });
      // You can perform further actions with the referral code here
    }

    // Load reCAPTCHA script dynamically
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }
  
  render() {

    const { error, loading, sent } = this.state;

    return (
      <>
        {sent ? <Navigate to={routes.SIGN_IN} replace={true} /> : null}
        <AdsLanding
          handleSubmitWithGoogle={this.handleSubmitWithGoogle}
          error={error}
        />
        {loading && <Spinner />}
      </>
    );
  }
}

export default compose(
  connect(null, { setUser }),
  withBackend
)(AdsLandingPageContainer);