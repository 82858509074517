import {
  Filename,
  GenInfo,
  ModelInfo,
  PowerPaintTask,
  Rect,
  ServerConfig,
} from "./types"
import { Settings } from "./states"
import { convertToBase64, srcToFile } from "./utils"
import axios from "axios"
import { v4 as uuidv4 } from 'uuid';

export const API_ENDPOINT = process.env.REACT_APP_APP_URL;
export const API_ENDPOINT_V3 = process.env.REACT_APP_APP_URL_V3;


const api = axios.create({
  baseURL: API_ENDPOINT,
})

export async function removeObjects(
  imageFile: File,
  mask: File | Blob,
  pro: Boolean,
  token: String
) {
  const fileSize = imageFile.size;
  const maxSize = 10 * 1024 * 1024;

  if (fileSize > maxSize) {
    throw new Error(`Your file is over size, please upload another one under 10MB`)
  }

  const imageBase64 = await convertToBase64(imageFile)
  const maskBase64 = await convertToBase64(mask)

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const requestData = JSON.stringify({
    image: imageBase64,
    mask: maskBase64,
    pro: pro
  });

  const res = await axios.post(`${API_ENDPOINT}/remove-objects`, requestData, { headers });

  const jobId = res.data.id;

  if (jobId !== null && jobId !== undefined) {
    while (true) {
      const response = await fetch(`${API_ENDPOINT}/remove-objects-job/${jobId}`);

      if (response.status === 200) {
        const blob = await response.blob(); // Convert response to Blob

        return {
          blob: URL.createObjectURL(blob),
        };
      } else {
        await new Promise(resolve => setTimeout(resolve, 3000));
      }
    }
  } else {
    throw new Error(`Something went wrong: ${res.data.message}`)
  }
}

export async function virtualStaging(
  imageFile: File,
  mask: File | Blob,
  pro: Boolean,
  firebase: any,
  prompt: String,
) {

  const fileSize = imageFile.size;
  const maxSize = 10 * 1024 * 1024;

  if (fileSize > maxSize) {
    throw new Error(`Your file is over size, please upload another one under 10MB`)
  }

  const random = uuidv4();

  // If the imageFile does not have a valid name
  if (!imageFile.name || imageFile.name === '' || imageFile.name === undefined) {
    const newFileName = `decorify-input-${random}.jpg`;
    // Create a new File object with the new name
    imageFile = new File([imageFile], newFileName, { type: imageFile.type });
  }

  // If the mask does not have a valid name
  if (mask instanceof Blob && !(mask instanceof File)) {
    const newFileName = `decorify-mask-${random}.jpg`;
    // Convert Blob to File with a name
    mask = new File([mask], newFileName, { type: mask.type });
  }

  const imageUrl = await firebase
  .uploadFile(imageFile)
  .then((downloadURL: any) => {
    const imageUrl = downloadURL;
    if (!imageUrl) return;

    return imageUrl;
  });

  const maskUrl = await firebase
  .uploadFile(mask)
  .then((downloadURL: any) => {
    const imageUrl = downloadURL;
    if (!imageUrl) return;

    return imageUrl;
  });

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${firebase.auth.currentUser.accessToken}`,
  };

  const requestData = JSON.stringify({
    image: imageUrl,
    mask: maskUrl,
    pro: pro,
    prompt: prompt
  });

  const res = await axios.post(`${API_ENDPOINT}/virtual-staging`, requestData, { headers });

  const jobId = res.data.id;

  if (jobId !== null && jobId !== undefined) {
    while (true) {
      const response = await fetch(`${API_ENDPOINT}/virtual-staging-job/${jobId}`);
  
      if (response.status === 200) {
        const blob = await response.blob(); // Convert response to Blob
  
        return {
          blob: URL.createObjectURL(blob),
        };
      } else {
        await new Promise(resolve => setTimeout(resolve, 3000));
      }
    }
  } else {
    throw new Error(`Something went wrong: ${res.data.message}`)
  }
}

export async function upscale(
  imageFile: File,
  pro: Boolean,
  firebase: any,
  scale: number
) {

  const fileSize = imageFile.size;
  const maxSize = 2 * 1024 * 1024;

  if (fileSize > maxSize) {
    throw new Error(`Your file is over size, please upload another one under 2MB to Upscale`)
  }

  const random = uuidv4();

  // If the imageFile does not have a valid name
  if (!imageFile.name || imageFile.name === '' || imageFile.name === undefined) {
    const newFileName = `decorify-input-${random}.jpg`;
    // Create a new File object with the new name
    imageFile = new File([imageFile], newFileName, { type: imageFile.type });
  }

  const imageUrl = await firebase
  .uploadFile(imageFile)
  .then((downloadURL: any) => {
    const imageUrl = downloadURL;
    if (!imageUrl) return;

    return imageUrl;
  });

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${firebase.auth.currentUser.accessToken}`,
  };

  const requestData = JSON.stringify({
    image: imageUrl,
    pro: pro,
    scaleTo: scale
  });

  const res = await axios.post(`${API_ENDPOINT}/upscale`, requestData, { headers });

  const jobId = res.data.id;

  if (jobId !== null && jobId !== undefined) {
    while (true) {
      const response = await fetch(`${API_ENDPOINT}/upscale-job/${jobId}`);
  
      if (response.status === 200) {
        const blob = await response.blob(); // Convert response to Blob
  
        return {
          blob: URL.createObjectURL(blob),
        };
      } else {
        await new Promise(resolve => setTimeout(resolve, 3000));
      }
    }
  } else {
    throw new Error(`Something went wrong: ${res.data.message}`)
  }
}

export default async function inpaint(
  imageFile: File,
  settings: Settings,
  croperRect: Rect,
  extenderState: Rect,
  mask: File | Blob,
  paintByExampleImage: File | null = null
) {
  const imageBase64 = await convertToBase64(imageFile)
  const maskBase64 = await convertToBase64(mask)
  const exampleImageBase64 = paintByExampleImage
    ? await convertToBase64(paintByExampleImage)
    : null

  const res = await fetch(`${API_ENDPOINT}/inpaint`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      image: imageBase64,
      mask: maskBase64,
      ldm_steps: settings.ldmSteps,
      ldm_sampler: settings.ldmSampler,
      zits_wireframe: settings.zitsWireframe,
      cv2_flag: settings.cv2Flag,
      cv2_radius: settings.cv2Radius,
      hd_strategy: "Crop",
      hd_strategy_crop_triger_size: 640,
      hd_strategy_crop_margin: 128,
      hd_trategy_resize_imit: 2048,
      prompt: settings.prompt,
      negative_prompt: settings.negativePrompt,
      use_croper: settings.showCropper,
      croper_x: croperRect.x,
      croper_y: croperRect.y,
      croper_height: croperRect.height,
      croper_width: croperRect.width,
      use_extender: settings.showExtender,
      extender_x: extenderState.x,
      extender_y: extenderState.y,
      extender_height: extenderState.height,
      extender_width: extenderState.width,
      sd_mask_blur: settings.sdMaskBlur,
      sd_strength: settings.sdStrength,
      sd_steps: settings.sdSteps,
      sd_guidance_scale: settings.sdGuidanceScale,
      sd_sampler: settings.sdSampler,
      sd_seed: settings.seedFixed ? settings.seed : -1,
      sd_match_histograms: settings.sdMatchHistograms,
      sd_freeu: settings.enableFreeu,
      sd_freeu_config: settings.freeuConfig,
      sd_lcm_lora: settings.enableLCMLora,
      paint_by_example_example_image: exampleImageBase64,
      p2p_image_guidance_scale: settings.p2pImageGuidanceScale,
      enable_controlnet: settings.enableControlnet,
      controlnet_conditioning_scale: settings.controlnetConditioningScale,
      controlnet_method: settings.controlnetMethod
        ? settings.controlnetMethod
        : "",
      powerpaint_task: settings.showExtender
        ? PowerPaintTask.outpainting
        : settings.powerpaintTask,
    }),
  })
  if (res.ok) {
    const blob = await res.blob()
    return {
      blob: URL.createObjectURL(blob),
      seed: res.headers.get("X-Seed"),
    }
  }
}

export async function getServerConfig(): Promise<ServerConfig> {
  const res = await api.get(`/server-config`)
  return res.data
}

export async function switchModel(name: string): Promise<ModelInfo> {
  const res = await api.post(`/model`, { name })
  return res.data
}

export async function switchPluginModel(
  plugin_name: string,
  model_name: string
) {
  return api.post(`/switch_plugin_model`, { plugin_name, model_name })
}

export async function currentModel(): Promise<ModelInfo> {
  const res = await api.get("/model")
  return res.data
}

export async function runPlugin(
  genMask: boolean,
  name: string,
  imageFile: File,
  upscale?: number,
  clicks?: number[][]
) {
  const imageBase64 = await convertToBase64(imageFile)
  const p = genMask ? "run_plugin_gen_mask" : "run_plugin_gen_image"
  const res = await fetch(`${API_ENDPOINT}/${p}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      image: imageBase64,
      upscale,
      clicks,
    }),
  })
  if (res.ok) {
    const blob = await res.blob()
    return { blob: URL.createObjectURL(blob) }
  }
  const errMsg = await res.json()
  throw new Error(errMsg)
}

export async function getMediaFile(tab: string, filename: string) {
  const res = await fetch(
    `${API_ENDPOINT}/media_file?tab=${tab}&filename=${encodeURIComponent(
      filename
    )}`,
    {
      method: "GET",
    }
  )
  if (res.ok) {
    const blob = await res.blob()
    const file = new File([blob], filename, {
      type: res.headers.get("Content-Type") ?? "image/png",
    })
    return file
  }
  const errMsg = await res.json()
  throw new Error(errMsg.errors)
}

export async function getMedias(tab: string): Promise<Filename[]> {
  const res = await api.get(`medias`, { params: { tab } })
  return res.data
}

export async function downloadToOutput(
  image: HTMLImageElement,
  filename: string,
  mimeType: string
) {
  const file = await srcToFile(image.src, filename, mimeType)
  const fd = new FormData()
  fd.append("file", file)

  try {
    const res = await fetch(`${API_ENDPOINT}/save_image`, {
      method: "POST",
      body: fd,
    })
    if (!res.ok) {
      const errMsg = await res.text()
      throw new Error(errMsg)
    }
  } catch (error) {
    throw new Error(`Something went wrong: ${error}`)
  }
}

export async function getGenInfo(file: File): Promise<GenInfo> {
  const fd = new FormData()
  fd.append("file", file)
  const res = await api.post(`/gen-info`, fd)
  return res.data
}

export async function getSamplers(): Promise<string[]> {
  const res = await api.post("/samplers")
  return res.data
}

export async function postAdjustMask(
  mask: File | Blob,
  operate: "expand" | "shrink" | "reverse",
  kernel_size: number
) {
  const maskBase64 = await convertToBase64(mask)
  const res = await fetch(`${API_ENDPOINT}/adjust_mask`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      mask: maskBase64,
      operate: operate,
      kernel_size: kernel_size,
    }),
  })
  if (res.ok) {
    const blob = await res.blob()
    return blob
  }
  const errMsg = await res.json()
  throw new Error(errMsg)
}
