import React, { useEffect, useState } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Gradient } from '../AdminHeader/Gradient.js';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import BeforeAfterTestimonial from '../BeforeAfterTestimonial/index.js';
import LovedBy from '../LovedBy/index.js';

const gradient = new Gradient();

const Privacy = () => {
  const [currentLanguage, setCurrentLanguage] = useState('');

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    gradient.initGradient('#canvas-gradient');
    window.scrollTo(0, 0);

    return () => { };
  }, []);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <>
      <main className='bg-black'>
      <div className="landing-cover" data-controller="gradient-animation">
          <div className="container">
            <div className="d-flex flex-column align-items-center">
              <h1 className="display-1 fw-semi-bold text-white text-center mb-5 mb-sm-4">{t('privacy.title')}</h1>
              <Link
                to={currentLanguage === 'en' ? '/' : `/${currentLanguage}/`}
                className="btn btn-soft-light btn-sm rounded-pill tag text-nowrap"
              >
                <HomeOutlined />
                {t('button.backHome')}
              </Link>
            </div>
          </div>
          <canvas id="canvas-gradient" data-transition-in />
          <div id="canvas-overlay" />
        </div>
      </main>
      <section className="bg-neutral-black text-gray-300">
        <div className="terms">
          <div>
            <div className="container mx-auto">
              <p className="mb-4">
                This privacy policy outlines how Decorify collects, uses, and protects your information when you use our website, mobile application, or services.
              </p>

              <h2 className="text-2xl font-bold mb-2">Information We Collect</h2>

              <p className="mb-4">
                We may collect the following types of information:
              </p>

              <ul className="list-disc list-inside mb-4" style={{ listStyleType: 'disc', paddingLeft: '1.5em' }}>
                <li>Personal information (e.g., name, email address, phone number)</li>
                <li>Demographic information (e.g., age, gender, location)</li>
                <li>Home decor preferences and style choices</li>
                <li>Information about your living space (e.g., room dimensions, photos)</li>
                {/* <li>Payment and billing information</li> */}
                <li>Usage data (e.g., how you interact with our platform)</li>
                <li>Device and browser information</li>
              </ul>

              <h2 className="text-2xl font-bold mb-2">How We Use the Information</h2>

              <p className="mb-4">
                We use your information for the following purposes:
              </p>

              <ul className="list-disc list-inside mb-4" style={{ listStyleType: 'disc', paddingLeft: '1.5em' }}>
                <li>To provide and personalize our home decor services</li>
                <li>To process orders, payments, and deliveries</li>
                <li>To improve our products, services, and user experience</li>
                <li>To communicate with you about your account, orders, and promotions</li>
                <li>To analyze usage patterns and conduct market research</li>
                <li>To detect and prevent fraudulent activities</li>
                <li>To comply with legal obligations</li>
              </ul>

              <h2 className="text-2xl font-bold mb-2">Security</h2>

              <p className="mb-4">
                Decorify implements robust security measures to protect your information, including:
              </p>

              <ul className="list-disc list-inside mb-4" style={{ listStyleType: 'disc', paddingLeft: '1.5em' }}>
                <li>Encryption of sensitive data in transit and at rest</li>
                <li>Regular security audits and vulnerability assessments</li>
                <li>Employee training on data protection and privacy</li>
                <li>Access controls and authentication mechanisms</li>
              </ul>

              <h2 className="text-2xl font-bold mb-2">Cookies and Tracking Technologies</h2>

              <p className="mb-4">
                We use cookies and similar technologies to:
              </p>

              <ul className="list-disc list-inside mb-4" style={{ listStyleType: 'disc', paddingLeft: '1.5em' }}>
                <li>Remember your preferences and settings</li>
                <li>Analyze how you use our platform</li>
                <li>Provide personalized content and recommendations</li>
                <li>Measure the effectiveness of our marketing campaigns</li>
              </ul>

              <p className="mb-4">
                You can manage your cookie preferences through your browser settings.
              </p>

              <h2 className="text-2xl font-bold mb-2">Sharing Your Information</h2>

              <p className="mb-4">
                We may share your information with:
              </p>

              <ul className="list-disc list-inside mb-4" style={{ listStyleType: 'disc', paddingLeft: '1.5em' }}>
                <li>Service providers who help us operate our business</li>
                <li>Payment processors for secure transactions</li>
                <li>Marketing partners for targeted advertising (with your consent)</li>
                <li>Legal authorities when required by law</li>
              </ul>

              <p className="mb-4">
                We do not sell your personal information to third parties.
              </p>

              <h2 className="text-2xl font-bold mb-2">Your Rights and Choices</h2>

              <p className="mb-4">
                Depending on your location, you may have the right to:
              </p>

              <ul className="list-disc list-inside mb-4" style={{ listStyleType: 'disc', paddingLeft: '1.5em' }}>
                <li>Access and receive a copy of your personal data</li>
                <li>Correct inaccurate or incomplete information</li>
                <li>Request deletion of your personal data</li>
                <li>Object to certain processing of your data</li>
                <li>Withdraw consent for optional data processing</li>
                <li>Opt-out of marketing communications</li>
              </ul>

              <p className="mb-4">
                To exercise these rights, please contact us using the information provided below.
              </p>

              <h2 className="text-2xl font-bold mb-2">Children's Privacy</h2>

              <p className="mb-4">
                Decorify does not knowingly collect or solicit personal information from anyone under the age of 13. If you are under 13, please do not attempt to register or send any information about yourself to us.
              </p>

              <h2 className="text-2xl font-bold mb-2">Changes to This Policy</h2>

              <p className="mb-4">
                We may update this privacy policy from time to time. We will notify you of any significant changes by posting the new policy on our website and updating the "Last Updated" date.
              </p>

              <h2 className="text-2xl font-bold mb-2">Contact Us</h2>

              <p className="mb-4">
                If you have any questions about this privacy policy or our data practices, please contact us at support@decorify.pro
              </p>

              {/* <p className="mb-4">
                Decorify, Inc.<br />
                123 Design Street, Suite 456<br />
                Creativity City, DC 78901<br />
                Email: privacy@decorify.pro<br />
                Phone: (555) 123-4567
              </p> */}

              <p className="mb-4" style={{ fontWeight: '700' }}>
                Last Updated: Mon, Aug 26, 2024
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="relative bg-neutral-black">
          <div className="flex flex-col align-items-center section-top-90">
            <BeforeAfterTestimonial />
            <div className="getstarted-btn-wrapper mt-4">
              <Link to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`} className="upgrade-button mt-4">
                {t('button.try')}
                <span style={{ marginLeft: '4px' }}>↗</span>
              </Link>
            </div>
            <div className="text-white mt-4">
              <LovedBy t={t} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacy;
