import React from 'react';
import MoneyBackGuarantee from '../MoneyBackGuarantee';

const LovedBy = ({t}) => {

  return (
    <div className="relative mx-auto mt-5 max-w-lg space-y-4">
      <div className="flex items-center gap-3 justify-content-center">
      <div className="flex -space-x-3 overflow-hidden">
        <img
          src="/images/customer13.webp"
          alt=""
          className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
          width={40}
          height={40}
        />{' '}
        <img
          style={{ marginLeft: '-12px' }}
          src="/images/customer16.webp"
          alt=""
          className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
          width={40}
          height={40}
        />{' '}
        <img
          style={{ marginLeft: '-12px' }}
          src="/images/customer17.webp"
          alt=""
          className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
          width={40}
          height={40}
        />{' '}
        <img
          style={{ marginLeft: '-12px' }}
          src="/images/customer11.webp"
          alt=""
          className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
          width={40}
          height={40}
        />{' '}
        <img
          style={{ marginLeft: '-12px' }}
          src="/images/customer19.webp"
          alt=""
          className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
          width={40}
          height={40}
        />
      </div>{' '}
        <div className="flex" style={{ color: '#facc15' }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>{' '}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>{' '}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>{' '}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>{' '}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        </div>
      </div>{' '}
      <p className="text-sm flex justify-content-center">{t('landing.loveBy')}</p>
      {/* <div className="flex items-center justify-center text-center relative mt-4">
        <MoneyBackGuarantee/>
      </div> */}
  </div>
  );
};

export default LovedBy;
