import React, { useEffect, useRef, useState } from 'react';
import {
  TextField,
  Button
} from '@mui/material';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import PhotographerCard from '../PhotographerCard';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '80%',
    backgroundColor: '#fff',
  },
});

const StyledTab = styled((props) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: 'rgba(255, 255, 255, 0.7)',
  '&.Mui-selected': {
    color: '#fff',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

const Photographer = ({ userData, firebase, photographerInfo, onShowStyle, t, stylesList, handleSelectPhotographer, onShowPricing }) => {
  const popupRef = useRef(null);
  const [currentStyles, setCurrentStylesList] = useState([]);
  const [prompt, setPrompt] = useState('');
  const [value, setValue] = useState(0);
  const [error, setError] = useState('');

  useEffect(() => {
    if (stylesList !== undefined ) {
      setCurrentStylesList(stylesList)
    }

    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (popupRef.current && event.target.contains(popupRef.current)) {
        onShowStyle(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onShowStyle]);

  function onSelectPhotographer(e, photographer) {
    e.preventDefault();
    handleSelectPhotographer(photographer);
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="photographer-showbox inset-0 z-999 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0">
      <section id="photographer-container" className="shadow-lg" ref={popupRef}>
        <div>
          <Box>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="styled tabs example"
            >
              {/* <StyledTab label={t('app.professionalMode.headshotsTab')} /> */}
              <StyledTab label={"AI Designers"} />
            </StyledTabs>
            <Box sx={{ p: 1 }} />
          </Box>
          <CustomTabPanel value={value} index={0}>
          <div className="rnftg-h-full rnftg-w-full">
            <div className="rnftg-h-full dark:rnftg-bg-gray-900">
              <div className="rnftg-flex rnftg-flex-col">
                <div className="rnftg-grid rnftg-gap-4 rnftg-grid-flow-row rnftg-grid-cols md:rnftg-grid-cols-2 lg:rnftg-grid-cols-2 xl:rnftg-grid-cols-2">      
                  {photographerInfo.map((c, i) => {
                    return (
                    <div className="pose-item" key={i}>
                        <div className="photographer-line-border">
                          <div className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="pose-col">
                                <PhotographerCard info={c}/>
                              </div>
                            </div>
                          </div>
                          <div className="select-pose-imagine-button generate-btn">
                            <Button className="generate-button w-button" onClick={(e) => onSelectPhotographer(e, c)}>
                              <span style={{ marginLeft: '4px', textTransform:'none' }}>{t('button.selectToStart')} 🚀</span>
                            </Button>
                          </div>
                        </div>
                    </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          </CustomTabPanel>
        </div>
      </section>
    </div>
  );
};

export default Photographer;
