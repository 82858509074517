const id = {
    Basic: "Dasar",
    Pro: "Pro",
    month: "bulan",
    year: "tahun",
    button: {
        getStarted: 'Mulai',
        try: 'Mulai desain Anda dengan AI',
        viewall: 'Lihat Semua',
        basic: 'Dasar',
        pro: 'Pro',
        generate: 'Hasilkan Desain',
        backHome: 'Kembali ke Beranda',
        googleSignin: 'Mulai dengan Google',
        googleSignup: 'Mulai dengan Google',
        emailSignin: 'Masuk dengan Email',
        emailSignup: 'Mulai dengan Email',
        submit: 'Kirim',
        choose: 'Pilih',
        download: 'Unduh Template Ini',
        upgrade: 'Beli kredit lebih banyak',
        subscribe: 'Beli',
        upgradePro: 'Tingkatkan',
        confirmPayment: 'Konfirmasi Pembayaran',
        tryThisFeature: 'Coba Sekarang',
        removeBG: 'Mulai',
        professional: 'Mulai',
        upscale: 'Mulai',
        ok: 'Oke',
        cancel: 'Batal',
        select: 'Pilih',
        selectToStart: 'Pilih untuk memulai',
        uploadPose: 'Unggah pose',
        uploadingPose: 'Mengunggah pose Anda',
        upgradeToUpload: 'Tingkatkan untuk mengunggah',
        unlock: "Tingkatkan",
        englishOnly: "Versi bahasa Inggris",
        contactUs: "Hubungi Kami",
        forEnterprise: "Untuk Solusi Perusahaan",
        tryThis: "Coba",
        upscale: "Tingkatkan",
        remove: "Hapus",
        render: "Render",
        rendering: "Sedang Merender",
    },
    header: {
        nav: {
            signIn: 'Masuk',
            signUp: 'Daftar',
            signOut: 'Keluar',
            billing: 'Tagihan',
            supportTitle: 'Dukungan',
            supportContact: 'Hubungi Kami',
            myFlowers: 'Desain Saya'
        }
    },
    landing: {
        title: 'Alat Render AI Arsitektur',
        joinNow: 'Siap membuat desain yang menakjubkan?',
        subTitle: "Aplikasi berbasis AI untuk Pementasan Virtual, Remodeling, Lansekap, Desain Interior, dan lainnya. Desain instan dalam hitungan detik.",
        subTitle1: "Hemat waktu dan uang dengan mudah. Dipercaya oleh pemilik rumah, layanan rumah airbnb, perusahaan, dan profesional di seluruh dunia.",
        input: 'Beritahu saya jenis bunga yang ingin Anda buat?',
        inside: 'Dalam Ruangan',
        outside: 'Luar Ruangan',
        loveBy: '45.880+ desain menyenangkan telah dibuat',
        respectTitle: 'Kami menghormati privasi Anda',
        respectSub1: 'Tidak perlu FaceID atau banyak foto. Cukup gunakan satu selfie, seperti yang biasa Anda posting di Facebook atau Instagram.',
        respectSub2: 'Data Anda sepenuhnya aman dan terjamin di platform Google dengan keamanan sangat tinggi.',
        howItWorks: 'Cara kerjanya',
        howItWorksSub: 'Ambil foto atau unggah -> Pilih gaya dan latar belakang -> Pilih atau unggah pose Anda -> Terima foto headshot Anda!',
        unBlurryTitle: 'Peningkat foto',
        unBlurrySub: 'Hapus blur, tingkatkan, dan perbaiki warna foto sebelum memposting di platform media sosial seperti Facebook, Instagram, Tiktok.',
        useThisFeature: 'Buat milik Anda sekarang',
        anotherFeatureTitle: 'Fitur Unggulan',
        anotherFeatureSub: 'Gratis, cepat, dan akurat. Coba fitur-fitur di bawah ini.',
        feature1: 'Hapus latar belakang',
        feature1Sub: 'Hapus latar belakang apa pun dengan akurat.',
        feature2: 'Desain Interior',
        feature2Sub: 'Desain interior, desain eksterior, dan sketsa dengan genhomeai.com',
        feature3: 'Ubah Latar Belakang (Segera Hadir)',
        feature3Sub: 'Ubah latar belakang untuk objek apa pun dengan AI REOK',
        hotFeatureTitle: 'Apa kata pelanggan kami',
        hotFeatureSub: 'Paling realistis. Dipercaya oleh Profesional. Dibuat untuk semua orang.',
        pricingTitle: 'Pilih paket Anda',
        pricingLabel: 'Harga',
        pricingSub: '1 foto profesional = 10 kredit atau kurang',
        step1: 'Unggah ruang Anda',
        step1Sub: 'Pilih gaya Anda atau masukkan milik Anda.',
        step2: 'Desainer AI kami bekerja dalam hitungan detik',
        step2Sub: 'Teknologi canggih kami dengan cepat membuat dekorasi Anda.',
        step3: 'Terima dekorasi Anda',
        step3Sub: 'Unduh dekorasi Anda.',
        trialTitle: "Uji Coba GRATIS",
        trialSubTitle: "Generator Foto Headshot AI #1 📸",
        feedbackLabel: 'Umpan Balik',
        faqLabel: 'FAQ',
        sketch: 'Sketsa',
        creativity: 'Kreativitas',
        upscale4k: 'Tingkatkan',
        remove: 'Ruang Bersih',
        fillup: 'Perabotan',
        exterior: 'Eksterior',
        interior: 'Interior',
        steps: 'Langkah-langkah',
        interiorShowCase: {
            label: "INTERIOR",
            title: "Desain ulang dengan gaya apa pun",
            subTitle: "Kami membantu Anda mengubah tempat Anda dalam berbagai gaya, membuatnya terlihat menakjubkan. Desain ulang jenis interior, eksterior, atau taman apa pun."
        },
        exteriorShowCase: {
            label: "EKSTERIOR",
            title: "Desain ulang kreatif untuk eksterior",
            subTitle: "Perbarui ruang dalam dan luar ruangan Anda dengan solusi desain ulang inovatif, menyuntikkan kreativitas ke setiap sudut untuk suasana yang benar-benar memukau."
        },
        furnitureShowCase: {
            label: "PERABOTAN",
            title: "Isi ruangan kosong dengan perabotan",
            subTitle: "Anda dapat mencoba pengaturan berbeda hingga menemukan yang sempurna. Dekorasi ruangan kosong dengan perabotan menggunakan Decorify. Cocok untuk ruang dalam konstruksi atau ruang kosong."
        },
        cleanRoomShowCase: {
            label: "RUANG BERSIH",
            title: "Hapus objek yang tidak diinginkan",
            subTitle: "Ubah ruangan Anda dengan mudah dengan menghapus objek yang tidak diinginkan, memungkinkan Anda menciptakan lingkungan sempurna yang disesuaikan dengan preferensi Anda."
        },
        upscaleShowCase: {
            label: "TINGKATKAN",
            title: "Tingkatkan desain apa pun ke 4K",
            subTitle: "Tingkatkan suasana lingkungan apa pun dengan layanan desain tingkat tinggi kami, memastikan kejelasan dan detail yang menakjubkan hingga resolusi 4K untuk pengalaman yang benar-benar mendalam."
        },
        creativityShowCase: {
            label: "KREATIVITAS",
            title: "Kreativitas AI kustom",
            subTitle: "Ambil kendali perjalanan desain Anda dengan Intervensi AI. Anda memutuskan seberapa banyak AI harus membantu. Apakah Anda hanya ingin beberapa saran atau perubahan total, semuanya terserah Anda."
        },
        sketchShowCase: {
            label: "SKETSA",
            title: "Sketsa menjadi desain realistis",
            subTitle: "Ubah sketsa apa pun menjadi desain realistis yang hidup, mewujudkan konsep Anda dengan presisi dan keaslian yang jelas."
        },
    },
    yearbook: {
        title: 'Pemotretan Buku Tahunan AI 📸',
        subTitle: "Tidak menggunakan banyak gambar Selfie. Dapatkan foto Buku Tahunan 90-an Anda dengan satu Selfie menggunakan fotografer AI kami dalam hitungan detik. Hemat 10x waktu Anda.",
        input: 'Beritahu saya jenis bunga yang ingin Anda buat?',
        inside: 'Dalam Ruangan',
        outside: 'Luar Ruangan',
        loveBy: '45.880+ desain menyenangkan telah dibuat',
        respectTitle: 'Kami menghormati privasi Anda',
        respectSub1: 'Kami tidak menggunakan FaceID atau memerlukan banyak foto untuk bekerja. Cukup gunakan Selfie, mirip dengan yang sering Anda posting di Facebook dan Instagram.',
        respectSub2: 'Data Anda sepenuhnya aman dan terjamin di platform Google dengan keamanan sangat tinggi.',
        howItWorks: 'Cara Kerja Buku Tahunan AI',
        howItWorksSub: 'Anda mengambil selfie atau menggunakan foto ID yang jelas, pilih gaya & pose Anda, buat foto headshot dalam hitungan detik, bukan jam!',
        unBlurryTitle: 'Peningkat foto',
        unBlurrySub: 'Hapus blur, tingkatkan, dan perbaiki warna foto sebelum memposting di platform media sosial seperti Facebook, Instagram, Tiktok.',
        useThisFeature: 'Buat milik Anda sekarang',
        anotherFeatureTitle: 'Fitur Unggulan',
        anotherFeatureSub: 'Gratis, cepat, dan akurat. Coba fitur-fitur di bawah ini.',
        feature1: 'Hapus latar belakang',
        feature1Sub: 'Hapus latar belakang apa pun dengan akurat.',
        feature2: 'Desain Interior',
        feature2Sub: 'Desain interior, desain eksterior, dan sketsa dengan genhomeai.com',
        feature3: 'Ubah Latar Belakang (Segera Hadir)',
        feature3Sub: 'Ubah latar belakang untuk objek apa pun dengan AI REOK',
        hotFeatureTitle: 'Foto Buku Tahunan AI Kami',
        hotFeatureSub: 'Ini adalah foto headshot yang dihasilkan AI.',
        pricingTitle: 'Pilih paket Anda',
        pricingLabel: 'Harga',
        pricingSub: '1 foto profesional = 10 kredit atau kurang',
    },
    showcase: {
        title: 'Contoh Bunga',
        status: {
            pending: 'sedang diproses',
            upscaling: 'sedang ditingkatkan',
            choosing: 'sedang memilih',
            finished: 'selesai'
        },
        mode: {
            pro: 'Pro',
            basic: 'Dasar'
        }
    },
    home: {
        title: 'Desain Anda',
        hotColletion: "Gaya Kustom",
        credits: 'Sisa desain',
        uploadTitle: 'Unggah ruang Anda',
        uploadPlaceHolder: 'Jatuhkan file atau klik untuk mengunggah',
        stylePlaceHolder: 'Pilih',
        uploadPosePlaceHolder: 'Pilih',
        uploadBGPlaceHolder: 'Pilih',
        roomTitle: 'Pilih jenis ruangan (29)',
        themeTitle: 'Pilih gaya ruangan (34)',
        pro: {
            title: 'Kreativitas untuk Semua Jenis Bunga',
            input: 'Deskripsikan bunga Anda...',
        },
        basic: {
            title: 'Kreativitas Tak Terbatas dengan Pro',
            chooseFlowerTitle: 'Bunga',
            flowers: 'Ruang Tamu',
            styles: 'Gaya',
            decoration: 'Dekorasi',
            events: 'Acara',
            backgrounds: 'Latar Belakang'
        },
    },
    profile: {
        title: 'Desain Saya',
        before: 'Sebelum -',
        after: 'Sesudah -'
    },
    terms: {
        title: 'Syarat dan Ketentuan',
        content: ''
    },
    // Part 2
    privacy: {
        title: 'Kebijakan Privasi',
        one: 'Privasi Anda sangat penting bagi kami. Kebijakan privasi decorify.pro menghormati privasi Anda terkait informasi apa pun yang mungkin kami kumpulkan dari Anda di situs web kami, decorify.pro, dan situs web lain yang kami miliki dan operasikan.',
        two: 'Kami hanya meminta informasi pribadi ketika kami benar-benar membutuhkannya untuk menyediakan layanan kepada Anda. Kami mengumpulkannya dengan cara yang adil dan sah, dengan pengetahuan dan persetujuan Anda. Kami juga memberi tahu Anda mengapa kami mengumpulkannya dan bagaimana informasi tersebut akan digunakan.',
        three: 'Kami hanya menyimpan informasi yang dikumpulkan selama diperlukan untuk menyediakan layanan yang Anda minta. Data yang kami simpan akan kami lindungi dengan cara yang dapat diterima secara komersial untuk mencegah kehilangan dan pencurian, serta akses, pengungkapan, penyalinan, penggunaan, atau modifikasi yang tidak sah.',
        four: 'Kami tidak membagikan informasi identitas pribadi apa pun secara publik atau dengan pihak ketiga, kecuali jika diwajibkan oleh hukum.',
        five: 'Situs web kami mungkin memiliki tautan ke situs eksternal yang tidak dioperasikan oleh kami. Harap perhatikan bahwa kami tidak memiliki kendali atas konten dan praktik situs-situs tersebut dan tidak dapat menerima tanggung jawab atau kewajiban atas kebijakan privasi masing-masing.',
        six: 'Anda bebas menolak permintaan kami untuk informasi pribadi Anda, dengan pemahaman bahwa kami mungkin tidak dapat menyediakan beberapa layanan yang Anda inginkan.',
        seven: 'Penggunaan berkelanjutan situs web kami akan dianggap sebagai penerimaan praktik kami seputar privasi dan informasi pribadi. Jika Anda memiliki pertanyaan tentang bagaimana kami menangani data pengguna dan informasi pribadi, jangan ragu untuk menghubungi kami.',
        eight: 'Kebijakan ini berlaku mulai 15 September 2023.'
    },
    refund: {
        moneyBack: 'Jaminan Uang Kembali',
        title: 'Kebijakan Pengembalian Dana',
        one: 'Tujuan kami adalah menyediakan layanan dan hasil berkualitas tinggi kepada semua klien kami. Kami memahami bahwa mungkin ada kasus di mana hasil tidak memenuhi harapan Anda',
        two: 'Kami menjamin kepuasan Anda. Jika Anda tidak puas dengan foto yang dihasilkan menggunakan Reok.pro, kami dengan senang hati akan memberikan pengembalian dana penuh. Atau, dalam beberapa kasus, kami mungkin menawarkan untuk mengulang foto Anda agar lebih memenuhi harapan Anda.',
        three: 'Kami akan meninjau dan menanggapi permintaan pengembalian dana, berdasarkan kebijakan ini, dalam waktu 1-3 hari kerja. Jika pengembalian dana Anda disetujui, dana tersebut akan kembali ke kartu Anda dalam waktu 5-10 hari kerja. Pengembalian dana akan diproses kembali ke metode pembayaran asli yang digunakan saat pembelian; kami tidak dapat mengembalikan dana ke akun yang berbeda.',
        four: 'Kami hanya dapat memproses permintaan pengembalian dana yang diajukan dalam waktu 30 hari sejak pembelian dan penggunaan KURANG DARI 20 kredit.',
        five: 'Untuk mengajukan pengembalian dana, silakan kirim email kepada kami di hello@decorify.pro atau gunakan fitur obrolan yang tersedia di situs web kami. Saat menghubungi kami tentang pengembalian dana, harap sertakan alamat email yang Anda gunakan saat melakukan pemesanan.',
        six: 'Terima kasih telah memilih Reok.pro untuk foto headshot Anda.',
    },
    footer: {
        title: 'Decorify adalah aplikasi berbasis AI untuk Pementasan Virtual, Remodeling, Lansekap, Desain Interior, dan lainnya.',
        terms: 'Syarat',
        privacy: 'Kebijakan Privasi',
        app: 'Aplikasi',
        signin: 'Masuk',
        signup: 'Daftar',
        email: 'Email',
        chatNow: 'Obrolan Sekarang',
        partner: 'Mitra',
        reportBug: 'Laporkan Bug',
        require: 'Ajukan Permintaan',
        page: 'Halaman',
        tos: 'Hukum',
        contact: 'Kontak',
        other: 'Lainnya',
        affiliate: 'Afiliasi',
        yearbook: 'Buku Tahunan AI',
        blog: 'Blog',
        pricing: 'Harga',
        howItWorks: 'Cara Kerja',
        sendFeedback: 'Kirim Umpan Balik'
    },
    tos: {
        agree: 'Akun baru tunduk pada ',
        one: '1. Pendahuluan',
        oneDetail: 'Dengan menggunakan decorify.pro, Anda setuju dan terikat oleh syarat dan ketentuan ini.',
        two: '2. Persetujuan terhadap Syarat',
        twoDetail: 'Perjanjian ini berlaku sejak tanggal pertama kali Anda menggunakan aplikasi decorify.pro.',
        three: '3. Fitur',
        threeDetail: 'Anda akan dapat menggunakan semua fitur setelah berlangganan paket berbayar tertentu. Detail fitur yang tersedia dapat ditemukan di halaman harga.',
        four: '4. Kebijakan Pengembalian Dana',
        fourDetail: 'Kami mengizinkan pengembalian dana untuk transaksi yang gagal dalam waktu 14 hari sejak pembelian. Hubungi dukungan untuk meminta pengembalian dana.',
        five: '5. Penggunaan Produk',
        fiveDetail: 'Dengan menggunakan decorify.pro, Anda setuju untuk menerima pembaruan produk penting dari decorify.pro melalui email yang terkait dengan akun Google Anda atau email yang Anda gunakan untuk mendaftarkan akun Anda. Anda dapat berhenti berlangganan pembaruan produk ini kapan saja dengan mengklik tautan "Berhenti berlangganan" di bagian bawah setiap email. Kami hanya mengirimkan pembaruan produk penting.',
        six: '6. Penafian',
        sixDetail: 'Kami tidak menjamin bahwa decorify.pro akan memenuhi persyaratan Anda atau bahwa operasinya akan bebas gangguan atau bebas kesalahan. Semua jaminan atau kondisi yang tidak secara tegas ditetapkan dalam Perjanjian ini (termasuk, tanpa batasan, kehilangan keuntungan, kehilangan atau kerusakan data, gangguan bisnis, atau kerugian uang, kerusakan, biaya, atau pengeluaran lain apa pun) dikecualikan dan ditolak sejauh diizinkan oleh hukum. Perjanjian ini tidak mempengaruhi hak hukum yang mungkin Anda miliki sebagai konsumen.',
        seven: '7. Batasan Jaminan dan Hukum',
        sevenDetail: 'decorify.pro tidak memberikan jaminan, garansi, atau representasi lain tentang kualitas, kesesuaian untuk tujuan tertentu, atau karakter atau kinerja lain dari perangkat lunak. decorify.pro tidak akan bertanggung jawab kepada Anda atas kehilangan keuntungan atau kerugian konsekuensial, khusus, insidental, atau tidak langsung yang timbul dari atau terkait dengan Perjanjian ini atau penggunaan decorify.pro oleh Anda (bagaimanapun timbulnya, termasuk kelalaian) kecuali di mana tanggung jawab tidak dapat dikecualikan berdasarkan hukum.',
        eight: '8. Syarat Umum dan Hukum',
        eightDetail: 'Anda mengakui bahwa tidak ada usaha patungan, kemitraan, hubungan kerja, atau hubungan keagenan yang ada antara Anda dan decorify.pro sebagai akibat dari penggunaan layanan ini. Anda setuju untuk tidak menyatakan diri Anda sebagai perwakilan, agen, atau karyawan decorify.pro. Anda setuju bahwa decorify.pro tidak akan bertanggung jawab kepada Anda atas tindakan apa pun yang kami ambil untuk mencegah atau mengatasi penyalahgunaan platform kami. Anda mengakui bahwa, dalam menyediakan penggunaan decorify.pro kepada Anda, kami telah mengandalkan persetujuan Anda untuk terikat oleh ketentuan Perjanjian ini.',
    },
    faq: {
        title: 'Pertanyaan yang sering diajukan',
        q1: 'Bagaimana cara kerja Decorify?',
        answer1: 'Decorify adalah aplikasi berbasis web yang memungkinkan Anda mengubah dan mendesain ulang semua bagian rumah Anda, termasuk interior, eksterior, taman, teras, dan lainnya. Memanfaatkan kekuatan Kecerdasan Buatan, aplikasi ini menghasilkan konsep baru setiap beberapa detik, menawarkan berbagai gaya, mode, jenis ruangan, dan lainnya untuk membantu Anda mencapai hasil yang diinginkan.',
        q2: 'Apakah benar menghemat uang dan waktu saya?',
        answer2: 'Ya, aplikasi kami menawarkan solusi hemat biaya untuk penggunaan pribadi dan profesional. Tanpa menyewa profesional mahal, Anda dapat memvisualisasikan bagaimana rumah Anda akan terlihat dengan perabotan, dekorasi, dan desain halaman belakang yang berbeda, tanpa harus menjelajahi situs web tak terbatas untuk inspirasi. Desainer AI kami menganalisis preferensi Anda dan menghasilkan rekomendasi yang dipersonalisasi, memudahkan untuk memvisualisasikan dan menciptakan rumah yang menakjubkan.',
        q3: 'Apakah berfungsi di perangkat apa pun? (Laptop, MacOS, PC, iOS, Android)',
        answer3: 'Tentu saja! Anda tidak perlu menginstal aplikasi seluler atau program desktop apa pun, Anda hanya perlu menjalankannya dari browser yang paling umum digunakan (Chrome, Firefox, Safari).',
        q4: 'Seberapa mudah saya dapat menggunakannya?',
        answer4: 'Cukup unggah gambar Anda dan mulai menghasilkan ide-ide AI baru.',
        q5: 'Siapa yang dapat menggunakan Decorify AI?',
        answer5: 'Aplikasi kami mudah digunakan oleh siapa saja, terlepas dari pengetahuan atau keahlian mereka dalam interior, taman, atau arsitektur. Dapat digunakan oleh pemilik rumah yang ingin merenovasi atau membangun, arsitek yang membutuhkan ide segar atau hasil instan, desainer interior yang mencari saran dekorasi, dan bahkan perancang lansekap. Agen real estate dan agensi juga menggunakan perangkat lunak kami untuk meningkatkan daftar mereka, menjual rumah lebih mudah, dan menunjukkan ide-ide interior dan eksterior AI dengan cepat.',
        q6: 'Bagaimana desainer AI dapat meningkatkan rumah saya?',
        answer6: 'Desainer AI menawarkan banyak kemungkinan untuk interior Anda. Anda dapat menjelajahi berbagai gaya dan pilihan dekorasi untuk menciptakan ruang hidup yang harmonis dan menarik secara visual.',
        q7: 'Apakah berfungsi dengan rencana arsitektur dan sketsa?',
        answer7: 'Tentu saja! Perangkat lunak kami dirancang untuk bekerja dengan sketsa arsitektur. Baik Anda memiliki sketsa tangan atau rendering digital, Anda dapat dengan mudah mengunggahnya ke platform kami kemudian merender sketsa Anda menjadi visualisasi realistis',
        q8: 'Apakah berfungsi untuk rumah/interior/eksterior yang sedang dalam konstruksi?',
        answer8: 'Tentu saja! Pengguna kami banyak menggunakan perangkat lunak kami untuk menghasilkan ide tentang cara mendekorasi interior dan eksterior mereka yang sedang dalam konstruksi (ruangan, rumah, halaman belakang).',
        q9: 'Apakah berfungsi dengan pementasan virtual dan pementasan rumah?',
        answer9: 'Tentu saja! Dengan mengunggah foto ruangan kosong atau ruang yang ada, kami membantu Anda bereksperimen dengan pementasan virtual. Ini adalah alat yang sangat berharga untuk profesional real estate, pemilik rumah, dan layanan rumah airbnb.',
        q10: 'Apakah Anda memiliki kebijakan pengembalian dana?',
        answer10: 'Ya! Kami sangat yakin dengan teknologi kami sehingga kami menawarkan jaminan uang kembali penuh. Silakan lihat kebijakan pengembalian dana kami untuk detailnya.'
    },
    signin: {
        title: 'Masuk',
        another: 'Atau dengan Email',
        emailPlaceHolder: 'Alamat Email',
        passwordPlaceHolder: 'Kata Sandi',
        rememberTitle: 'Ingat untuk Lain Kali',
        forgetPassword: 'Lupa Kata Sandi?',
        noAccount: 'Belum punya akun?'
    },
    signup: {
        title: 'Buat akun atau masuk dengan akun yang sudah ada.',
        another: 'Atau dengan Email',
        firstNamePlaceHolder: 'Nama Depan',
        lastNamePlaceHolder: 'Nama Belakang',
        emailPlaceHolder: 'Alamat Email',
        passwordPlaceHolder: 'Kata Sandi',
        rememberTitle: 'Ingat untuk Lain Kali',
        forgetPassword: 'Lupa Kata Sandi?',
        gotAccount: 'Sudah punya akun?'
    },
    // Part 3
    forgetPassword: {
        title: 'Pulihkan Kata Sandi',
        content: 'Kami akan mengirimkan email pemulihan kata sandi kepada Anda',
        emailPlaceHolder: 'Silakan masukkan Email Anda',
        sendBtn: 'Kirim',
        backSignin: 'Kembali ke Masuk'
    },
    pricing: {
        yearlySubscription: '1000 desain',
        monthlySubscription: '100 desain',
        saveDesk: 'Hemat 10%',
        saveMobile: 'Hemat 10% saat membeli 1000 desain',
        popular: "76% memilih paket ini",
        free: {
            title: 'Kredit Gratis',
            price: '0$',
            trialTitle: 'Kode Registrasi: Silakan Masuk',
            joinTitle: 'Tempelkan kode Anda di sini',
            one: 'Dapatkan 20 Kredit Gratis',
            two: 'Termasuk Semua Fitur 100 Kredit',
            affiliateTitle: 'Program afiliasi',
            shareTitle: 'Bagikan dengan teman👇',
            loginToShare: 'Silakan masuk untuk berbagi'
        },
        basic: {
            title: '100 Kredit',
            price: '7$',
            duration: '/seumur hidup',
            recommend: 'Untuk Individu',
            one: '20 Foto Profesional',
            two: 'Hapus Latar Belakang',
            three: 'Tingkatkan ke 4K',
            four: 'Unduh Semua Foto',
            five: 'Penyimpanan Awan Tak Terbatas',
            six: 'Penggunaan Komersial Foto',
            seven: 'Akses ke Semua Fitur Baru',
            subscribed: 'Dibeli'
        },
        pro: {
            title: '1000 Kredit',
            subTitle: '(Hemat 30%)',
            price: '$49',
            duration: '/seumur hidup',
            recommend: 'Pemilik Kedai Kopi, Bisnis, Studio Foto',
            one: '200 Foto Profesional',
            two: 'Termasuk Semua Fitur 100 Kredit',
            three: 'Hemat Uang Anda',
            subscribed: 'Dibeli'
        },
        credit: {
            title: 'Pilih paket yang sesuai dengan kebutuhan Anda!'
        },
        first: {
            title: 'PEMULA',
            price: '$19',
            priceDetail: '100 desain - tanpa langganan',
            duration: '/seumur hidup',
            recommend: '1 Foto Headshot = 5 Kredit',
            five: 'Dekorasi dengan desainer AI dasar',
            credit: '500 kredit',
            two: '100 desain/pembelian',
            three: 'Semua mode terbuka',
            one: 'Penghapusan perabotan AI',
            six: 'Lisensi komersial',
            four: 'Sketsa ke render',
            seven: '85+ jenis ruangan tersedia',
            eight: '40+ gaya tersedia',
            nine: 'Tingkatkan desain apa pun ke 4K',
            ten: 'Unduh kualitas tinggi',
            eleven: 'Penyimpanan render tak terbatas',
            twelve: 'Akses awal ke fitur baru',
            thirteen: 'Batalkan kapan saja',
            fourteen: 'Pementasan virtual AI',
        },
        second: {
            title: 'PRO',
            subTitle: '(Hemat 85%)',
            price: '$29',
            priceDetail: '1.000 desain per bulan',
            duration: '/seumur hidup',
            recommend: 'Pemilik Kedai Kopi, Bisnis, Studio Foto',
            five: 'Selalu desainer AI terbaru',
            credit: '5.000 kredit',
            two: '1.000 desain/bulan',
            three: 'Semua mode terbuka',
            one: 'Penghapusan perabotan AI',
            six: 'Lisensi komersial',
            four: 'Sketsa ke render',
            seven: '85+ jenis ruangan tersedia',
            eight: '40+ gaya tersedia',
            nine: 'Tingkatkan desain apa pun ke 4K',
            ten: 'Unduh kualitas tinggi',
            eleven: 'Penyimpanan render tak terbatas',
            twelve: 'Akses awal ke fitur baru',
            thirteen: 'Batalkan kapan saja',
            fourteen: 'Pementasan virtual AI',
            subscribed: 'Berlangganan'
        },
        third: {
            title: 'PRO TAHUNAN',
            subTitle: '(Hemat 93%)',
            price: '$199',
            priceDetail: '12.000 desain per tahun',
            duration: '/seumur hidup',
            recommend: 'Pemilik Kedai Kopi, Bisnis, Studio Foto',
            five: 'Selalu desainer AI terbaru',
            credit: '60.000 kredit',
            two: '12.000 desain/tahun',
            three: 'Semua mode terbuka',
            one: 'Penghapusan perabotan AI',
            six: 'Lisensi komersial',
            four: 'Sketsa ke render',
            seven: '85+ jenis ruangan tersedia',
            eight: '40+ gaya tersedia',
            nine: 'Tingkatkan desain apa pun ke 4K',
            ten: 'Unduh kualitas tinggi',
            eleven: 'Penyimpanan render tak terbatas',
            twelve: 'Akses awal ke fitur baru',
            thirteen: 'Batalkan kapan saja',
            fourteen: 'Pementasan virtual AI',
            subscribed: 'Berlangganan'
        },
        four: {
            title: 'Solusi Perusahaan',
            subTitle: '(Hubungi Kami)',
            price: '$0',
            priceDetail: '$ 0 / foto headshot',
            duration: '/seumur hidup',
            recommend: 'Pemilik Kedai Kopi, Bisnis, Studio Foto',
            one: 'Foto headshot berkualitas tinggi',
            two: 'Perusahaan atau tim jarak jauh',
            three: 'Hemat waktu dan uang',
            four: 'Ambil foto headshot dalam hitungan detik',
            five: 'Tanpa pemotretan tradisional',
            subscribed: 'Dibeli',
        },
        currency: 'Rp',
        perMonth: '/bulan',
        safePayment: 'Pembayaran Aman',
        qr: 'Atau Gunakan Kode QR'
    },
    milestoneMessage: {
        first: 'Pelanggan lebih bahagia dengan',
        second: 'desain yang dibuat'
    },
    message: {
        success: {
            redering: 'Desain Anda sedang dirender, harap tunggu sebentar',
            rederingDone: 'AI telah selesai membuat desain',
            activated: 'Aktivasi berhasil! Nikmati membuat desain yang indah dan memuaskan!',
            removingBG: 'Menghapus latar belakang Anda',
            upscale: 'Meningkatkan resolusi',
            resetPassword: 'Kami baru saja mengirimkan email untuk mengatur ulang kata sandi Anda. Silakan periksa!',
            signIn: 'Kami baru saja mengirimkan email untuk mengaktifkan akun Anda. Silakan periksa!',
            reviewing: 'Kami telah menerima permintaan Anda. Ini akan ditinjau dalam satu hari',
            remindReviewing: 'Foto ini sedang ditinjau untuk mengklaim kredit baru',
            removed: 'Berhasil menghapus foto',
            useTheTemplate: "Menggunakan template",
            subscribed: "Anda telah berlangganan!"
        },
        error: {
            selectPhotographer: 'Silakan pilih desainer AI Anda untuk memulai',
            uploadPhoto: 'Silakan unggah ruang Anda',
            chooseRoom: 'Silakan pilih jenis ruangan',
            chooseTheme: 'Silakan pilih gaya ruangan',
            fileOverBasic: 'Silakan unggah file yang lebih kecil dari 5MB, atau tingkatkan ke Pro untuk mengunggah file yang lebih besar',
            fileOverPro: 'Silakan unggah file yang lebih kecil dari 10MB',
            wrongFile: 'File tidak valid, silakan coba file lain',
            wrongFace: 'Jangan mengambil selfie terlalu dekat, silakan coba lagi!',
            busy: 'Koneksi terputus, silakan coba lagi',
            gender: 'Silakan pilih jenis kelamin Anda',
            age: 'Silakan pilih usia Anda',
            roomType: 'Silakan pilih jenis',
            style: 'Silakan pilih satu gaya atau sesuaikan milik Anda',
            numberOfResult: 'Silakan pilih jumlah foto headshot',
            outOfCredits: 'Kredit Anda habis, silakan beli lebih banyak kredit!',
            userNotFound: 'Akun ini tidak ditemukan. Silakan masukkan akun Anda dengan benar!',
            wrongPassword: 'Kata sandi salah! Jika Anda tidak ingat kata sandi Anda, silakan gunakan Lupa Kata Sandi.',
            accountExist: 'Akun sudah ada. Coba masuk dari akun ini sebagai gantinya',
            tiktok: 'Untuk alasan Keamanan Pengguna Google, silakan gunakan browser Chrome atau Safari. Kunjungi decorify.pro untuk mengakses.',
            reviewing: 'Anda tidak dapat menghapus foto ini karena sedang ditinjau',
            background: 'Silakan pilih latar belakang Anda',
            pose: 'Silakan pilih pose Anda',
            signUpReCaptcha: 'Silakan selesaikan reCAPTCHA sebelum mendaftar.',
            emptyBG: 'Latar belakang Anda kosong, silakan masukkan!',
            tooShortBG: 'Latar belakang Anda harus lebih dari 2 kata.',
            tooLongBG: 'Latar belakang Anda terlalu panjang.',
            badWordsBG: 'Latar belakang Anda mengandung kata-kata yang tidak pantas. Silakan coba yang lain',
            emptyStyle: 'Gaya Anda kosong, silakan masukkan!',
            tooShortStyle: 'Gaya Anda harus lebih dari 2 kata.',
            tooLongStyle: 'Gaya Anda terlalu panjang.',
            badWordsStyle: 'Gaya Anda mengandung kata-kata yang tidak pantas. Silakan coba yang lain',
            emptyEmail: 'Silakan masukkan email Anda',
            validEmail: 'Silakan masukkan alamat email yang valid',
            subscribeError: 'Maaf, kami tidak dapat mendaftarkan Anda.'
        },
        warning: {
            runOutBasicCredits: 'Anda telah menggunakan semua kredit dalam paket Basic, silakan beli lebih banyak kredit',
            runOutProCredits: 'Silakan beli lebih banyak kredit untuk digunakan ^^',
            selectPose: 'Silakan pilih Jenis Kelamin Anda sebelum Pose',
            aiVirtualStaging: 'Beralih ke Rumah Virtual AI',
            aiFurnitureRemoval: 'Beralih ke Penghapusan Furnitur AI. Unduh gambar sebelum meninggalkan, karena riwayat penghapusan tidak disimpan.',
            upscale: 'Beralih ke Peningkatan Kualitas',
        },
        prompt: {
            professional: 'Foto profesional',
            removeBG: 'Hapus latar belakang',
            upscale: 'Resolusi ditingkatkan',
            rendering: 'Rendering AI',
            faceNotFound: 'Wajah Anda terlalu dekat, silakan coba selfie lain!',
            inReview: 'Sedang ditinjau',
            styles: {
                business: 'Foto bisnis profesional',
                realEstate: 'Foto agen real estate profesional',
                carShowroom: 'Foto wiraniaga mobil profesional',
                doctor: 'Foto dokter profesional',
                yearbook: 'Foto buku tahunan 90-an profesional',
                wedding: 'Foto pernikahan profesional',
                halloween: 'Foto Halloween'
            },
        },
        maintain: {
            backsoon: 'Kami sedang memelihara fitur ini, dan akan segera kembali. Silakan gunakan fitur lain sementara waktu. Terima kasih! 💗💗💗'
        }
    },
    // Part 4 
    app : {
        menu: {
            creditTitle: 'Kredit Anda',
            affiliateTitle: 'Program afiliasi',
            affiliateSub: 'Terima komisi 30% dari pembayaran untuk semua pelanggan yang Anda referensikan kepada kami!',
            professionalMode: 'Desain Rumah AI',
            removeBGMode: 'Hapus Perabotan',
            unBluryMode: 'Peningkatan Desain',
            myPhotos: 'Desain Saya',
            aiVirtualStaging: "Penataan Virtual AI"
        },
        tryThese: 'Tidak ada gambar? Coba ini:',
        goodSelfie: 'Selfie bagus',
        badSelfie: 'Selfie buruk',
        trick: 'Gunakan Peningkatan untuk mendapatkan kualitas lebih baik',
        removeBGSub: 'Menghapus latar belakang menjadi cepat, sederhana, dan akurat dengan AI.',
        professionalMode: {
            title: 'Dekorasi ruangan apa pun dengan AI',
            refundTitle: 'AI kami telah belajar untuk hasil yang hebat 💓',
            creditTitle: '1 foto = 10 kredit',
            genderTitle: 'Pilih jenis',
            ageTitle: 'Pilih usia Anda',
            styleTitle: 'Pilih gaya atau sesuaikan',
            quantityTitle: 'Jumlah desain',
            poseTitle: 'Pilih/unggah pose',
            backgroundTitle: 'Pilih/sesuaikan latar belakang',
            skipPose: 'Lewati untuk mempertahankan pose asli Anda',
            defaultPose: 'Default',
            uploadedPose: 'Diunggah',
            defaultBackground: 'Latar Belakang',
            defaultStyle: 'Gaya',
            headshotsTab: 'Foto Headshot',
            selectPhotographer: 'Pilih desainer AI Anda',
            selectPhotographerSub: 'Silakan pilih desainer yang sesuai dengan kebutuhan Anda',
            requirements: 'Sesuaikan',
            guideSub: 'Ikuti persyaratan desainer 👇',
            creativity: "Kreativitas AI",
            customize: "✍️ sesuaikan (beta)",
            enOnlyCustomize: "✍️ sesuaikan (hanya versi bahasa Inggris)",
            interiorsTitle: "Interior",
            exteriorsTitle: "Eksterior",
            gardensTitle: "Taman",
        },
        upscaleMode: {
            title: 'Hapus keburaman dan tingkatkan foto Anda dengan cepat dan akurat menggunakan AI.',
        }
    },
    myDesign: {
        title: 'Desain Saya',
        scrollTitle: 'Gulir untuk melihat semua desain Anda.',
    },
    imageComponent: {
        compare: 'Lihat',
        download: 'Unduh',
        removeObjects: 'Penghapusan Perabotan',
        upscale: 'Tingkatkan',
        virtualHome: 'Pementasan Dekorasi',
        errorPhoto: 'Perbarui Foto Buruk'
    },
    results: {
        one: '1 desain',
        four: '4 desain',
    },
    genders: {
        man: 'Pria',
        woman: 'Wanita'
    },
    styles: {
        "modern": "Modern",
        "minimalist": "Minimalis",
        "professional": "Profesional",
        "tropical": "Tropis",
        "coastal": "Pantai",
        "vintage": "Vintage",
        "neoclassic": "Neoklasik",
        "tribal": "Suku",
        "industrial": "Industrial",
        "bohemian": "Bohemian",
        "contemporary": "Kontemporer",
        "farmhouse": "Rumah Pertanian",
        "scandinavian": "Skandinavia",
        "skichalet": "Pondok Ski",
        "artdeco": "Art Deco",
        "biophilic": "Biofilik",
        "christmas": "Natal",
        "tetvn": "Tahun Baru Imlek",
        "midautumn": "Pertengahan Musim Gugur",
        "halloween": "Halloween",
        "easter": "Paskah",
        "baroque": "Barok",
        "cottagecore": "Cottagecore",
        "japanesestyle": "Gaya Jepang",
        "rustic": "Rustic",
        "artnouveau": "Art Nouveau",
        "maximalist": "Maksimalis",
        "coffee": "Kopi",
        "medieval": "Abad Pertengahan",
        "frenchcountry": "Pedesaan Prancis",
        "vaporwave": "Vaporwave",
        "hotpink": "Merah Muda Terang",
        "gaming": "Gaming",
        "cyberpunk": "Cyberpunk",
        dating: "Kencan",
        other: "Lainnya",
        custom: {
            title: 'Gaya Kustom',
            btn: 'Mulai kustom',
            dialogTitle: 'Gaya dan pakaian Anda',
            dialogSubTitle: 'Beri tahu kami gaya, pakaian, usia Anda, fotografer AI kami akan mencoba membantu Anda tampil dengannya!',
            upgrade: 'Tingkatkan fitur ini',
            example: 'setelan klasik tanpa dasi, usia 26 tahun tergantung pada apa yang Anda inginkan...',
        },
        "cyberpunk_style_bathroom": "Gaya Cyberpunk",
        "eclectic_style_coworking_space": "Gaya Eklektik",
        "maximalist_style_bedroom": "Gaya Maksimalis",
        "contemporary_style_hotel_bedroom": "Gaya Kontemporer",
        "zen_style_bedroom": "Gaya Zen",
        "cyberpunk_style_outdoor_patio": "Gaya Cyberpunk",
        "midcentury_modern_style_pool": "Gaya Modern Pertengahan Abad",
        "easter_style_drop_zone": "Gaya Paskah",
        "coastal_style_kitchen": "Gaya Pantai",
    },
    backgrounds: {
        coffeeShop: "Kedai Kopi",
        office: "Kantor",
        cityStreet: "Jalan Kota",
        factory: "Pabrik",
        garden: "Taman",
        carShowroom: "Showroom Mobil",
        bookShelf: "Rak Buku",
        hotel: "Hotel",
        goldenGate: "Golden Gate",
        snow: "Salju",
        realtor: "Agen Properti",
        brick: "Bata",
        custom: {
            title: 'Latar Belakang',
            btn: 'Mulai kustom',
            dialogTitle: 'Latar belakang Anda',
            dialogSubTitle: 'Beri tahu kami latar belakang Anda, fotografer AI kami akan mencoba membantu Anda tampil dengannya!',
            upgrade: 'Tingkatkan fitur ini',
            example: 'sebuah kedai kopi, tergantung di mana Anda inginkan...',
        },
    },
    removeTitle: 'Menghapus foto ini',
    removeSub: 'Apakah Anda yakin ingin menghapus foto ini? Foto ini akan dihapus secara permanen. Tindakan ini tidak dapat dibatalkan.',
    renewTitle: 'Perbarui foto ini',
    renewSub: 'Kami akan meninjau permintaan Anda. Jika foto ini bermasalah, foto akan dihapus secara permanen. Anda akan mendapatkan 10 kredit baru.',
    reasonTitle: 'Beri tahu kami mengapa Anda ingin memperbaruinya?',
    reasonEx: 'Itu tidak formal...',
    photographers: {
        sam: {
            name: "Sam, 👋 Hai pelanggan",
            title: "Saya ahli dalam Gaya",
            intro: "Apa yang bisa saya lakukan?",
            intro1: "Gaya dan Suasana apa pun dalam hitungan detik",
            intro2: "Foto real estate, Seni, dan Blog",
            intro3: "Desain Interior & Eksterior",
            intro4: "Isi ruangan kosong dengan Perabotan",
            intro5: "Render fotorealistis"
        },
        david: {
            name: "David, 👋 Hai pelanggan",
            title: "Saya ahli dalam Sketsa",
            intro: "Apa yang bisa saya lakukan?",
            intro1: "Sketsa apa pun menjadi foto realistis dalam hitungan detik",
            intro2: "Sketsa menjadi desain apa pun",
            intro3: "Desain rumah",
            intro4: "Desain arsitektur",
            intro5: "Desain interior"
        },
        annie: {
            name: "Annie, 👋 Hai pelanggan",
            title: "Saya ahli dalam Remodeling",
            intro: "Apa yang bisa saya lakukan?",
            intro1: "Pertahankan jendela, langit-langit, dan pintu.",
            intro2: "Isi ruangan dengan Perabotan",
            intro3: "Desain rumah",
            intro4: "Desain arsitektur",
            intro5: "Desain interior"
        }
    },
    roomsList: {
        "living_room": "Ruang tamu",
        "bedroom": "Kamar tidur",
        "dining_room": "Ruang makan",
        "bath_room": "Kamar mandi",
        "study_room": "Ruang belajar",
        "gaming_room": "Ruang gaming",
        "meeting_room": "Ruang rapat",
        "kitchen": "Dapur",
        "toilet": "Toilet",
        "coworking_space": "Ruang kerja bersama",
        "fitness_gym": "Gym kebugaran",
        "mudroom": "Ruang lumpur",
        "walk_in_closet": "Lemari berjalan",
        "office": "Kantor",
        "hotel_room": "Kamar hotel",
        "hotel_lobby": "Lobi hotel",
        "hotel_bathroom": "Kamar mandi hotel",
        "exhibition_space": "Ruang pameran",
        "house_exterior": "Eksterior rumah",
        "attic": "Loteng",
        "onsen": "Pemandian air panas",
        "drop_zone": "Area penyimpanan",
        "workshop": "Bengkel",
        "nail": "Salon kuku",
        "restaurant": "Restoran",
        "coffee_shop": "Kedai kopi",
        "clothing_store": "Toko pakaian",
        "family_room": "Ruang keluarga",
        "kids_room": "Kamar anak",
        "balcony": "Balkon",
        "wine_cellar": "Ruang penyimpanan anggur",
        "rooftop_terrace": "Teras atap",
        "sunroom": "Ruang berjemur",
        "home_spa": "Spa rumah",
        "craft_room": "Ruang kerajinan",
        "dressing_room": "Ruang ganti",
        "guest_bedroom": "Kamar tamu",
        "home_bar": "Bar rumah",
        "library": "Perpustakaan",
        "art_studio": "Studio seni",
        "yoga_studio": "Studio yoga",
        "photo_studio": "Studio foto",
        "multimedia_room": "Ruang multimedia",
        "auditorium": "Auditorium",
        "medical_exam_room": "Ruang pemeriksaan medis",
        "reception_area": "Area resepsi",
        "music_room": "Ruang musik",
        "science_laboratory": "Laboratorium sains",
        "home_theater": "Teater rumah",
        "wedding_room": "Ruang pernikahan",
        "laundry_room": "Ruang cuci",
        "outdoor_kitchen": "Dapur luar ruangan",
        "utility_room": "Ruang utilitas",
        "pet_room": "Ruang hewan peliharaan",
        "home_gym": "Gym rumah",
        "lounge": "Ruang santai",
        "play_room": "Ruang bermain",
        "reading_nook": "Sudut baca",
        "sauna": "Sauna",
        "man_cave": "Ruang pribadi pria",
        "foyer": "Ruang depan",
        "green_house": "Rumah kaca",
        "she_shed": "Ruang pribadi wanita",
        "conservatory": "Ruang kaca",
        "nursery": "Kamar bayi",
        "prayer_room": "Ruang ibadah",
        "side_of_house": "Samping rumah",
        "front_of_house": "Depan rumah",
        "back_of_house": "Belakang rumah",
        "backyard": "Halaman belakang",
        "patio": "Teras",
        "terrace": "Teras",
        "front_yard": "Halaman depan",
        "garden": "Taman",
        "court_yard": "Halaman dalam",
        "pool_area": "Area kolam renang",
        "porch": "Beranda",
        "playground": "Taman bermain"
    },
    giveAway: {
        title: 'Diskon 10% untuk semua paket',
        subTitle: 'Dapatkan info fitur baru dan hadiah dengan menggunakan aplikasi kami 👇',
        enterYourEmail: 'Masukkan email Anda...',
        notifyMe: 'Dapatkan sekarang ↗',
        note: 'Kami hanya kirim update penting. Bukan spam! Berhenti langganan kapan saja.',
        submitting: 'Mengirim...',
        emailSent: 'Email terkirim.',
        emailSentSub: 'Silakan cek email Anda dan mulai gunakan Decorify!',
        emailSentTitle: 'Hore! Anda berhasil!',
    },
    feedback: {
        title: 'Kami menghargai umpan balik Anda!',
        subTitle: 'Berikan kami umpan balik tentang bagaimana kami dapat meningkatkan. Anda akan memiliki kesempatan untuk memenangkan 100 kredit!',
        enterYourFeedback: 'Ceritakan kepada kami...',
        notifyMe: 'Kirim sekarang ↗',
        note: 'Kami hanya mengirim pembaruan aplikasi penting. Tidak akan mengirim spam! Berhenti berlangganan kapan saja.',
        submitting: 'Mengirim...',
        sent: 'Kami telah menerima umpan balik Anda ✅',
        sentSub: 'Kami akan meninjau umpan balik Anda dan segera menghubungi Anda!',
        type: {
            bug: 'Laporan bug',
            feature: 'Permintaan fitur',
            feedback: 'Umpan balik lainnya',
            refund: 'Permintaan pengembalian dana',
        },
        emptyFeedback: 'Harap masukkan pemikiran Anda!',
        emptyFeedbackType: 'Harap pilih jenis umpan balik!',
    }
};

export default id;  