import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import { Link } from 'react-router-dom';
import FAQ from '../FAQ';
import BeforeAfter from '../BeforeAfter';
import PricingLandingPage from '../PricingLandingPage';
import Testimonial from '../Testimonial';
import LovedBy from '../LovedBy';
import BeforeAfterTestimonial from '../BeforeAfterTestimonial';
import QuickSignUpLandingPageContainer from '../../containers/QuickSignUpLandingPageContainer';
import CookieConsent from '../CookieConsent';
import GiveawayModal from '../GiveAway';
import QuickSignUpEmailAndGoogleContainer from '../../containers/QuickSignUpEmailAndGoogleContainer';

const LandingPage = ({ userData, engine }) => {
  const [currentLanguage, setCurrentLanguage] = useState('');
  const [imageIndex, setImageIndex] = useState(0);
  const [isShowLoginWithGoogle, setIsShowLoginWithGoogle] = useState(false);
  const [isShowLoginWithEmailLink, setIsShowLoginWithEmailLink] = useState(false); // For unsupported browsers
  const [redirectPaymentUrl, setRedirectPaymentUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // const urls = [
  //   'https://firebasestorage.googleapis.com/v0/b/decorifyai.appspot.com/o/landingpage%2F1.webp?alt=media&token=f34a08f5-4fad-4985-86a5-688cf88c6224',
  //   'https://firebasestorage.googleapis.com/v0/b/decorifyai.appspot.com/o/landingpage%2F2.webp?alt=media&token=5c72d196-c579-42d8-9aac-ee371e645700',
  //   'https://firebasestorage.googleapis.com/v0/b/decorifyai.appspot.com/o/3.webp?alt=media&token=6c3b46e7-e516-4394-9fdb-3ab928c251be',
  //   'https://firebasestorage.googleapis.com/v0/b/decorifyai.appspot.com/o/4.webp?alt=media&token=d75ead71-9b18-4dd8-aad7-5a9a76c61c84',
  //   'https://firebasestorage.googleapis.com/v0/b/decorifyai.appspot.com/o/landingpage%2F3.webp?alt=media&token=dead1e95-35d9-477f-8bbc-323ad0b48c9a',
  //   'https://firebasestorage.googleapis.com/v0/b/decorifyai.appspot.com/o/landingpage%2F5.webp?alt=media&token=4bd9ccc6-e967-47c5-abf5-359a576a11f2',
  //   'https://firebasestorage.googleapis.com/v0/b/decorifyai.appspot.com/o/landingpage%2F6.webp?alt=media&token=85b265a9-51d7-4d61-89b2-df4daa02f1b1',
  //   'https://firebasestorage.googleapis.com/v0/b/decorifyai.appspot.com/o/landingpage%2F8.webp?alt=media&token=ee14f7ef-849e-4ee9-b0f2-850744b1d7d4'
  // ];

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  // const userPreferredLanguage = navigator.language || navigator.userLanguage;

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const refParam = urlSearchParams.get('ref');
    const affParam = urlSearchParams.get('aff');

    if (refParam) {
      localStorage.setItem('refCode', refParam);
    } else {
      localStorage.setItem('refCode', '');
    }

    if (affParam) {
      localStorage.setItem('affCode', affParam);
    } else {
      localStorage.setItem('affCode', 'decorify');
    }

    const redirect = urlSearchParams.get('r');

    if (redirect) {
      localStorage.setItem('redirect', redirect);
    } else {
      localStorage.setItem('redirect', '');
    }

    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setImageIndex((prevIndex) => (prevIndex + 1) % urls.length);
  //   }, 3000);

  //   // Clear the interval when the component unmounts
  //   return () => clearInterval(intervalId)
  // }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isShowLoginWithGoogle && ( userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)))
        setShowModal(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, [isShowLoginWithGoogle, userData]);

  function showLogin() {
    setIsShowLoginWithGoogle(true);
  }

  function showLoginWithOutPayment() {
    setRedirectPaymentUrl('');

    // const redirect = localStorage.getItem('redirect');
    // if (redirect === "fb") {
    //   setIsShowLoginWithEmailLink(true);
    //   return;
    // }
    setIsShowLoginWithGoogle(true);
  }

  function onShowLogin(isShow) {
    setIsShowLoginWithGoogle(false);
    setIsShowLoginWithEmailLink(false);
  }

  function showLoginForPayment(redirectUrl) {
    setRedirectPaymentUrl(redirectUrl);

    const redirect = localStorage.getItem('redirect');
    if (redirect === "fb") {
      setIsShowLoginWithEmailLink(true);
      return;
    }
    setIsShowLoginWithGoogle(true);
  }

  const handleSubmitted = (submitted) => {
    setIsSubmitted(submitted);
  };

  return (
    <div>
      <main className='bg-black'>
        <img src="/images/landing-cover.webp" alt="decorify" className="absolute w-full landing-photo" />
        <div className="landing-cover-header" data-controller="gradient-animation">
          <div className="flex flex-wrap justify-content-evenly lg:w-1/2">
            <div className="lg:w-1/2 px-2">
              <div className="landing-order-second flex items-center">
                {/* Content for the first column */}
                <div className="left-landing-wp">
                  <div className="title-landing-wrapper">
                    <div className="text-root-0-2-141 text-landing-title banner-landing-title banner-landing-block-title">
                      <span>
                        <h1 className="display-title-landing fw-bold text-white">{t('landing.title')}</h1>
                      </span>
                    </div>
                    <div className="sub-title-landing" style={{ maxWidth: '490px' }}>
                      <span style={{ color: '#adb5bd' }}>{t('landing.subTitle')}</span>
                    </div>
                    <div className="quick-sign-up-mobile mt-5">
                      <div className="upgrade-btn-wrapper">
                        {( userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) ? (
                          <div onClick={showLoginWithOutPayment} className="upgrade-button">
                            {t('button.try')}
                            <span style={{ marginLeft: '4px' }}>↗</span>
                          </div>
                        ) : (
                          <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                            {t('button.try')}
                            <span style={{ marginLeft: '4px' }}>↗</span>
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className="customer-demo-wrapper">
                      <div className=" relative mx-auto mt-6 space-y-4">
                        <div className="flex items-center gap-3">
                          <div className="flex -space-x-3 overflow-hidden">
                            <img
                              src="/images/customer13.webp"
                              alt=""
                              className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                              width={40}
                              height={40}
                            />{' '}
                            <img
                              style={{ marginLeft: '-12px' }}
                              src="/images/customer16.webp"
                              alt=""
                              className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                              width={40}
                              height={40}
                            />{' '}
                            <img
                              style={{ marginLeft: '-12px' }}
                              src="/images/customer17.webp"
                              alt=""
                              className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                              width={40}
                              height={40}
                            />{' '}
                            <img
                              style={{ marginLeft: '-12px' }}
                              src="/images/customer11.webp"
                              alt=""
                              className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                              width={40}
                              height={40}
                            />{' '}
                            <img
                              style={{ marginLeft: '-12px' }}
                              src="/images/customer19.webp"
                              alt=""
                              className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                              width={40}
                              height={40}
                            />
                          </div>{' '}
                          <div className="flex" style={{ color: '#facc15' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>{' '}
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>{' '}
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>{' '}
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>{' '}
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>
                          </div>
                        </div>{' '}
                        <p className="text-sm text-gray-500">{t('landing.loveBy')}</p>
                      </div>
                    </div>
                    <div className="banner-block-ctaHolder-d126-0-2-398  mt-4">
                      <div className="upgrade-btn-wrapper">
                        {( userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) ? (

                          <div onClick={showLoginWithOutPayment} className="upgrade-button">
                            {t('button.try')}
                            <span style={{ marginLeft: '4px' }}>↗</span>
                          </div>
                        ) : (
                          <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                            {t('button.try')}
                            <span style={{ marginLeft: '4px' }}>↗</span>
                          </Link>
                        )}
                      </div>
                      {/* <span className="text-gray-500">OR</span> */}
                      {/* <div className="upgrade-btn-wrapper">
                      <a className="enterprise-button" href='https://share.hsforms.com/1_jRJFYMUQMSBTV61q7Y2Pwq3ize' target='_'>
                        {t('button.forEnterprise')}
                      </a>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 px-2 mt-4">
              <div className="landing-order-second">
                <div className="banner-block-mediaWrapper">
                  <div className="media-content">
                    <div data-testid="image-root" className="base-image-content">
                      <div className="before-after-landing-wrapper">
                        <div className="video-slider">
                          <div className="banner-video-landing">
                            {!videoLoaded && (
                              <div className="video-placeholder" style={{
                                width: '500px',
                                height: '368px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                                <div className="loading-spinner"></div>
                              </div>
                            )}
                            <video
                              className="banner-video-landing"
                              autoPlay
                              loop
                              muted
                              playsInline
                              data-wf-ignore="true"
                              dataobjectfit="cover"
                              alt="decorify"
                              width={367}
                              height={313}
                              onLoadedData={() => setVideoLoaded(true)}
                              style={{ display: videoLoaded ? 'block' : 'none' }}
                            >
                              <source
                                src="https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-intro.mp4?alt=media&token=3d1a0ae3-48d3-476f-ace5-c412785a0d98"
                                data-wf-ignore="true"
                              />
                            </video>
                            <div className="flex justify-center mt-4">
                              <div className="w-full flex justify-center">
                                <div className="text-align-center text-sm text-gray-500 pl-5 pr-5">{t('landing.subTitle1')}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center text-center relative as-seen-on-wrapper">
            <span className="text-gray-500">as seen on</span>
            <img className="tiktok-seen-on" loading="lazy" alt="decorify" src="images/tiktok.webp" />
            <img className="facebook-seen-on" loading="lazy" alt="decorify" src="images/facebook.svg" />
            <img className="instagram-seen-on" loading="lazy" alt="decorify" src="images/instagram.webp" />
            <img className="linkedin-seen-on" loading="lazy" alt="decorify" src="images/linkedin.png" />
            <img className="x-seen-on" loading="lazy" alt="decorify" src="images/x.webp" />
            <img className="reddit-seen-on" loading="lazy" alt="decorify" src="images/reddit.webp" />
          </div>
          {/* <div className="flex items-center justify-center text-center relative">
            <MoneyBackGuarantee/>
          </div> */}
        </div>
      </main>
      <main>
        {/* Before After slider */}
        <section className="bg-black relative overflow-hidden pb-1" data-layouts="simple_cards">
          <BeforeAfterTestimonial />
        </section>

        {/* How it works */}
        <section className="bg-black relative overflow-hidden section-top-90" data-layouts="simple_cards">
          <div className="space-y-8 md:space-y-[85px]">
            <div className="container">
              <div className="w-full" id="how-it-works">
                <h2 className="mx-auto relative z-[10] t-50 text-white mb-3 md:mb-6 font-bold text-center mx-auto md:text-center">
                  {t('landing.howItWorks')}
                </h2>
              </div>

              {/* How it works steps */}
              <div className="space-y-8">
                <ul className="cards">
                  <li className="cards_item">
                    <div className="card">
                      <div className="card_content">
                        <div className="w-full h-20 flex items-center justify-center">
                          <div className="relative items-center justify-center inline-block p-4 px-5 py-3 overflow-hidden font-medium text-indigo-600 rounded-lg shadow-2xl group">
                            <span className="absolute top-0 left-0 w-40 h-40 -mt-10 -ml-3 transition-all duration-700 bg-red-500 rounded-full blur-md ease"></span>
                            <span className="absolute inset-0 w-full h-full transition duration-700 group-hover:rotate-180 ease">
                              <span className="absolute bottom-0 left-0 w-24 h-24 -ml-10 bg-purple-500 rounded-full blur-md"></span>
                              <span className="absolute bottom-0 right-0 w-24 h-24 -mr-10 bg-pink-500 rounded-full blur-md"></span>
                            </span>
                            <span className="relative text-white t-30">1</span>
                          </div>
                        </div>
                        <h2 className="card_title text-align-center mt-4">{t('landing.step1')}</h2>
                        <p className="card_text text-align-center text-gray-500">{t('landing.step1Sub')}</p>
                      </div>
                    </div>
                  </li>
                  <li className="cards_item">
                    <div className="card">
                      <div className="card_content">
                        <div className="w-full h-20 flex items-center justify-center">
                          <div className="relative items-center justify-center inline-block p-4 px-5 py-3 overflow-hidden font-medium text-indigo-600 rounded-lg shadow-2xl group">
                            <span className="absolute top-0 left-0 w-40 h-40 -mt-10 -ml-3 transition-all duration-700 bg-gray-800 rounded-full blur-md ease"></span>
                            <span className="absolute inset-0 w-full h-full transition duration-700 group-hover:rotate-180 ease">
                              <span className="absolute bottom-0 left-0 w-24 h-24 -ml-10 bg-gray-500 rounded-full blur-md"></span>
                              <span className="absolute bottom-0 right-0 w-24 h-24 -mr-10 bg-black-500 rounded-full blur-md"></span>
                            </span>
                            <span className="relative text-white t-30">2</span>
                          </div>
                        </div>
                        <h2 className="card_title text-align-center mt-4">{t('landing.step2')}</h2>
                        <p className="card_text text-align-center text-gray-500">{t('landing.step2Sub')}</p>
                      </div>
                    </div>
                  </li>
                  <li className="cards_item">
                    <div className="card">
                      <div className="card_content">
                        <div className="w-full h-20 flex items-center justify-center">
                          <div className="relative items-center justify-center inline-block p-4 px-5 py-3 overflow-hidden font-medium text-indigo-600 rounded-lg shadow-2xl group">
                            <span className="absolute top-0 left-0 w-40 h-40 -mt-10 -ml-3 transition-all duration-700 bg-yellow-500 rounded-full blur-md ease"></span>
                            <span className="absolute inset-0 w-full h-full transition duration-700 group-hover:rotate-180 ease">
                              <span className="absolute bottom-0 left-0 w-24 h-24 -ml-10 bg-yellow rounded-full blur-md"></span>
                              <span className="absolute bottom-0 right-0 w-24 h-24 -mr-10 bg-yellow-500 rounded-full blur-md"></span>
                            </span>
                            <span className="relative text-white t-30">3</span>
                          </div>
                        </div>
                        <h2 className="card_title text-align-center mt-4">{t('landing.step3')}</h2>
                        <p className="card_text text-align-center text-gray-500">{t('landing.step3Sub')}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              {/* <div className="section-top-90" style={{color: "#fff"}}>
                <LovedBy t={t}/>
              </div> */}
              {/* <div className="grid gap-5 sm:grid-cols-2 sm:gap-4 lg:gap-10">
                <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300 bg-light-aliceblue">
                  <div className="rnftg-item__img-wrapper_landing">
                    <div>
                      <img
                        className="banner-video"
                        alt="decorify"
                        src="https://firebasestorage.googleapis.com/v0/b/decorifyai.appspot.com/o/results%2Fmanshowcase1.webp?alt=media&token=6824ac4d-0d53-49b1-b7bc-fbe19f8dafe5"
                      ></img>
                    </div>
                  </div>
                </article>
                <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300 bg-light-purple">
                  <div className="rnftg-item__img-wrapper_landing">
                    <div>
                      <img
                        className="banner-video"
                        alt="decorify"
                        src="https://firebasestorage.googleapis.com/v0/b/decorifyai.appspot.com/o/results%2Fwoman-showcase.webp?alt=media&token=26253631-2f1f-4d91-bd09-71203c63a324"
                      ></img>
                    </div>
                  </div>
                </article>
              </div> */}
            </div>
          </div>
        </section>

        {/* Redesign with any Style */}
        <section
          className="relative overflow-hidden bg-black section-top-180 section-bottom-90"
          data-layouts="text_card,featured_cards"
        >
          <div className="container">
            <div className="flex flex-wrap justify-content-evenly w-1/2 md:w-full">
              <div className="card-w">
                <div className="w-full p-2">
                  <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                    <span>{t('landing.interiorShowCase.label')}</span>
                  </div>
                  <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-3 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    {t('landing.interiorShowCase.title')}
                  </h2>
                  <div
                    className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                    style={{ maxWidth: '722px' }}
                  >
                    <p className="text-lg">
                      {t('landing.interiorShowCase.subTitle')}
                    </p>
                  </div>
                  <div className="flex flex-col align-items-center mb-6">
                    <div className="getstarted-btn-wrapper">
                      {( userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) ? (

                        <div onClick={showLoginWithOutPayment} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>↗</span>
                        </div>
                      ) : (
                        <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>↗</span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-w">
                <div className="flex justify-center">
                  <BeforeAfter
                    key={1}
                    images={{
                      before:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F1.webp?alt=media&token=7324c826-ecd9-460b-a38d-581901182099',
                      after:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F2.webp?alt=media&token=e867de48-3bf5-48a7-a7c0-616d6fa3cc31',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Creative redesign for exteriors */}
        <section
          className="relative overflow-hidden bg-black section-top-90 section-bottom-90"
          data-layouts="text_card,featured_cards"
        >
          <div className="container">
            <div className="flex flex-wrap justify-content-evenly w-1/2 md:w-full">
              <div className="card-w landing-order-second">
                <div className="flex justify-center">
                  <BeforeAfter
                    key={2}
                    images={{
                      before:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F3.webp?alt=media&token=bca96e92-1d8f-47b5-b74f-e41fbfbb044d',
                      after:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F4.webp?alt=media&token=5ee936a2-291d-44c9-9fcc-bb2f45be1283',
                    }}
                  />
                </div>
              </div>
              <div className="card-w landing-order-first">
                <div className="w-full p-2">
                  <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                    <span>{t('landing.exteriorShowCase.label')}</span>
                  </div>
                  <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-3 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    {t('landing.exteriorShowCase.title')}
                  </h2>
                  <div
                    className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                    style={{ maxWidth: '722px' }}
                  >
                    <p className="text-lg">
                      {t('landing.exteriorShowCase.subTitle')}
                    </p>
                  </div>
                  <div className="flex flex-col align-items-center mb-6">
                    <div className="getstarted-btn-wrapper">
                      {( userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) ? (

                        <div onClick={showLoginWithOutPayment} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>↗</span>
                        </div>
                      ) : (
                        <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>↗</span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Fill an empty room with furnitures */}
        <section
          className="relative overflow-hidden bg-black section-top-90 section-bottom-90"
          data-layouts="text_card,featured_cards"
        >
          <div className="container">
            <div className="flex flex-wrap justify-content-evenly w-1/2 md:w-full">
              <div className="card-w">
                <div className="w-full p-2">
                  <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                    <span>{t('landing.furnitureShowCase.label')}</span>
                  </div>
                  <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-3 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    {t('landing.furnitureShowCase.title')}
                  </h2>
                  <div
                    className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                    style={{ maxWidth: '722px' }}
                  >
                    <p className="text-lg">
                      {t('landing.furnitureShowCase.subTitle')}
                    </p>
                  </div>
                  <div className="flex flex-col align-items-center mb-6">
                    <div className="getstarted-btn-wrapper">
                      {( userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) ? (

                        <div onClick={showLoginWithOutPayment} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>↗</span>
                        </div>
                      ) : (
                        <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>↗</span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-w">
                <div className="flex justify-center">
                  <BeforeAfter
                    key={2}
                    images={{
                      before:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F30.webp?alt=media&token=89fda116-52d0-4917-989c-71b815c7b416',
                      after:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F31.webp?alt=media&token=5c4b57b3-7851-4d2b-a528-47c19d4814a5',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Remove unwanted objects */}
        <section
          className="relative overflow-hidden bg-black section-top-90 section-bottom-90"
          data-layouts="text_card,featured_cards"
        >
          <div className="container">
            <div className="flex flex-wrap justify-content-evenly w-1/2 md:w-full">
              <div className="card-w landing-order-second">
                <div className="flex justify-center">
                  <BeforeAfter
                    images={{
                      before:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F33.webp?alt=media&token=fef50326-a4ae-4012-9748-8e242a29f4f8',
                      after:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F34.webp?alt=media&token=cf8d1daa-9bac-401d-8a48-ebe3ca518435',
                    }}
                  />
                </div>
              </div>
              <div className="card-w landing-order-first">
                <div className="w-full p-2">
                  <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                    <span>{t('landing.cleanRoomShowCase.label')}</span>
                  </div>
                  <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-3 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    {t('landing.cleanRoomShowCase.title')}
                  </h2>
                  <div
                    className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                    style={{ maxWidth: '722px' }}
                  >
                    <p className="text-lg">
                      {t('landing.cleanRoomShowCase.subTitle')}
                    </p>
                  </div>
                  <div className="flex flex-col align-items-center mb-6">
                    <div className="getstarted-btn-wrapper">
                      {( userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) ? (

                        <div onClick={showLoginWithOutPayment} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>↗</span>
                        </div>
                      ) : (
                        <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>↗</span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Upscale any design to 4K */}
        <section
          className="relative overflow-hidden bg-black section-top-90 section-bottom-90"
          data-layouts="text_card,featured_cards"
        >
          <div className="container">
            <div className="flex flex-wrap justify-content-evenly w-1/2 md:w-full">
              <div className="card-w">
                <div className="w-full p-2">
                  <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                    <span>{t('landing.upscaleShowCase.label')}</span>
                  </div>
                  <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-3 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    {t('landing.upscaleShowCase.title')}
                  </h2>
                  <div
                    className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                    style={{ maxWidth: '722px' }}
                  >
                    <p className="text-lg">
                      {t('landing.upscaleShowCase.subTitle')}
                    </p>
                  </div>
                  <div className="flex flex-col align-items-center mb-6">
                    <div className="getstarted-btn-wrapper">
                      {( userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) ? (

                        <div onClick={showLoginWithOutPayment} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>↗</span>
                        </div>
                      ) : (
                        <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>↗</span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-w">
                <div className="flex justify-center">
                  <BeforeAfter
                    images={{
                      before:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F46.webp?alt=media&token=3239a1df-c14a-402a-80e8-9e78151cba5d',
                      after:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F47.webp?alt=media&token=adba3673-c203-417c-80fe-83bddf933013',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Custom AI designers creativity */}
        <section
          className="relative overflow-hidden bg-black section-top-90 section-bottom-90"
          data-layouts="text_card,featured_cards"
        >
          <div className="container">
            <div className="flex flex-wrap justify-content-evenly w-1/2 md:w-full">
              <div className="card-w landing-order-second">
                <div className="flex justify-center">
                  <BeforeAfter
                    images={{
                      before:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F21.webp?alt=media&token=e894742f-5809-4c33-9086-1e824fb87cf8',
                      after:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F44.webp?alt=media&token=0a51edd8-52f2-4265-b22f-2d3deefb94a9',
                    }}
                  />
                </div>
              </div>
              <div className="card-w landing-order-first">
                <div className="w-full p-2">
                  <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                    <span>{t('landing.creativityShowCase.label')}</span>
                  </div>
                  <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-3 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    {t('landing.creativityShowCase.title')}
                  </h2>
                  <div
                    className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                    style={{ maxWidth: '722px' }}
                  >
                    <p className="text-lg">
                      {t('landing.creativityShowCase.subTitle')}
                    </p>
                  </div>
                  <div className="flex flex-col align-items-center mb-6">
                    <div className="getstarted-btn-wrapper">
                      {( userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) ? (

                        <div onClick={showLoginWithOutPayment} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>↗</span>
                        </div>
                      ) : (
                        <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>↗</span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Sketch to realistic designs */}
        <section
          className="relative overflow-hidden bg-black section-top-90 section-bottom-90"
          data-layouts="text_card,featured_cards"
        >
          <div className="container">
            <div className="flex flex-wrap justify-content-evenly w-1/2 md:w-full">
              <div className="card-w">
                <div className="w-full p-2">
                  <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                    <span>{t('landing.sketchShowCase.label')}</span>
                  </div>
                  <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-3 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    {t('landing.sketchShowCase.title')}
                  </h2>
                  <div
                    className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                    style={{ maxWidth: '722px' }}
                  >
                    <p className="text-lg">
                      {t('landing.sketchShowCase.subTitle')}
                    </p>
                  </div>
                  <div className="flex flex-col align-items-center mb-6">
                    <div className="getstarted-btn-wrapper">
                      {( userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) ? (

                        <div onClick={showLoginWithOutPayment} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>↗</span>
                        </div>
                      ) : (
                        <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>↗</span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-w">
                <div className="flex justify-center">
                  <BeforeAfter
                    images={{
                      before:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F5.webp?alt=media&token=3b21a822-754e-4aff-acd8-278b17250ca3',
                      after:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F48.webp?alt=media&token=f7dae04e-a20c-448f-8608-6d5cf04e0bfb',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Show customers feedback section */}
        <section

          className="relative overflow-hidden bg-black section-top-none section-top-90 section-bottom-90"
          data-layouts="simple_cards"
        >
          <Testimonial t={t} />
        </section>
        {/* Pricing section */}
        <section className="bg-neutral-black section-top-90 section-bottom-90">
          <PricingLandingPage t={t} currentLanguage={currentLanguage} userData={userData} showLoginForPayment={showLoginForPayment} />
        </section>
        {/* FAQ section */}
        <section className="bg-neutral-black section-bottom-90">
          <div className="relative bg-neutral-black mb-4">
            <div className="container">
              <FAQ t={t} />
            </div>
          </div>
        </section>
        <section className="bg-[#101014] bg-blend-luminosity bg-no-repeat bg-center bg-cover h-[50vh] flex items-center justify-center"
          style={{ backgroundImage: "linear-gradient(360deg, rgb(13, 13, 13) 0%, rgba(16, 16, 20, 30%) 100%), url('/images/footer-cover.webp')", backgroundSize: "cover", backgroundPosition: "center" }}>
          <div className="container mx-auto px-4 call-to-action">
            <div className="flex flex-col items-center text-center">
              <h2
                className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-6 md:mb-6 font-bold text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                {t('landing.joinNow')}
              </h2>
              <div className="getstarted-btn-wrapper">
                {( userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) ? (

                  <div onClick={showLoginWithOutPayment} className="upgrade-button">
                    {t('button.try')}
                    <span style={{ marginLeft: '4px' }}>↗</span>
                  </div>
                ) : (
                  <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                    {t('button.try')}
                    <span style={{ marginLeft: '4px' }}>↗</span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      {isShowLoginWithGoogle && <QuickSignUpEmailAndGoogleContainer onShowLogin={onShowLogin} redirectPaymentUrl={redirectPaymentUrl} isSubmitted={isSubmitted} onSubmitted={handleSubmitted}/>}
      {/* {isShowLoginWithGoogle && <QuickSignUpLandingPageContainer onShowLogin={onShowLogin} redirectPaymentUrl={redirectPaymentUrl} />} */}
      <CookieConsent />
      {/* Modal overlay */}
      <GiveawayModal isOpen={showModal} onClose={() => setShowModal(false)} isSubmitted={isSubmitted} onSubmitted={handleSubmitted} currentLanguage={currentLanguage} t={t} />
    </div>
  );
};

export default LandingPage;
