import React, { useEffect, useState } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';

import { Gradient } from '../AdminHeader/Gradient.js';
import BeforeAfterTestimonial from '../BeforeAfterTestimonial';
import LovedBy from '../LovedBy';

const gradient = new Gradient();

const Terms = () => {
  const [currentLanguage, setCurrentLanguage] = useState('');

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    gradient.initGradient('#canvas-gradient');
    window.scrollTo(0, 0);

    return () => { };
  }, []);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <>
      <main className='bg-black'>
      <div className="landing-cover" data-controller="gradient-animation">
          <div className="container">
            <div className="d-flex flex-column align-items-center">
              <h1 className="display-1 fw-semi-bold text-white text-center mb-5 mb-sm-4">{t('terms.title')}</h1>
              <Link
                to={currentLanguage === 'en' ? '/' : `/${currentLanguage}/`}
                className="btn btn-soft-light btn-sm rounded-pill tag text-nowrap"
              >
                <HomeOutlined />
                {t('button.backHome')}
              </Link>
            </div>
          </div>
          <canvas id="canvas-gradient" data-transition-in />
          <div id="canvas-overlay" />
        </div>
      </main>
      <section className="bg-neutral-black text-gray-300">
        <div className="terms">
          <div className="container mx-auto">
            <p className="mb-4">
              These Terms of Service ("Terms") govern your use of Decorify's website, mobile application, and services. By using Decorify, you agree to these Terms.
            </p>

            <h2 className="text-2xl font-bold mb-2">1. Acceptance of Terms</h2>

            <p className="mb-4">
              By accessing or using Decorify, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not use our services.
            </p>

            <h2 className="text-2xl font-bold mb-2">2. Description of Service</h2>

            <p className="mb-4">
              Decorify provides AI-powered home decoration and interior design services, including:
            </p>

            <ul className="list-disc list-inside mb-4" style={{ listStyleType: 'disc', paddingLeft: '1.5em' }}>
              <li>Virtual room design and visualization</li>
              <li>Personalized decor recommendations</li>
              <li>Access to a marketplace of home decor products</li>
              <li>Design consultation services</li>
            </ul>

            <h2 className="text-2xl font-bold mb-2">3. User Accounts</h2>

            <p className="mb-4">
              To use certain features of Decorify, you may need to create an account. You are responsible for:
            </p>

            <ul className="list-disc list-inside mb-4" style={{ listStyleType: 'disc', paddingLeft: '1.5em' }}>
              <li>Providing accurate and complete information</li>
              <li>Maintaining the confidentiality of your account</li>
              <li>All activities that occur under your account</li>
            </ul>

            <h2 className="text-2xl font-bold mb-2">4. User Content</h2>

            <p className="mb-4">
              You retain ownership of any content you submit to Decorify. By submitting content, you grant Decorify a non-exclusive, worldwide, royalty-free license to use, modify, and display the content for the purpose of providing our services.
            </p>

            <h2 className="text-2xl font-bold mb-2">5. Intellectual Property</h2>

            <p className="mb-4">
              All content, features, and functionality of Decorify are owned by Decorify or its licensors and are protected by copyright, trademark, and other intellectual property laws.
            </p>

            <p className="mb-4">
              Decorify does not pre-screen content, but reserves the right (but not the obligation) in their sole discretion to refuse or remove any content that is available via the Service.
            </p>

            <p className="mb-4">
              The look and feel of the Service is copyright© to Decorify. All rights reserved. You may not duplicate, copy, or reuse any portion of the HTML, CSS, JavaScript, or visual design elements without express written permission from the Company.
            </p>

            <h2 className="text-2xl font-bold mb-2">6. Prohibited Conduct</h2>

            <p className="mb-4">
              You agree not to:
            </p>

            <ul className="list-disc list-inside mb-4" style={{ listStyleType: 'disc', paddingLeft: '1.5em' }}>
              <li>Use Decorify for any unlawful purpose</li>
              <li>Impersonate any person or entity</li>
              <li>Interfere with or disrupt the service or servers</li>
              <li>Attempt to gain unauthorized access to any part of Decorify</li>
            </ul>

            <h2 className="text-2xl font-bold mb-2">7. Payments and Subscriptions</h2>

            <p className="mb-4">
              Some Decorify services may require payment. By subscribing to a paid service, you agree to pay all fees associated with the service. Fees are non-refundable except as required by law.
            </p>

            <h2 className="text-2xl font-bold mb-2">8. Termination</h2>

            <p className="mb-4">
              Decorify may terminate or suspend your account and access to the service at any time, without prior notice, for any reason, including breach of these Terms.
            </p>

            <h2 className="text-2xl font-bold mb-2">9. Disclaimer of Warranties</h2>

            <p className="mb-4">
              Decorify is provided "as is" without any warranties, express or implied. We do not guarantee that the service will be uninterrupted, secure, or error-free.
            </p>

            <h2 className="text-2xl font-bold mb-2">10. Limitation of Liability</h2>

            <p className="mb-4">
              Decorify shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of or inability to use the service.
            </p>

            <h2 className="text-2xl font-bold mb-2">11. Changes to Terms</h2>

            <p className="mb-4">
              We may modify these Terms at any time. We will notify you of significant changes by posting the new Terms on our website and updating the "Last Updated" date.
            </p>

            <h2 className="text-2xl font-bold mb-2">12. Governing Law</h2>

            <p className="mb-4">
              These Terms shall be governed by and construed in accordance with the laws of the State of California, United States, without regard to its conflict of law provisions.
            </p>

            <h2 className="text-2xl font-bold mb-2">13. Contact Us</h2>

            <p className="mb-4">
              If you have any questions about these Terms, please contact us at support@decorify.pro.
            </p>

            <p className="mb-4" style={{ fontWeight: '700' }}>
              Last Updated: Mon, Aug 26, 2024
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="relative bg-neutral-black">
          <div className="flex flex-col align-items-center section-top-90">
            <BeforeAfterTestimonial />
            <div className="getstarted-btn-wrapper mt-4">
              <Link to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`} className="upgrade-button mt-4">
                {t('button.try')}
                <span style={{ marginLeft: '4px' }}>↗</span>
              </Link>
            </div>
            <div className="text-white mt-4">
              <LovedBy t={t} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Terms;