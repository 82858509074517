import React from "react";
import { Link } from "react-router-dom";

class HeaderSticky extends React.Component {
  constructor(props) {
    super(props);
    this.state = { height: 0, scrolltop: 0 };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    const height = this.divElement.clientHeight;

    this.setState({ height });
    window.addEventListener("scroll", this.handleScroll);
  }
  componentDidUpdate(prevProps, prevState) {
    const scrollTop2 = window.pageYOffset;

    if (scrollTop2 !== prevState.scrolltop) {
      this.setState({ scrolltop: scrollTop2 });
    }
  }

  handleScroll(event) {
    const scrollTop = window.scrollTo;

    this.setState({
      scrolltop: scrollTop
    });
  }
  render() {
    const { currentLanguage, isMobile, isActiveMobileMenu, openMobileMenu, onActiveProfessional } = this.props;

    return (
      <div>
        <div className="nav-header" ref={divElement => (this.divElement = divElement)}>
          {/* <Link id="brand" className="navbar-brand" to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} onClick={onActiveProfessional}>
            <div className="halloween">
              <img src="/images/santa.png" alt="ghost" className="halloween-animation"/>
            </div>
            <img src="/images/santa-hat.png" alt="santa hat" className="christmas"/>
              <img width={180} src='/images/airoomdecor-full.webp'/>
          </Link>
          {isMobile && (
          <nav className="hamburger-menu" role="navigation">
            <div id="menuToggle">
              <input type="checkbox" checked={isActiveMobileMenu} onChange={() => openMobileMenu()} />
              <span></span>
              <span></span>
              <span></span>
              <div id="menu"></div>
            </div>
          </nav>
          )} */}
        </div>
        <nav
          // className={
          //   this.state.scrolltop > this.state.height -50
          //     ? "main-nav main-nav-scrolled"
          //     : "main-nav"
          // }
          className="main-nav-scrolled"
        >
          <Link id="brand" className="navbar-brand" to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} onClick={onActiveProfessional}>
            {/* <div className="halloween">
              <img src="/images/santa.png" alt="ghost" className="halloween-animation"/>
            </div>
            <img src="/images/santa-hat.png" alt="santa hat" className="christmas"/> */}
             {isMobile ? (
                <img width={160} style={{padding: 7}} src='/images/airoomdecor-full.webp'/>
             ) : (
              <img width={140} src='/images/airoomdecor-full.webp'/>
             )}
          </Link>
          {isMobile && (
          <nav className="hamburger-menu" role="navigation">
            <div id="menuToggle">
              <input type="checkbox" checked={isActiveMobileMenu} onChange={() => openMobileMenu()} />
              <span></span>
              <span></span>
              <span></span>
              <div id="menu"></div>
            </div>
          </nav>
          )}
        </nav>
      </div>
    );
  }
}
export default HeaderSticky;