import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Button, message } from 'antd';
import FAQ from '../FAQ';
import BeforeAfter from '../BeforeAfter';
import GenComponent from '../GenComponent';
import TextField from '@mui/material/TextField';
import QuickSignUpContainer from '../../containers/QuickSignUpContainer';
import axios from 'axios';

const APP_URL_V1 = process.env.REACT_APP_APP_URL;

const upgradeResolution = {
  before: 'https://cdn.discordapp.com/attachments/1105800665105711166/1170558969941606530/blurry.webp',
  after: 'https://cdn.discordapp.com/attachments/1105800665105711166/1170558970604302437/unblurry.webp',
};

const XPage = ({firebase, setUser}) => {
  const [currentLanguage, setCurrentLanguage] = useState('');
  const [xUrl, setXUrl] = useState('');
  const [showLogin, setShowLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [genButtonText, setGenButtonText] = useState("Take Free Headshots");
  const [showGenComponent, setShowGenComponent] = useState(false);
  const [imageUrl, setImageUrl] = useState('https://pbs.twimg.com/profile_images/1645880810958929921/8mi_I17M_400x400.jpg');

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  // const userPreferredLanguage = navigator.language || navigator.userLanguage;

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const refParam = urlSearchParams.get('ref');
    const affParam = urlSearchParams.get('aff');

    if (refParam) {
      localStorage.setItem('refCode', refParam);
      // You can perform further actions with the referral code here
    } else {
      localStorage.setItem('refCode', '');
    }

    if (affParam) {
      localStorage.setItem('affCode', affParam);
      // You can perform further actions with the referral code here
    } else {
      localStorage.setItem('affCode', '');
    }

    const redirect = urlSearchParams.get('r');

    if (redirect) {
      localStorage.setItem('redirect', redirect);
      // You can perform further actions with the referral code here
    } else {
      localStorage.setItem('redirect', "");
    }

    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const handleInputChange = (event) => {
    setXUrl(event.target.value);
  };

  const checkAuth = () => {
    return new Promise((resolve) => {
      console.log(firebase.auth);
      const unsubscribe = firebase.auth.onAuthStateChanged((user) => {
        if (user) {
          resolve(true);
        } else {
          resolve(false);
        }
  
        // Don't forget to unsubscribe to avoid memory leaks
        unsubscribe();
      });
    });
  };

  const onSubmitHeadshot = () => {
    if (xUrl === undefined || xUrl === ""){
      message.error("Please input your X user name or url");
      return;
    }

    setLoading(true);
    setGenButtonText("Getting your X photo");

    checkAuth().then(async (isLoggedIn) => {
      if (!isLoggedIn) {
        setShowLogin(true);
        setLoading(false);
        return;
      } else {
        // Get image from fetchImageFromX
        const fetchReult = await fetchImageFromX(xUrl);
        console.log(fetchReult);
        if (fetchReult.status === 400){
          setTimeout(() => {
            setLoading(false);
            message.error(fetchReult.error);
            setGenButtonText("Take Free Headshots");
          }, 3000);
        } else {
          setTimeout(() => {
            const newUrl = `/app?url=${encodeURIComponent(fetchReult.imgUrl)}`;
            window.location.href = newUrl;
          }, 2000);
        }

        // Show gen component
        // setImageUrl(imageUrl);
        // setShowGenComponent(true);
      }
    });
  }

  const onSubmitWithGoogle = () => {
    setShowLogin(false);
    // Get image from fetchImageFromX
    setLoading(true);
    setGenButtonText("Getting your X image");

    setTimeout(() => {
      const newUrl = `/app?url=${encodeURIComponent(imageUrl)}`;
      window.location.href = newUrl;
    }, 2000);
  };

  const fetchImageFromX = async (url) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${firebase.auth.currentUser.accessToken}`,
      };
  
      const requestDataEng = {
        url: url
      };
  
      const result = await axios.post(`${APP_URL_V1}/verify-x-headshot`, requestDataEng, { headers });
      return {
        status: 200,
        imgUrl: result.data.imgUrl
      }
    } catch (error) {
      return {
        status: 400,
        error: error.response.data
      }
    }
  }

  return (
    <>
      <main style={{ background: '#000' }}>
        <img src="/images/night-background.webp" alt="reok" className="absolute inset-0 object-cover object-bottom w-full h-full" />
        <div className="x-landing-cover-header" data-controller="gradient-animation">
          <Row gutter={12}>
            <Col md={24} xs={24} className="x-landing-right flex justify-content-center items-center">
              {!showGenComponent && 
                <div className="x-url-wrapper">
                <div className="x-url-padding">
                <h1 className="fw-bold text-white text-align-center" style={{fontSize: "32px"}}>X Headshots 📸</h1>
                <p className="text-sm text-gray-500 flex justify-content-center mb-4">
                  Only verified account
                  <span style={{marginLeft: "4px"}}>
                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M21.007 8.27C22.194 9.125 23 10.45 23 12c0 1.55-.806 2.876-1.993 3.73.24 1.442-.134 2.958-1.227 4.05-1.095 1.095-2.61 1.459-4.046 1.225C14.883 22.196 13.546 23 12 23c-1.55 0-2.878-.807-3.731-1.996-1.438.235-2.954-.128-4.05-1.224-1.095-1.095-1.459-2.611-1.217-4.05C1.816 14.877 1 13.551 1 12s.816-2.878 2.002-3.73c-.242-1.439.122-2.955 1.218-4.05 1.093-1.094 2.61-1.467 4.057-1.227C9.125 1.804 10.453 1 12 1c1.545 0 2.88.803 3.732 1.993 1.442-.24 2.956.135 4.048 1.227 1.093 1.092 1.468 2.608 1.227 4.05Zm-4.426-.084a1 1 0 0 1 .233 1.395l-5 7a1 1 0 0 1-1.521.126l-3-3a1 1 0 0 1 1.414-1.414l2.165 2.165 4.314-6.04a1 1 0 0 1 1.395-.232Z"
                      fill="#fff"/>
                    </svg>
                  </span>
                </p>
                  <div className="x-url">
                    <TextField 
                      value={xUrl}
                      sx={{ width: '100%'}}
                      maxRows={1}
                      minRows={1}
                      onChange={handleInputChange}
                      placeholder="Type your username or url"
                      inputProps={{style: {fontSize: 14}}} // font size of input text
                      InputLabelProps={{style: {fontSize: 14}}} // font size of input label
                    />
                  </div>
                  <div className="upgrade-btn-wrapper">
                    <Button 
                      className="x-submit-button mt-5"
                      onClick={() => onSubmitHeadshot()}
                      type="submit"
                      loading={loading}
                    >
                      {genButtonText}
                    </Button>
                  </div>
                  <div className="relative mx-auto mt-6 max-w-lg space-y-4">
                    <div className="flex items-center gap-3 justify-content-center">
                      <div className="flex -space-x-3 overflow-hidden">
                        <img
                          src="/images/customer13.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer16.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer17.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer11.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer19.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                        />
                      </div>{' '}
                      <div className="flex" style={{ color: '#facc15' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </div>
                    </div>{' '}
                    <p className="text-sm text-gray-500 flex justify-content-center">{t('landing.loveBy')}</p>
                  </div>
                </div>
                </div>
              }
              {showLogin &&
                <QuickSignUpContainer onSubmitWithGoogle={onSubmitWithGoogle}/>
              }
              {/* {showGenComponent &&
                <GenComponent firebase={firebase} setUser={setUser} imageUrl={imageUrl}/>
              } */}
            </Col>
          </Row>
          {/* <div className="flex items-center justify-center text-center relative mt-5">
            <span className="text-gray-500">as seen on</span>
            <img className="tiktok-seen-on" loading="lazy" alt="reok" src="images/tiktok.webp" />
            <img className="facebook-seen-on" loading="lazy" alt="reok" src="images/facebook.svg" />
            <img className="instagram-seen-on" loading="lazy" alt="reok" src="images/instagram.webp" />
            <img className="linkedin-seen-on" loading="lazy" alt="reok" src="images/linkedin.png" />
          </div> */}
        </div>
      </main>
      {/* <div className="mb-3" /> */}
      <main>
        <section
          id="5section1"
          className="relative overflow-hidden bg-white section-top-none section-top-90 section-bottom-90"
          data-layouts="simple_cards"
        >
          <div className="container space-y-8 md:space-y-[85px]">
            <div className="w-full">
              <h2 className="mx-auto relative z-[10]  t-50 text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center">
                {t('landing.respectTitle')}
              </h2>
              <div
                className="mx-auto font-body relative z-10  t-20-l text-neutral-dark-grey/80  text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                <p>
                  {t('landing.respectSub1')}
                </p>
                {/* <p>{t('landing.respectSub2')}</p> */}
              </div>
            </div>
            <div className="rnftg-h-full rnftg-w-full">
              <div className="rnftg-h-full dark:rnftg-bg-gray-900">
                <div className="rnftg-flex rnftg-flex-col pd-2">
                  <div className="rnftg-grid rnftg-gap-4 rnftg-grid-flow-row rnftg-grid-cols-3 md:rnftg-grid-cols-3 lg:rnftg-grid-cols-4 xl:rnftg-grid-cols-6">
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1170571755866300446/4.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1170571754431856710/1.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1170571754884833302/2.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1170571755295879178/3.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1170571756336066591/5.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1170571756898107402/6.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full">
              <h2 className="mx-auto relative z-[10] t-50 text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center">
                {t('landing.howItWorks')}
              </h2>
              <div
                className="mx-auto font-body relative z-10  t-20-l text-neutral-dark-grey/80  text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                <p>{t('landing.howItWorksSub')}</p>
              </div>
            </div>
            <div className="grid gap-5 sm:grid-cols-2 sm:gap-4 lg:gap-10">
              <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300 bg-light-aliceblue">
                <div className="rnftg-item__img-wrapper_landing">
                  <div>
                    <img
                      className="banner-video"
                      alt="reok"
                      src="https://cdn.discordapp.com/attachments/1105800665105711166/1170641885925220373/4.webp"
                    ></img>
                  </div>
                </div>
              </article>
              <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300 bg-light-purple">
                <div className="rnftg-item__img-wrapper_landing">
                  <div>
                    <img
                      className="banner-video"
                      alt="reok"
                      src="https://cdn.discordapp.com/attachments/1105800665105711166/1170641885044408320/womanshowcase.webp"
                    ></img>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </section>
        <section
          className="relative overflow-hidden bg-black section-top-90 section-bottom-90"
          data-layouts="text_card,featured_cards"
        >
          <div className="container space-y-8">
            <div className="w-full">
              <h2
                className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                <svg
                  width="80px"
                  height="80px"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                  className="bi bi-badge-4k"
                >
                  <path d="M4.807 5.001C4.021 6.298 3.203 7.6 2.5 8.917v.971h2.905V11h1.112V9.888h.733V8.93h-.733V5.001h-1.71zm-1.23 3.93v-.032a46.781 46.781 0 0 1 1.766-3.001h.062V8.93H3.577zm9.831-3.93h-1.306L9.835 7.687h-.057V5H8.59v6h1.187V9.075l.615-.699L12.072 11H13.5l-2.232-3.415 2.14-2.584z" />
                  <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" />
                </svg>
              </h2>
              <h2
                className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                {t('landing.unBlurryTitle')}
              </h2>
              <div
                className="mx-auto font-body text-neutral-light-grey relative z-10 t-20-l text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                <p>
                  {t('landing.unBlurrySub')}
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between">
              <BeforeAfter images={upgradeResolution} />
            </div>
            <div className="flex flex-col align-items-center">
              <div className="getstarted-btn-wrapper">
                <Link to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`} className="upgrade-button">
                  {t('landing.useThisFeature')}
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section
          id="5section1"
          className="relative overflow-hidden bg-white section-top-none section-top-90 section-bottom-90"
          data-layouts="simple_cards"
        >
          <div className="container space-y-8 md:space-y-[85px]">
            <div className="w-full">
              <h2 className="mx-auto relative z-[10]  t-50 text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center">
                👇
              </h2>
              <h2 className="mx-auto relative z-[10]  t-50 text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center">
                {t('landing.anotherFeatureTitle')}
              </h2>
              <div
                className="mx-auto font-body relative z-10  t-20-l text-neutral-dark-grey/80  text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                <p>{t('landing.anotherFeatureSub')}</p>
              </div>
            </div>
            <ul className="cards">
              <li className="cards_item">
                <div className="card">
                  <div className="card_image">
                    <video
                      className="banner-video "
                      autoPlay
                      loop
                      style={{
                        backgroundImage: 'url("https://cdn.discordapp.com/attachments/1105800665105711166/1154308928889434163/removebg.webp")',
                      }}
                      muted
                      playsInline
                      data-wf-ignore="true"
                      dataobjectfit="cover"
                    >
                      <source src="https://cdn.discordapp.com/attachments/1105800665105711166/1154308403582222336/removebg.mp4" data-wf-ignore="true" />
                    </video>
                  </div>
                  <div className="card_content">
                    <h2 className="card_title">{t('landing.feature1')}</h2>
                    <p className="card_text">{t('landing.feature1Sub')}</p>
                    <div className="card_btn">
                      <div className="relative overflow-hidden rounded-sm">
                        <Link
                          to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`}
                          className=" inline-flex shrink-0 items-center justify-center text-center relative focus:outline-none font-medium transition-all group transition-all transition-all overflow-hidden bg-neutral-black text-white hover:bg-dark-purple t-17 tracking-[0.01em] px-5 py-[15px]"
                        >
                          <div className="relative z-30">{t('button.tryThisFeature')}</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="cards_item">
                <div className="card">
                  <div className="card_image">
                    <video
                      className="banner-video "
                      autoPlay
                      loop
                      style={{
                        backgroundImage: 'url("https://cdn.discordapp.com/attachments/1105800665105711166/1135474511580508221/intro.webp")',
                      }}
                      muted
                      playsInline
                      data-wf-ignore="true"
                      dataobjectfit="cover"
                    >
                      <source
                        src="https://cdn.discordapp.com/attachments/1105800665105711166/1128614735609344080/intro.mp4"
                        data-wf-ignore="true"
                      />
                    </video>
                  </div>
                  <div className="card_content">
                  <h2 className="card_title">{t('landing.feature2')}</h2>
                    <p className="card_text">{t('landing.feature2Sub')}</p>
                    <div className="card_btn">
                      <div className="relative overflow-hidden rounded-sm">
                        <a
                          href={currentLanguage !== 'vi' ? 'https://genhomeai.com' : 'https://noithatai.com/?ref=5d12c1ad-ed10-4f48-aab4-d4f3af4ecbd0'}
                          target="_blank"
                          className=" inline-flex shrink-0 items-center justify-center text-center relative focus:outline-none font-medium transition-all group transition-all transition-all overflow-hidden bg-neutral-black text-white hover:bg-dark-purple t-17 tracking-[0.01em] px-5 py-[15px]"
                        >
                          <div className="relative z-30">{t('button.tryThisFeature')}</div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* <li className="cards_item">
                <div className="card">
                  <div className="card_image">
                    <video
                      className="banner-video "
                      autoPlay
                      loop
                      style={{
                        backgroundImage: 'url("https://a.storyblok.com/f/191576/x/754ea3a216/background-remover.mp4")',
                      }}
                      muted
                      playsInline
                      data-wf-ignore="true"
                      dataobjectfit="cover"
                    >
                      <source src="https://a.storyblok.com/f/191576/x/754ea3a216/background-remover.mp4" data-wf-ignore="true" />
                    </video>
                  </div>
                  <div className="card_content">
                    <h2 className="card_title">{t('landing.feature3')}</h2>
                    <p className="card_text">{t('landing.feature3Sub')}</p>
                    <div className="card_btn">
                      <div className="relative overflow-hidden rounded-sm">
                        <Link
                          to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`}
                          className=" inline-flex shrink-0 items-center justify-center text-center relative focus:outline-none font-medium transition-all group transition-all transition-all overflow-hidden bg-neutral-black text-white hover:bg-dark-purple t-17 tracking-[0.01em] px-5 py-[15px]"
                        >
                          <div className="relative z-30">{t('button.tryThisFeature')}</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li> */}
            </ul>
          </div>
        </section>
        <section
          id="5section1"
          className="relative overflow-hidden bg-black section-top-none section-top-90"
          data-layouts="simple_cards"
        >
          <div className="container space-y-8 md:space-y-[85px]">
            <div className="w-full">
              <h2 className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center">
                {t('landing.hotFeatureTitle')}
              </h2>
              <div
                className="mx-auto font-body relative z-10 text-white t-20-l text-neutral-dark-grey/80  text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                <p>{t('landing.hotFeatureSub')}</p>
              </div>
            </div>
            <div className="rnftg-h-full rnftg-w-full">
              <div className="rnftg-h-full dark:rnftg-bg-gray-900">
                <div className="rnftg-flex rnftg-flex-col pd-2">
                  <div className="rnftg-grid rnftg-gap-4 rnftg-grid-flow-row rnftg-grid-cols-2 md:rnftg-grid-cols-3 lg:rnftg-grid-cols-4 xl:rnftg-grid-cols-5">
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1151896408299929670/showcase6.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1151896407972786317/showcase5.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1151892585640624168/showcase3.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1169134775961927721/noithatai-778627539.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1152074195652587570/showcase10.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1151896407549149265/showcase4.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1169135688747323432/noithatai-891058351.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1158340448377655316/showcase25.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1169136929380507690/showcase_reok.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1152074195895865414/showcase11.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1152075176628985956/showcase16.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1158340446406316044/showcase20.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1158340449786925167/showcase28.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1161188046985769000/reok-549558578.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1161182410470326282/reok-197802826.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1161185115582513212/reok-938384.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1161182410864611418/reok-82383284.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1169137620576641054/showcase_reok.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1169137621042221126/showcase_reok.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1169137621532938260/showcase_reok.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-neutral-black section-top-180">
          <h2 className="mx-auto relative z-[10] t-50 text-white mb-5 md:mb-6 font-bold text-center mx-auto md:text-center">
            {t('landing.pricingTitle')}
          </h2>
          <div
            className="mx-auto font-body relative z-10 md:mb-5 t-20-l text-white text-neutral-dark-grey/80  text-center mx-auto md:text-center"
            style={{ maxWidth: '722px'}}
          >
            <p>{t('pricing.credit.title')}</p>
          </div>
          {currentLanguage === 'vi' ? (
          <div id="pricing-landing">
          <div id="pricing-switch">
          </div>
          <div id="pricing-cards">
            <div className="price-card">
              <div className="price-card--header">
                <h4>{t('pricing.free.title')}</h4>
              </div>
              <div className="price-card--price">
                <div className="price-card--price-text">
                  <div className="price-card--price-number toggle-price-content odometer">{t('pricing.free.price')}</div>
                </div>
                <div className="price-card--price-conditions">
                  <div className="price-card--onboarding-fee toggle-price-content">{t('pricing.free.trialTitle')}</div>
                </div>
              </div>
              <div className="price-card--cta">
              {currentLanguage === 'vi' ? (
                  <a className="price-card--cta--button upgrade-button" href="https://vt.tiktok.com/ZSNJ2CSGY/">
                    {t('pricing.free.joinTitle')}
                  </a>
              ) : (
                <a className="price-card--cta--button upgrade-button" href="https://vt.tiktok.com/ZSNdPX5kC/">
                  {t('pricing.free.joinTitle')}
                </a>
              )}
              </div>
              <div className="price-card--features">
                <ul className="price-card--features--list">
                  <li className="price-card--features--item">
                    <span className="green-check">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                          fill="#339557"
                        />
                      </svg>
                    </span>
                    {t('pricing.free.one')}
                  </li>
                  <li className="price-card--features--item">
                    <span className="green-check">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                          fill="#339557"
                        />
                      </svg>
                    </span>
                    {t('pricing.free.two')}
                  </li>
                  <li>
                    <hr />
                  </li>
                  <li className="price-card--features--item">
                    <div className="ref-wrapper">
                      <svg
                        width="20"
                        height="20"
                        stroke="#ef4444"
                        fill="#ef4444"
                        strokeWidth="0"
                        viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M160 894c0 17.7 14.3 32 32 32h286V550H160v344zm386 32h286c17.7 0 32-14.3 32-32V550H546v376zm334-616H732.4c13.6-21.4 21.6-46.8 21.6-74 0-76.1-61.9-138-138-138-41.4 0-78.7 18.4-104 47.4-25.3-29-62.6-47.4-104-47.4-76.1 0-138 61.9-138 138 0 27.2 7.9 52.6 21.6 74H144c-17.7 0-32 14.3-32 32v140h366V310h68v172h366V342c0-17.7-14.3-32-32-32zm-402-4h-70c-38.6 0-70-31.4-70-70s31.4-70 70-70 70 31.4 70 70v70zm138 0h-70v-70c0-38.6 31.4-70 70-70s70 31.4 70 70-31.4 70-70 70z" />
                      </svg>
                      <div className="price-card--reward">{t('pricing.free.affiliateTitle')}</div>
                    </div>
                  </li>
                  {currentLanguage === 'vi' ? (
                        <>
                          <li className="price-card--features--item">
                            <span style={{ fontSize: '14px', marginLeft: '6px' }}>{t('pricing.free.shareTitle')}</span>
                          </li>
                          <li className="price-card--features--item">
                            <span style={{ fontSize: '14px', marginLeft: '6px' }}>{t('pricing.free.loginToShare')}</span>
                          </li>
                        </>
                    ) : (
                      <li className="price-card--features--item">
                        <a target='_blank' href='https://decorify.lemonsqueezy.com/affiliates'>
                            <div className="ref-des" style={{width: "200px"}}>{t('app.menu.affiliateSub')} Join here</div>
                          </a>
                      </li>
                    )}
                </ul>
              </div>
            </div>
            <div className="price-card price-card--hero">
              <div className="price-card--hero-text">{t('pricing.popular')}</div>
              <div className="price-card--header">
                <h4>{t('pricing.basic.title')}</h4>
              </div>
              <div className="price-card--price">
                <div className="price-card--price-text">
                  <div className="price-card--price-number toggle-price-content odometer">
                    {t('pricing.basic.price')}
                    <span className="price-card--price-number-after">{t('pricing.basic.duration')}</span>
                  </div>
                </div>
                <div className="price-card--price-conditions">
                  <div className="price-card--onboarding-fee toggle-price-content">{t('pricing.basic.recommend')}</div>
                </div>
              </div>
              <div className="price-card--cta">
                <Link 
                  to={currentLanguage === 'en' ? '/signin' : `/${currentLanguage}/signin`}
                  className="price-card--cta--button upgrade-button"
                >
                  {t('button.getStarted')}
                </Link>
              </div>
              <div className="price-card--features">
                <ul className="price-card--features--list">
                  <li className="price-card--features--item">
                    <span className="green-check">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                          fill="#339557"
                        />
                      </svg>
                    </span>
                    {t('pricing.basic.one')}
                  </li>
                  <li className="price-card--features--item">
                    <span className="green-check">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                          fill="#339557"
                        />
                      </svg>
                    </span>
                    {t('pricing.basic.two')}
                  </li>
                  <li className="price-card--features--item">
                    <span className="green-check">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                          fill="#339557"
                        />
                      </svg>
                    </span>
                    {t('pricing.basic.three')}
                  </li>
                  <li className="price-card--features--item">
                    <span className="green-check">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                          fill="#339557"
                        />
                      </svg>
                    </span>
                    {t('pricing.basic.four')}
                  </li>
                  <li className="price-card--features--item">
                    <span className="green-check">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                          fill="#339557"
                        />
                      </svg>
                    </span>
                    {t('pricing.basic.five')}
                  </li>
                  <li className="price-card--features--item">
                    <span className="green-check">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                          fill="#339557"
                        />
                      </svg>
                    </span>
                    {t('pricing.basic.six')}
                  </li>
                  <li className="price-card--features--item">
                    <span className="green-check">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                          fill="#339557"
                        />
                      </svg>
                    </span>
                    {t('pricing.basic.seven')}
                  </li>
                </ul>
              </div>
            </div>
            <div className="price-card">
              <div className="price-card--header">
                <h4>
                  {t('pricing.pro.title')}
                  <span className="price-card--price-number-after" style={{ marginLeft: '4px', color: '#72ff9a' }}>
                    {t('pricing.pro.subTitle')}
                  </span>
                </h4>
              </div>
              <div className="price-card--price">
                <div className="price-card--price-text">
                  <div className="price-card--price-number toggle-price-content odometer">
                    {/* <span className="price-card--price-title-after">{t('pricing.currency')}</span> */}
                    {t('pricing.pro.price')}
                    {/* {basic.toLocaleString('en-US', { minimumFractionDigits: 3 })} */}
                    <span className="price-card--price-number-after">{t('pricing.pro.duration')}</span>
                  </div>
                </div>
                <div className="price-card--price-conditions">
                  <div className="price-card--onboarding-fee toggle-price-content">{t('pricing.pro.recommend')}</div>
                </div>
              </div>
              <div className="price-card--cta">
                <Link
                  to={currentLanguage === 'en' ? '/signin' : `/${currentLanguage}/signin`}
                  className="price-card--cta--button upgrade-button"
                >
                  {t('button.getStarted')}
                </Link>
              </div>
              <div className="price-card--features">
                <ul className="price-card--features--list">
                  <li className="price-card--features--item">
                    <span className="green-check">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                          fill="#339557"
                        />
                      </svg>
                    </span>
                    {t('pricing.pro.one')}
                  </li>
                  <li className="price-card--features--item">
                    <span className="green-check">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                          fill="#339557"
                        />
                      </svg>
                    </span>
                    {t('pricing.pro.two')}
                  </li>
                  <li className="price-card--features--item">
                    <span className="green-check">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                          fill="#339557"
                        />
                      </svg>
                    </span>
                    {t('pricing.pro.three')}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
          ) : (
          <div id="pricing-landing">
            <div id="pricing-switch">
            <h2 className="mx-auto relative z-[10] t-50 text-white mb-5 md:mb-6 font-bold text-center mx-auto md:text-center">
            {currentLanguage=== 'vi' &&
              <div className="idea-alert-pricing">
                <a href="https://noithatai.com/?ref=reok">
                <div className="idea-inner-interior">
                  <div className="ref-title-interior-pricing">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" zoomAndPan="magnify" viewBox="0 0 375 374.999991" height="20"  version="1.0">
                    <defs>
                      <clipPath id="e821bab1d3">
                        <path d="M 375 187.5 C 375 291.054688 291.054688 375 187.5 375 C 83.945312 375 0 291.054688 0 187.5 C 0 83.945312 83.945312 0 187.5 0 C 291.054688 0 375 83.945312 375 187.5 Z M 375 187.5 " clipRule="nonzero"/>
                      </clipPath>
                      <clipPath id="f749ba33a6">
                        <path d="M 375 187.5 C 375 291.054688 291.054688 375 187.5 375 C 83.945312 375 0 291.054688 0 187.5 C 0 83.949219 83.945312 0 187.5 0 C 291.054688 0 375 83.949219 375 187.5 " clipRule="nonzero"/>
                      </clipPath>
                      <clipPath id="14d8dcea73">
                        <path d="M 190.335938 -187.476562 L -187.476562 184.664062 L 184.660156 562.476562 L 562.476562 190.339844 Z M 190.335938 -187.476562 " clipRule="nonzero"/>
                      </clipPath>
                      <linearGradient x1="-0.0019554" gradientTransform="matrix(371.787281, 377.462731, -377.462731, 371.787281, 2.157462, -0.6694)" y1="0" x2="0.998984" gradientUnits="userSpaceOnUse" y2="0" id="17edf9dbc5">
                        <stop stopOpacity="1" stopColor="rgb(0.00305176%, 85.894775%, 87.098694%)" offset="0"/>
                        <stop stopOpacity="1" stopColor="rgb(0.201416%, 85.696411%, 87.098694%)" offset="0.00390625"/>
                        <stop stopOpacity="1" stopColor="rgb(0.39978%, 85.499573%, 87.098694%)" offset="0.0078125"/>
                        <stop stopOpacity="1" stopColor="rgb(0.782776%, 85.115051%, 87.098694%)" offset="0.0117187"/>
                        <stop stopOpacity="1" stopColor="rgb(1.165771%, 84.732056%, 87.098694%)" offset="0.015625"/>
                        <stop stopOpacity="1" stopColor="rgb(1.60675%, 84.291077%, 87.298584%)" offset="0.0195312"/>
                        <stop stopOpacity="1" stopColor="rgb(2.047729%, 83.851624%, 87.5%)" offset="0.0234375"/>
                        <stop stopOpacity="1" stopColor="rgb(2.388%, 83.660889%, 87.5%)" offset="0.0273438"/>
                        <stop stopOpacity="1" stopColor="rgb(2.728271%, 83.470154%, 87.5%)" offset="0.03125"/>
                        <stop stopOpacity="1" stopColor="rgb(3.118896%, 83.081055%, 87.5%)" offset="0.0351563"/>
                        <stop stopOpacity="1" stopColor="rgb(3.509521%, 82.691956%, 87.5%)" offset="0.0390625"/>
                        <stop stopOpacity="1" stopColor="rgb(3.900146%, 82.348633%, 87.5%)" offset="0.0429688"/>
                        <stop stopOpacity="1" stopColor="rgb(4.290771%, 82.006836%, 87.5%)" offset="0.046875"/>
                        <stop stopOpacity="1" stopColor="rgb(4.681396%, 81.616211%, 87.649536%)" offset="0.0507813"/>
                        <stop stopOpacity="1" stopColor="rgb(5.072021%, 81.227112%, 87.799072%)" offset="0.0546875"/>
                        <stop stopOpacity="1" stopColor="rgb(5.462646%, 81.036377%, 87.799072%)" offset="0.0585938"/>
                        <stop stopOpacity="1" stopColor="rgb(5.853271%, 80.845642%, 87.799072%)" offset="0.0625"/>
                        <stop stopOpacity="1" stopColor="rgb(6.29425%, 80.404663%, 87.799072%)" offset="0.0664062"/>
                        <stop stopOpacity="1" stopColor="rgb(6.735229%, 79.963684%, 87.799072%)" offset="0.0703125"/>
                        <stop stopOpacity="1" stopColor="rgb(7.122803%, 79.573059%, 87.998962%)" offset="0.0742188"/>
                        <stop stopOpacity="1" stopColor="rgb(7.511902%, 79.182434%, 88.198853%)" offset="0.078125"/>
                        <stop stopOpacity="1" stopColor="rgb(7.853699%, 78.990173%, 88.198853%)" offset="0.0820312"/>
                        <stop stopOpacity="1" stopColor="rgb(8.197021%, 78.799438%, 88.198853%)" offset="0.0859375"/>
                        <stop stopOpacity="1" stopColor="rgb(8.587646%, 78.408813%, 88.198853%)" offset="0.0898437"/>
                        <stop stopOpacity="1" stopColor="rgb(8.978271%, 78.019714%, 88.198853%)" offset="0.09375"/>
                        <stop stopOpacity="1" stopColor="rgb(9.368896%, 77.67334%, 88.198853%)" offset="0.0976562"/>
                        <stop stopOpacity="1" stopColor="rgb(9.759521%, 77.328491%, 88.198853%)" offset="0.101562"/>
                        <stop stopOpacity="1" stopColor="rgb(10.198975%, 76.913452%, 88.398743%)" offset="0.105469"/>
                        <stop stopOpacity="1" stopColor="rgb(10.639954%, 76.499939%, 88.598633%)" offset="0.109375"/>
                        <stop stopOpacity="1" stopColor="rgb(11.030579%, 76.287842%, 88.598633%)" offset="0.113281"/>
                        <stop stopOpacity="1" stopColor="rgb(11.421204%, 76.077271%, 88.598633%)" offset="0.117188"/>
                        <stop stopOpacity="1" stopColor="rgb(11.811829%, 75.686646%, 88.598633%)" offset="0.121094"/>
                        <stop stopOpacity="1" stopColor="rgb(12.202454%, 75.296021%, 88.598633%)" offset="0.125"/>
                        <stop stopOpacity="1" stopColor="rgb(12.542725%, 74.905396%, 88.598633%)" offset="0.128906"/>
                        <stop stopOpacity="1" stopColor="rgb(12.884521%, 74.514771%, 88.598633%)" offset="0.132812"/>
                        <stop stopOpacity="1" stopColor="rgb(13.275146%, 74.307251%, 88.781738%)" offset="0.136719"/>
                        <stop stopOpacity="1" stopColor="rgb(13.665771%, 74.099731%, 88.964844%)" offset="0.140625"/>
                        <stop stopOpacity="1" stopColor="rgb(14.105225%, 73.675537%, 88.981628%)" offset="0.144531"/>
                        <stop stopOpacity="1" stopColor="rgb(14.546204%, 73.251343%, 88.999939%)" offset="0.148437"/>
                        <stop stopOpacity="1" stopColor="rgb(14.936829%, 72.86377%, 88.999939%)" offset="0.152344"/>
                        <stop stopOpacity="1" stopColor="rgb(15.327454%, 72.477722%, 88.999939%)" offset="0.15625"/>
                        <stop stopOpacity="1" stopColor="rgb(15.718079%, 72.137451%, 88.999939%)" offset="0.160156"/>
                        <stop stopOpacity="1" stopColor="rgb(16.108704%, 71.798706%, 88.999939%)" offset="0.164062"/>
                        <stop stopOpacity="1" stopColor="rgb(16.499329%, 71.603394%, 89.195251%)" offset="0.167969"/>
                        <stop stopOpacity="1" stopColor="rgb(16.889954%, 71.408081%, 89.390564%)" offset="0.171875"/>
                        <stop stopOpacity="1" stopColor="rgb(17.233276%, 71.017456%, 89.395142%)" offset="0.175781"/>
                        <stop stopOpacity="1" stopColor="rgb(17.578125%, 70.626831%, 89.399719%)" offset="0.179687"/>
                        <stop stopOpacity="1" stopColor="rgb(17.965698%, 70.236206%, 89.399719%)" offset="0.183594"/>
                        <stop stopOpacity="1" stopColor="rgb(18.353271%, 69.845581%, 89.399719%)" offset="0.1875"/>
                        <stop stopOpacity="1" stopColor="rgb(18.792725%, 69.604492%, 89.416504%)" offset="0.191406"/>
                        <stop stopOpacity="1" stopColor="rgb(19.233704%, 69.364929%, 89.433289%)" offset="0.195312"/>
                        <stop stopOpacity="1" stopColor="rgb(19.624329%, 68.974304%, 89.616394%)" offset="0.199219"/>
                        <stop stopOpacity="1" stopColor="rgb(20.014954%, 68.583679%, 89.7995%)" offset="0.203125"/>
                        <stop stopOpacity="1" stopColor="rgb(20.405579%, 68.193054%, 89.7995%)" offset="0.207031"/>
                        <stop stopOpacity="1" stopColor="rgb(20.796204%, 67.802429%, 89.7995%)" offset="0.210937"/>
                        <stop stopOpacity="1" stopColor="rgb(21.186829%, 67.460632%, 89.7995%)" offset="0.214844"/>
                        <stop stopOpacity="1" stopColor="rgb(21.577454%, 67.120361%, 89.7995%)" offset="0.21875"/>
                        <stop stopOpacity="1" stopColor="rgb(21.968079%, 66.929626%, 89.7995%)" offset="0.222656"/>
                        <stop stopOpacity="1" stopColor="rgb(22.358704%, 66.740417%, 89.7995%)" offset="0.226562"/>
                        <stop stopOpacity="1" stopColor="rgb(22.749329%, 66.299438%, 89.99939%)" offset="0.230469"/>
                        <stop stopOpacity="1" stopColor="rgb(23.139954%, 65.858459%, 90.19928%)" offset="0.234375"/>
                        <stop stopOpacity="1" stopColor="rgb(23.530579%, 65.467834%, 90.19928%)" offset="0.238281"/>
                        <stop stopOpacity="1" stopColor="rgb(23.921204%, 65.077209%, 90.19928%)" offset="0.242187"/>
                        <stop stopOpacity="1" stopColor="rgb(24.311829%, 64.886475%, 90.19928%)" offset="0.246094"/>
                        <stop stopOpacity="1" stopColor="rgb(24.702454%, 64.69574%, 90.19928%)" offset="0.25"/>
                        <stop stopOpacity="1" stopColor="rgb(25.093079%, 64.305115%, 90.19928%)" offset="0.253906"/>
                        <stop stopOpacity="1" stopColor="rgb(25.483704%, 63.91449%, 90.19928%)" offset="0.257812"/>
                        <stop stopOpacity="1" stopColor="rgb(25.874329%, 63.523865%, 90.39917%)" offset="0.261719"/>
                        <stop stopOpacity="1" stopColor="rgb(26.264954%, 63.13324%, 90.59906%)" offset="0.265625"/>
                        <stop stopOpacity="1" stopColor="rgb(26.504517%, 62.765503%, 90.59906%)" offset="0.269531"/>
                        <stop stopOpacity="1" stopColor="rgb(26.745605%, 62.399292%, 90.59906%)" offset="0.273437"/>
                        <stop stopOpacity="1" stopColor="rgb(27.133179%, 62.185669%, 90.59906%)" offset="0.277344"/>
                        <stop stopOpacity="1" stopColor="rgb(27.520752%, 61.972046%, 90.59906%)" offset="0.28125"/>
                        <stop stopOpacity="1" stopColor="rgb(27.864075%, 61.581421%, 90.603638%)" offset="0.285156"/>
                        <stop stopOpacity="1" stopColor="rgb(28.208923%, 61.190796%, 90.608215%)" offset="0.289062"/>
                        <stop stopOpacity="1" stopColor="rgb(28.598022%, 60.800171%, 90.803528%)" offset="0.292969"/>
                        <stop stopOpacity="1" stopColor="rgb(28.988647%, 60.409546%, 90.99884%)" offset="0.296875"/>
                        <stop stopOpacity="1" stopColor="rgb(29.379272%, 60.203552%, 90.99884%)" offset="0.300781"/>
                        <stop stopOpacity="1" stopColor="rgb(29.769897%, 59.999084%, 90.99884%)" offset="0.304687"/>
                        <stop stopOpacity="1" stopColor="rgb(30.160522%, 59.622192%, 90.99884%)" offset="0.308594"/>
                        <stop stopOpacity="1" stopColor="rgb(30.551147%, 59.246826%, 90.99884%)" offset="0.3125"/>
                        <stop stopOpacity="1" stopColor="rgb(30.992126%, 58.805847%, 91.19873%)" offset="0.316406"/>
                        <stop stopOpacity="1" stopColor="rgb(31.433105%, 58.364868%, 91.398621%)" offset="0.320312"/>
                        <stop stopOpacity="1" stopColor="rgb(31.820679%, 57.981873%, 91.398621%)" offset="0.324219"/>
                        <stop stopOpacity="1" stopColor="rgb(32.209778%, 57.598877%, 91.398621%)" offset="0.328125"/>
                        <stop stopOpacity="1" stopColor="rgb(32.551575%, 57.450867%, 91.398621%)" offset="0.332031"/>
                        <stop stopOpacity="1" stopColor="rgb(32.894897%, 57.302856%, 91.398621%)" offset="0.335937"/>
                        <stop stopOpacity="1" stopColor="rgb(33.285522%, 56.912231%, 91.398621%)" offset="0.339844"/>
                        <stop stopOpacity="1" stopColor="rgb(33.676147%, 56.521606%, 91.398621%)" offset="0.34375"/>
                        <stop stopOpacity="1" stopColor="rgb(34.066772%, 56.130981%, 91.577148%)" offset="0.347656"/>
                        <stop stopOpacity="1" stopColor="rgb(34.457397%, 55.740356%, 91.757202%)" offset="0.351562"/>
                        <stop stopOpacity="1" stopColor="rgb(34.898376%, 55.499268%, 91.778564%)" offset="0.355469"/>
                        <stop stopOpacity="1" stopColor="rgb(35.339355%, 55.259705%, 91.799927%)" offset="0.359375"/>
                        <stop stopOpacity="1" stopColor="rgb(35.72998%, 54.86908%, 91.799927%)" offset="0.363281"/>
                        <stop stopOpacity="1" stopColor="rgb(36.120605%, 54.478455%, 91.799927%)" offset="0.367187"/>
                        <stop stopOpacity="1" stopColor="rgb(36.51123%, 54.08783%, 91.799927%)" offset="0.371094"/>
                        <stop stopOpacity="1" stopColor="rgb(36.901855%, 53.697205%, 91.799927%)" offset="0.375"/>
                        <stop stopOpacity="1" stopColor="rgb(37.243652%, 53.497314%, 91.990662%)" offset="0.378906"/>
                        <stop stopOpacity="1" stopColor="rgb(37.586975%, 53.29895%, 92.182922%)" offset="0.382812"/>
                        <stop stopOpacity="1" stopColor="rgb(37.974548%, 52.915955%, 92.190552%)" offset="0.386719"/>
                        <stop stopOpacity="1" stopColor="rgb(38.363647%, 52.534485%, 92.199707%)" offset="0.390625"/>
                        <stop stopOpacity="1" stopColor="rgb(38.804626%, 52.14386%, 92.199707%)" offset="0.394531"/>
                        <stop stopOpacity="1" stopColor="rgb(39.245605%, 51.753235%, 92.199707%)" offset="0.398438"/>
                        <stop stopOpacity="1" stopColor="rgb(39.63623%, 51.376343%, 92.199707%)" offset="0.402344"/>
                        <stop stopOpacity="1" stopColor="rgb(40.026855%, 50.999451%, 92.199707%)" offset="0.40625"/>
                        <stop stopOpacity="1" stopColor="rgb(40.41748%, 50.794983%, 92.349243%)" offset="0.410156"/>
                        <stop stopOpacity="1" stopColor="rgb(40.808105%, 50.590515%, 92.498779%)" offset="0.414062"/>
                        <stop stopOpacity="1" stopColor="rgb(41.19873%, 50.19989%, 92.498779%)" offset="0.417969"/>
                        <stop stopOpacity="1" stopColor="rgb(41.589355%, 49.809265%, 92.498779%)" offset="0.421875"/>
                        <stop stopOpacity="1" stopColor="rgb(41.97998%, 49.41864%, 92.498779%)" offset="0.425781"/>
                        <stop stopOpacity="1" stopColor="rgb(42.370605%, 49.028015%, 92.498779%)" offset="0.429687"/>
                        <stop stopOpacity="1" stopColor="rgb(42.710876%, 48.812866%, 92.498779%)" offset="0.433594"/>
                        <stop stopOpacity="1" stopColor="rgb(43.051147%, 48.599243%, 92.498779%)" offset="0.4375"/>
                        <stop stopOpacity="1" stopColor="rgb(43.490601%, 48.18573%, 92.698669%)" offset="0.441406"/>
                        <stop stopOpacity="1" stopColor="rgb(43.93158%, 47.773743%, 92.89856%)" offset="0.445312"/>
                        <stop stopOpacity="1" stopColor="rgb(44.322205%, 47.428894%, 92.89856%)" offset="0.449219"/>
                        <stop stopOpacity="1" stopColor="rgb(44.71283%, 47.085571%, 92.89856%)" offset="0.453125"/>
                        <stop stopOpacity="1" stopColor="rgb(45.103455%, 46.694946%, 92.89856%)" offset="0.457031"/>
                        <stop stopOpacity="1" stopColor="rgb(45.49408%, 46.304321%, 92.89856%)" offset="0.460938"/>
                        <stop stopOpacity="1" stopColor="rgb(45.884705%, 46.113586%, 92.89856%)" offset="0.464844"/>
                        <stop stopOpacity="1" stopColor="rgb(46.27533%, 45.922852%, 92.89856%)" offset="0.46875"/>
                        <stop stopOpacity="1" stopColor="rgb(46.665955%, 45.532227%, 93.09845%)" offset="0.472656"/>
                        <stop stopOpacity="1" stopColor="rgb(47.05658%, 45.141602%, 93.299866%)" offset="0.476562"/>
                        <stop stopOpacity="1" stopColor="rgb(47.447205%, 44.700623%, 93.299866%)" offset="0.480469"/>
                        <stop stopOpacity="1" stopColor="rgb(47.83783%, 44.259644%, 93.299866%)" offset="0.484375"/>
                        <stop stopOpacity="1" stopColor="rgb(48.228455%, 44.068909%, 93.299866%)" offset="0.488281"/>
                        <stop stopOpacity="1" stopColor="rgb(48.61908%, 43.8797%, 93.299866%)" offset="0.492187"/>
                        <stop stopOpacity="1" stopColor="rgb(49.009705%, 43.489075%, 93.299866%)" offset="0.496094"/>
                        <stop stopOpacity="1" stopColor="rgb(49.40033%, 43.09845%, 93.301392%)" offset="0.5"/>
                        <stop stopOpacity="1" stopColor="rgb(49.790955%, 42.755127%, 93.499756%)" offset="0.503906"/>
                        <stop stopOpacity="1" stopColor="rgb(50.18158%, 42.411804%, 93.699646%)" offset="0.507812"/>
                        <stop stopOpacity="1" stopColor="rgb(50.572205%, 42.022705%, 93.699646%)" offset="0.511719"/>
                        <stop stopOpacity="1" stopColor="rgb(50.96283%, 41.635132%, 93.699646%)" offset="0.515625"/>
                        <stop stopOpacity="1" stopColor="rgb(51.403809%, 41.394043%, 93.699646%)" offset="0.519531"/>
                        <stop stopOpacity="1" stopColor="rgb(51.844788%, 41.15448%, 93.699646%)" offset="0.523438"/>
                        <stop stopOpacity="1" stopColor="rgb(52.171326%, 40.763855%, 93.711853%)" offset="0.527344"/>
                        <stop stopOpacity="1" stopColor="rgb(52.49939%, 40.37323%, 93.725586%)" offset="0.53125"/>
                        <stop stopOpacity="1" stopColor="rgb(52.702332%, 39.982605%, 93.911743%)" offset="0.535156"/>
                        <stop stopOpacity="1" stopColor="rgb(52.906799%, 39.59198%, 94.099426%)" offset="0.539062"/>
                        <stop stopOpacity="1" stopColor="rgb(53.297424%, 39.395142%, 94.099426%)" offset="0.542969"/>
                        <stop stopOpacity="1" stopColor="rgb(53.688049%, 39.199829%, 94.099426%)" offset="0.546875"/>
                        <stop stopOpacity="1" stopColor="rgb(54.078674%, 38.813782%, 94.099426%)" offset="0.550781"/>
                        <stop stopOpacity="1" stopColor="rgb(54.469299%, 38.42926%, 94.099426%)" offset="0.554688"/>
                        <stop stopOpacity="1" stopColor="rgb(54.859924%, 38.038635%, 94.274902%)" offset="0.558594"/>
                        <stop stopOpacity="1" stopColor="rgb(55.250549%, 37.64801%, 94.450378%)" offset="0.5625"/>
                        <stop stopOpacity="1" stopColor="rgb(55.690002%, 37.272644%, 94.474792%)" offset="0.566406"/>
                        <stop stopOpacity="1" stopColor="rgb(56.130981%, 36.898804%, 94.499207%)" offset="0.570312"/>
                        <stop stopOpacity="1" stopColor="rgb(56.521606%, 36.691284%, 94.499207%)" offset="0.574219"/>
                        <stop stopOpacity="1" stopColor="rgb(56.912231%, 36.485291%, 94.499207%)" offset="0.578125"/>
                        <stop stopOpacity="1" stopColor="rgb(57.254028%, 36.094666%, 94.499207%)" offset="0.582031"/>
                        <stop stopOpacity="1" stopColor="rgb(57.595825%, 35.704041%, 94.499207%)" offset="0.585937"/>
                        <stop stopOpacity="1" stopColor="rgb(57.984924%, 35.313416%, 94.68689%)" offset="0.589844"/>
                        <stop stopOpacity="1" stopColor="rgb(58.375549%, 34.922791%, 94.874573%)" offset="0.59375"/>
                        <stop stopOpacity="1" stopColor="rgb(58.766174%, 34.710693%, 94.88678%)" offset="0.597656"/>
                        <stop stopOpacity="1" stopColor="rgb(59.156799%, 34.498596%, 94.898987%)" offset="0.601562"/>
                        <stop stopOpacity="1" stopColor="rgb(59.596252%, 34.078979%, 94.898987%)" offset="0.605469"/>
                        <stop stopOpacity="1" stopColor="rgb(60.037231%, 33.660889%, 94.898987%)" offset="0.609375"/>
                        <stop stopOpacity="1" stopColor="rgb(60.427856%, 33.270264%, 94.898987%)" offset="0.613281"/>
                        <stop stopOpacity="1" stopColor="rgb(60.818481%, 32.879639%, 94.898987%)" offset="0.617187"/>
                        <stop stopOpacity="1" stopColor="rgb(61.209106%, 32.539368%, 95.098877%)" offset="0.621094"/>
                        <stop stopOpacity="1" stopColor="rgb(61.599731%, 32.199097%, 95.298767%)" offset="0.625"/>
                        <stop stopOpacity="1" stopColor="rgb(61.990356%, 32.008362%, 95.298767%)" offset="0.628906"/>
                        <stop stopOpacity="1" stopColor="rgb(62.380981%, 31.817627%, 95.298767%)" offset="0.632812"/>
                        <stop stopOpacity="1" stopColor="rgb(62.721252%, 31.427002%, 95.298767%)" offset="0.636719"/>
                        <stop stopOpacity="1" stopColor="rgb(63.063049%, 31.036377%, 95.298767%)" offset="0.640625"/>
                        <stop stopOpacity="1" stopColor="rgb(63.502502%, 30.595398%, 95.298767%)" offset="0.644531"/>
                        <stop stopOpacity="1" stopColor="rgb(63.943481%, 30.154419%, 95.298767%)" offset="0.648438"/>
                        <stop stopOpacity="1" stopColor="rgb(64.334106%, 29.963684%, 95.498657%)" offset="0.652344"/>
                        <stop stopOpacity="1" stopColor="rgb(64.724731%, 29.774475%, 95.698547%)" offset="0.65625"/>
                        <stop stopOpacity="1" stopColor="rgb(65.115356%, 29.38385%, 95.698547%)" offset="0.660156"/>
                        <stop stopOpacity="1" stopColor="rgb(65.505981%, 28.993225%, 95.698547%)" offset="0.664062"/>
                        <stop stopOpacity="1" stopColor="rgb(65.896606%, 28.6026%, 95.698547%)" offset="0.667969"/>
                        <stop stopOpacity="1" stopColor="rgb(66.287231%, 28.211975%, 95.698547%)" offset="0.671875"/>
                        <stop stopOpacity="1" stopColor="rgb(66.677856%, 27.867126%, 95.698547%)" offset="0.675781"/>
                        <stop stopOpacity="1" stopColor="rgb(67.068481%, 27.522278%, 95.698547%)" offset="0.679688"/>
                        <stop stopOpacity="1" stopColor="rgb(67.414856%, 27.334595%, 95.898438%)" offset="0.683594"/>
                        <stop stopOpacity="1" stopColor="rgb(67.76123%, 27.148438%, 96.099854%)" offset="0.6875"/>
                        <stop stopOpacity="1" stopColor="rgb(68.196106%, 26.707458%, 96.099854%)" offset="0.691406"/>
                        <stop stopOpacity="1" stopColor="rgb(68.630981%, 26.268005%, 96.099854%)" offset="0.695312"/>
                        <stop stopOpacity="1" stopColor="rgb(69.021606%, 25.87738%, 96.099854%)" offset="0.699219"/>
                        <stop stopOpacity="1" stopColor="rgb(69.412231%, 25.486755%, 96.099854%)" offset="0.703125"/>
                        <stop stopOpacity="1" stopColor="rgb(69.802856%, 25.292969%, 96.099854%)" offset="0.707031"/>
                        <stop stopOpacity="1" stopColor="rgb(70.193481%, 25.099182%, 96.099854%)" offset="0.710937"/>
                        <stop stopOpacity="1" stopColor="rgb(70.584106%, 24.711609%, 96.299744%)" offset="0.714844"/>
                        <stop stopOpacity="1" stopColor="rgb(70.974731%, 24.324036%, 96.499634%)" offset="0.71875"/>
                        <stop stopOpacity="1" stopColor="rgb(71.365356%, 23.933411%, 96.499634%)" offset="0.722656"/>
                        <stop stopOpacity="1" stopColor="rgb(71.755981%, 23.542786%, 96.499634%)" offset="0.726562"/>
                        <stop stopOpacity="1" stopColor="rgb(72.146606%, 23.120117%, 96.499634%)" offset="0.730469"/>
                        <stop stopOpacity="1" stopColor="rgb(72.537231%, 22.698975%, 96.499634%)" offset="0.734375"/>
                        <stop stopOpacity="1" stopColor="rgb(72.927856%, 22.538757%, 96.508789%)" offset="0.738281"/>
                        <stop stopOpacity="1" stopColor="rgb(73.318481%, 22.380066%, 96.517944%)" offset="0.742188"/>
                        <stop stopOpacity="1" stopColor="rgb(73.709106%, 21.989441%, 96.708679%)" offset="0.746094"/>
                        <stop stopOpacity="1" stopColor="rgb(74.099731%, 21.598816%, 96.899414%)" offset="0.75"/>
                        <stop stopOpacity="1" stopColor="rgb(74.490356%, 21.208191%, 96.899414%)" offset="0.753906"/>
                        <stop stopOpacity="1" stopColor="rgb(74.880981%, 20.817566%, 96.899414%)" offset="0.757812"/>
                        <stop stopOpacity="1" stopColor="rgb(75.271606%, 20.608521%, 96.899414%)" offset="0.761719"/>
                        <stop stopOpacity="1" stopColor="rgb(75.662231%, 20.399475%, 96.899414%)" offset="0.765625"/>
                        <stop stopOpacity="1" stopColor="rgb(76.101685%, 19.976807%, 96.920776%)" offset="0.769531"/>
                        <stop stopOpacity="1" stopColor="rgb(76.542664%, 19.555664%, 96.942139%)" offset="0.773438"/>
                        <stop stopOpacity="1" stopColor="rgb(76.930237%, 19.165039%, 97.120667%)" offset="0.777344"/>
                        <stop stopOpacity="1" stopColor="rgb(77.31781%, 18.774414%, 97.299194%)" offset="0.78125"/>
                        <stop stopOpacity="1" stopColor="rgb(77.661133%, 18.386841%, 97.299194%)" offset="0.785156"/>
                        <stop stopOpacity="1" stopColor="rgb(78.004456%, 17.999268%, 97.299194%)" offset="0.789062"/>
                        <stop stopOpacity="1" stopColor="rgb(78.395081%, 17.805481%, 97.299194%)" offset="0.792969"/>
                        <stop stopOpacity="1" stopColor="rgb(78.785706%, 17.611694%, 97.299194%)" offset="0.796875"/>
                        <stop stopOpacity="1" stopColor="rgb(78.97644%, 17.271423%, 97.436523%)" offset="0.800781"/>
                        <stop stopOpacity="1" stopColor="rgb(79.167175%, 16.931152%, 97.575378%)" offset="0.804688"/>
                        <stop stopOpacity="1" stopColor="rgb(79.5578%, 16.540527%, 97.587585%)" offset="0.808594"/>
                        <stop stopOpacity="1" stopColor="rgb(79.948425%, 16.149902%, 97.599792%)" offset="0.8125"/>
                        <stop stopOpacity="1" stopColor="rgb(80.389404%, 15.908813%, 97.599792%)" offset="0.816406"/>
                        <stop stopOpacity="1" stopColor="rgb(80.830383%, 15.667725%, 97.599792%)" offset="0.820312"/>
                        <stop stopOpacity="1" stopColor="rgb(81.221008%, 15.2771%, 97.599792%)" offset="0.824219"/>
                        <stop stopOpacity="1" stopColor="rgb(81.611633%, 14.888%, 97.599792%)" offset="0.828125"/>
                        <stop stopOpacity="1" stopColor="rgb(82.002258%, 14.497375%, 97.795105%)" offset="0.832031"/>
                        <stop stopOpacity="1" stopColor="rgb(82.392883%, 14.10675%, 97.991943%)" offset="0.835937"/>
                        <stop stopOpacity="1" stopColor="rgb(82.733154%, 13.716125%, 97.994995%)" offset="0.839844"/>
                        <stop stopOpacity="1" stopColor="rgb(83.073425%, 13.3255%, 97.999573%)" offset="0.84375"/>
                        <stop stopOpacity="1" stopColor="rgb(83.46405%, 13.134766%, 97.999573%)" offset="0.847656"/>
                        <stop stopOpacity="1" stopColor="rgb(83.854675%, 12.944031%, 97.999573%)" offset="0.851562"/>
                        <stop stopOpacity="1" stopColor="rgb(84.295654%, 12.553406%, 97.999573%)" offset="0.855469"/>
                        <stop stopOpacity="1" stopColor="rgb(84.736633%, 12.162781%, 97.999573%)" offset="0.859375"/>
                        <stop stopOpacity="1" stopColor="rgb(85.127258%, 11.772156%, 98.199463%)" offset="0.863281"/>
                        <stop stopOpacity="1" stopColor="rgb(85.517883%, 11.381531%, 98.399353%)" offset="0.867188"/>
                        <stop stopOpacity="1" stopColor="rgb(85.908508%, 11.18927%, 98.399353%)" offset="0.871094"/>
                        <stop stopOpacity="1" stopColor="rgb(86.299133%, 10.998535%, 98.399353%)" offset="0.875"/>
                        <stop stopOpacity="1" stopColor="rgb(86.688232%, 10.60791%, 98.399353%)" offset="0.878906"/>
                        <stop stopOpacity="1" stopColor="rgb(87.078857%, 10.218811%, 98.399353%)" offset="0.882812"/>
                        <stop stopOpacity="1" stopColor="rgb(87.423706%, 9.828186%, 98.399353%)" offset="0.886719"/>
                        <stop stopOpacity="1" stopColor="rgb(87.770081%, 9.437561%, 98.399353%)" offset="0.890625"/>
                        <stop stopOpacity="1" stopColor="rgb(88.204956%, 9.017944%, 98.599243%)" offset="0.894531"/>
                        <stop stopOpacity="1" stopColor="rgb(88.641357%, 8.599854%, 98.799133%)" offset="0.898438"/>
                        <stop stopOpacity="1" stopColor="rgb(89.031982%, 8.387756%, 98.799133%)" offset="0.902344"/>
                        <stop stopOpacity="1" stopColor="rgb(89.422607%, 8.175659%, 98.799133%)" offset="0.90625"/>
                        <stop stopOpacity="1" stopColor="rgb(89.813232%, 7.833862%, 98.799133%)" offset="0.910156"/>
                        <stop stopOpacity="1" stopColor="rgb(90.203857%, 7.493591%, 98.799133%)" offset="0.914063"/>
                        <stop stopOpacity="1" stopColor="rgb(90.594482%, 7.102966%, 98.799133%)" offset="0.917969"/>
                        <stop stopOpacity="1" stopColor="rgb(90.985107%, 6.712341%, 98.799133%)" offset="0.921875"/>
                        <stop stopOpacity="1" stopColor="rgb(91.375732%, 6.504822%, 98.999023%)" offset="0.925781"/>
                        <stop stopOpacity="1" stopColor="rgb(91.766357%, 6.298828%, 99.198914%)" offset="0.929688"/>
                        <stop stopOpacity="1" stopColor="rgb(92.112732%, 5.924988%, 99.198914%)" offset="0.933594"/>
                        <stop stopOpacity="1" stopColor="rgb(92.460632%, 5.551147%, 99.198914%)" offset="0.9375"/>
                        <stop stopOpacity="1" stopColor="rgb(92.893982%, 5.110168%, 99.198914%)" offset="0.941406"/>
                        <stop stopOpacity="1" stopColor="rgb(93.328857%, 4.669189%, 99.198914%)" offset="0.945312"/>
                        <stop stopOpacity="1" stopColor="rgb(93.719482%, 4.283142%, 99.205017%)" offset="0.949219"/>
                        <stop stopOpacity="1" stopColor="rgb(94.110107%, 3.898621%, 99.211121%)" offset="0.953125"/>
                        <stop stopOpacity="1" stopColor="rgb(94.500732%, 3.701782%, 99.404907%)" offset="0.957031"/>
                        <stop stopOpacity="1" stopColor="rgb(94.891357%, 3.50647%, 99.598694%)" offset="0.960937"/>
                        <stop stopOpacity="1" stopColor="rgb(95.281982%, 3.115845%, 99.598694%)" offset="0.964844"/>
                        <stop stopOpacity="1" stopColor="rgb(95.672607%, 2.72522%, 99.598694%)" offset="0.96875"/>
                        <stop stopOpacity="1" stopColor="rgb(96.063232%, 2.384949%, 99.598694%)" offset="0.972656"/>
                        <stop stopOpacity="1" stopColor="rgb(96.453857%, 2.044678%, 99.598694%)" offset="0.976562"/>
                        <stop stopOpacity="1" stopColor="rgb(96.890259%, 1.803589%, 99.617004%)" offset="0.980469"/>
                        <stop stopOpacity="1" stopColor="rgb(97.32666%, 1.5625%, 99.635315%)" offset="0.984375"/>
                        <stop stopOpacity="1" stopColor="rgb(97.671509%, 1.171875%, 99.816895%)" offset="0.988281"/>
                        <stop stopOpacity="1" stopColor="rgb(98.016357%, 0.782776%, 100%)" offset="0.992188"/>
                        <stop stopOpacity="1" stopColor="rgb(98.406982%, 0.392151%, 100%)" offset="0.996094"/>
                        <stop stopOpacity="1" stopColor="rgb(98.797607%, 0.00152588%, 100%)" offset="1"/>
                      </linearGradient>
                      <clipPath id="0eb7e04366">
                        <path d="M 37.5 67 L 95 67 L 95 141.332031 L 37.5 141.332031 Z M 37.5 67 " clipRule="nonzero"/>
                      </clipPath>
                      <clipPath id="e652d1edac">
                        <path d="M 116 50.582031 L 156.75 50.582031 L 156.75 105 L 116 105 Z M 116 50.582031 " clipRule="nonzero"/>
                      </clipPath>
                    </defs>
                    <g clipPath="url(#e821bab1d3)">
                      <g clipPath="url(#f749ba33a6)">
                        <g clipPath="url(#14d8dcea73)">
                          <path fill="url(#17edf9dbc5)" d="M 190.339844 -187.476562 L -187.476562 184.660156 L 184.660156 562.476562 L 562.476562 190.339844 Z M 190.339844 -187.476562 " fillRule="nonzero"/>
                        </g>
                      </g>
                    </g>
                    <path fill="#ffffff" d="M 253.167969 192.695312 C 248.574219 192.695312 244.851562 196.417969 244.851562 201.011719 L 244.851562 267.230469 L 211.644531 267.230469 L 211.644531 227.3125 C 211.644531 222.71875 207.921875 218.996094 203.328125 218.996094 L 171.027344 218.996094 C 166.4375 218.996094 162.710938 222.71875 162.710938 227.3125 L 162.710938 267.230469 L 129.507812 267.230469 L 129.507812 201.011719 C 129.507812 196.417969 125.785156 192.695312 121.191406 192.695312 C 116.597656 192.695312 112.875 196.417969 112.875 201.011719 L 112.875 275.546875 C 112.875 280.140625 116.597656 283.863281 121.191406 283.863281 L 171.027344 283.863281 C 175.621094 283.863281 179.347656 280.140625 179.347656 275.546875 L 179.347656 235.628906 L 195.011719 235.628906 L 195.011719 275.546875 C 195.011719 280.140625 198.734375 283.863281 203.328125 283.863281 L 253.167969 283.863281 C 257.757812 283.863281 261.484375 280.140625 261.484375 275.546875 L 261.484375 201.011719 C 261.484375 196.417969 257.757812 192.695312 253.167969 192.695312 " fillOpacity="1" fillRule="nonzero"/>
                    <path fill="#ffffff" d="M 290.066406 190.257812 L 253.386719 153.578125 L 253.386719 123.964844 C 253.386719 119.371094 249.660156 115.648438 245.066406 115.648438 C 240.476562 115.648438 236.753906 119.371094 236.753906 123.964844 L 236.753906 136.941406 L 193.382812 93.570312 C 190.132812 90.324219 184.867188 90.324219 181.621094 93.570312 L 84.9375 190.257812 C 81.6875 193.503906 81.6875 198.769531 84.9375 202.015625 C 88.183594 205.265625 93.449219 205.265625 96.699219 202.015625 L 187.5 111.214844 L 239.164062 162.878906 C 239.179688 162.894531 239.195312 162.910156 239.210938 162.925781 L 278.300781 202.015625 C 279.925781 203.640625 282.054688 204.453125 284.183594 204.453125 C 286.308594 204.453125 288.441406 203.640625 290.066406 202.015625 C 293.3125 198.769531 293.3125 193.503906 290.066406 190.257812 " fillOpacity="1" fillRule="nonzero"/>
                    <g clipPath="url(#0eb7e04366)">
                      <path fill="#ffffff" d="M 92.394531 102.355469 C 71.191406 102.214844 67.40625 99.695312 67.242188 68.453125 C 67.238281 67.664062 66.597656 67.027344 65.8125 67.027344 C 65.023438 67.027344 64.382812 67.667969 64.378906 68.453125 C 64.21875 99.695312 60.429688 102.214844 39.226562 102.355469 C 38.273438 102.363281 37.5 103.136719 37.5 104.078125 C 37.5 105.023438 38.273438 105.796875 39.226562 105.800781 C 60.429688 105.945312 64.21875 108.464844 64.378906 139.703125 C 64.382812 140.492188 65.023438 141.132812 65.808594 141.132812 C 66.597656 141.132812 67.238281 140.492188 67.242188 139.707031 C 67.402344 108.464844 71.191406 105.945312 92.394531 105.804688 C 93.347656 105.796875 94.121094 105.023438 94.121094 104.082031 C 94.121094 103.136719 93.347656 102.363281 92.394531 102.355469 Z M 92.394531 102.355469 " fillOpacity="1" fillRule="nonzero"/>
                    </g>
                    <g clipPath="url(#e652d1edac)">
                      <path fill="#ffffff" d="M 155.757812 76.472656 C 140.324219 76.371094 137.289062 74.359375 137.171875 51.515625 C 137.167969 51.085938 136.820312 50.738281 136.394531 50.738281 C 135.964844 50.738281 135.617188 51.085938 135.617188 51.515625 C 135.5 74.359375 132.464844 76.371094 117.03125 76.472656 C 116.484375 76.476562 116.035156 76.921875 116.035156 77.464844 C 116.035156 78.003906 116.484375 78.449219 117.03125 78.453125 C 132.464844 78.554688 135.5 80.566406 135.617188 103.414062 C 135.617188 103.839844 135.96875 104.1875 136.394531 104.1875 C 136.820312 104.1875 137.167969 103.839844 137.171875 103.414062 C 137.289062 80.566406 140.324219 78.554688 155.757812 78.453125 C 156.304688 78.449219 156.75 78.003906 156.75 77.464844 C 156.75 76.921875 156.304688 76.476562 155.757812 76.472656 Z M 155.757812 76.472656 " fillOpacity="1" fillRule="nonzero"/>
                    </g>
                    <path fill="#ffffff" d="M 115.664062 117.6875 C 114.691406 120.53125 113.035156 122.660156 110.882812 123.816406 C 113.035156 124.96875 114.691406 127.097656 115.664062 129.945312 C 116.636719 127.097656 118.292969 124.96875 120.449219 123.816406 C 118.292969 122.660156 116.636719 120.53125 115.664062 117.6875 Z M 115.664062 117.6875 " fillOpacity="1" fillRule="nonzero"/>
                    <path fill="#ffffff" d="M 87.453125 72.179688 C 88.425781 69.332031 90.082031 67.203125 92.238281 66.050781 C 90.082031 64.894531 88.425781 62.769531 87.453125 59.921875 C 86.480469 62.769531 84.824219 64.898438 82.671875 66.050781 C 84.824219 67.203125 86.484375 69.332031 87.453125 72.179688 Z M 87.453125 72.179688 " fillOpacity="1" fillRule="nonzero"/>
                  </svg>
                    <span>Thiết kế nhà với AI thử ngay 🏡</span>
                  </div>
                </div>
                </a>
              </div>
              }
            </h2>
            </div>
            <div id="pricing-cards">
              <div className="price-card price-card--hero">
                <div className="price-card--header">
                  <h4>
                    {t('pricing.first.title')}
                  </h4>
                </div>
                <div className="price-card--price">
                  <div className="price-card--price-text">
                    <div className="price-card--price-number toggle-price-content odometer">
                      {t('pricing.first.price')}
                    </div>
                  </div>
                  <div className="price-card--price-conditions">
                    <div className="price-card--onboarding-fee toggle-price-content">
                      {t('pricing.first.priceDetail')}
                      </div>
                  </div>
                </div>
                <div className="price-card--cta">
                <Link 
                  to={currentLanguage === 'en' ? '/signin' : `/${currentLanguage}/signin`}
                  className="price-card--cta--button upgrade-button"
                >
                  {t('button.getStarted')}
                </Link>
                </div>
                <div className="price-card--features">
                  <ul className="price-card--features--list">
                    <li className="price-card--features--item font-bold text-white">
                      <span className="green-check">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                            fill="#339557"
                          />
                        </svg>
                      </span>
                      {t('pricing.first.one')}
                    </li>
                    <li className="price-card--features--item">
                      <span className="green-check">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                            fill="#339557"
                          />
                        </svg>
                      </span>
                      {t('pricing.first.eight')}
                    </li>
                    <li className="price-card--features--item">
                      <span className="green-check">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                            fill="#339557"
                          />
                        </svg>
                      </span>
                      {t('pricing.first.nine')}
                    </li>
                    <li className="price-card--features--item">
                      <span className="green-check">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                            fill="#339557"
                          />
                        </svg>
                      </span>
                      {t('pricing.first.two')}
                    </li>
                    <li className="price-card--features--item">
                      <span className="green-check">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                            fill="#339557"
                          />
                        </svg>
                      </span>
                      {t('pricing.first.three')}
                    </li>
                    <li className="price-card--features--item">
                      <span className="green-check">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                            fill="#339557"
                          />
                        </svg>
                      </span>
                      {t('pricing.first.ten')}
                    </li>
                    <li className="price-card--features--item">
                      <span className="green-check">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                            fill="#339557"
                          />
                        </svg>
                      </span>
                      {t('pricing.first.seven')}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="x-price-card price-card--hero">
                <div className="price-card--hero-text">{t('pricing.popular')}</div>
                <div className="price-card--header">
                  <h4>
                    {t('pricing.second.title')}
                    <span className="price-card--price-number-after" style={{ marginLeft: '4px', color: '#72ff9a' }}>
                      {t('pricing.second.subTitle')}
                    </span>
                  </h4>
                </div>
                <div className="price-card--price">
                  <div className="price-card--price-text">
                    <div className="price-card--price-number toggle-price-content odometer">
                      {t('pricing.second.price')}
                    </div>
                  </div>
                  <div className="price-card--price-conditions">
                    <div className="price-card--onboarding-fee toggle-price-content">
                      {t('pricing.second.priceDetail')}
                    </div>
                  </div>
                </div>
                <div className="price-card--cta">
                <Link 
                  to={currentLanguage === 'en' ? '/signin' : `/${currentLanguage}/signin`}
                  className="price-card--cta--button upgrade-button"
                >
                  {t('button.getStarted')}
                </Link>
                </div>
                <div className="price-card--features">
                  <ul className="price-card--features--list">
                    <li className="price-card--features--item">
                      <span className="green-check">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                            fill="#339557"
                          />
                        </svg>
                      </span>
                      {t('pricing.second.one')}
                    </li>
                    <li className="price-card--features--item font-bold text-white">
                      <span className="green-check">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                            fill="#339557"
                          />
                        </svg>
                      </span>
                      {t('pricing.second.two')}
                    </li>
                    <li className="price-card--features--item">
                      <span className="green-check">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                            fill="#339557"
                          />
                        </svg>
                      </span>
                      {t('pricing.second.three')}
                    </li>
                    <li className="price-card--features--item">
                      <span className="green-check">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                            fill="#339557"
                          />
                        </svg>
                      </span>
                      {t('pricing.second.four')}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="price-card">
                <div className="price-card--header">
                  <h4>
                    {t('pricing.third.title')}
                    <span className="price-card--price-number-after" style={{ marginLeft: '4px', color: '#72ff9a' }}>
                      {t('pricing.third.subTitle')}
                    </span>
                  </h4>
                </div>
                <div className="price-card--price">
                  <div className="price-card--price-text">
                    <div className="price-card--price-number toggle-price-content odometer">
                      {t('pricing.third.price')}
                    </div>
                  </div>
                  <div className="price-card--price-conditions">
                    <div className="price-card--onboarding-fee toggle-price-content">
                      {t('pricing.third.priceDetail')}
                      </div>
                  </div>
                </div>
                <div className="price-card--cta">
                <Link 
                  to={currentLanguage === 'en' ? '/signin' : `/${currentLanguage}/signin`}
                  className="price-card--cta--button upgrade-button"
                >
                  {t('button.getStarted')}
                </Link>
                </div>
                <div className="price-card--features">
                  <ul className="price-card--features--list">
                  <li className="price-card--features--item">
                      <span className="green-check">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                            fill="#339557"
                          />
                        </svg>
                      </span>
                      {t('pricing.third.one')}
                    </li>
                    <li className="price-card--features--item font-bold text-white">
                      <span className="green-check">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                            fill="#339557"
                          />
                        </svg>
                      </span>
                      {t('pricing.third.two')}
                    </li>
                    <li className="price-card--features--item">
                      <span className="green-check">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                            fill="#339557"
                          />
                        </svg>
                      </span>
                      {t('pricing.third.three')}
                    </li>
                    <li className="price-card--features--item">
                      <span className="green-check">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                            fill="#339557"
                          />
                        </svg>
                      </span>
                      {t('pricing.third.four')}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          )}
        </section>
        <section>
          <div className="relative bg-neutral-black section-top-180 section-bottom-90">
            <div className="container">
              <FAQ t={t}/>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default XPage;
