import React, { StrictMode } from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client"
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import Firebase, { FirebaseContext } from './services/firebase';
// import { MemoryRouter } from 'react-router-dom';
import './index.css';
import App from './containers/App';
import { TooltipProvider } from './components/SmartEditor/components/ui/tooltip';

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
      <FirebaseContext.Provider value={new Firebase()}>
        <StrictMode>
          {/* <MemoryRouter> */}
          <TooltipProvider>
            <App />
          </TooltipProvider>
          {/* </MemoryRouter> */}
        </StrictMode>
      </FirebaseContext.Provider>
    </PersistGate>
  </Provider>
);
