import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import LovedBy from '../LovedBy';

const RECAPTCHA = process.env.REACT_APP_RECAPTCHA;

function QuickSignUpEmailAndGoogle({ onShowLogin, handleSubmitWithEmailLink, error, handleSubmitWithGoogle, isSubmitted, onSubmitted }) {
  const [currentLanguage, setCurrentLanguage] = useState('');
  const popupRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (popupRef.current && event.target.contains(popupRef.current)) {
        onShowLogin(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
  }, []);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const onSubmitWithEmailLink = (e) => {
    e.preventDefault();
    if (loading) return;

    if (!email) {
      setEmailError(t('message.error.emptyEmail'));
      return;
    }

    if (!validateEmail(email)) {
      setEmailError(t('message.error.validEmail'));
      return;
    }

    setLoading(true);
    handleSubmitWithEmailLink(e, t, currentLanguage, email);
    // onSubmitted(true);
  };

  return (
    <div className="pricing-showbox inset-0 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0">
      <div className="sign_in-container">
        <div ref={popupRef} className="dark-login-placeholder shadow-lg">
          <div className="login-form-container pt-2 pb-4">
            <div>
              {/* <h1 className="auth-panel-title" style={{ color: "#fff" }}>{t('signup.title')}</h1> */}
              <a href="#" className="px-3 py-3 link-block-6 w-inline-block signup-button" onClick={(e) => handleSubmitWithGoogle(e, t)}>
                <div>
                  <img src="fonts/6373e8bb67056287b1380aa8_image%206.svg" loading="lazy" alt="" className="signup-button" />
                </div>
                <p className="body-1-semi-bold ml-12 signup-button" style={{ color: "#fff" }}>{t('button.googleSignup')}</p>
              </a>
            </div>
            <div className='mt-2' />
            <div className="sign-with-box mt-2">
              <div className="sign-with-divider" />
              <p className='pl-2 pr-2'>{t('signin.another')}</p>
              <div className="sign-with-divider" />
            </div>
            {isSubmitted ? (
              <div className="flex flex-col items-center justify-center mb-2">
                <hr className="w-full border-t border-gray-700 mb-2" />
                <div className="flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                  <span className="text-white font-semibold">&nbsp;{t('giveAway.emailSent')}</span>
                </div>
                <h2 className="text-center text-2xl font-bold">
                  <div className="mx-auto relative z-[10] text-center mx-auto md:text-center label-giveaway">
                    <span>{t('giveAway.emailSentTitle')}</span>&nbsp;🎉
                  </div>
                </h2>
                <p className="text-gray-400 text-center">
                  {t('giveAway.emailSentSub')}
                </p>
              </div>
            ) : (
              <>
                <div className='mt-2'>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t('giveAway.enterYourEmail')}
                    className="w-full px-4 py-4 rounded-md border border-gray-700 mb-2 focus:outline-none text-white placeholder-gray-500" style={{ backgroundColor: '#151515' }}
                  />
                  {emailError && <p className="text-red text-sm mb-2">{emailError}</p>}
                </div>
                <button
                  onClick={(e) => onSubmitWithEmailLink(e)}
                  className="w-full bg-yellow-500 text-black py-4 text-sm rounded-md hover:bg-yellow-400 hover:scale-105 transition duration-300 font-semibold"
                >
                  {loading ? t('giveAway.submitting') : t('button.emailSignup')}
                </button>
                <div className="forget-passwrod-form w-form">
                  <form id="email-form" name="email-form" data-name="Email Form">
                    <div className="_2nd-form" style={{ color: "#fff" }}>
                      <p className="agree-service" style={{ color: "#c2c2c2" }}>
                        {t('tos.agree')}
                        <Link
                          className="agree-service" style={{ color: "#c2c2c2" }}
                          to={currentLanguage === 'en' ? '/tos' : `/${currentLanguage}/tos`}
                        >
                          {t('footer.terms')}
                        </Link>
                        {' '}&{' '}
                        <Link
                          className="agree-service" style={{ color: "#c2c2c2" }}
                          to={currentLanguage === 'en' ? '/privacy' : `/${currentLanguage}/privacy`}
                        >
                          {t('footer.privacy')}
                        </Link>
                      </p>
                      <LovedBy t={t} />
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

QuickSignUpEmailAndGoogle.propTypes = {
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.object,
};

export default QuickSignUpEmailAndGoogle;
