import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from '../General/Avatar';
import SignOutContainer from '../../containers/SignOutContainer';
import { HomeOutlined, CustomerServiceOutlined, LoginOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import LanguageSwitcher from '../LanguageSwitcher';
import MoneyBackGuarantee from '../MoneyBackGuarantee';
import Header from '../CompareImage/Header';
import HeaderCoupon from '../HeaderCoupon';

const AdminHeader = ({ userData, firebase }) => {
  const [currentLanguage, setCurrentLanguage] = useState('');
  const dropdownRef = useRef(null);
  const dropdownDesktopRef = useRef(null);
  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (dropdownDesktopRef.current && !dropdownDesktopRef.current.contains(event.target)) {
        dropdownDesktopRef.current.classList.remove('show');
        // Your code to handle closing the popup goes here
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        dropdownRef.current.classList.remove('show');
        // Your code to handle closing the popup goes here
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  function onOpenToggler(e) {
    e.stopPropagation();
    dropdownRef.current.classList.toggle('show');
  }

  function onOpenTogglerDesktop(e) {
    e.stopPropagation();
    dropdownDesktopRef.current.classList.toggle('show');
  }

  function showSupport() {
    window.HubSpotConversations.widget.open();
  }

  function hideDropDown() {
    dropdownRef.current.classList.remove('show');
    dropdownDesktopRef.current.classList.remove('show');
  }

  const scrollToPricing = () => {
    const section = document.querySelector( '#pricing' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

  const scrollToHowItWorks = () => {
    const section = document.querySelector( '#how-it-works' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

  return (
    <>
      <HeaderCoupon/>
      <header
        className="dynamic-top"
        data-controller="class-toggler"
        data-class-toggler-name-value="mobile-search"
        data-stick-it-target="header"
      >
        <nav data-stick-it-target="nav" className="navbar navbar-main navbar-height navbar-expand-lg theme-transparent">
          <div className="container-fluid gap-3">
            <div className="d-flex flex-grow-1 align-items-center">
              <Link id="brand" className="navbar-brand" to={currentLanguage === 'en' ? '/' : `/${currentLanguage}/`} aria-label="Reok logo">
                {/* <div className="halloween">
                  <img src="/images/santa.png" alt="ghost" className="halloween-animation"/>
                </div> */}
                {/* <img src="/images/santa-hat.png" alt="santa hat" className="christmas"></img> */}
                <img width={160} src='/images/airoomdecor-full.webp'/>
              </Link>
            </div>
            <div id="nav" className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                {/* Mobile */}
                <div className="offcanvas" tabIndex={-1} id="offcanvasNavigation" ref={dropdownRef}>
                  <div className="d-flex d-lg-none ms-auto p-3">
                    <button
                      className="btn btn-ghost-light btn-sm rounded-pill btn-icon text-white text-nowrap"
                      style={{}}
                      type="default"
                      data-bs-dismiss="offcanvas"
                      onClick={onOpenToggler}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width={10} height={10} viewBox="0 0 10 10" fill="none" className="svg-icon">
                        <g clipPath="url(#acef96ee167e529de66b)">
                          <path
                            d="M8.53553 1.46447C8.61367 1.54261 8.65757 1.64859 8.65757 1.75909C8.65757 1.8696 8.61367 1.97558 8.53553 2.05372L5.58926 5L8.53553 7.94628C8.61367 8.02442 8.65757 8.1304 8.65757 8.24091C8.65757 8.35141 8.61367 8.45739 8.53553 8.53553C8.45739 8.61367 8.35141 8.65757 8.24091 8.65757C8.1304 8.65757 8.02442 8.61367 7.94628 8.53553L5 5.58926L2.05372 8.53553C1.97558 8.61367 1.8696 8.65757 1.75909 8.65757C1.64859 8.65757 1.54261 8.61367 1.46447 8.53553C1.38633 8.45739 1.34243 8.35141 1.34243 8.24091C1.34243 8.1304 1.38633 8.02442 1.46447 7.94628L4.41074 5L1.46447 2.05372C1.38633 1.97558 1.34243 1.8696 1.34243 1.75909C1.34243 1.64859 1.38633 1.54261 1.46447 1.46447C1.54261 1.38633 1.64859 1.34243 1.75909 1.34243C1.8696 1.34243 1.97558 1.38633 2.05372 1.46447L5 4.41074L7.94628 1.46447C8.02442 1.38633 8.1304 1.34243 8.24091 1.34243C8.35141 1.34243 8.45739 1.38633 8.53553 1.46447Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="acef96ee167e529de66b">
                            <rect width={10} height={10} fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                  <div className="offcanvas-body pt-0 px-3 pb-3 p-lg-0">
                    {userData ? (
                      <div className="nav flex-column flex-lg-row">
                        <div className="nav-item">
                          <div className="lang-mobile">
                            <LanguageSwitcher/>
                          </div>
                        </div>
                        <div className="dropdown-divider d-lg-none order-3" />
                        <div className="nav-item d-lg-none order-5">
                          <div className="nav-link d-flex d-lg-none fw-semi-bold"
                            style={{cursor: "pointer"}}
                            onClick={scrollToHowItWorks}
                          >
                            {t('footer.howItWorks')}
                          </div>
                          <div className="nav-link d-flex d-lg-none fw-semi-bold"              
                            style={{cursor: "pointer"}}
                            onClick={scrollToPricing}
                          >
                            {t('footer.pricing')}
                          </div>
                            <a className="nav-link d-flex d-lg-none fw-semi-bold"
                              href='https://decorify.pro/'
                            >
                              {t('footer.blog')}
                            </a>
                        </div>
                          
                        <div className="nav-item d-lg-none order-5">
                          {/* <a className="nav-link d-flex d-lg-none fw-semi-bold"
                            href={currentLanguage === 'en' ? '/how-to-get-ai-yearbook-for-free' : `/${currentLanguage}/how-to-get-ai-yearbook-for-free`}
                          >
                            {t('footer.yearbook')}
                          </a> */}
                          <a className="nav-link d-flex d-lg-none fw-semi-bold"
                            href="https://decorify.lemonsqueezy.com/affiliates"
                          >
                            {t('footer.affiliate')}
                          </a>
                        </div>
                        <div className="dropdown-divider order-5" />
                        <div className="nav-item d-lg-none order-5">
                          <div className="nav-link d-flex d-lg-none fw-semi-bold">
                            <SignOutContainer t={t} />
                          </div>
                        </div>
                        <div className="dropdown-divider order-5" />
                        <div className="nav-item d-lg-none order-5">
                          <div className="nav-link d-flex d-lg-none fw-semi-bold">{t('header.nav.supportTitle')}</div>
                        </div>
                        <div className="text-indent-2 order-5" onClick={hideDropDown}>
                          <div className="nav-item d-lg-none order-5">
                            <div className="nav-link d-flex d-lg-none" onClick={showSupport} style={{ cursor: 'pointer' }}>
                              <CustomerServiceOutlined />
                              <span style={{ marginLeft: '5px' }}>{t('header.nav.supportContact')}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="nav flex-column flex-lg-row">
                        <div className="dropdown-divider d-lg-none order-3" />
                        <div className="nav-item d-lg-none order-5">
                          <Link className="nav-link d-flex d-lg-none fw-semi-bold" to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`}>
                            <LoginOutlined />
                            <span style={{ marginLeft: '5px' }}>{t('header.nav.signUp')}</span>
                          </Link>
                        </div>
                        <div className="dropdown-divider order-5" />
                        <div className="nav-item d-lg-none order-5">
                        <div className="nav-link d-flex d-lg-none fw-semi-bold"
                          style={{cursor: "pointer"}}
                          onClick={scrollToHowItWorks}
                        >
                          {t('footer.howItWorks')}
                        </div>
                        <div className="nav-link d-flex d-lg-none fw-semi-bold"
                          style={{cursor: "pointer"}}
                          onClick={scrollToPricing}
                        >
                          {t('footer.pricing')}
                        </div>
                        <a className="nav-link d-flex d-lg-none fw-semi-bold"
                          href='https://decorify.pro/'
                        >
                          {t('footer.blog')}
                        </a>
                      </div>
                      <div className="nav-item d-lg-none order-5">
                        {/* <a className="nav-link d-flex d-lg-none fw-semi-bold"
                          href={currentLanguage === 'en' ? '/how-to-get-ai-yearbook-for-free' : `/${currentLanguage}/how-to-get-ai-yearbook-for-free`}
                        >
                          {t('footer.yearbook')}
                        </a> */}
                        <a className="nav-link d-flex d-lg-none fw-semi-bold"
                          href="https://decorify.lemonsqueezy.com/affiliates"
                        >
                          {t('footer.affiliate')}
                        </a>
                        </div>
                        <div className="dropdown-divider order-5" />
                        <div className="nav-item d-lg-none order-5">
                          <div className="nav-link d-flex d-lg-none fw-semi-bold">{t('header.nav.supportTitle')}</div>
                        </div>
                        <div className="text-indent-2 order-5">
                          <div className="nav-item d-lg-none order-5">
                            <div className="nav-link d-flex d-lg-none" onClick={showSupport} style={{ cursor: 'pointer' }}>
                              <CustomerServiceOutlined />
                              <span style={{ marginLeft: '5px' }}>{t('header.nav.supportContact')}</span>
                            </div>
                          </div>
                        </div>
                        <div className="nav-item d-lg-none order-5">
                          <div className="lang-mobile">
                            <LanguageSwitcher/>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* Desktop */}
                <div className="dropdown d-none d-lg-flex" style={{fontWeight: "500"}}>
                  <div className="bg-gray-200 flex items-center justify-center mx-2" onClick={scrollToHowItWorks}>
                    <div className="w-full md:w-1/2 lg:w-1/3" style={{cursor:"pointer"}}>
                    {t('footer.howItWorks')}
                    </div>
                  </div>
                  <div className="bg-gray-200 flex items-center justify-center mx-6" onClick={scrollToPricing}>
                    <div className="w-full md:w-1/2 lg:w-1/3" style={{cursor:"pointer"}}>
                    {t('footer.pricing')}
                    </div>
                  </div>
                  <div className="bg-gray-200 flex items-center justify-center mx-2">
                    <div className="w-full md:w-1/2 lg:w-1/3">
                      <a href='https://decorify.pro/'>
                        {t('footer.blog')}
                      </a>
                    </div>
                  </div>
                  <div className="bg-gray-200 flex items-center justify-center mx-6">
                    <div className="w-full md:w-1/2 lg:w-1/3">
                    <a href="https://decorify.lemonsqueezy.com/affiliates">
                      {t('footer.affiliate')}
                    </a>
                    </div>
                  </div>
                </div>
                {/* <LanguageSwitcher/> */}
                <div className="lang-wrapper">
                  <LanguageSwitcher/>
                </div>
                {/* Mobile */}
                <div className="navbar-toggler navbar-dropdown-account-wrapper ms-2" onClick={onOpenToggler}>
                  {userData?.emailVerified ? (
                    <>
                      <Avatar
                        color='#222'
                        size={40}
                        initials={`${userData?.firstName?.charAt(0).toUpperCase() ?? ''} ${userData?.lastName?.charAt(0).toUpperCase() ?? ''
                          }`}
                      />
                      {/* <span className="avatar-name">{`${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`}</span> */}
                    </>
                  ) : (
                    <span className="avatar flex-shrink-0 avatar-base avatar-circle">
                      <div className="avatar-initials bg-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                          className="svg-icon rounded-0"
                        >
                          <g clipPath="url(#4f25a6d6acd8dd3fbe3e)">
                            <path
                              d="M8 0C3.58849 0 0 3.58849 0 8C0 12.4115 3.58849 16 8 16C12.4115 16 16 12.4115 16 8C16 3.58849 12.4115 0 8 0ZM8 2.85712C9.57119 2.85712 10.8571 4.14313 10.8571 5.71431C10.8571 7.2855 9.57119 8.57144 8 8.57144C6.42881 8.57144 5.14286 7.2855 5.14286 5.71431C5.14286 4.14313 6.42881 2.85712 8 2.85712V2.85712ZM5.71429 9.71431H10.2857C11.8459 9.71431 13.1199 10.9781 13.1406 12.5335C11.8852 13.9566 10.052 14.8571 8 14.8571C5.94803 14.8571 4.11482 13.9566 2.85937 12.5335C2.88006 10.9781 4.15412 9.71431 5.71429 9.71431V9.71431Z"
                              fill="#495057"
                            />
                          </g>
                          <defs>
                            <clipPath id="4f25a6d6acd8dd3fbe3e">
                              <rect width={16} height={16} fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </span>
                  )}
                </div>
                {/* Desktop */}
                <div className="dropdown d-none d-lg-flex" style={{fontWeight: "500"}}>
                  <div className="navbar-dropdown-account-wrapper ms-2" id="accountNavbarDropdown" onClick={onOpenTogglerDesktop}>
                    {userData?.emailVerified ? (
                      <>
                        <Avatar
                          color='#222'
                          size={40}
                          initials={`${userData?.firstName?.charAt(0).toUpperCase() ?? ''} ${userData?.lastName?.charAt(0).toUpperCase() ?? ''
                            }`}
                        />
                        <span className="avatar-name">{`${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`}</span>
                      </>
                    ) : (
                      <span className="avatar flex-shrink-0 avatar-base avatar-circle">
                        <div className="avatar-initials bg-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            viewBox="0 0 16 16"
                            fill="none"
                            className="svg-icon rounded-0"
                          >
                            <g clipPath="url(#4f25a6d6acd8dd3fbe3e)">
                              <path
                                d="M8 0C3.58849 0 0 3.58849 0 8C0 12.4115 3.58849 16 8 16C12.4115 16 16 12.4115 16 8C16 3.58849 12.4115 0 8 0ZM8 2.85712C9.57119 2.85712 10.8571 4.14313 10.8571 5.71431C10.8571 7.2855 9.57119 8.57144 8 8.57144C6.42881 8.57144 5.14286 7.2855 5.14286 5.71431C5.14286 4.14313 6.42881 2.85712 8 2.85712V2.85712ZM5.71429 9.71431H10.2857C11.8459 9.71431 13.1199 10.9781 13.1406 12.5335C11.8852 13.9566 10.052 14.8571 8 14.8571C5.94803 14.8571 4.11482 13.9566 2.85937 12.5335C2.88006 10.9781 4.15412 9.71431 5.71429 9.71431V9.71431Z"
                                fill="#495057"
                              />
                            </g>
                            <defs>
                              <clipPath id="4f25a6d6acd8dd3fbe3e">
                                <rect width={16} height={16} fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </span>
                    )}
                  </div>
                  {userData ? (
                    <div
                      className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                      aria-labelledby="accountNavbarDropdown"
                      ref={dropdownDesktopRef}
                    >
                      <div className="dropdown-item fw-semi-bold">
                        <SignOutContainer t={t} />
                      </div>
                      <div className="dropdown-divider order-5" />
                      <a className="dropdown-item fw-semi-bold"
                        href='https://decorify.pro/'
                      >
                        {t('footer.blog')}
                      </a>
                      {/* <a className="dropdown-item fw-semi-bold"
                        href={currentLanguage === 'en' ? '/how-to-get-ai-yearbook-for-free' : `/${currentLanguage}/how-to-get-ai-yearbook-for-free`}
                      >
                        {t('footer.yearbook')}
                      </a> */}
                      <div className="dropdown-divider order-5" />
                      <div className="dropdown-item fw-semi-bold">{t('header.nav.supportTitle')}</div>
                      <div className="text-indent-2 order-5" onClick={hideDropDown}>
                        <div className="dropdown-item" onClick={showSupport} style={{ cursor: 'pointer' }}>
                          <CustomerServiceOutlined />
                          <span style={{ marginLeft: '5px' }}>{t('header.nav.supportContact')}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                      aria-labelledby="accountNavbarDropdown"
                      ref={dropdownDesktopRef}
                    >
                      <a className="dropdown-item fw-semi-bold" href={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`}>
                        <LoginOutlined />
                        <span style={{ marginLeft: '5px' }}>{t('header.nav.signUp')}</span>
                      </a>
                      {/* <div className="dropdown-divider order-5" />
                      <a className="dropdown-item fw-semi-bold"
                        href={currentLanguage === 'en' ? '/how-to-get-ai-yearbook-for-free' : `/${currentLanguage}/how-to-get-ai-yearbook-for-free`}
                      >
                        {t('footer.yearbook')}
                      </a> */}
                      <div className="dropdown-divider order-5" />
                      <div className="dropdown-item fw-semi-bold">{t('header.nav.supportTitle')}</div>
                      <div className="text-indent-2 order-5" onClick={hideDropDown}>
                        <div className="dropdown-item" onClick={showSupport} style={{ cursor: 'pointer' }}>
                          <CustomerServiceOutlined />
                          <span style={{ marginLeft: '5px' }}>{t('header.nav.supportContact')}</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default AdminHeader;
