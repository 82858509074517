import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import LovedBy from '../LovedBy';

const RECAPTCHA = process.env.REACT_APP_RECAPTCHA;

function QuickSignUpLandingPage({ onShowLogin, handleSubmitWithGoogle, error }) {
  const [currentLanguage, setCurrentLanguage] = useState('');
  const popupRef = useRef(null);

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (popupRef.current && event.target.contains(popupRef.current)) {
        onShowLogin(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
  }, [])

  return (
    <div className="pricing-showbox inset-0 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0">
        <div className="sign_in-container">
          <div ref={popupRef} className="dark-login-placeholder shadow-lg">
            <div className="login-form-container pt-5 pb-5">
              <div>
                <h1 className="auth-panel-title" style={{color: "#fff"}}>{t('signup.title')}</h1>
                <a href="#" className="link-block-6 w-inline-block signup-button" onClick={(e) => handleSubmitWithGoogle(e, t)}>
                  <div>
                    <img src="fonts/6373e8bb67056287b1380aa8_image%206.svg" loading="lazy" alt="" className="signup-button"/>
                  </div>
                  <p className="body-1-semi-bold ml-12 signup-button" style={{color: "#fff"}}>{t('button.googleSignup')}</p>
                </a>
              </div>
              <div className="forget-passwrod-form w-form">
                <form id="email-form" name="email-form" data-name="Email Form">
                  <div className="_2nd-form" style={{color: "#fff"}}>
                    <p className="agree-service" style={{color: "#c2c2c2"}}>
                      {t('tos.agree')} 
                      <Link
                        className="agree-service" style={{color: "#c2c2c2"}}
                        to={currentLanguage === 'en' ? '/tos' : `/${currentLanguage}/tos`}
                      >
                        {t('footer.terms')}
                      </Link>
                      {' '}&{' '}
                    <Link
                      className="agree-service" style={{color: "#c2c2c2"}}
                      to={currentLanguage === 'en' ? '/privacy' : `/${currentLanguage}/privacy`}
                    >
                      {t('footer.privacy')}
                    </Link>
                    </p>
                    <LovedBy t={t}/>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

QuickSignUpLandingPage.propTypes = {
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.object,
};

export default QuickSignUpLandingPage;
