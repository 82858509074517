import React, { useEffect, useState } from 'react';
import { Button, message, Input } from 'antd';
import MoneyBackGuarantee from '../MoneyBackGuarantee';

const modes = {
  pro: "Pro",
  basic: "Basic"
}

const ComposeSection = ({ userData, onShowReferal, onShowPricing, defaultMode, t, currentLanguage }) => {

  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(defaultMode);

  return (
    <>
      <main>
        <div className="progress-area">
          <div className="flex space-between w-100p">
            <strong>{t('app.menu.creditTitle')}</strong>
            <div className="flex items-center mb-0">
              {/* <span style={{marginRight: "4px", marginTop: "-2px"}}>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 291.764 291.764" width="14px">
                  <g>
                    <path fill="#EFC75E" fillOpacity="1" d="M145.882,0c80.573,0,145.882,65.319,145.882,145.882s-65.31,145.882-145.882,145.882
                    S0,226.446,0,145.882S65.31,0,145.882,0z"/>
                    <path fill="#CC9933" fillOpacity="1" d="M145.882,27.353c-65.465,0-118.529,53.065-118.529,118.529s53.065,118.529,118.529,118.529
                    s118.529-53.065,118.529-118.529S211.347,27.353,145.882,27.353z M145.882,246.176c-55.39,0-100.294-44.904-100.294-100.294
                    S90.493,45.588,145.882,45.588s100.294,44.904,100.294,100.294S201.281,246.176,145.882,246.176z M158.009,138.269
                    c-5.452-2.289-25.493-5.452-25.493-14.214c0-6.464,7.495-8.334,11.99-8.334c4.094,0,8.999,1.295,11.589,3.875
                    c1.641,1.577,2.316,2.726,2.854,4.313c0.684,1.869,1.094,3.875,3.684,3.875h13.357c3.136,0,3.957-0.574,3.957-4.021
                    c0-14.789-11.589-23.122-24.809-25.994V86.28c0-2.58-0.821-4.167-3.957-4.167h-10.64c-3.136,0-3.957,1.577-3.957,4.167v11.051
                    c-14.178,2.726-26.031,11.634-26.031,27.718c0,18.235,12.683,23.979,26.441,28.566c11.589,3.884,23.724,4.021,23.724,12.063
                    s-5.99,9.765-13.357,9.765c-5.051,0-10.631-1.304-13.366-4.741c-1.769-2.152-2.453-4.021-2.58-5.89
                    c-0.274-3.592-1.769-4.021-4.914-4.021H113.28c-3.136,0-3.957,0.729-3.957,4.021c0,16.366,13.093,26.286,27.262,29.441v11.206
                    c0,2.58,0.821,4.167,3.957,4.167h10.64c3.136,0,3.957-1.586,3.957-4.167v-10.905c16.084-2.453,27.125-12.209,27.125-29.441
                    C182.28,145.591,167.829,141.424,158.009,138.269z"/>
                  </g>
                </svg>
              </span> */}
              <span style={{marginRight: "2px", marginTop: "-2px"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="#ffc337"><path d="M17.333 4v9.333h8L14.666 28v-9.333H6.667z"/></svg>
              </span>
              {mode === modes.pro ?
                <span className="text-white">{userData.proCredits?.toFixed(0)}</span>
                :
                <span className="text-white">{userData.basicCredits?.toFixed(0)}</span>
              }
            </div>
          </div>
          {/* <div className="mt-2">
            <MoneyBackGuarantee/>
          </div> */}
          <Button 
            className="upgrade-button mt-4"
            onClick={() => onShowPricing(true)}
            type="submit"
            loading={loading}
          >
            {t('button.upgrade')}
          </Button>
          {/* {currentLanguage=== 'vi' ? 
            (<div className="idea-alert" onClick={() => onShowReferal(true)}>
              <div className="idea-inner">
                <div className="ref-title">
                  <svg stroke="#ef4444" fill="#ef4444" strokeWidth="0" viewBox="0 0 1024 1024"  xmlns="http://www.w3.org/2000/svg">
                    <path d="M160 894c0 17.7 14.3 32 32 32h286V550H160v344zm386 32h286c17.7 0 32-14.3 32-32V550H546v376zm334-616H732.4c13.6-21.4 21.6-46.8 21.6-74 0-76.1-61.9-138-138-138-41.4 0-78.7 18.4-104 47.4-25.3-29-62.6-47.4-104-47.4-76.1 0-138 61.9-138 138 0 27.2 7.9 52.6 21.6 74H144c-17.7 0-32 14.3-32 32v140h366V310h68v172h366V342c0-17.7-14.3-32-32-32zm-402-4h-70c-38.6 0-70-31.4-70-70s31.4-70 70-70 70 31.4 70 70v70zm138 0h-70v-70c0-38.6 31.4-70 70-70s70 31.4 70 70-31.4 70-70 70z"/>
                  </svg>
                  <span>{t('app.menu.affiliateTitle')}</span>
                </div>
                <div className="ref-des">{t('app.menu.affiliateSub')}</div>
              </div>
            </div>)
            : 
            (<div className="idea-alert">
              <a href='https://decorify.lemonsqueezy.com/affiliates'>
              <div className="idea-inner">
                <div className="ref-title">
                  <svg stroke="#ef4444" fill="#ef4444" strokeWidth="0" viewBox="0 0 1024 1024"  xmlns="http://www.w3.org/2000/svg">
                    <path d="M160 894c0 17.7 14.3 32 32 32h286V550H160v344zm386 32h286c17.7 0 32-14.3 32-32V550H546v376zm334-616H732.4c13.6-21.4 21.6-46.8 21.6-74 0-76.1-61.9-138-138-138-41.4 0-78.7 18.4-104 47.4-25.3-29-62.6-47.4-104-47.4-76.1 0-138 61.9-138 138 0 27.2 7.9 52.6 21.6 74H144c-17.7 0-32 14.3-32 32v140h366V310h68v172h366V342c0-17.7-14.3-32-32-32zm-402-4h-70c-38.6 0-70-31.4-70-70s31.4-70 70-70 70 31.4 70 70v70zm138 0h-70v-70c0-38.6 31.4-70 70-70s70 31.4 70 70-31.4 70-70 70z"/>
                  </svg>
                  <span>{t('app.menu.affiliateTitle')}</span>
                </div>
                <div className="ref-des">{t('app.menu.affiliateSub')}</div>
              </div>
              </a>
            </div>)
          } */}
        </div>
      </main>
    </>
  )
}

export default ComposeSection
