import { Note } from "@mui/icons-material";

const en = {
    Basic: "Basic",
    Pro: "Pro",
    month: "month",
    year: "year",
    button: {
        getStarted: 'Get Started',
        try: 'Start your designs with AI',
        viewall: 'View All',
        basic: 'Basic',
        pro: 'Pro',
        generate: 'Generate Design',
        backHome: 'Back to Home',
        googleSignin: 'Start with Google',
        googleSignup: 'Start with Google',
        emailSignin: 'Sign In with Email',
        emailSignup: 'Start with Email',
        submit: 'Submit',
        choose: 'Choose',
        download: 'Download This Template',
        upgrade: 'Buy more credits',
        subscribe: 'Buy it',
        upgradePro: 'Upgrade',
        confirmPayment: 'Confirm Payment',
        tryThisFeature: 'Try This Now',
        removeBG: 'Start',
        professional: 'Start',
        upscale: 'Start',
        ok: 'Ok',
        cancel: 'Cancel',
        select: 'Select',
        selectToStart: 'Select to start',
        uploadPose: 'Upload pose',
        uploadingPose: 'Uploading your pose',
        upgradeToUpload: 'Upgrade to upload',
        unlock: "Upgrade",
        englishOnly: "English version",
        contactUs: "Contact Us",
        forEnterprise: "For Enterprise Solutions",
        tryThis: "Try",
        upscale: "Upscale",
        remove: "Remove",
        render: "Render",
        rendering: "Rendering",
    },
    header: {
        nav: {
            signIn: 'Sign In',
            signUp: 'Sign Up',
            signOut: 'Sign Out',
            billing: 'Billing',
            supportTitle: 'Support',
            supportContact: 'Contact Us',
            myFlowers: 'My Designs'
        }
    },
    landing: {
        title: 'AI render tools for architecture',
        joinNow: 'Ready to create stunning designs?',
        subTitle: "An AI-powered app for Virtual Staging, Remodeling, Landscaping, Interior Designing, and beyond. Instantly design in seconds.",
        subTitle1: "Save time and money effortlessly. Trusted by homeowners, airbnb home services, companies and professionals all over the world.",
        input: 'Tell me the type of flower you want to create?',
        inside: 'Indoor',
        outside: 'Outdoor',
        loveBy: '45,880+ happy designs already created',
        respectTitle: 'We respect your privacy',
        respectSub1: 'No FaceID or multiple photos needed. Simply use a single selfie, just like your usual Facebook or Instagram posts.',
        respectSub2: 'Your data is completely safe and secure on Google platform very high security.',
        howItWorks: 'How it works',
        howItWorksSub: 'Snap or upload -> Pick style and background -> Pick or upload your pose -> Receive your headshots!',
        unBlurryTitle: 'Photo upscaler',
        unBlurrySub: 'Remove blurs, enhance, and improve photo colors before posting on social media platforms like Facebook, Instagram, Tiktok.',
        useThisFeature: 'Create yours now',
        anotherFeatureTitle: 'Outstanding Features',
        anotherFeatureSub: 'Free, fast, and accurate. Try these features below.',
        feature1: 'Remove backgrounds',
        feature1Sub: 'Accurately remove any background.',
        feature2: 'Interior Design',
        feature2Sub: 'Interior design, exterior design, and sketching with genhomeai.com',
        feature3: 'Change Backgrounds (Coming soon)',
        feature3Sub: 'Change backgrounds for any object with REOK’s AI',
        hotFeatureTitle: 'What our customers say',
        hotFeatureSub: 'Most realistic. Trusted by Professionals. Made for everyone.',
        pricingTitle: 'Pick your plan',
        pricingLabel: 'Pricing',
        pricingSub: '1 professional photo = 10 credits or less',
        step1: 'Upload your space',
        step1Sub: 'Select your style or input yours.',
        step2: 'Our AI designer gets to work in seconds',
        step2Sub: 'Our cutting-edge technology quickly creates your decors.',
        step3: 'Receive your decors',
        step3Sub: 'Download your decors.',
        trialTitle: "FREE Trial",
        trialSubTitle: "#1 AI Headshot Generator 📸",
        feedbackLabel: 'Feedback',
        faqLabel: 'FAQ',
        sketch: 'Sketch',
        creativity: 'Creativity',
        upscale4k: 'Upscale',
        remove: 'Cleanroom',
        fillup: 'Furniture',
        exterior: 'Exterior',
        interior: 'Interior',
        steps: 'Steps',
        interiorShowCase: {
            label: "INTERIORS",
            title: "Redesign with any style",
            subTitle: "We help you change up your place in different styles, making it look amazing. Redesign any type of interior, exteriors or gardens."
        },
        exteriorShowCase: {
            label: "EXTERIORS",
            title: "Creative exterior redesign",
            subTitle: "Revamp your indoor and outdoor spaces with innovative redesign solutions, infusing creativity into every corner for a truly captivating atmosphere."
        },
        furnitureShowCase: {
            label: "FILL FURNITURE",
            title: "Fill an empty room with furnitures",
            subTitle: "You can try out different setups until you find the perfect one. Decorate empty room with furniture with Decorify. Works for under construction or empty spaces."
        },
        cleanRoomShowCase: {
            label: "CLEAN ROOM",
            title: "Remove unwanted objects",
            subTitle: "Effortlessly transform your room by easily removing any unwanted object, allowing you to create the perfect environment tailored to your preferences."
        },
        upscaleShowCase: {
            label: "UPSCALE",
            title: "Upscale any design to 4K",
            subTitle: "Elevate the ambiance of any environment with our upscale design services, ensuring stunning clarity and detail up to 4K resolution for a truly immersive experience."
        },
        creativityShowCase: {
            label: "AI CREATIVITY",
            title: "Custom AI creativity",
            subTitle: "Take charge of your design journey with AI Intervention. You decide how much the AI should help. Whether you want just a few suggestions or a complete makeover, it's all up to you."
        },
        sketchShowCase: {
            label: "SKETCH",
            title: "Sketch to realistic designs",
            subTitle: "Transform any sketch into lifelike, realistic designs, bringing your concepts to vivid fruition with precision and authenticity."
        },
    },
    yearbook: {
        title: 'AI Yearbook photoshoot 📸',
        subTitle: "Not using many Selfie images. Get your 90s Yearbook photos with a single Selfie with our AI-photographer in seconds. Save 10x your time.",
        input: 'Tell me the type of flower you want to create?',
        inside: 'Indoor',
        outside: 'Outdoor',
        loveBy: '45,880+ happy designs already created',
        respectTitle: 'We respect your privacy',
        respectSub1: 'We do not use FaceID or require a lot of photos to perform. Just use a Selfie, similar to what you often post on Facebook and Instagram.',
        respectSub2: 'Your data is completely safe and secure on Google platform very high security.',
        howItWorks: 'How AI Yearbook Works',
        howItWorksSub: 'You take a selfie or use a clear ID photo, select your style & pose do headshots in seconds not hours!',
        unBlurryTitle: 'Photo upscaler',
        unBlurrySub: 'Remove blurs, enhance, and improve photo colors before posting on social media platforms like Facebook, Instagram, Tiktok.',
        useThisFeature: 'Create yours now',
        anotherFeatureTitle: 'Outstanding Features',
        anotherFeatureSub: 'Free, fast, and accurate. Try these features below.',
        feature1: 'Remove backgrounds',
        feature1Sub: 'Accurately remove any background.',
        feature2: 'Interior Design',
        feature2Sub: 'Interior design, exterior design, and sketching with genhomeai.com',
        feature3: 'Change Backgrounds (Coming soon)',
        feature3Sub: 'Change backgrounds for any object with REOK’s AI',
        hotFeatureTitle: 'Our AI Yearbook Photos',
        hotFeatureSub: 'These are AI-generated headshots.',
        pricingTitle: 'Pick your plan',
        pricingLabel: 'Pricing',
        pricingSub: '1 professional photo = 10 credits or less',
    },
    showcase: {
        title: 'Sample Flowers',
        status: {
            pending: 'processing',
            upscaling: 'upscaling',
            choosing: 'choosing',
            finished: 'finished'
        },
        mode: {
            pro: 'Pro',
            basic: 'Basic'
        }
    },
    home: {
        title: 'Your Designs',
        hotColletion: "Custom Styles",
        credits: 'Designs left',
        uploadTitle: 'Upload your space',
        uploadPlaceHolder: 'Click here or drag an image file',
        stylePlaceHolder: 'Select',
        uploadPosePlaceHolder: 'Select',
        uploadBGPlaceHolder: 'Select',
        roomTitle: 'Choose room type (29)',
        themeTitle: 'Choose room style (34)',
        pro: {
            title: 'Creativity for All Types of Flowers',
            input: 'Describe your flower...',
        },
        basic: {
            title: 'Unlimited Creativity with Pro',
            chooseFlowerTitle: 'Flower',
            flowers: 'Living Room',
            styles: 'Style',
            decoration: 'Decoration',
            events: 'Events',
            backgrounds: 'Backgrounds'
        },
    },
    profile: {
        title: 'My Designs',
        before: 'Before -',
        after: 'After -'
    },
    terms: {
        title: 'Terms and Conditions',
        content: ''
    },
    //Part 2
    privacy: {
        title: 'Privacy Policy',
        one: 'Your privacy is very important to us. The privacy policy of decorify.pro respects your privacy regarding any information we may collect from you on our website, decorify.pro, and other websites we own and operate.',
        two: 'We only request personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.',
        three: 'We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.',
        four: 'We do not share any personally identifying information publicly or with third-parties, except when required to by law.',
        five: 'Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites and cannot accept responsibility or liability for their respective privacy policies.',
        six: 'You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.',
        seven: 'Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.',
        eight: 'This policy is effective as of September 15, 2023.'
    },
    refund: {
        moneyBack: 'Money-back guarantee',
        title: 'Refund Policy',
        one: 'Our goal is to provide high-quality service and outcomes to all our clients. We understand that there can be instances where the output does not meet your expectations',
        two: 'We guarantee your satisfaction. If you are unhappy with the photos generated using Reok.pro, we are more than happy to provide you with a full refund. Alternatively, in some cases, we may offer to redo your photos to better meet your expectations.',
        three: 'We will review and respond to refund requests, based on this policy, within 1-3 business days. If your refund is approved, the funds should be back on your card within 5-10 business days. Refunds will be processed back to the original payment method used during purchase; we cannot refund to a different account.',
        four: 'We can only process refund requests submitted within 30 days of purchase and use UNDER 20 credits.',
        five: 'To apply for a refund, kindly email us at hello@decorify.pro or use our chat feature available on our website. When contacting us about a refund, please include the email address you used when placing your order.',
        six: 'Thank you for choosing Reok.pro for your headshots.',
    },
    footer: {
        title: 'Decorify.pro is an AI-powered app for Virtual Staging, Remodeling, Landscaping, Interior Designing, and beyond.',
        terms: 'Terms',
        privacy: 'Privacy Policy',
        app: 'App',
        signin: 'Sign In',
        signup: 'Sign Up',
        email: 'Email',
        chatNow: 'Chat Now',
        partner: 'Partner',
        reportBug: 'Report a Bug',
        require: 'Submit a Request',
        page: 'Page',
        tos: 'Legal',
        contact: 'Contact',
        other: 'Other',
        affiliate: 'Affiliates',
        yearbook: 'AI Yearbook',
        blog: 'Blog',
        pricing: 'Pricing',
        howItWorks: 'How it works',
        sendFeedback: 'Contact Us'
    },
    tos: {
        agree: 'New accounts are subject to our ',
        one: '1. Introduction',
        oneDetail: 'By using decorify.pro, you agree to and are bound by these terms and conditions.',
        two: '2. Agreement to Terms',
        twoDetail: 'This Agreement is effective from the date you first use the decorify.pro app.',
        three: '3. Features',
        threeDetail: 'You will be able to use all features after subscribing to a specific paid plan. Details of available features can be found on the pricing page.',
        four: '4. Refund Policy',
        fourDetail: 'We allow refunds for failed transactions within 14 days of purchase. Contact support to request a refund.',
        five: '5. Use of Product',
        fiveDetail: 'By using decorify.pro, you agree to receive important product updates from decorify.pro via the email associated with your Google account or the email you used to register your account. You can opt out of receiving these product updates at any time by clicking the "Unsubscribe" link at the bottom of each email. We only send important product updates.',
        six: '6. Disclaimer',
        sixDetail: 'We do not guarantee that decorify.pro will meet your requirements or that its operation will be uninterrupted or error-free. All warranties or conditions not expressly set out in this Agreement (including, without limitation, loss of profit, loss or corruption of data, business interruption, or any other pecuniary loss, damage, cost, or expense of any nature whatsoever) are, to the maximum extent permitted by law, excluded and disclaimed. This Agreement does not affect any statutory rights you may have as a consumer.',
        seven: '7. Warranty and Legal Limitations',
        sevenDetail: 'decorify.pro does not make any warranties, guarantees, or other representations about the quality, fitness for a particular purpose, or other character or performance of the software. decorify.pro will not be liable to you for any lost profits or any consequential, special, incidental, or indirect damages arising out of or relating to this Agreement or your use of decorify.pro (however arising, including negligence) except where liability cannot be excluded under law.',
        eight: '8. General Terms and Law',
        eightDetail: 'You acknowledge that no joint venture, partnership, employment, or agency relationship exists between you and decorify.pro as a result of using these services. You agree not to hold yourself out as a representative, agent, or employee of decorify.pro. You agree that decorify.pro will not be liable to you for any action that we take to prevent or address misuse of our platform. You acknowledge that, in providing you with the use of decorify.pro, we have relied upon your agreement to be bound by the terms of this Agreement.',
    },
    faq: {
        title: 'Commonly asked questions',
        q1: 'How does Decorify work?',
        answer1: 'Decorify is a web-based application that allows you to transform and redesign all the parts of your home, including interiors, exteriors, gardens, patios, and beyond. Utilizing the power of Artificial Intelligence, the app generates new ceoncepts every few seconds, offering a wide range of styles, modes, room types, and more to help you achieve the desired outcome.',
        q2: 'Sure it saves my money and time?',
        answer2: 'Yes, our app offers a cost-effective solution for both personal and professional use. Without hiring expensive professionals, you can visualize how your home will look with different furniture, decorations, and backyard designs, without scrolling through endless websites for inspiration. Our AI designers analyze your preferences and generate personalized recommendations, making it easy to visualize and create stunning homes.',
        q3: 'Does it work on any device? (Laptop, MacOS, PC, iOS, Android)',
        answer3: 'Absolutely! You don`t need to install any mobile app or desktop program, you just run it from the most common used browsers(Chrome, Firefox, Safari).',
        q4: 'How easy I can use it?',
        answer4: 'Just upload your image and start to generated new AI ideas.',
        q5: 'Who can use Decorify AI?',
        answer5: 'Our app is easy to be used by anyone, regardless of their knowledge or expertise in interiors, gardens or architecture. It can be utilized by homeowners seeking to remodel or build, architects in need of fresh ideas or instant results, interior designers looking for decorating suggestions and even landscapers. Real estate agents and agencies also use our software to enhace their listing, sell homes easier and show quick AI interiors and exteriors ideas.',
        q6: 'How can AI designers enhance my home?',
        answer6: 'AI designers offers a wealth of possibilities for your interiors. You can explore wide styles, and decor options to create a harmonious and visually appealing living space.',
        q7: 'Does it work with architectural plans and sketches?',
        answer7: 'Absolutely! Our software is designed to work with architectural sketches. Whether you have hand-drawn sketches or digital renderings, you can easily upload them into our platform then render your sketches to realistic visualizations',
        q8: 'Does it work for under construction homes/houses/interiors/exteriors?',
        answer8: 'Absolutely! Our users are using heavily our software to generate ideas on how to decorate their under construction interiors and exteriors (rooms, houses, backyards).',
        q9: 'Does it work with virtual staging and home staging?',
        answer9: 'Absolutely! By uploading a photo of an empty room or an existing space, we help you experiment with virtual staging. It`s a very valuable tool for both real estate professionals, homeowners and airbnb home services.',
        q10: 'Do you have a refund policy?',
        answer10: 'Yes! We are so confident in our technology that we offer a full money-back guarantee. Please see our refund policy for details.'
    },
    signin: {
        title: 'Sign In',
        another: 'or',
        emailPlaceHolder: 'Email Address',
        passwordPlaceHolder: 'Password',
        rememberTitle: 'Remember for Next Time',
        forgetPassword: 'Forgot Password?',
        noAccount: 'Don’t have an account?'
    },
    signup: {
        title: 'Create an account or log in with an existing one.',
        another: 'or',
        firstNamePlaceHolder: 'First Name',
        lastNamePlaceHolder: 'Last Name',
        emailPlaceHolder: 'Email Address',
        passwordPlaceHolder: 'Password',
        rememberTitle: 'Remember for Next Time',
        forgetPassword: 'Forgot Password?',
        gotAccount: 'Already have an account?'
    },
    // Part 3
    forgetPassword: {
        title: 'Retrieve Password',
        content: 'We will send a password retrieval email to you',
        emailPlaceHolder: 'Please enter your Email',
        sendBtn: 'Send',
        backSignin: 'Back to Sign in'
    },
    pricing: {
        yearlySubscription: '1000 designs',
        monthlySubscription: '100 designs',
        saveDesk: 'Save 10%',
        saveMobile: 'Save 10% when purchasing 1000 designs',
        popular: "76% pick this plan",
        free: {
            title: 'Free Credits',
            price: '0$',
            trialTitle: 'Registration Code: Please Sign In',
            joinTitle: 'Paste your code here',
            one: 'Get 20 Free Credits',
            two: 'Included All 100 Credits Features',
            affiliateTitle: 'Affiliate program',
            shareTitle: 'Share with friends👇',
            loginToShare: 'Please sign in to share'
        },
        basic: {
            title: '100 Credits',
            price: '7$',
            duration: '/lifetime',
            recommend: 'For Individuals',
            one: '20 Professional Photos',
            two: 'Remove Background',
            three: 'Upscale to 4K',
            four: 'Download All Photos',
            five: 'Unlimited Cloud Storage',
            six: 'Commercial Usage Of Photos',
            seven: 'Access to All New Features',
            subscribed: 'Purchased'
        },
        pro: {
            title: '1000 Credits',
            subTitle: '(Save 30%)',
            price: '$49',
            duration: '/lifetime',
            recommend: 'Coffee Shop Owners, Businesses, Photo Studios',
            one: '200 Professional Photos',
            two: 'Included All 100 Credits Features',
            three: 'Save Your Money',
            subscribed: 'Purchased'
        },
        credit: {
            title: 'Choose a plan that fits your needs!'
        },
        first: {
            title: 'STARTER',
            price: '$19',
            priceDetail: '100 designs - no subscription',
            duration: '/lifetime',
            recommend: '1 Headshot = 5 Credits',
            five: 'Decor with basic AI designers',
            credit: '500 credits',
            two: '100 designs/purchase',
            three: 'All modes unlocked',
            one: 'AI furniture removal',
            six: 'Commercial license',
            four: 'Sketch to render',
            seven: '85+ room types available',
            eight: '40+ styles available',
            nine: 'Upscale any design to 4K',
            ten: 'Download high quality',
            eleven: 'Unlimited render storage',
            twelve: 'Early access to new features',
            thirteen: 'Cancel any time',
            fourteen: 'AI virtual staging',
        },
        second: {
            title: 'PRO',
            subTitle: '(Save 85%)',
            price: '$29',
            priceDetail: '1,000 designs per month',
            duration: '/lifetime',
            recommend: 'Coffee Shop Owners, Businesses, Photo Studios',
            five: 'Always newest AI designers',
            credit: '5,000 credits',
            two: '1,000 designs/mo',
            three: 'All modes unlocked',
            one: 'AI furniture removal',
            six: 'Commercial license',
            four: 'Sketch to render',
            seven: '85+ room types available',
            eight: '40+ styles available',
            nine: 'Upscale any design to 4K',
            ten: 'Download high quality',
            eleven: 'Unlimited render storage',
            twelve: 'Early access to new features',
            thirteen: 'Cancel any time',
            fourteen: 'AI virtual staging',
            subscribed: 'Subscribe'
        },
        third: {
            title: 'PRO YEARLY',
            subTitle: '(Save 93%)',
            price: '$199',
            priceDetail: '12,000 designs per year',
            duration: '/lifetime',
            recommend: 'Coffee Shop Owners, Businesses, Photo Studios',
            five: 'Always newest AI designers',
            credit: '60,000 credits',
            two: '12,000 designs/yr',
            three: 'All modes unlocked',
            one: 'AI furniture removal',
            six: 'Commercial license',
            four: 'Sketch to render',
            seven: '85+ room types available',
            eight: '40+ styles available',
            nine: 'Upscale any design to 4K',
            ten: 'Download high quality',
            eleven: 'Unlimited render storage',
            twelve: 'Early access to new features',
            thirteen: 'Cancel any time',
            fourteen: 'AI virtual staging',
            subscribed: 'Subscribe'
        },
        four: {
            title: 'Enterprise Solutions',
            subTitle: '(Contact Us)',
            price: '$0',
            priceDetail: '$ 0 / headshot',
            duration: '/lifetime',
            recommend: 'Coffee Shop Owners, Businesses, Photo Studios',
            one: 'High-quality headshots',
            two: 'Companies or remote teams',
            three: 'Save time and money',
            four: 'Take headshots in seconds',
            five: 'Without traditional photo shoots',
            subscribed: 'Purchased',
        },
        currency: 'đ',
        perMonth: '/month',
        safePayment: 'Secure Payment',
        qr: 'Or Use QR Code'
    },
    milestoneMessage: {
        first: 'Happier customers with',
        second: 'designs created'
    },
    message: {
        success: {
            redering: 'Your designs are rendering, please wait a moment',
            rederingDone: 'AI has finished creating the design',
            activated: 'Activation successful! Enjoy creating beautiful and satisfying designs!',
            removingBG: 'Removing your background',
            upscale: 'Upscaling resolution',
            resetPassword: 'We just sent an email to reset your password. Please check it!',
            signIn: 'We just sent an email to activate your account. Please check it!',
            reviewing: 'We have received your request. It will be reviewed in a day',
            remindReviewing: 'This photo has been reviewing to clam new credits',
            removed: 'Removed the photo successfully',
            useTheTemplate: "Using the template",
            subscribed: "Email sent successfully!"
        },
        error: {
            selectPhotographer: 'Please select your AI designer to start',
            uploadPhoto: 'Please upload your space',
            chooseRoom: 'Please select the room type',
            chooseTheme: 'Please select the room style',
            fileOverBasic: 'Please upload a file smaller than 5MB, or upgrade to Pro to upload larger files',
            fileOverPro: 'Please upload a file smaller than 10MB',
            wrongFile: 'Invalid file, please try another file',
            wrongFace: 'Please do not take your selfie too close, please try another!',
            busy: 'Connection interrupted, please try again',
            gender: 'Please select your gender',
            age: 'Please select your age',
            roomType: 'Please select type',
            style: 'Please select one style or custom yours',
            numberOfResult: 'Please select number of headshots',
            outOfCredits: 'You are out of Credits, please buy more credits!',
            userNotFound: 'This account is not found. Please input correctly your account!',
            wrongPassword: 'Wrong password! If you don`t remember your password please use Forget Password.',
            accountExist: 'Account already exists. Try to login from this account instead',
            tiktok: 'For Google User Security reasons, please use the Chrome or Safari browser. Visit decorify.pro to access.',
            reviewing: 'You can not remove this photo because it has been reviewing',
            background: 'Please select your background',
            pose: 'Please select your pose',
            signUpReCaptcha: 'Please complete the reCAPTCHA before sign up.',
            emptyBG: 'Your background is empty, please input it!',
            tooShortBG: 'Your background should be longer than 2 words.',
            tooLongBG: 'Your background too long.',
            badWordsBG: 'Your background contains inappropriate words. Please try another',
            emptyStyle: 'Your style is empty, please input it!',
            tooShortStyle: 'Your style should be longer than 2 words.',
            tooLongStyle: 'Your style too long.',
            badWordsStyle: 'Your style contains inappropriate words. Please try another',
            emptyEmail: 'Please enter your email',
            validEmail: 'Please enter a valid email address',
            subscribeError: 'Sorry, we couldn\'t subscribe you.'
        },
        warning: {
            runOutBasicCredits: 'You have used all the credits in the Basic plan, please purchase more credits',
            runOutProCredits: 'Looks like you need more credits? ^^',
            selectPose: 'Please select your Gender before Pose',
            aiVirtualStaging: 'Switch to AI Vitual Home',
            aiFurnitureRemoval: 'Switching to AI Furniture Removal. Download image before leaving, as removal history isn\'t saved.',
            upscale: 'Switch to Upscale',
        },
        prompt: {
            professional: 'Professional photo',
            removeBG: 'Remove background',
            upscale: 'Upscaled resolution',
            rendering: 'AI rendering',
            faceNotFound: 'Your face was too close, please try another selfie!',
            inReview: 'In review',
            styles: {
                business: 'Professional business photo',
                realEstate: 'Professional realtor photo',
                carShowroom: 'Professional car salesperson photo',
                doctor: 'Professional doctor photo',
                yearbook: 'Professional 90s Yearbook photo',
                wedding: 'Professional wedding photo',
                halloween: 'Halloween photo'
            },
        },
        maintain: {
            backsoon: 'We are maintaining this feature, and it will be back soon. Please use other features in the meantime. Thank you! 💗💗💗'
        }
    },   
    // Part 4 
    app : {
        menu: {
            creditTitle: 'Your credits',
            affiliateTitle: 'Affiliate program',
            affiliateSub: 'Receive a 30% commission on payments for all customers you refer to us!',
            professionalMode: 'AI Home Design',
            removeBGMode: 'Furniture Removal',
            unBluryMode: 'Upscale Design',
            myPhotos: 'My Design',
            aiVirtualStaging: "AI Virtual Staging"
        },
        tryThese: 'No image? Try these:',
        goodSelfie: 'Good selfie',
        badSelfie: 'Bad selfie',
        trick: 'Use Upscale to get better quality',
        removeBGSub: 'Removing backgrounds becomes quick, simple, and accurate with AI.',
        professionalMode: {
            title: 'Decor any space with AI',
            refundTitle: 'Our AI has been learning for great results 💓',
            creditTitle: '1 photo = 10 credits',
            genderTitle: 'Select type',
            ageTitle: 'Select your age',
            styleTitle: 'Select style or customize',
            quantityTitle: 'Number of designs',
            poseTitle: 'Select/upload pose',
            backgroundTitle: 'Select/custom background',
            skipPose: 'Skip to keep your original pose',
            defaultPose: 'Default',
            uploadedPose: 'Uploaded',
            defaultBackground: 'Backgrounds',
            defaultStyle: 'Styles',
            headshotsTab: 'Headshots',
            selectPhotographer: 'Select your ai designer',
            selectPhotographerSub: 'Please choose a designer who fits your needs',
            requirements: 'Customize',
            guideSub: 'Follow the designer requirements 👇',
            creativity: "AI Creativity",
            customize: "✍️ customize (beta)",
            enOnlyCustomize: "✍️ customize (english version only)",
            interiorsTitle: "Interiors",
            exteriorsTitle: "Exteriors",
            gardensTitle: "Gardens",
        },
        upscaleMode: {
            title: 'Quickly and accurately remove blurriness and enhance your photos with AI.',
        }
    },
    myDesign: {
        title: 'My Designs',
        scrollTitle: 'Scroll to see all your designs.',
    },
    imageComponent: {
        compare: 'View',
        download: 'Download',
        removeObjects: 'Furniture Removal',
        upscale: 'Upscale',
        virtualHome: 'Decor Staging',
        errorPhoto: 'Renew Bad Photo'
    },
    results: {
        one: '1 design',
        four: '4 designs',
    },
    genders: {
        man: 'Man',
        woman: 'Woman'
    },
    styles: {
        "modern": "Modern",
        "minimalist": "Minimalist",
        "professional": "Professional",
        "tropical": "Tropical",
        "coastal": "Coastal",
        "vintage": "Vintage",
        "neoclassic": "Neoclassic",
        "tribal": "Tribal",
        "industrial": "Industrial",
        "bohemian": "Bohemian",
        "contemporary": "Contemporary",
        "farmhouse": "Farm house",
        "scandinavian": "Scandinavian",
        "skichalet": "Ski Chalet",
        "artdeco": "Art Deco",
        "biophilic": "Biophilic",
        "christmas": "Christmas",
        "tetvn": "Lunar New Year",
        "midautumn": "Mid Autumn",
        "halloween": "Halloween",
        "easter": "Easter",
        "baroque": "Baroque",
        "cottagecore": "Cottagecore",
        "japanesestyle": "Japanese Style",
        "rustic": "Rustic",
        "artnouveau": "Art Nouveau",
        "maximalist": "Maximalist",
        "coffee": "Coffee",
        "medieval": "Medieval",
        "frenchcountry": "French Country",
        "vaporwave": "Vaporwave",
        "hotpink": "Hot Pink",
        "gaming": "Gaming",
        "cyberpunk": "Cyberpunk",
        dating: "Dating",
        other: "Other",
        custom: {
            title: 'Custom Styles',
            btn: 'Start custom',
            dialogTitle: 'Your style and clothes',
            dialogSubTitle: 'Tell us your style, clothes, age, our AI photographer will try to help you appear with it!',
            upgrade: 'Upgrade this feature',
            example: 'a classic suit without tie, 26 years old depends on what you want...',
        },
        "cyberpunk_style_bathroom": "Cyberpunk-style",
        "eclectic_style_coworking_space": "Eclectic-style",
        "maximalist_style_bedroom": "Maximalist-style",
        "contemporary_style_hotel_bedroom": "Contemporary-style",
        "zen_style_bedroom": "Zen-style",
        "cyberpunk_style_outdoor_patio": "Cyberpunk-style",
        "midcentury_modern_style_pool": "Midcentury modern-style",
        "easter_style_drop_zone": "Easter-style",
        "coastal_style_kitchen": "Coastal-style",
    },
    backgrounds: {
        coffeeShop: "Coffee Shop",
        office: "Office",
        cityStreet: "City Street",
        factory: "Factories",
        garden: "Garden",
        carShowroom: "Car Showroom",
        bookShelf: "Bookshelf",
        hotel: "Hotel",
        goldenGate: "Golden Gate",
        snow: "Snow",
        realtor: "Realtor",
        brick: "Brick",
        custom: {
            title: 'Backgrounds',
            btn: 'Start custom',
            dialogTitle: 'Your backgrounds',
            dialogSubTitle: 'Tell us your background, our AI photographer will try to help you appear with it!',
            upgrade: 'Upgrade this feature',
            example: 'a coffee shop, depends on where you want...',
        },
    },
    removeTitle: 'Removing this photo',
    removeSub: 'Are you sure you want to remove this photo? This photo will be permanently removed. This action cannot be undone.',
    renewTitle: 'Renew this photo',
    renewSub: 'We will review your request. If this photo has problem, it will be permanently removed. You will get 10 new credits.',
    reasonTitle: 'Tell us why would you renew it?',
    reasonEx: 'It was unformal...',
    photographers: {
        sam: {
            name: "Sam, 👋 Hi customer",
            title: "I'm good at Styles",
            intro: "What I can do?",
            intro1: "Any Style and Mood in seconds",
            intro2: "Real estate, Art and Blog photos",
            intro3: "Interior & Exterior designs",
            intro4: "Fill an empty room with Furniture",
            intro5: "Photorealistic renders"
        },
        david: {
            name: "David, 👋 Hi customer",
            title: "I'm good at Sketch",
            intro: "What I can do?",
            intro1: "Any Sketch to realistic photos in seconds",
            intro2: "Sketch to any design",
            intro3: "House design",
            intro4: "Architecture design",
            intro5: "Interior design"
        },
        annie: {
            name: "Annie, 👋 Hi customer",
            title: "I'm good at Remodeling",
            intro: "What I can do?",
            intro1: "Keep windows, ceilings, and doors.",
            intro2: "Fill the room with Furniture",
            intro3: "House design",
            intro4: "Architecture design",
            intro5: "Interior design"
        }
    },
    roomsList: {
        "living_room": "Living room",
        "bedroom": "Bedroom",
        "dining_room": "Dining room",
        "bath_room": "Bath room",
        "study_room": "Study room",
        "gaming_room": "Gaming room",
        "meeting_room": "Meeting room",
        "kitchen": "Kitchen",
        "toilet": "Toilet",
        "coworking_space": "Coworking space",
        "fitness_gym": "Fitness gym",
        "mudroom": "Mudroom",
        "walk_in_closet": "Walk-in closet",
        "office": "Office",
        "hotel_room": "Hotel room",
        "hotel_lobby": "Hotel lobby",
        "hotel_bathroom": "Hotel bathroom",
        "exhibition_space": "Exhibition space",
        "house_exterior": "House exterior",
        "attic": "Attic",
        "onsen": "Onsen",
        "drop_zone": "Drop zone",
        "workshop": "Workshop",
        "nail": "Nail",
        "restaurant": "Restaurant",
        "coffee_shop": "Coffee shop",
        "clothing_store": "Clothing store",
        "family_room": "Family Room",
        "kids_room": "Kids Room",
        "balcony": "Balcony",
        "wine_cellar": "Wine Cellar",
        "rooftop_terrace": "Rooftop Terrace",
        "sunroom": "Sunroom",
        "home_spa": "Home Spa",
        "craft_room": "Craft Room",
        "dressing_room": "Dressing Room",
        "guest_bedroom": "Guest Bedroom",
        "home_bar": "Home Bar",
        "library": "Library",
        "art_studio": "Art Studio",
        "yoga_studio": "Yoga Studio",
        "photo_studio": "Photo Studio",
        "multimedia_room": "Multimedia Room",
        "auditorium": "Auditorium",
        "medical_exam_room": "Medical Exam Room",
        "reception_area": "Reception Area",
        "music_room": "Music Room",
        "science_laboratory": "Science Laboratory",
        "home_theater": "Home Theater",
        "wedding_room": "Wedding Room",
        "laundry_room": "Laundry Room",
        "outdoor_kitchen": "Outdoor Kitchen",
        "utility_room": "Utility Room",
        "pet_room": "Pet Room",
        "home_gym": "Home Gym",
        "lounge": "Lounge",
        "play_room": "Play Room",
        "reading_nook": "Reading Nook",
        "sauna": "Sauna",
        "man_cave": "Man Cave",
        "foyer": "Foyer",
        "green_house": "Green House",
        "she_shed": "She Shed",
        "conservatory": "Conservatory",
        "nursery": "Nursery",
        "prayer_room": "Prayer Room",
        "side_of_house": "Side Of House",
        "front_of_house": "Front Of House",
        "back_of_house": "Back Of House",
        "backyard": "Backyard",
        "patio": "Patio",
        "terrace": "Terrace",
        "front_yard": "Front Yard",
        "garden": "Garden",
        "court_yard": "Court Yard",
        "pool_area": "Pool Area",
        "porch": "Porch",
        "playground": "Playground"
    },
    giveAway: {
        title: 'Get 10% off on all plans',
        subTitle: 'Get notified about new features and future giveaways by using our app 👇',
        enterYourEmail: 'Enter your email...',
        notifyMe: 'Get it now ↗',
        note: 'We only send important app updates. Not spam you! Unsubscribe anytime.',
        submitting: 'Submitting...',
        emailSent: 'Email sent.',
        emailSentSub: 'Please check your email and start to use Decorify!',
        emailSentTitle: 'Woohoo! You\'re in!',
    },
    feedback: {
        title: 'We appreciate your feedback!',
        subTitle: 'Please give us feedback on how we can improve. You will have a chance to win 100 credits!',
        enterYourFeedback: 'Tell us about it...',
        notifyMe: 'Send it now ↗',
        note: 'We only send important app updates. Not spam you! Unsubscribe anytime.',
        submitting: 'Submitting...',
        sent: 'We received your feedback ✅',
        sentSub: 'We will review your feedback and get back soon!',
        type: {
            bug: 'Bug report',
            feature: 'Feature request',
            feedback: 'Other feedback',
            refund: 'Refund request',
        },
        emptyFeedback: 'Please enter your thoughts!',
        emptyFeedbackType: 'Please select a feedback type!',
    }
}

export default en;
