const zh = {
    Basic: "基础版",
    Pro: "专业版",
    month: "月",
    year: "年",
    button: {
        getStarted: '开始使用',
        try: '使用AI开始您的设计',
        viewall: '查看全部',
        basic: '基础版',
        pro: '专业版',
        generate: '生成设计',
        backHome: '返回首页',
        googleSignin: '使用谷歌账号登录',
        googleSignup: '使用谷歌账号注册',
        emailSignin: '使用邮箱登录',
        emailSignup: '使用邮箱注册',
        submit: '提交',
        choose: '选择',
        download: '下载此模板',
        upgrade: '购买更多积分',
        subscribe: '购买',
        upgradePro: '升级',
        confirmPayment: '确认支付',
        tryThisFeature: '立即尝试',
        removeBG: '开始',
        professional: '开始',
        upscale: '开始',
        ok: '确定',
        cancel: '取消',
        select: '选择',
        selectToStart: '选择以开始',
        uploadPose: '上传姿势',
        uploadingPose: '正在上传您的姿势',
        upgradeToUpload: '升级以上传',
        unlock: "升级",
        englishOnly: "仅英文版",
        contactUs: "联系我们",
        forEnterprise: "企业解决方案",
        tryThis: "尝试",
        upscale: "提升画质",
        remove: "移除",
        render: "渲染",
        rendering: "正在渲染",
    },
    header: {
        nav: {
            signIn: '登录',
            signUp: '注册',
            signOut: '退出登录',
            billing: '账单',
            supportTitle: '支持',
            supportContact: '联系我们',
            myFlowers: '我的设计'
        }
    },
    landing: {
        title: '建筑AI渲染高级工具',
        joinNow: '准备好创造令人惊叹的设计了吗？',
        subTitle: "一款用于虚拟装修、改造、景观设计、室内设计等的AI驱动应用。瞬间完成设计。",
        subTitle1: "轻松节省时间和金钱。受到全球房主、Airbnb房屋服务、公司和专业人士的信赖。",
        input: '告诉我你想创建什么类型的花？',
        inside: '室内',
        outside: '室外',
        loveBy: '已创建45,880+个满意设计',
        respectTitle: '我们尊重您的隐私',
        respectSub1: '无需人脸识别或多张照片。只需使用一张自拍照，就像您在Facebook或Instagram上发布的那样。',
        respectSub2: '您的数据在Google平台上完全安全可靠，具有很高的安全性。',
        howItWorks: '工作原理',
        howItWorksSub: '拍摄或上传 -> 选择风格和背景 -> 选择或上传您的姿势 -> 接收您的头像！',
        unBlurryTitle: '照片提升器',
        unBlurrySub: '在发布到Facebook、Instagram、Tiktok等社交媒体平台之前，去除模糊、增强和改善照片颜色。',
        useThisFeature: '立即创建您的设计',
        anotherFeatureTitle: '出色功能',
        anotherFeatureSub: '免费、快速、准确。尝试以下功能。',
        feature1: '去除背景',
        feature1Sub: '准确去除任何背景。',
        feature2: '室内设计',
        feature2Sub: '使用genhomeai.com进行室内设计、室外设计和草图绘制',
        feature3: '更改背景（即将推出）',
        feature3Sub: '使用REOK的AI为任何对象更改背景',
        hotFeatureTitle: '客户评价',
        hotFeatureSub: '最真实。受专业人士信赖。适合每个人。',
        pricingTitle: '选择您的计划',
        pricingLabel: '定价',
        pricingSub: '1张专业照片 = 10积分或更少',
        step1: '上传您的空间',
        step1Sub: '选择您的风格或输入您的风格。',
        step2: '我们的AI设计师在几秒钟内开始工作',
        step2Sub: '我们的尖端技术快速创建您的装饰。',
        step3: '接收您的装饰',
        step3Sub: '下载您的装饰。',
        trialTitle: "免费试用",
        trialSubTitle: "#1 AI头像生成器 📸",
        feedbackLabel: '反馈',
        faqLabel: '常见问题',
        sketch: '草图',
        creativity: '创意',
        upscale4k: '提升',
        remove: '清理室',
        fillup: '家具',
        exterior: '外部',
        interior: '内部',
        steps: '步骤',
        interiorShowCase: {
            label: "室内",
            title: "以任何风格重新设计",
            subTitle: "我们帮助您以不同的风格改变您的空间，使其看起来令人惊叹。重新设计任何类型的室内、室外或花园。"
        },
        exteriorShowCase: {
            label: "室外",
            title: "创意室外重新设计",
            subTitle: "通过创新的重新设计解决方案改造您的室内和室外空间，为每个角落注入创意，营造真正引人入胜的氛围。"
        },
        furnitureShowCase: {
            label: "家具",
            title: "用家具填满空房间",
            subTitle: "您可以尝试不同的布置，直到找到完美的方案。使用Decorify为空房间装饰家具。适用于在建或空置的空间。"
        },
        cleanRoomShowCase: {
            label: "清理室",
            title: "移除不需要的物品",
            subTitle: "轻松移除任何不需要的物品，轻松改变您的房间，让您创造出完全符合您喜好的完美环境。"
        },
        upscaleShowCase: {
            label: "提升",
            title: "将任何设计提升至4K",
            subTitle: "通过我们的高级设计服务提升任何环境的氛围，确保高达4K分辨率的惊人清晰度和细节，带来真正身临其境的体验。"
        },
        creativityShowCase: {
            label: "创意",
            title: "自定义AI创意",
            subTitle: "通过AI干预掌控您的设计之旅。您决定AI应该提供多少帮助。无论您只想要一些建议还是完整的改造，一切由您决定。"
        },
        sketchShowCase: {
            label: "草图",
            title: "将草图转化为逼真的设计",
            subTitle: "将任何草图转化为栩栩如生的逼真设计，以精确和真实的方式将您的概念变为生动的现实。"
        },
    },
    yearbook: {
        title: 'AI Yearbook photoshoot 📸',
        subTitle: "Not using many Selfie images. Get your 90s Yearbook photos with a single Selfie with our AI-photographer in seconds. Save 10x your time.",
        input: 'Tell me the type of flower you want to create?',
        inside: 'Indoor',
        outside: 'Outdoor',
        loveBy: '45,880+ happy designs already created',
        respectTitle: 'We respect your privacy',
        respectSub1: 'We do not use FaceID or require a lot of photos to perform. Just use a Selfie, similar to what you often post on Facebook and Instagram.',
        respectSub2: 'Your data is completely safe and secure on Google platform very high security.',
        howItWorks: 'How AI Yearbook Works',
        howItWorksSub: 'You take a selfie or use a clear ID photo, select your style & pose do headshots in seconds not hours!',
        unBlurryTitle: 'Photo upscaler',
        unBlurrySub: 'Remove blurs, enhance, and improve photo colors before posting on social media platforms like Facebook, Instagram, Tiktok.',
        useThisFeature: 'Create yours now',
        anotherFeatureTitle: 'Outstanding Features',
        anotherFeatureSub: 'Free, fast, and accurate. Try these features below.',
        feature1: 'Remove backgrounds',
        feature1Sub: 'Accurately remove any background.',
        feature2: 'Interior Design',
        feature2Sub: 'Interior design, exterior design, and sketching with genhomeai.com',
        feature3: 'Change Backgrounds (Coming soon)',
        feature3Sub: 'Change backgrounds for any object with REOK’s AI',
        hotFeatureTitle: 'Our AI Yearbook Photos',
        hotFeatureSub: 'These are AI-generated headshots.',
        pricingTitle: 'Pick your plan',
        pricingLabel: 'Pricing',
        pricingSub: '1 professional photo = 10 credits or less',
    },
    showcase: {
        title: '示例设计',
        status: {
            pending: '处理中',
            upscaling: '提升中',
            choosing: '选择中',
            finished: '已完成'
        },
        mode: {
            pro: '专业版',
            basic: '基础版'
        }
    },
    home: {
        title: '您的设计',
        hotColletion: "自定义风格",
        credits: '剩余设计次数',
        uploadTitle: '上传您的空间',
        uploadPlaceHolder: '拖放文件或点击上传',
        stylePlaceHolder: '选择',
        uploadPosePlaceHolder: '选择',
        uploadBGPlaceHolder: '选择',
        roomTitle: '选择房间类型（29种）',
        themeTitle: '选择房间风格（34种）',
        pro: {
            title: '适用于各种空间的创意设计',
            input: '描述您的空间...',
        },
        basic: {
            title: '使用专业版获得无限创意',
            chooseFlowerTitle: '空间',
            flowers: '客厅',
            styles: '风格',
            decoration: '装饰',
            events: '活动',
            backgrounds: '背景'
        },
    },
    profile: {
        title: '我的设计',
        before: '改造前 -',
        after: '改造后 -'
    },
    terms: {
        title: '条款和条件',
        content: ''
    },
    //Part 2
    privacy: {
        title: '隐私政策',
        one: '您的隐私对我们非常重要。decorify.pro的隐私政策尊重您的隐私，涉及我们可能从您在我们的网站decorify.pro以及我们拥有和运营的其他网站上收集的任何信息。',
        two: '我们只在真正需要为您提供服务时才请求个人信息。我们以公平和合法的方式收集信息，并征得您的知情同意。我们还会告诉您我们为什么收集这些信息以及如何使用。',
        three: '我们只在为您提供所请求的服务所需的时间内保留收集的信息。对于我们存储的数据，我们将采取商业上可接受的方式保护，以防止丢失和被盗，以及未经授权的访问、披露、复制、使用或修改。',
        four: '除非法律要求，否则我们不会公开或与第三方分享任何可识别个人身份的信息。',
        five: '我们的网站可能链接到不由我们运营的外部网站。请注意，我们无法控制这些网站的内容和做法，也不对它们各自的隐私政策承担责任或义务。',
        six: '您可以自由拒绝我们对您个人信息的请求，但请理解我们可能无法为您提供某些所需的服务。',
        seven: '您继续使用我们的网站将被视为接受我们围绕隐私和个人信息的做法。如果您对我们如何处理用户数据和个人信息有任何疑问，请随时与我们联系。',
        eight: '本政策自2023年9月15日起生效。'
    },
    refund: {
        moneyBack: '退款保证',
        title: '退款政策',
        one: '我们的目标是为所有客户提供高质量的服务和结果。我们理解，在某些情况下，输出可能不符合您的期望',
        two: '我们保证您的满意。如果您对使用Reok.pro生成的照片不满意，我们很乐意为您提供全额退款。或者，在某些情况下，我们可能会提供重新制作您的照片，以更好地满足您的期望。',
        three: '我们将根据此政策在1-3个工作日内审核并回复退款请求。如果您的退款获得批准，资金应在5-10个工作日内返回到您的卡上。退款将处理回购买时使用的原始支付方式；我们无法退款到不同的账户。',
        four: '我们只能处理在购买后30天内提交的退款请求，并且使用不超过20个积分。',
        five: '要申请退款，请发送电子邮件至hello@decorify.pro或使用我们网站上提供的聊天功能。在联系我们关于退款时，请包括您下订单时使用的电子邮件地址。',
        six: '感谢您选择Reok.pro制作您的头像照片。',
    },
    footer: {
        title: 'Decorify是一款AI驱动的应用程序，用于虚拟装修、改造、景观设计、室内设计等。',
        terms: '条款',
        privacy: '隐私政策',
        app: '应用',
        signin: '登录',
        signup: '注册',
        email: '电子邮件',
        chatNow: '立即聊天',
        partner: '合作伙伴',
        reportBug: '报告错误',
        require: '提交请求',
        page: '页面',
        tos: '法律',
        contact: '联系',
        other: '其他',
        affiliate: '联盟计划',
        yearbook: 'AI年鉴',
        blog: '博客',
        pricing: '定价',
        howItWorks: '工作原理',
        sendFeedback: '发送反馈'
    },
    tos: {
        agree: '新账户受我们的',
        one: '1. 简介',
        oneDetail: '使用decorify.pro即表示您同意并受这些条款和条件的约束。',
        two: '2. 同意条款',
        twoDetail: '本协议自您首次使用decorify.pro应用程序之日起生效。',
        three: '3. 功能',
        threeDetail: '订阅特定付费计划后，您将能够使用所有功能。可用功能的详细信息可在定价页面上找到。',
        four: '4. 退款政策',
        fourDetail: '我们允许在购买后14天内对失败的交易进行退款。请联系支持以请求退款。',
        five: '5. 产品使用',
        fiveDetail: '使用decorify.pro即表示您同意通过与您的Google账户关联的电子邮件或您用于注册账户的电子邮件接收来自decorify.pro的重要产品更新。您可以随时通过点击每封电子邮件底部的"取消订阅"链接选择不接收这些产品更新。我们只发送重要的产品更新。',
        six: '6. 免责声明',
        sixDetail: '我们不保证decorify.pro将满足您的要求或其运行将不间断或无错误。本协议中未明确规定的所有保证或条件（包括但不限于利润损失、数据丢失或损坏、业务中断或任何性质的其他金钱损失、损害、成本或费用）在法律允许的最大范围内被排除和否认。本协议不影响您作为消费者可能拥有的任何法定权利。',
        seven: '7. 保证和法律限制',
        sevenDetail: 'decorify.pro不对软件的质量、特定用途的适用性或其他特性或性能做出任何保证、担保或其他陈述。除非法律规定不能排除责任，否则decorify.pro不对因本协议或您使用decorify.pro而产生的或与之相关的任何利润损失或任何后果性、特殊、偶然或间接损害（无论如何产生，包括疏忽）承担责任。',
        eight: '8. 一般条款和法律',
        eightDetail: '您承认，使用这些服务不会在您和decorify.pro之间建立任何合资、合伙、雇佣或代理关系。您同意不将自己表现为decorify.pro的代表、代理人或员工。您同意，decorify.pro不对我们为防止或解决平台滥用而采取的任何行动向您负责。您承认，在为您提供decorify.pro的使用时，我们依赖于您同意受本协议条款的约束。',
    },
    faq: {
        title: '常见问题',
        q1: 'Decorify是如何工作的？',
        answer1: 'Decorify是一个基于网络的应用程序，允许您改造和重新设计家中的所有部分，包括室内、室外、花园、露台等。利用人工智能的力量，该应用每隔几秒钟就会生成新的概念，提供各种风格、模式、房间类型等，帮助您实现理想的效果。',
        q2: '确定能节省我的金钱和时间吗？',
        answer2: '是的，我们的应用为个人和专业用途提供了一个经济实惠的解决方案。无需聘请昂贵的专业人士，您就可以直观地看到您的家在不同的家具、装饰和后院设计下的样子，而无需浏览无尽的网站寻找灵感。我们的AI设计师分析您的偏好并生成个性化的建议，让您轻松地可视化并创造出令人惊叹的家居。',
        q3: '它可以在任何设备上运行吗？（笔记本电脑、MacOS、PC、iOS、Android）',
        answer3: '当然可以！您无需安装任何移动应用或桌面程序，只需在最常用的浏览器（Chrome、Firefox、Safari）中运行即可。',
        q4: '使用起来有多容易？',
        answer4: '只需上传您的图片，然后开始生成新的AI创意。',
        q5: '谁可以使用Decorify AI？',
        answer5: '我们的应用程序易于任何人使用，无论他们在室内、花园或建筑方面的知识或专业程度如何。它可以被寻求重新装修或建造的房主、需要新想法或即时结果的建筑师、寻找装饰建议的室内设计师甚至景观设计师使用。房地产经纪人和中介也使用我们的软件来增强他们的房源，更容易地销售房屋，并快速展示AI室内和室外创意。',
        q6: 'AI设计师如何提升我的家？',
        answer6: 'AI设计师为您的室内提供了丰富的可能性。您可以探索广泛的风格和装饰选项，创造一个和谐且视觉上吸引人的生活空间。',
        q7: '它可以处理建筑平面图和草图吗？',
        answer7: '当然可以！我们的软件设计用于处理建筑草图。无论您有手绘草图还是数字渲染图，您都可以轻松地将它们上传到我们的平台，然后将您的草图渲染成逼真的可视化效果。',
        q8: '它适用于在建的房屋/室内/室外吗？',
        answer8: '当然可以！我们的用户大量使用我们的软件来生成如何装饰他们在建的室内和室外（房间、房屋、后院）的想法。',
        q9: '它适用于虚拟装修和家居展示吗？',
        answer9: '当然可以！通过上传空房间或现有空间的照片，我们帮助您尝试虚拟装修。这对房地产专业人士、房主和Airbnb房屋服务来说都是一个非常有价值的工具。',
        q10: '你们有退款政策吗？',
        answer10: '是的！我们对我们的技术非常有信心，以至于我们提供全额退款保证。请查看我们的退款政策了解详情。'    },
    signin: {
        title: '登录',
        another: '或使用邮箱',
        emailPlaceHolder: '邮箱地址',
        passwordPlaceHolder: '密码',
        rememberTitle: '记住我',
        forgetPassword: '忘记密码？',
        noAccount: '还没有账户？'
    },
    signup: {
        title: '创建新账户或使用现有账户登录。',
        another: '或使用邮箱',
        firstNamePlaceHolder: '名',
        lastNamePlaceHolder: '姓',
        emailPlaceHolder: '邮箱地址',
        passwordPlaceHolder: '密码',
        rememberTitle: '记住我',
        forgetPassword: '忘记密码？',
        gotAccount: '已有账户？'
    },
    // Part 3
    forgetPassword: {
        title: '找回密码',
        content: '我们将向您发送一封密码重置邮件',
        emailPlaceHolder: '请输入您的邮箱',
        sendBtn: '发送',
        backSignin: '返回登录'
    },
    pricing: {
        yearlySubscription: '1000次设计',
        monthlySubscription: '100次设计',
        saveDesk: '节省10%',
        saveMobile: '购买1000次设计可节省10%',
        popular: "76%的用户选择此计划",
        free: {
            title: '免费积分',
            price: '0元',
            trialTitle: '注册码：请登录',
            joinTitle: '在此粘贴您的代码',
            one: '获得20个免费积分',
            two: '包含所有100积分功能',
            affiliateTitle: '推广计划',
            shareTitle: '与朋友分享👇',
            loginToShare: '请登录后分享'
        },
        basic: {
            title: '100积分',
            price: '7元',
            duration: '/终身',
            recommend: '适合个人',
            one: '20张专业照片',
            two: '去除背景',
            three: '提升至4K',
            four: '下载所有照片',
            five: '无限云存储',
            six: '照片商业用途',
            seven: '访问所有新功能',
            subscribed: '已购买'
        },
        pro: {
            title: '1000积分',
            subTitle: '（节省30%）',
            price: '49元',
            duration: '/终身',
            recommend: '咖啡店老板、企业、照相馆',
            one: '200张专业照片',
            two: '包含所有100积分功能',
            three: '节省您的资金',
            subscribed: '已购买'
        },
        credit: {
            title: '选择适合您需求的计划！'
        },
        first: {
            title: '入门版',
            price: '$19',
            priceDetail: '100次设计 - 无订阅',
            duration: '/终身',
            recommend: '1次设计 = 5积分',
            five: '使用基础AI设计师装修',
            credit: '500积分',
            two: '100次设计/购买',
            three: '解锁所有模式',
            one: 'AI家具移除',
            six: '商业许可',
            four: '草图到渲染',
            seven: '85+种房间类型可用',
            eight: '40+种风格可用',
            nine: '将任何设计提升至4K',
            ten: '下载高质量图片',
            eleven: '无限渲染存储',
            twelve: '抢先体验新功能',
            thirteen: '随时取消',
            fourteen: 'AI虚拟装修',
        },
        second: {
            title: '专业版',
            subTitle: '（节省85%）',
            price: '$29',
            priceDetail: '每月1,000次设计',
            duration: '/终身',
            recommend: '咖啡店老板、企业、照相馆',
            five: '始终使用最新AI设计师',
            credit: '5,000积分',
            two: '1,000次设计/月',
            three: '解锁所有模式',
            one: 'AI家具移除',
            six: '商业许可',
            four: '草图到渲染',
            seven: '85+种房间类型可用',
            eight: '40+种风格可用',
            nine: '将任何设计提升至4K',
            ten: '下载高质量图片',
            eleven: '无限渲染存储',
            twelve: '抢先体验新功能',
            thirteen: '随时取消',
            fourteen: 'AI虚拟装修',
            subscribed: '订阅'
        },
        third: {
            title: '专业年度版',
            subTitle: '（节省93%）',
            price: '$199',
            priceDetail: '每年12,000次设计',
            duration: '/终身',
            recommend: '咖啡店老板、企业、照相馆',
            five: '始终使用最新AI设计师',
            credit: '60,000积分',
            two: '12,000次设计/年',
            three: '解锁所有模式',
            one: 'AI家具移除',
            six: '商业许可',
            four: '草图到渲染',
            seven: '85+种房间类型可用',
            eight: '40+种风格可用',
            nine: '将任何设计提升至4K',
            ten: '下载高质量图片',
            eleven: '无限渲染存储',
            twelve: '抢先体验新功能',
            thirteen: '随时取消',
            fourteen: 'AI虚拟装修',
            subscribed: '订阅'
        },
        four: {
            title: '企业解决方案',
            subTitle: '（联系我们）',
            price: '0元',
            priceDetail: '0元/设计',
            duration: '/终身',
            recommend: '咖啡店老板、企业、照相馆',
            one: '高质量设计',
            two: '适合公司或远程团队',
            three: '节省时间和金钱',
            four: '几秒钟内完成设计',
            five: '无需传统拍摄',
            subscribed: '已购买',
        },
        currency: '元',
        perMonth: '/月',
        safePayment: '安全支付',
        qr: '或使用二维码'
    },
    milestoneMessage: {
        first: '已有更多满意的客户，共',
        second: '次设计已完成'
    },
    message: {
        success: {
            redering: '您的设计正在渲染中，请稍等片刻',
            rederingDone: 'AI已完成设计创作',
            activated: '激活成功！开始创建美丽满意的设计吧！',
            removingBG: '正在移除背景',
            upscale: '正在提升分辨率',
            resetPassword: '我们刚刚发送了一封重置密码的邮件。请查收！',
            signIn: '我们刚刚发送了一封激活账户的邮件。请查收！',
            reviewing: '我们已收到您的请求。将在一天内审核',
            remindReviewing: '此照片正在审核以获得新积分',
            removed: '成功移除照片',
            useTheTemplate: "正在使用模板",
            subscribed: "您已成功订阅！"
        },
        error: {
            selectPhotographer: '请选择您的AI设计师以开始',
            uploadPhoto: '请上传您的空间',
            chooseRoom: '请选择房间类型',
            chooseTheme: '请选择房间风格',
            fileOverBasic: '请上传小于5MB的文件，或升级到专业版以上传更大的文件',
            fileOverPro: '请上传小于10MB的文件',
            wrongFile: '无效文件，请尝试另一个文件',
            wrongFace: '请不要拍摄太近的自拍，请尝试另一张！',
            busy: '连接中断，请重试',
            gender: '请选择您的性别',
            age: '请选择您的年龄',
            roomType: '请选择类型',
            style: '请选择一种风格或自定义您的风格',
            numberOfResult: '请选择头像数量',
            outOfCredits: '您的积分已用完，请购买更多积分！',
            userNotFound: '未找到此账户。请正确输入您的账户！',
            wrongPassword: '密码错误！如果您忘记密码，请使用忘记密码功能。',
            accountExist: '账户已存在。请尝试登录此账户',
            tiktok: '出于Google用户安全原因，请使用Chrome或Safari浏览器。访问decorify.pro以访问。',
            reviewing: '您无法移除此照片，因为它正在审核中',
            background: '请选择您的背景',
            pose: '请选择您的姿势',
            signUpReCaptcha: '注册前请完成reCAPTCHA验证。',
            emptyBG: '您的背景为空，请输入！',
            tooShortBG: '您的背景应长于2个词。',
            tooLongBG: '您的背景太长。',
            badWordsBG: '您的背景包含不适当的词语。请尝试另一个',
            emptyStyle: '您的风格为空，请输入！',
            tooShortStyle: '您的风格应长于2个词。',
            tooLongStyle: '您的风格太长。',
            badWordsStyle: '您的风格包含不适当的词语。请尝试另一个',
            emptyEmail: '请输入您的邮箱',
            validEmail: '请输入有效的邮箱地址',
            subscribeError: '抱歉，我们无法为您订阅。'
        },
        warning: {
            runOutBasicCredits: '您已用完基础计划中的所有积分，请购买更多积分',
            runOutProCredits: '请购买更多积分以使用 ^^',
            selectPose: '请在选择姿势之前选择您的性别',
            aiVirtualStaging: '切换到AI虚拟家居',
            aiFurnitureRemoval: '正在切换到AI家具移除。离开前请下载图片，因为移除历史不会被保存。',
            upscale: '切换到提升分辨率',
        },
        prompt: {
            professional: '专业照片',
            removeBG: '移除背景',
            upscale: '提升分辨率',
            rendering: 'AI渲染中',
            faceNotFound: '您的脸部太近，请尝试另一张自拍！',
            inReview: '审核中',
            styles: {
                business: '专业商务照片',
                realEstate: '专业房地产经纪人照片',
                carShowroom: '专业汽车销售人员照片',
                doctor: '专业医生照片',
                yearbook: '专业90年代年鉴照片',
                wedding: '专业婚礼照片',
                halloween: '万圣节照片'
            },
        },
        maintain: {
            backsoon: '我们正在维护此功能，很快就会恢复。请暂时使用其他功能。谢谢！💗💗💗'
        }
    },   
    // Part 4 
    app : {
        menu: {
            creditTitle: '您的积分',
            affiliateTitle: '联盟计划',
            affiliateSub: '获得您推荐给我们的所有客户付款的30%佣金！',
            professionalMode: 'AI家居设计',
            removeBGMode: '家具移除',
            unBluryMode: '提升设计',
            myPhotos: '我的设计',
            aiVirtualStaging: "AI虚拟装修"
        },
        tryThese: '没有图片？试试这些：',
        goodSelfie: '好的自拍',
        badSelfie: '不好的自拍',
        trick: '使用提升功能获得更好的质量',
        removeBGSub: '使用AI快速、简单、准确地移除背景。',
        professionalMode: {
            title: '使用AI装饰任何空间',
            refundTitle: '我们的AI一直在学习以获得更好的结果 💓',
            creditTitle: '1张照片 = 10积分',
            genderTitle: '选择类型',
            ageTitle: '选择您的年龄',
            styleTitle: '选择风格或自定义',
            quantityTitle: '设计数量',
            poseTitle: '选择/上传姿势',
            backgroundTitle: '选择/自定义背景',
            skipPose: '跳过以保持原始姿势',
            defaultPose: '默认',
            uploadedPose: '已上传',
            defaultBackground: '背景',
            defaultStyle: '风格',
            headshotsTab: '头像',
            selectPhotographer: '选择您的AI设计师',
            selectPhotographerSub: '请选择一个适合您需求的设计师',
            requirements: '自定义',
            guideSub: '按照设计师要求操作 👇',
            creativity: "AI创意",
            customize: "✍️ 自定义（测试版）",
            enOnlyCustomize: "✍️ 自定义（仅英文版）",
            interiorsTitle: "室内",
            exteriorsTitle: "室外",
            gardensTitle: "花园",
        },
        upscaleMode: {
            title: '使用AI快速准确地去除模糊并增强您的照片。',
        }
    },
    myDesign: {
        title: '我的设计',
        scrollTitle: '滚动查看您的所有设计。',
    },
    imageComponent: {
        compare: '查看',
        download: '下载',
        removeObjects: '家具移除',
        upscale: '提升',
        virtualHome: '装饰布置',
        errorPhoto: '更新不良照片'
    },
    results: {
        one: '1个设计',
        four: '4个设计',
    },
    genders: {
        man: '男性',
        woman: '女性'
    },
    styles: {
        "modern": "现代",
        "minimalist": "极简",
        "professional": "专业",
        "tropical": "热带",
        "coastal": "海岸",
        "vintage": "复古",
        "neoclassic": "新古典",
        "tribal": "部落",
        "industrial": "工业",
        "bohemian": "波西米亚",
        "contemporary": "当代",
        "farmhouse": "农舍",
        "scandinavian": "北欧",
        "skichalet": "滑雪小屋",
        "artdeco": "装饰艺术",
        "biophilic": "亲生物",
        "christmas": "圣诞",
        "tetvn": "农历新年",
        "midautumn": "中秋",
        "halloween": "万圣节",
        "easter": "复活节",
        "baroque": "巴洛克",
        "cottagecore": "田园核心",
        "japanesestyle": "日式",
        "rustic": "乡村",
        "artnouveau": "新艺术",
        "maximalist": "极繁主义",
        "coffee": "咖啡",
        "medieval": "中世纪",
        "frenchcountry": "法国乡村",
        "vaporwave": "蒸汽波",
        "hotpink": "热粉",
        "gaming": "游戏",
        "cyberpunk": "赛博朋克",
        dating: "约会",
        other: "其他",
        custom: {
            title: '自定义风格',
            btn: '开始自定义',
            dialogTitle: '您的风格和服装',
            dialogSubTitle: '告诉我们您的风格、服装、年龄，我们的AI摄影师将尝试帮助您呈现出来！',
            upgrade: '升级此功能',
            example: '一套经典西装不打领带，26岁，取决于您想要的...',
        },
        "cyberpunk_style_bathroom": "赛博朋克风格",
        "eclectic_style_coworking_space": "折衷风格",
        "maximalist_style_bedroom": "极繁主义风格",
        "contemporary_style_hotel_bedroom": "当代风格",
        "zen_style_bedroom": "禅风格",
        "cyberpunk_style_outdoor_patio": "赛博朋克风格",
        "midcentury_modern_style_pool": "中世纪现代风格",
        "easter_style_drop_zone": "复活节风格",
        "coastal_style_kitchen": "海岸风格",
    },
    backgrounds: {
        coffeeShop: "咖啡店",
        office: "办公室",
        cityStreet: "城市街道",
        factory: "工厂",
        garden: "花园",
        carShowroom: "汽车展厅",
        bookShelf: "书架",
        hotel: "酒店",
        goldenGate: "金门大桥",
        snow: "雪景",
        realtor: "房地产经纪人",
        brick: "砖墙",
        custom: {
            title: '背景',
            btn: '开始自定义',
            dialogTitle: '您的背景',
            dialogSubTitle: '告诉我们您想要的背景，我们的AI摄影师将尝试帮助您呈现出来！',
            upgrade: '升级此功能',
            example: '一家咖啡店，取决于您想要的地方...',
        },
    },
    removeTitle: '删除此照片',
    removeSub: '您确定要删除此照片吗？此照片将被永久删除。此操作无法撤消。',
    renewTitle: '更新此照片',
    renewSub: '我们将审核您的请求。如果此照片有问题，它将被永久删除。您将获得10个新积分。',
    reasonTitle: '告诉我们您为什么要更新它？',
    reasonEx: '它不正式...',
    photographers: {
        sam: {
            name: "Sam，👋 您好，顾客",
            title: "我擅长风格",
            intro: "我能做什么？",
            intro1: "几秒钟内创造任何风格和氛围",
            intro2: "房地产、艺术和博客照片",
            intro3: "室内和室外设计",
            intro4: "用家具填满空房间",
            intro5: "逼真的渲染图"
        },
        david: {
            name: "David，👋 您好，顾客",
            title: "我擅长草图",
            intro: "我能做什么？",
            intro1: "几秒钟内将任何草图转化为逼真的照片",
            intro2: "草图到任何设计",
            intro3: "房屋设计",
            intro4: "建筑设计",
            intro5: "室内设计"
        },
        annie: {
            name: "Annie，👋 您好，顾客",
            title: "我擅长改造",
            intro: "我能做什么？",
            intro1: "保留窗户、天花板和门。",
            intro2: "用家具填满房间",
            intro3: "房屋设计",
            intro4: "建筑设计",
            intro5: "室内设计"
        }
    },
    roomsList: {
        "living_room": "客厅",
        "bedroom": "卧室",
        "dining_room": "餐厅",
        "bath_room": "浴室",
        "study_room": "书房",
        "gaming_room": "游戏室",
        "meeting_room": "会议室",
        "kitchen": "厨房",
        "toilet": "卫生间",
        "coworking_space": "共享工作空间",
        "fitness_gym": "健身房",
        "mudroom": "玄关",
        "walk_in_closet": "步入式衣柜",
        "office": "办公室",
        "hotel_room": "酒店房间",
        "hotel_lobby": "酒店大堂",
        "hotel_bathroom": "酒店浴室",
        "exhibition_space": "展览空间",
        "house_exterior": "房屋外观",
        "attic": "阁楼",
        "onsen": "温泉",
        "drop_zone": "收纳区",
        "workshop": "工作室",
        "nail": "美甲店",
        "restaurant": "餐厅",
        "coffee_shop": "咖啡店",
        "clothing_store": "服装店",
        "family_room": "家庭活动室",
        "kids_room": "儿童房",
        "balcony": "阳台",
        "wine_cellar": "酒窖",
        "rooftop_terrace": "屋顶露台",
        "sunroom": "阳光房",
        "home_spa": "家庭水疗",
        "craft_room": "手工室",
        "dressing_room": "化妆间",
        "guest_bedroom": "客房",
        "home_bar": "家庭酒吧",
        "library": "图书馆",
        "art_studio": "艺术工作室",
        "yoga_studio": "瑜伽室",
        "photo_studio": "摄影工作室",
        "multimedia_room": "多媒体室",
        "auditorium": "礼堂",
        "medical_exam_room": "医疗检查室",
        "reception_area": "接待区",
        "music_room": "音乐室",
        "science_laboratory": "科学实验室",
        "home_theater": "家庭影院",
        "wedding_room": "婚礼房间",
        "laundry_room": "洗衣房",
        "outdoor_kitchen": "户外厨房",
        "utility_room": "杂物间",
        "pet_room": "宠物房",
        "home_gym": "家庭健身房",
        "lounge": "休息室",
        "play_room": "游戏室",
        "reading_nook": "阅读角",
        "sauna": "桑拿房",
        "man_cave": "男士洞穴",
        "foyer": "门厅",
        "green_house": "温室",
        "she_shed": "她的小屋",
        "conservatory": "温室",
        "nursery": "婴儿房",
        "prayer_room": "祈祷室",
        "side_of_house": "房屋侧面",
        "front_of_house": "房屋正面",
        "back_of_house": "房屋背面",
        "backyard": "后院",
        "patio": "露台",
        "terrace": "阳台",
        "front_yard": "前院",
        "garden": "花园",
        "court_yard": "庭院",
        "pool_area": "泳池区",
        "porch": "门廊",
        "playground": "游乐场"
    },
    giveAway: {
        title: '所有套餐9折优惠',
        subTitle: '使用我们的应用获取新功能和未来赠品通知 👇',
        enterYourEmail: '输入您的邮箱...',
        notifyMe: '立即获取 ↗',
        note: '我们只发送重要的应用更新。绝不发送垃圾邮件！随时可以取消订阅。',
        submitting: '正在提交...',
        emailSent: '邮件已发送。',
        emailSentSub: '请查看您的邮箱并开始使用Decorify！',
        emailSentTitle: '太棒了！您已成功加入！',
    },
    feedback: {
        title: '我们感谢您的反馈！',
        subTitle: '请给我们反馈如何改进。您将有机会赢得100积分！',
        enterYourFeedback: '告诉我们您的想法...',
        notifyMe: '立即发送 ↗',
        note: '我们只发送重要的应用更新。不发送垃圾邮件！随时取消订阅。',
        submitting: '提交中...',
        sent: '我们已收到您的反馈 ✅',
        sentSub: '我们将审查您的反馈并尽快回复！',
        type: {
            bug: '错误报告',
            feature: '功能请求',
            feedback: '其他反馈',
            refund: '退款请求',
        },
        emptyFeedback: '请输入您的想法！',
        emptyFeedbackType: '请选择反馈类型！',
    }
}

export default zh;
