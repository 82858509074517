import React from 'react';

const Spinner = () => {
  return (
    <div className="showbox">
    <div className="logo-loader-wrapper">
      {/* <p/><p/><p/><p/> */}
      <img src='/images/airoomdecor-full.webp' width={140} className=''/>
    </div>
  </div>
  );
};

export default Spinner;
