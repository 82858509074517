import React, { useEffect, useState } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Gradient } from '../AdminHeader/Gradient.js';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import BeforeAfterTestimonial from '../BeforeAfterTestimonial/index.js';
import LovedBy from '../LovedBy/index.js';

const gradient = new Gradient();

const RefundPolicy = () => {
  const [currentLanguage, setCurrentLanguage] = useState('');

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    gradient.initGradient('#canvas-gradient');
    window.scrollTo(0, 0);

    return () => { };
  }, []);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <>
      <main style={{ background: '#000' }}>
      <div className="landing-cover" data-controller="gradient-animation">
          <div className="container">
            <div className="d-flex flex-column align-items-center">
              <h1 className="display-1 fw-semi-bold text-white text-center mb-5 mb-sm-4">{t('refund.title')}</h1>
              <Link
                to={currentLanguage === 'en' ? '/' : `/${currentLanguage}/`}
                className="btn btn-soft-light btn-sm rounded-pill tag text-nowrap"
              >
                <HomeOutlined />
                {t('button.backHome')}
              </Link>
            </div>
          </div>
          <canvas id="canvas-gradient" data-transition-in />
          <div id="canvas-overlay" />
        </div>
      </main>
      {/* <div className="page-header mb-0 page-header-reset mb-4" /> */}
      <section className="bg-neutral-black text-gray-300">
        <div className="terms">
          <div className="container mx-auto">
            <p className="mb-4">
              This Refund Policy outlines the terms and conditions for refunds on Decorify's services. By using Decorify, you agree to this policy.
            </p>

            <h2 className="text-2xl font-bold mb-2">1. Eligibility for Refunds</h2>

            <p className="mb-4">
              Decorify offers refunds under specific circumstances. To be eligible for a refund, you must meet the following criteria:
            </p>

            <ul className="list-disc list-inside mb-4" style={{ listStyleType: 'disc', paddingLeft: '1.5em' }}>
              <li>Request made within 14 days of purchase</li>
              <li>Service not used or accessed</li>
              <li>Valid reason for dissatisfaction provided</li>
            </ul>

            <h2 className="text-2xl font-bold mb-2">2. Refund Process</h2>

            <p className="mb-4">
              To request a refund:
            </p>

            <ol className="list-decimal list-inside mb-4" style={{ listStyleType: 'decimal', paddingLeft: '1.5em' }}>
              <li>Contact our support team at support@decorify.pro</li>
              <li>Provide your order number and reason for the refund</li>
              <li>Allow up to 5-10 business days for processing</li>
            </ol>

            <h2 className="text-2xl font-bold mb-2">3. Non-Refundable Items</h2>

            <p className="mb-4">
              The following are not eligible for refunds:
            </p>

            <ul className="list-disc list-inside mb-4" style={{ listStyleType: 'disc', paddingLeft: '1.5em' }}>
            <li>Services where more than 20 credits have been used</li>
            </ul>

            <h2 className="text-2xl font-bold mb-2">4. Partial Refunds</h2>

            <p className="mb-4">
              In some cases, partial refunds may be granted at Decorify's discretion, particularly for subscription-based services.
            </p>

            <h2 className="text-2xl font-bold mb-2">5. Refund Method</h2>

            <p className="mb-4">
              Refunds will be issued to the original payment method used for the purchase.
            </p>

            <h2 className="text-2xl font-bold mb-2">6. Cancellation of Recurring Subscriptions</h2>

            <p className="mb-4">
              You may cancel recurring subscriptions at any time. Refunds for unused portions of the current billing cycle are not provided.
            </p>

            <h2 className="text-2xl font-bold mb-2">7. Exceptions</h2>

            <p className="mb-4">
              Decorify reserves the right to make exceptions to this policy on a case-by-case basis.
            </p>

            <h2 className="text-2xl font-bold mb-2">8. Changes to Refund Policy</h2>

            <p className="mb-4">
              We may update this Refund Policy from time to time. Any changes will be posted on our website with an updated "Last Updated" date.
            </p>

            <h2 className="text-2xl font-bold mb-2">9. Contact Us</h2>

            <p className="mb-4">
              If you have any questions about this Refund Policy, please contact us at support@decorify.pro.
            </p>

            <p className="mb-4" style={{ fontWeight: '700' }}>
              Last Updated: Mon, Aug 26, 2024
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="relative bg-neutral-black">
          <div className="flex flex-col align-items-center section-top-90">
            <BeforeAfterTestimonial />
            <div className="getstarted-btn-wrapper mt-4">
              <Link to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`} className="upgrade-button mt-4">
                {t('button.try')}
                <span style={{ marginLeft: '4px' }}>↗</span>
              </Link>
            </div>
            <div className="text-white mt-4">
              <LovedBy t={t} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RefundPolicy;
