import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import FAQ from '../FAQ';
import BeforeAfter from '../BeforeAfter';
import PricingLandingPage from '../PricingLandingPage';
import Testimonial from '../Testimonial';
import LovedBy from '../LovedBy';
import BeforeAfterTestimonial from '../BeforeAfterTestimonial';

const AdsLanding = ({ handleSubmitWithGoogle }) => {
  const [currentLanguage, setCurrentLanguage] = useState('');
  const [imageIndex, setImageIndex] = useState(0);

  const urls = [
    'https://firebasestorage.googleapis.com/v0/b/decorifyai.appspot.com/o/landingpage%2F1.webp?alt=media&token=f34a08f5-4fad-4985-86a5-688cf88c6224',
    'https://firebasestorage.googleapis.com/v0/b/decorifyai.appspot.com/o/landingpage%2F2.webp?alt=media&token=5c72d196-c579-42d8-9aac-ee371e645700',
    'https://firebasestorage.googleapis.com/v0/b/decorifyai.appspot.com/o/landingpage%2F3.webp?alt=media&token=dead1e95-35d9-477f-8bbc-323ad0b48c9a',
    'https://firebasestorage.googleapis.com/v0/b/decorifyai.appspot.com/o/landingpage%2F5.webp?alt=media&token=4bd9ccc6-e967-47c5-abf5-359a576a11f2',
    'https://firebasestorage.googleapis.com/v0/b/decorifyai.appspot.com/o/landingpage%2F6.webp?alt=media&token=85b265a9-51d7-4d61-89b2-df4daa02f1b1',
    'https://firebasestorage.googleapis.com/v0/b/decorifyai.appspot.com/o/landingpage%2F8.webp?alt=media&token=ee14f7ef-849e-4ee9-b0f2-850744b1d7d4',
  ];

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  // const userPreferredLanguage = navigator.language || navigator.userLanguage;

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const refParam = urlSearchParams.get('ref');
    const affParam = urlSearchParams.get('aff');

    if (refParam) {
      localStorage.setItem('refCode', refParam);
      // You can perform further actions with the referral code here
    } else {
      localStorage.setItem('refCode', '');
    }

    if (affParam) {
      localStorage.setItem('affCode', affParam);
      // You can perform further actions with the referral code here
    } else {
      localStorage.setItem('affCode', '');
    }

    const redirect = urlSearchParams.get('r');

    if (redirect) {
      localStorage.setItem('redirect', redirect);
      // You can perform further actions with the referral code here
    } else {
      localStorage.setItem('redirect', '');
    }

    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImageIndex(prevIndex => (prevIndex + 1) % urls.length);
    }, 3000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <main className='bg-black'>
        <img src="/images/night-background.webp" alt="decorify" className="absolute inset-0 object-cover object-bottom w-full h-full" />
        <div className="landing-cover-header" data-controller="gradient-animation">
          <Row gutter={12}>
            <Col lg={12} md={24} xs={24} className="landing-order-first">
              <div className="banner-block banner-block-copy-d123-0-2-395">
                <div className="left-ads-landing-wrapper">
                  <div>
                    <h1 className="text-root-0-2-141 text-landing-title banner-landing-title banner-landing-block-title display-title-landing fw-bold text-white">
                      {t('landing.trialTitle')}
                    </h1>
                  </div>
                  <div className="top1-title" style={{ maxWidth: '490px' }}>
                    <span style={{ color: '#adb5bd' }}>{t('landing.trialSubTitle')}</span>
                  </div>
                  <div>
                    <div className=" media-content media-root-d6-0-2-411 banner-block-media-0-2-88 banner-block-media-d128-0-2-400">
                      <div data-testid="image-root" className="base-image-content">
                        <div className="before-after-landing-wrapper">
                          <div className="ads-video-slider">
                            <img loading="lazy" className="ads-video-landing" alt="decorify" width={367} height={313} src={urls[imageIndex]} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="relative mx-auto mt-6 max-w-lg space-y-4">
                    <div className="flex items-center gap-3">
                      <div className="flex -space-x-3 overflow-hidden">
                        <img
                          src="/images/customer13.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                          width={40}
                          height={40}
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer16.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                          width={40}
                          height={40}
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer17.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                          width={40}
                          height={40}
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer11.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                          width={40}
                          height={40}
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer19.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                          width={40}
                          height={40}
                        />
                      </div>{' '}
                      <div className="flex" style={{ color: '#facc15' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </div>
                    </div>{' '}
                    <p className="text-sm text-gray-500">{t('landing.loveBy')}</p>
                  </div>
                  <div className="banner-block-ctaHolder-d126-0-2-398">
                    <div className="upgrade-btn-wrapper">
                      <Link to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`} className="upgrade-button mt-4">
                                                {t('button.try')}
                        <span style={{marginLeft: "4px"}}>
                          <svg fill="#fff" width="22px" height="22px" viewBox="0 0 0.66 0.66" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.493 0.183A0.033 0.033 0 0 0 0.477 0.167L0.468 0.165h-0.275a0.028 0.028 0 0 0 0 0.055h0.209L0.173 0.448a0.028 0.028 0 0 0 0 0.039 0.028 0.028 0 0 0 0.039 0L0.44 0.259v0.209a0.028 0.028 0 0 0 0.055 0v-0.275L0.493 0.182"/>
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </div> */}
                </div>
              </div>
            </Col>
            <Col lg={12} md={24} xs={24} className="landing-order-second">
              <div className="sign_in-container mb-10">
                <div className="dark-login-placeholder">
                  <div className="login-form-container pt-5 pb-5">
                    <div>
                      <h1 className="auth-panel-title" style={{ color: '#fff' }}>
                        {t('signup.title')}
                      </h1>
                      <a href="#" className="link-block-6 w-inline-block signup-button" onClick={e => handleSubmitWithGoogle(e, t)}>
                        <div>
                          <img src="fonts/6373e8bb67056287b1380aa8_image%206.svg" loading="lazy" alt="" className="signup-button" />
                        </div>
                        <p className="body-1-semi-bold ml-12 signup-button" style={{ color: '#fff' }}>
                          {t('button.googleSignup')}
                        </p>
                      </a>
                    </div>
                    <div className="forget-passwrod-form w-form">
                      <form id="email-form" name="email-form" data-name="Email Form">
                        <div className="_2nd-form" style={{ color: '#fff' }}>
                          <p className="agree-service" style={{ color: '#c2c2c2' }}>
                            {t('tos.agree')}
                            <Link
                              className="agree-service"
                              style={{ color: '#c2c2c2' }}
                              to={currentLanguage === 'en' ? '/tos' : `/${currentLanguage}/tos`}
                            >
                              {t('footer.terms')}
                            </Link>{' '}
                            &{' '}
                            <Link
                              className="agree-service"
                              style={{ color: '#c2c2c2' }}
                              to={currentLanguage === 'en' ? '/privacy' : `/${currentLanguage}/privacy`}
                            >
                              {t('footer.privacy')}
                            </Link>
                          </p>
                          <LovedBy t={t} />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="flex items-center justify-center text-center relative">
            <span className="text-gray-500">as seen on</span>
            <img className="tiktok-seen-on" loading="lazy" alt="decorify" src="images/tiktok.webp" />
            <img className="facebook-seen-on" loading="lazy" alt="decorify" src="images/facebook.svg" />
            <img className="instagram-seen-on" loading="lazy" alt="decorify" src="images/instagram.webp" />
            <img className="linkedin-seen-on" loading="lazy" alt="decorify" src="images/linkedin.png" />
            <img className="x-seen-on" loading="lazy" alt="decorify" src="images/x.webp" />
            <img className="reddit-seen-on" loading="lazy" alt="decorify" src="images/reddit.webp" />
          </div>
        </div>
      </main>
      <main>
        <section>
          <div className="relative bg-neutral-black section-bottom-90">
            <div className="flex flex-col align-items-center">
              <BeforeAfterTestimonial />
              <div className="getstarted-btn-wrapper mt-4">
                <Link to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`} className="upgrade-button mt-4">
                  {t('button.try')}
                  <span style={{ marginLeft: '4px' }}>↗</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AdsLanding;
