import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    const acceptedCookies = localStorage.getItem('cookiesAccepted');
    if (!acceptedCookies) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowBanner(false);
  };

  return (
    showBanner && (
      <div className="cookie-wrapper" aria-live="polite" aria-atomic="true">
        <div className="cookie-text" role="dialog">
          <span id="cookie-consent-description">
            We use cookies to ensure that you get the best experience.{' '}
            <a
              href="/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="underline text-blue-400 hover:text-blue-600 learn-more-title"
              aria-label="Learn more about our cookie policy"
            >
              Learn more
            </a>
          </span>
        </div>
        <div className="cookie-buttons">
          <button onClick={handleAccept} className='accept-cookie-btn' aria-label="Accept cookies">Accept</button>
        </div>
      </div>
    )
  );
};

export default CookieConsent;
